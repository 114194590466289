import { defineStore } from 'pinia'

import { client } from '@/services/request'

export const useStoreEngagements = defineStore('storeEngagements', {
  state: () => {
    return {}
  },

  actions: {
    async createEngagementContactWhatsApp(listingUuid) {
      const url = `engagements/listing/${listingUuid}/contact/whatsapp/`
      try {
        await client.authOrAnonRequest(url, { method: 'POST', raiseErrors: true })
      } catch (e) {
        this.$rollbar.error(e)
      }
    },

    async createEngagementContactEmail(listingUuid) {
      const url = `engagements/listing/${listingUuid}/contact/email/`
      try {
        await client.authOrAnonRequest(url, { method: 'POST', raiseErrors: true })
      } catch (e) {
        this.$rollbar.error(e)
      }
    },

    async createEngagementContactPhone(listingUuid) {
      const url = `engagements/listing/${listingUuid}/contact/phone/`
      try {
        await client.authOrAnonRequest(url, { method: 'POST', raiseErrors: true })
      } catch (e) {
        this.$rollbar.error(e)
      }
    },

    async createEngagementHostListingLink(listingUuid) {
      const url = `engagements/listing/${listingUuid}/host-listing-link/`
      try {
        await client.authOrAnonRequest(url, { method: 'POST', raiseErrors: true })
      } catch (e) {
        this.$rollbar.error(e)
      }
    },

    async createEngagementContactCompanyWhatsApp(companyUuid) {
      const url = `engagements/company/${companyUuid}/contact/whatsapp/`
      try {
        await client.authOrAnonRequest(url, { method: 'POST', raiseErrors: true })
      } catch (e) {
        this.$rollbar.error(e)
      }
    },

    async createEngagementContactCompanyEmail(companyUuid) {
      const url = `engagements/company/${companyUuid}/contact/email/`
      try {
        await client.authOrAnonRequest(url, { method: 'POST', raiseErrors: true })
      } catch (e) {
        this.$rollbar.error(e)
      }
    },

    async createEngagementContactCompanyPhone(companyUuid) {
      const url = `engagements/company/${companyUuid}/contact/phone/`
      try {
        await client.authOrAnonRequest(url, { method: 'POST', raiseErrors: true })
      } catch (e) {
        this.$rollbar.error(e)
      }
    },

    async createEngagementHostCompanyLink(companyUuid) {
      const url = `engagements/company/${companyUuid}/host-company-link/`
      try {
        await client.authOrAnonRequest(url, { method: 'POST', raiseErrors: true })
      } catch (e) {
        this.$rollbar.error(e)
      }
    },
  }
})
