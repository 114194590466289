<template>
  <q-tab-panel>
    <div class="row">
      <div class="col-10">
        <div v-if="listingImageInfo.total_images > 0">
          <div class="text-subtitle1">
            There are a total of
            <b>{{ listingImageInfo.total_images }}</b> images of which
            <b>{{ listingImageInfo.published_images }}</b>
            are published.
          </div>

          <div class="text-subtitle1">
            There are {{ listingImageInfo.total_images - listingImageInfo.published_images || 0 }} unpublished images.
          </div>

          <div class="text-subtitle2 text-grey-7">
            Although you can upload unlimited images, your license allows for a maximum of {{
              listingImageInfo.publish_limit || 0 }} <b><u>published</u></b> images per listing.
          </div>
        </div>
        <div v-else class="text-center text-h6 text-grey">
          There are no images to display.
        </div>
      </div>
      <q-separator vertical class="q-mr-md" />
      <div class="col self-center">
        <q-btn class="rb-host-buttons__submit" @click="showUploadImageDialog = true">Upload Image</q-btn>
      </div>
    </div>
    <q-separator v-if="listingImages" class="q-my-md" />
    <div class="fit row wrap items-start content-start" v-for="(row, index) in chunkedImages" :key="index">
      <div class="col-4" v-for="image in row" :key="image.uuid">
        <q-card class="q-ma-sm">
          <q-card-section>
            <q-img @click="openCarousel(image)" @error="imageErrors[image.uuid] = true" fit="contain"
              :src="image.signed_url" spinner-color="primary" spinner-size="82px" style="height: 200px">
              <template v-slot:error>
                <span class="text-h6 text-grey-8">Could not load this image</span>
                <br />
                <br />
                <span class="text-body2 text-grey-8">
                  <b>Please try refreshing the page.</b>
                  <br /><br />
                  If the error persists, this image is probably corrupted.
                  Please delete and re-upload the image.
                </span>
              </template>
            </q-img>
          </q-card-section>

          <q-separator inset />

          <q-card-actions class="justify-center">
            <q-btn class="rb-host-buttons__deactivate" @click="publishImage(image)" :disabled="isPublishing"
              :loading="isPublishing" :color="image.is_published ? 'orange-8' : 'primary'">
              <template v-if="image.is_published">
                Unpublish
              </template>
              <template v-else>
                Publish
              </template>
            </q-btn>
            <q-btn class="rb-host-buttons__delete" @click="openDeleteImageDialog(image)" :disabled="isPublishing"
              :loading="isPublishing">Delete</q-btn>
          </q-card-actions>
        </q-card>
      </div>
    </div>
    <AddImagesDialog />
    <ConfirmDeleteImageDialog />

    <q-dialog v-model="carousel" @keyup="handleKeyPress($event)">
      <q-carousel swipeable animated arrows transition-prev="slide-right" transition-next="slide-left" v-model="slide"
        thumbnails fullscreen control-color="primary" padding class="bg-white shadow-1 rounded-borders">
        <q-carousel-slide v-for="(image, index) in listingImages" :key="image.uuid" :name="index"
          :img-src="image.signed_url">
          <!-- <q-img :src="image.signed_url" :ratio="16 / 9" /> -->
        </q-carousel-slide>
        <template v-slot:control>
          <q-carousel-control position="bottom-right" :offset="[18, 18]">
            <q-btn push round dense color="white" text-color="primary" icon="fullscreen_exit"
              @click="carousel = null" />
          </q-carousel-control>
        </template>
      </q-carousel>
    </q-dialog>

  </q-tab-panel>
</template>


<script setup>
import _ from 'lodash'

import { computed, onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'

import { useImages } from '@/composables/images'
import AddImagesDialog from '@/components/dialogs/AddImagesDialog.vue'
import ConfirmDeleteImageDialog from '@/components/dialogs/ConfirmDeleteImageDialog.vue'
import { notifyHostUpdateValueError } from '@/notifications/events'

const route = useRoute()

const {
  fetchListingImages,
  fetchListingImageInfo,
  updateListingImage,
  listingImageInfo,
  listingImages,
  showUploadImageDialog,
  isPublishing,
  showDeleteImageDialog,
  imageToDelete,
} = useImages()

onMounted(async () => {
  await fetchListingImages(route.params.uuid)
  await fetchListingImageInfo(route.params.uuid)
})

const imageErrors = ref({})
const carousel = ref(null)
const slide = ref(null)

const chunkedImages = computed(() => {
  return _.chunk(listingImages.value, 3)
})

const openCarousel = (image) => {
  carousel.value = true
  // Set the slide based on index of image
  const index = listingImages.value.findIndex((item) => {
    if (item.uuid === image.uuid) {
      return true
    }
  })

  slide.value = index
}

const handleKeyPress = (event) => {
  if (event.code === 'ArrowLeft') {
    slide.value = Math.max(slide.value - 1, 0)
  } else if (event.code === 'ArrowRight') {
    slide.value = Math.min(slide.value + 1, listingImages.value.length - 1)
  }
}

const publishImage = async (image) => {
  const form = {
    is_published: !image.is_published
  }
  isPublishing.value = true
  const resp = await updateListingImage(route.params.uuid, image.uuid, form)

  if (resp.status === 200) {
    await fetchListingImages(route.params.uuid)
    await fetchListingImageInfo(route.params.uuid)
  } else if (resp.status === 400 && resp.data.is_published) {
    notifyHostUpdateValueError(resp.data.is_published[0])
  }

  isPublishing.value = false
}

const openDeleteImageDialog = (image) => {
  imageToDelete.value = image
  showDeleteImageDialog.value = true
}
</script>
