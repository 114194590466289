import { useStoreEngagements } from '@/stores/storeEngagements'

export function useEngagements() {
  const storeEngagements = useStoreEngagements()

  const createEngagementContactWhatsApp = async (listingUuid) => {
    return await storeEngagements.createEngagementContactWhatsApp(listingUuid)
  }

  const createEngagementContactEmail = async (listingUuid) => {
    return await storeEngagements.createEngagementContactEmail(listingUuid)
  }

  const createEngagementContactPhone = async (listingUuid) => {
    return await storeEngagements.createEngagementContactPhone(listingUuid)
  }

  const createEngagementHostListingLink = async (listingUuid) => {
    return await storeEngagements.createEngagementHostListingLink(listingUuid)
  }

  const createEngagementContactCompanyWhatsApp = async (companyUuid) => {
    return await storeEngagements.createEngagementContactCompanyWhatsApp(companyUuid)
  }

  const createEngagementContactCompanyEmail = async (companyUuid) => {
    return await storeEngagements.createEngagementContactCompanyEmail(companyUuid)
  }

  const createEngagementContactCompanyPhone = async (companyUuid) => {
    return await storeEngagements.createEngagementContactCompanyPhone(companyUuid)
  }

  const createEngagementHostCompanyLink = async (companyUuid) => {
    return await storeEngagements.createEngagementHostCompanyLink(companyUuid)
  }

  return {
    createEngagementContactWhatsApp,
    createEngagementContactEmail,
    createEngagementContactPhone,
    createEngagementHostListingLink,
    createEngagementContactCompanyWhatsApp,
    createEngagementContactCompanyEmail,
    createEngagementContactCompanyPhone,
    createEngagementHostCompanyLink,
  }
}
