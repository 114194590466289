import { storeToRefs } from 'pinia'

import { useStoreAuth } from '@/stores/storeAuth'

const IMPERSONATORS = ['alex@roomb.io', 'eng@roomb.io']

const FRONT_END_URL = import.meta.env.VITE_ROOMBEE_FRONTEND_URL

export function useAuth() {
  const storeAuth = useStoreAuth()
  const { token, user } = storeToRefs(storeAuth)

  const canImpersonate = () => {
    if (user.value.is_ops && IMPERSONATORS.includes(user.value.email)) {
      return true
    }

    return false
  }

  const impersonateHostUser = async (companyUuid) => {
    // Send request to set the ops_company
    const success = await storeAuth.setOpsCompany(companyUuid)

    if (success === true) {
      window.open(`${FRONT_END_URL}/h/listings`)
    }
  }

  const verifyEmailKey = async (key) => {
    const form = { key }
    return await storeAuth.verifyEmailKey(form)
  }

  const logIn = async (form) => {
    return await storeAuth.logIn(form)
  }

  const logOut = async () => {
    await storeAuth.logOut()
  }

  const getToken = () => {
    return storeAuth.getToken()
  }

  const setToken = (token) => {
    storeAuth.setToken(token)
  }

  const showNewOpsChip = (listing) => {
    return storeAuth.showNewOpsChip(listing)
  }

  const getUser = () => {
    return storeAuth.getUser()
  }

  const isOpsUser = () => {
    return storeAuth.isOpsUser()
  }

  const fetchUser = async () => {
    return await storeAuth.fetchUser()
  }

  const createAccount = async (form, accountType) => {
    return await storeAuth.createAccount(form, accountType)
  }

  const verifyOauthTokenGoogle = async (token) => {
    return await storeAuth.verifyOauthTokenGoogle(token)
  }

  const verifyOauthTokenFacebook = async (token, email) => {
    return await storeAuth.verifyOauthTokenFacebook(token, email)
  }

  return {
    canImpersonate,
    impersonateHostUser,
    createAccount,
    logIn,
    logOut,
    verifyEmailKey,
    getToken,
    getUser,
    isOpsUser,
    fetchUser,
    setToken,
    showNewOpsChip,
    verifyOauthTokenGoogle,
    verifyOauthTokenFacebook,
    token,
    user,
  }
}
