<template>
  <q-tab-panel ref="descriptionTabPanelRef">
    <div style="white-space: break-spaces;" class="text-body1 q-ma-sm">
      {{ publicListingDetails.description }}
      <template v-if="user.is_ops">
        <span v-if="!publicListingDetails.description">Click to Update Description</span>
        <q-popup-edit ref="descriptionPopupRef" :disable="opsUpdatingListing" v-model="publicListingDetails.description"
          v-slot="scope" persistent>
          <q-input maxlength="10000" type="textarea" :input-style="{ minHeight: '300px' }" counter autofocus autogrow
            dense v-model="scope.value" :model-value="scope.value" hint="Update description for this listing.">
            <template v-slot:after>
              <q-btn :disable="opsUpdatingListing" flat dense color="negatives" icon="cancel"
                @click="cancel(scope, 'description')" />
              <q-btn flat dense color="green" icon="check_circle" @click="updateListing(scope, 'description')"
                :disable="opsUpdatingListing" />
            </template>
          </q-input>
        </q-popup-edit>
      </template>
    </div>
  </q-tab-panel>
</template>


<script setup>
import { ref } from 'vue'
import { useRoute } from 'vue-router'

import { usePublicListings } from '@/composables/publicListings'
import { useAuth } from '@/composables/auth'
import { useOpsListings } from '@/composables/opsListings'
import { notifySorry, notifySuccess } from '@/notifications/events'

const {
  publicListingDetails,
  fetchPublicListingDetails,
} = usePublicListings()

const route = useRoute()

const { user } = useAuth()

const descriptionPopupRef = ref(null)
const descriptionTabPanelRef = ref(null)

const {
  opsUpdateListingForm,
  opsUpdatingListing,
  opsUpdateListing,
} = useOpsListings()

// Disable this for now, because there is a bug when switching between Map and Description
// panels (jumpy scroll behavior)
//
// onMounted(async () => {
//   await nextTick()
//   console.log('mounted description!')

//   const top = descriptionTabPanelRef.value.$el.getBoundingClientRect().top
//   console.log('top is: ', top)
//   // offset the header
//   window.scrollTo({ top: top - 50, behavior: 'smooth' })
// })

const updateListing = async (scope, field) => {
  opsUpdateListingForm.value[field] = scope.value
  const result = await opsUpdateListing(route.params.uuid)

  if (result.status === 400) {
    const error = `Error while updating listing: ${result.data}`
    notifySorry(error)
  } else {
    scope.set()
    const message = `Successfully updated ${field} to ${scope.value}`
    notifySuccess(message)
    await fetchPublicListingDetails(route.params.uuid)
  }
}

const cancel = (scope, field) => {
  // Set it back to the original value
  opsUpdateListingForm.value[field] = publicListingDetails.value[field]
  scope.cancel()
}
</script>
