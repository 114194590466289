<template>
  <q-card flat bordered class="q-ma-sm">
    <div v-if="review.images.length > 0">
      <q-card flat square>
        <q-carousel arrows swipeable animated v-model="slide" infinite height="10em">
          <q-carousel-slide v-for="(image, index) in review.images" :key="image.uuid" :name="index"
            :img-src="image.signed_url" />
        </q-carousel>
      </q-card>
    </div>
    <div v-else class="row text-h5 text-grey text-center" style="min-height: 10em; background-color: #efefef;">
      <div class="col self-center">
        No images to show.
      </div>
    </div>

    <div style="white-space: break-spaces;" class="text-body1 text-grey-10 q-pa-sm">
      {{ review.review }}
    </div>

    <div class="flex justify-between q-mx-sm">
      <q-avatar style="background-color: #eeeeee;">
        <img :src='getGravatarUrl(review.created_by.uuid)' />
      </q-avatar>

      <q-separator vertical inset />

      <div class="flex self-center text-center text-body2 text-grey-8">
        <q-icon @click="onVote('up')" name="thumb_up" size="sm"
          :class="review.up_vote_exists ? 'rb-public-icon__review-thumbs-up' : 'rb-public-icon__neutral'" />
        <span class="q-ml-xs">{{ review.up_votes }}</span>

        <q-icon @click="onVote('down')" name="thumb_down" size="sm" class="q-ml-sm"
          :class="review.down_vote_exists ? 'rb-public-icon__review-thumbs-down' : 'rb-public-icon__neutral'" />
        <span class="q-ml-xs">{{ review.down_votes }}</span>

        <q-icon @click="onFlag" name="flag" size="sm" class="q-ml-sm"
          :class="review.flag_exists ? 'rb-public-icon__flag' : 'rb-public-icon__neutral'" />
      </div>

      <q-separator vertical inset />

      <div class="self-center">
        <q-rating class="text-center" :model-value="parseFloat(review.rating)" :max="5" size="2em" color="blue-9"
          icon="star_border" icon-selected="star" icon-half="star_half" no-dimming readonly />
      </div>
    </div>

    <div class="text-center text-caption text-grey-8">
      Created by <b>{{ truncate(review.created_by.username, 25) }}</b>
      <span class="q-ml-sm">on {{ review.created_on }}</span>
    </div>


    <q-dialog v-model="showDeleteConfirmDialog" persistent>
      <q-card style="width: 500px; max-width: 100%;">
        <q-card-section>
          <div class="text-h6 text-grey-8 text-center">
            Are you sure you want to delete your review?
          </div>
        </q-card-section>
        <q-card-section>
          <q-card flat bordered>
            <q-card-section>
              <div class="text-body1 text-center">
                Deleting your review is a <b>permanent</b> action and cannot be reversed!
              </div>
            </q-card-section>
          </q-card>
        </q-card-section>

        <q-card-actions align="right" class="bg-white text-grey-10 q-mr-sm q-mb-sm">
          <q-btn label="Cancel" v-close-popup class="rb-public-buttons__cancel-color" />
          <q-btn @click="confirmDeleteReview" label="Delete" color="red-10" class="rb-public-buttons__submit-color" />
        </q-card-actions>
      </q-card>
    </q-dialog>
  </q-card>
</template>

<script setup>
import { computed, ref } from 'vue'

import { getGravatarUrl } from '@/utils/avatar'

import { useAuth } from '@/composables/auth'
import { usePublicListings } from '@/composables/publicListings'
import { useReviews } from '@/composables/reviews'
import { useVotes } from '@/composables/votes'
import { useFlags } from '@/composables/flags'
import { useGuestProfile } from '@/composables/guestProfile'
import { notifyLogIn, notifySorry } from '@/notifications/events'
import { truncate } from '@/utils/string'

import {
  FLAG_REVIEW_LISTING_REASONS,
} from '@/constants/flag'

const { token, user } = useAuth()
const {
  reviewSelf,
  selectedReviewForUpdate,
  showUpdateReviewDialog,
  fetchListingReviews,
  getListingReviewSelf,
} = useReviews()

const {
  publicListingDetails
} = usePublicListings()

const {
  deleteReviewListing,
} = useGuestProfile()

const {
  showFlagReviewListingDialog,
  reviewlistingUuidToFlag,
  flagReviewListing,
} = useFlags()

const {
  castListingReviewVote,
} = useVotes()

const props = defineProps({
  review: Object
})

const showDeleteConfirmDialog = ref(false)
const slide = ref(0)

const isMyReview = computed(() => {
  if (!token.value) {
    return false
  }

  if (reviewSelf.value.review_uuid === props.review.uuid) {
    return true
  }

  return false
})

const onEditReview = () => {
  selectedReviewForUpdate.value = { ...props.review }
  showUpdateReviewDialog.value = true
}

const onVote = async (direction) => {
  if (!token.value) {
    notifyLogIn()
    return
  }

  if (props.review.created_by.uuid === user.value.uuid) {
    notifySorry("You can't vote on your own review.")
    return
  }

  const resp = await castListingReviewVote(props.review.uuid, direction)
  if (resp.status !== 200) {
    return
  }

  fetchListingReviews()
  getListingReviewSelf()
}

const onFlag = async () => {
  if (!token.value) {
    notifyLogIn()
    return
  }

  if (props.review.created_by.uuid === user.value.uuid) {
    notifySorry("You can't flag your own review.")
    return
  }

  if (props.review.flag_exists === true) {
    const resp = await flagReviewListing(
      props.review.uuid,
      // Any reason will do, we are triggering a delete.
      FLAG_REVIEW_LISTING_REASONS[0],
      // Any description will do, we are triggering a delete.
      'foo'.repeat(10),
    )

    if (resp.status !== 200) {
      return
    } else if (token.value) {
      await fetchListingReviews()
    }
  } else {
    reviewlistingUuidToFlag.value = props.review.uuid
    showFlagReviewListingDialog.value = true
  }
}

const confirmDeleteReview = async () => {
  const result = await deleteReviewListing(publicListingDetails.value.uuid)
  if (result.status === 204) {
    showDeleteConfirmDialog.value = false
    fetchListingReviews()
    getListingReviewSelf()
  }
}
</script>
