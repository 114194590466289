import { storeToRefs } from 'pinia'

import { useAuth } from '@/composables/auth'
import { useStoreContact } from '@/stores/storeContact'

export function useContact() {
  const storeContact = useStoreContact()
  const { getUser } = useAuth()

  const {
    sendingMessage,
  } = storeToRefs(storeContact)

  const sendMessage = async (name, email, message) => {
    const user = getUser()
    const form = {
      name,
      email,
      message,
      user: user?.uuid,
    }

    return await storeContact.sendMessage(form)
  }

  return {
    sendingMessage,
    sendMessage,
  }
}
