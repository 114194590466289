<template>
  <q-tab-panel class="no-padding" ref="reviewItemsTabPanelMobileRef">
    <div class="flex text-center q-mt-sm q-mx-sm">
      <div style="width: 78%;">
        <q-input dense v-model="reviewSearch" debounce="500" filled placeholder="Search Reviews">
          <template v-slot:prepend>
            <q-icon name="search" />
          </template>

          <template v-slot:append>
            <q-icon name="close" @click="reviewSearch = ''" class="cursor-pointer" />
          </template>
        </q-input>
      </div>

      <div style="width: 11%;" class="self-center">
        <q-btn dense @click="showSearchReviewsOrder = true" icon="swap_vert" class="q-mx-sm">
          <q-badge v-if="showSearchOrderBadge" color="orange" floating>!</q-badge>
        </q-btn>
      </div>


      <div style="width: 11%;" class="self-center">
        <q-btn dense :disable="reviewSelf.review_exists === true" @click="handleCreateReview" no-caps color="black"
          outline>
          <q-icon name="add_comment" />
          <q-tooltip v-if="reviewSelf.review_exists === true">
            You have already created a review for this listing.
          </q-tooltip>
        </q-btn>
      </div>

    </div>

    <div class="text-grey text-subtitle2 text-center q-my-sm">
      Found {{ reviewsPage.pageTotal }} reviews.
    </div>

    <div v-for="review in listingReviews" :key="review.uuid">
      <ListingReviewItemMobile :review="review" />
    </div>

    <div v-if="listingReviews.length" class="flex flex-center q-my-sm">
      <q-pagination v-model="reviewsPage.pageCurrent" color="blue" :max="reviewsPage.pageDisplayMax"
        :max-pages="reviewsPage.pageTotal" boundary-numbers />
    </div>
    <PublicReviewsSearchOrderDialog />
  </q-tab-panel>
</template>


<script setup>
import { computed, nextTick, onMounted, ref } from 'vue'

import { useReviews } from '@/composables/reviews'
import { useAuth } from '@/composables/auth'
import { notifyLogIn } from '@/notifications/events'

import ListingReviewItemMobile from '@/components/cards/mobile/ListingReviewItemMobile.vue'

import PublicReviewsSearchOrderDialog from '@/components/dialogs/PublicReviewsSearchOrderDialog.vue'

const { token } = useAuth()
const {
  getListingReviewSelf,
  listingReviews,
  reviewsPage,
  reviewSearch,
  showCreateReviewDialog,
  searchReviewsOrder,
  reviewSelf,
  showSearchReviewsOrder,
} = useReviews()

const reviewItemsTabPanelMobileRef = ref(null)

onMounted(async () => {
  await nextTick()

  const top = reviewItemsTabPanelMobileRef.value.$el.getBoundingClientRect().top
  // offset the header
  window.scrollTo({ top: top - 50, behavior: 'smooth' })

  if (token.value) {
    await getListingReviewSelf()
  }
})

const handleCreateReview = () => {
  if (!token.value) {
    notifyLogIn()
    return
  }

  showCreateReviewDialog.value = true
}

const showSearchOrderBadge = computed(() => {
  if (
    searchReviewsOrder.value.length
  ) {
    return true
  }
  return false
})
</script>
