import { defineStore } from 'pinia'

import { client } from '@/services/request'

import { refreshPaginationValues } from '@/utils/pagination'

export const useStoreGuestProfile = defineStore('storeGuestProfile', {
  state: () => {
    return {
      fetchingProfile: false,
      fetchingBookmarksListing: false,
      fetchingReviewsListing: false,
      profileTab: 'profile',
      bookmarksListing: [],
      reviewsListing: [],
      pageBookmarksListing: {
        pageCurrent: 1,
        pageLast: 1,
        pageDisplayMax: 1,
        pageTotal: 0,
      },
      pageReviewsListing: {
        pageCurrent: 1,
        pageLast: 1,
        pageDisplayMax: 1,
        pageTotal: 0,
      },
    }
  },

  actions: {
    async fetchProfile() {
    },

    async fetchBookmarksListing(pageSize, pageDisplayMax) {
      const params = new URLSearchParams({
        limit: pageSize,
        offset: (this.pageBookmarksListing.pageCurrent * pageSize) - pageSize,
      })

      const url = `bookmarks/listing/?${params}`
      this.fetchingBookmarksListing = true
      const response = await client.authRequest(url)
      this.fetchingBookmarksListing = false

      if (response.status === 200) {
        const data = response.data
        this.bookmarksListing = data.results
        refreshPaginationValues(this.pageBookmarksListing, data.count, pageSize, pageDisplayMax)
      } else {
        // TODO-FEATURE: Handle unsuccessful responses
      }
    },

    async deleteBookmarkListing(uuid) {
      const url = `bookmarks/listing/${uuid}/delete/`
      return await client.authRequest(url, { method: 'DELETE' })
    },

    async fetchReviewsListing(pageSize, pageDisplayMax) {
      const params = new URLSearchParams({
        limit: pageSize,
        offset: (this.pageReviewsListing.pageCurrent * pageSize) - pageSize,
      })

      const url = `reviews/listing/my/?${params}`
      this.fetchingReviewsListing = true
      const response = await client.authRequest(url)
      this.fetchingReviewsListing = false

      if (response.status === 200) {
        const data = response.data
        this.reviewsListing = data.results
        refreshPaginationValues(this.pageReviewsListing, data.count, pageSize, pageDisplayMax)
      } else {
        // TODO-FEATURE: Handle unsuccessful responses
      }
    },

    async deleteReviewListing(uuid) {
      // const url = `reviews/listing/my/${uuid}/delete/`
      // return await client.authRequest(url, { method: 'DELETE' })
      const url = `reviews/listing/${uuid}/delete/`
      return await client.authRequest(url, { method: 'DELETE' })
    },
  },
})
