/**
 * Re-calculate pagination values so that the previous/next links
 * work as expected.
 */
export function refreshPaginationValues(page, totalResults, pageSize, pageMaxLinks) {
  // QPagination API
  // max: Number of the last page
  // max-pages: Max number of pages to show at once

  page.pageLast = Math.max(
    Math.ceil(totalResults / pageSize),
    1,
  )

  page.pageMaxLinks = Math.min(
    Math.ceil(totalResults / pageSize),
    pageMaxLinks,
  )

  page.pageTotal = totalResults

  return page
}
