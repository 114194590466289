<template>
  <q-dialog v-model="showUploadLogoDialog" persistent>
    <q-card style="width: 600px; max-width: 100%;">
      <q-card-section>
        <div class="row">
          <div class="col-9">
            <q-file v-model="logoToUpload" label="Pick Logo" outlined :clearable="!uploadingLogo"
              style="max-width: 100%;">
              <template v-slot:file="{ index, file }">
                <q-chip class="full-width q-my-xs row" :removable="uploadingLogo" square>
                  <div class="col-auto">
                    <q-avatar>
                      <q-icon name="photo" />
                    </q-avatar>
                  </div>

                  <div class="col-auto ellipsis relative-position text-green">
                    {{ file.name.substring(0, 44) }}
                  </div>

                  <div class="col text-right" v-if="uploadingLogo">
                    <q-circular-progress indeterminate size="sm" :thickness="0.3" font-size="50px" color="blue-3"
                      track-color="grey-3" center-color="blue-7" class="q-mr-md" />
                  </div>

                  <div class="col text-right" v-if="uploadSuccess === true">
                    <q-icon name="check_circle" color="green" size="sm" />
                  </div>
                </q-chip>
              </template>
            </q-file>
          </div>
          <div class="col q-ml-sm self-center">
            <q-btn color="primary" dense icon="cloud_upload" @click="uploadLogoImage"
              :disable="!logoToUpload || uploadingLogo" :loading="uploadingLogo" label="Upload Logo" />
          </div>
        </div>

      </q-card-section>

      <q-card-actions align="right" class="bg-white text-orange">
        <q-btn flat label="Cancel" v-close-popup />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>


<script setup>
import { ref } from 'vue'

import { useHostProfile } from '@/composables/hostProfile'
import { notifyHostUpdateValueError } from '@/notifications/events'

const logoToUpload = ref(null)
const uploadingLogo = ref(false)
const uploadSuccess = ref(false)

const maxBytes = parseInt(Number(import.meta.env.VITE_ROOMBEE_IMAGE_MAX_BYTES))

const {
  createLogoPresignedUrl,
  showUploadLogoDialog,
  updateLogoImage,
  fetchHostProfile,
} = useHostProfile()

const uploadLogoImage = async () => {
  if (logoToUpload.value.size > maxBytes) {
    const maxAllowedMB = parseFloat(maxBytes / 1024 / 1024).toFixed(2)
    const error = `The max allowed file size is ${maxAllowedMB} MB. The files is too large.`
    notifyHostUpdateValueError(error)
  }

  uploadingLogo.value = true

  const result = await fetchLogoPresignedUrl(logoToUpload.value.name, logoToUpload.value.type)
  if (!result) {
    uploadingLogo.value = false
    return
  }

  const formdata = new FormData()
  formdata.append("image", logoToUpload.value)
  const options = {
    method: 'PUT',
    body: logoToUpload.value,
    headers: { 'Content-Type': "multipart/form-data" }
  }

  let resp
  try {
    resp = await fetch(result.presigned_url, options)
  } catch {
    notifyHostUpdateValueError('There was a issue uploading the logo. Please try again later or contact support. Error: L101.')
    uploadingLogo.value = false
    return
  }

  if (resp.status !== 200) {
    notifyHostUpdateValueError('The logo server is experiencing some problems. Please try again later or contact support. Error: L102.')
    uploadingLogo.value = false
    return
  }

  const form = { is_uploaded: true }
  const updateResp = await updateLogoImage(result.uuid, form)

  if (updateResp.status === 200) {
    await fetchHostProfile()
    uploadingLogo.value = false
    logoToUpload.value = null
    showUploadLogoDialog.value = false
  } else {
    notifyHostUpdateValueError('The logo service is experiencing problems. Please try again later or contact support. Error: 103.')
    uploadingLogo.value = false
    return false
  }
}

const fetchLogoPresignedUrl = async (filename, mimeType) => {
  const form = {
    filename,
    mime_type: mimeType,
  }
  const result = await createLogoPresignedUrl(form)

  if (result.status === 201) {
    return result.data
  } else {
    const error = `Error while preparing the upload! Error: 100.`
    notifyHostUpdateValueError(error)
    return null
  }
}
</script>
