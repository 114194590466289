<template>
  <LayoutPublicBase>
    <template v-slot:my-router-view>
      <q-page-container>
        <div class="row justify-center" v-if="showListingsBreadcrumbs">
          <div class="col-xs-12 col-sm-10 col-md-8 col-lg-6 col-xl-4">
            <div class="q-pt-md">
              <q-breadcrumbs>
                <q-breadcrumbs-el label="All Listings" icon="home" :to="{ name: 'publicListingsView' }" />
                <q-breadcrumbs-el v-if="route.name === 'publicListingsDetailsView'" label="Details" icon="info" />
              </q-breadcrumbs>
            </div>
          </div>
        </div>

        <div class="row justify-center" v-if="showHostDetailsBreadcrumbs">
          <div class="col-xs-12 col-sm-10 col-md-8 col-lg-6 col-xl-4">
            <div class="q-pt-md">
              <q-breadcrumbs>
                <q-breadcrumbs-el label="All Listings" icon="home" :to="{ name: 'publicListingsView' }" />
                <q-breadcrumbs-el :label="hostDetails.name + ' Listings'" icon="list"
                  :to="{ name: 'PublicHostDetailsView', params: { uuid: hostDetails.uuid } }" />
              </q-breadcrumbs>
            </div>
          </div>
        </div>

        <div class="row justify-center">
          <div class="col-xs-12 col-sm-10 col-md-8 col-lg-6 col-xl-4">
            <router-view />
          </div>
        </div>
      </q-page-container>
    </template>
  </LayoutPublicBase>
</template>

<script setup>
import LayoutPublicBase from '@/components/layouts/LayoutPublicBase.vue'

import { useRoute } from 'vue-router'

import { usePublicHost } from '@/composables/publicHost'
import { useBreadcrumbs } from '@/composables/breadcrumbs'

const route = useRoute()

const {
  hostDetails,
} = usePublicHost()

const {
  showListingsBreadcrumbs,
  showHostDetailsBreadcrumbs,
} = useBreadcrumbs()
</script>
