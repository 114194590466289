import { defineStore } from 'pinia'

export const useStoreBreadcrumbs = defineStore('storeBreadcrumbs', {
  state: () => {
    return {
      showListingsBreadcrumbs: false,
      showHostDetailsBreadcrumbs: false,
    }
  },
})
