<template>
  <q-card flat bordered class="q-mb-md">
    <div class="row q-mt-sm q-ml-sm">
      <div class="col-3">
        <div class="row">
          <div class="col">
            <div v-if="review.images.length > 0">
              <q-card class="q-pa-xs">
                <q-carousel arrows swipeable animated v-model="slide" infinite height="13em">
                  <q-carousel-slide v-for="(image, index) in review.images" :key="image.uuid" :name="index"
                    :img-src="image.signed_url" />
                </q-carousel>
              </q-card>
            </div>
            <div v-else class="row text-h5 text-grey text-center" style="min-height: 200px; background-color: #efefef;">
              <div class="col self-center">
                No images to show.
              </div>
            </div>
          </div>
        </div>

        <div class="row text-center q-mt-sm">
          <router-link
            :to="{ name: 'publicListingsDetailsView', params: { uuid: review.listing_uuid, listingSlug: review.listing_slug } }">
            <span class="text-body2">{{ review.listing_title }}</span>
          </router-link>
        </div>

        <div class="row q-my-sm">
          <div class="col">
            <q-separator inset />
          </div>
        </div>

        <div class="row justify-center">
          <q-rating :model-value="parseFloat(review.rating)" :max="5" size="2em" color="blue-9" icon="star_border"
            icon-selected="star" icon-half="star_half" no-dimming readonly />
        </div>

        <div class="row text-center text-caption text-grey-8 q-mb-sm">
          <div class="col">
            Rated <b>{{ review.rating }}</b> out of 5 by <b>{{ review.created_by.username }}</b>
            <br />on {{ review.created_on }}
          </div>
        </div>

        <div class="row q-my-sm">
          <div class="col">
            <q-separator inset />
          </div>
        </div>

        <div class="flex justify-between text-caption text-grey-8 q-mx-md q-mb-sm">
          <div class="q-ml-md">
            <q-icon @click="onVote('up')" name="thumb_up" size="sm"
              :color="review.up_vote_exists ? 'teal-6' : 'grey-8'" />
            <span class="q-ml-sm">
              {{ review.up_votes }}
            </span>
          </div>

          <div>
            <q-icon @click="onVote('down')" name="thumb_down" size="sm"
              :color="review.down_vote_exists ? 'red-8' : 'grey-8'" />
            <span class="q-ml-sm">
              {{ review.down_votes }}
            </span>
          </div>

          <div class="q-mr-md">
            <q-icon @click="onFlag" name="flag" size="sm" :color="review.flag_exists ? 'red-8' : 'grey-8'" />
          </div>
        </div>
      </div>

      <div class="col q-ml-md q-mt-sm">
        <div style="white-space: break-spaces;" class="text-body1">
          {{ review.review }}
        </div>
      </div>
    </div>
  </q-card>
</template>

<script setup>
import { ref } from 'vue'

import { useAuth } from '@/composables/auth'
import { useReviews } from '@/composables/reviews'
import { useVotes } from '@/composables/votes'
import { useFlags } from '@/composables/flags'

import { notifyLogIn, notifySorry } from '@/notifications/events'

import {
  FLAG_REVIEW_LISTING_REASONS,
} from '@/constants/flag'

const { token, user } = useAuth()
const {
  fetchListingReviews,
  getListingReviewSelf,
} = useReviews()

const {
  showFlagReviewListingDialog,
  reviewlistingUuidToFlag,
  flagReviewListing,
} = useFlags()

const {
  castListingReviewVote,
} = useVotes()

const props = defineProps({
  review: Object
})

const slide = ref(0)

const onVote = async (direction) => {
  if (!token.value) {
    notifyLogIn()
    return
  }

  if (props.review.created_by.uuid === user.value.uuid) {
    notifySorry("You can't vote on your own review.")
    return
  }

  const resp = await castListingReviewVote(props.review.uuid, direction)
  if (resp.status !== 200) {
    return
  }

  fetchListingReviews()
  getListingReviewSelf()
}

const onFlag = async () => {
  if (!token.value) {
    notifyLogIn()
    return
  }

  if (props.review.created_by.uuid === user.value.uuid) {
    notifySorry("You can't flag on your own review.")
    return
  }

  if (props.review.flag_exists === true) {
    const resp = await flagReviewListing(
      props.review.uuid,
      // Any reason will do, we are triggering a delete.
      FLAG_REVIEW_LISTING_REASONS[0],
      // Any description will do, we are triggering a delete.
      'foo'.repeat(10),
    )

    if (resp.status !== 200) {
      return
    } else if (token.value) {
      await fetchListingReviews()
    }
  } else {
    reviewlistingUuidToFlag.value = props.review.uuid
    showFlagReviewListingDialog.value = true
  }
}
</script>
