<template>
  <div>
    <q-stepper v-model="createListingStep" ref="stepper" animated active-color="primary" vertical>
      <CreateListingFormStep1 />
      <CreateListingFormStep2 />
      <CreateListingFormStep3 />
      <CreateListingFormStep4 />
    </q-stepper>
  </div>
</template>

<script setup>
import { useHostListings } from '@/composables/hostListings'

import CreateListingFormStep1 from '@/components/forms/createListingForm/CreateListingFormStep1.vue'
import CreateListingFormStep2 from '@/components/forms/createListingForm/CreateListingFormStep2.vue'
import CreateListingFormStep3 from '@/components/forms/createListingForm/CreateListingFormStep3.vue'
import CreateListingFormStep4 from '@/components/forms/createListingForm/CreateListingFormStep4.vue'

const {
  createListingStep,
} = useHostListings()
</script>
