<template>
  <q-page>
    <div class="q-pa-md" style="height: 100vh; display: grid; place-items: center; transform: translateY(-20%);">
      <div style="width: 80%; max-width: 800px;">

        <div class="row text-center q-mb-xl">
          <div class="col">
            <q-img style="width: 300px" src="@/assets/login-logo-horizontal-x-small.png"></q-img>
          </div>
        </div>

        <div class="row text-center">
          <div class="col">
            <SocialGoogle prefix="Log In" />
          </div>
        </div>

        <div class="row justify-center q-my-lg">
          <div class="col">
            <div class="row fit no-wrap items-center">
              <q-separator class="col" />
              <div class="col-grow q-mx-sm text-body1 text-grey-8">Or Log In Manually</div>
              <q-separator class="col" />
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <q-input ref="emailRef" rounded outlined maxlength="50" counter v-model="email" :rules="emailRules"
              label="Enter your Email Address" hint="Enter the Email Address you used to sign up." dense />
          </div>
        </div>

        <div class="row">
          <div class="col">
            <q-input class="q-mt-lg" ref="passwordRef" @keyup.enter="onLogIn" :type="isPassword ? 'password' : 'text'"
              rounded outlined maxlength="50" counter :rules="passwordRules" v-model="password"
              label="Enter your Password" dense>
              <template v-slot:append>
                <q-icon :name="isPassword ? 'visibility_off' : 'visibility'" class="cursor-pointer"
                  @click="isPassword = !isPassword" />
              </template>
            </q-input>
          </div>
        </div>

        <div class="row" v-if="error">
          <div class="col">
            <div class="text-h6 text-center text-red-10">Login Error</div>
            <div>Could not log in with these credentials. Please check your email/password and try again. If the problem
              persists, please
              <router-link :to="{ name: 'publicContactView' }" target="_blank">Contact Us</router-link>
              or send us an email at {{ supportEmail }}
            </div>
          </div>
        </div>

        <div class="flex justify-center q-my-md">
          <q-btn color="primary" @click="onLogIn" :disable="!formIsValid" :loading="isLoggingIn">Log
            In</q-btn>
        </div>
      </div>
    </div>
  </q-page>

</template>

<script setup>
import { computed, onMounted, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useQuasar } from 'quasar'

import SocialGoogle from '@/components/registration/SocialGoogle.vue'
// import SocialFacebook from '@/components/registration/SocialFacebook.vue'

import { useAuth } from '@/composables/auth'

const { logIn } = useAuth()
const route = useRoute()
const $q = useQuasar()
const router = useRouter()

const email = ref('')
const password = ref('')
const isPassword = ref(true)
const emailRef = ref(null)
const passwordRef = ref(null)

const emailRules = [val => (val && val.length > 0) || 'Please enter your Email']
const passwordRules = [val => (val && val.length > 0) || 'Please enter your Password']

const isLoggingIn = ref(false)
const error = ref(false)
const supportEmail = import.meta.env.VITE_ROOMBEE_SUPPORT_EMAIL

const formIsValid = computed(() => {
  const result = Boolean(email.value.length > 0 && password.value.length > 0) && !isLoggingIn.value
  return result
})

onMounted(async () => {
  if (!$q.platform.is.mobile) {
    router.push({ name: 'publicLoginView' })
  }
})

const onLogIn = async () => {
  emailRef.value.validate()
  passwordRef.value.validate()
  if (emailRef.value.hasError || passwordRef.value.hasError) {
    return
  }

  error.value = false
  const form = {
    email: email.value,
    password: password.value,
  }
  isLoggingIn.value = true
  const resp = await logIn(form)
  isLoggingIn.value = false

  if (resp.status === 200) {
    const nextUrl = route?.query?.next
    if (nextUrl && nextUrl.length > 0) {
      window.location.replace(nextUrl)
    } else {
      router.push({ name: 'publicListingsMobileView' })
    }
  } else {
    error.value = true
  }
}
</script>
