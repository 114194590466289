<template>
  <div class="flex">
    <div class="rb-public-host-listing-details__logo-container-mobile">
      <q-card flat class="q-pa-md">
        <div v-if="hostDetails.logo">
          <q-img :src="hostDetails.logo" />
        </div>
        <div v-else class="flex justify-center rb-public-host-listing-details__no-logo-mobile">
          <div class="self-center text-body2 text-grey">
            No Logo
          </div>
        </div>
      </q-card>
    </div>

    <div class="text-h5 text-grey-8 self-center rb-public-host-listing-details__title-container-mobile">
      <span>{{ hostDetails.name }}</span>
    </div>
  </div>

  <div class="q-mx-md text-grey-8 text-body1">{{ hostDetails.title }}</div>

  <div class="q-mx-md q-my-md text-right">
    <q-btn round class="q-mr-md" size="sm" @click="onSendWhatsApp" :disable="!hostDetails.whatsapp?.length">
      <img src="@/assets/whats-app-logo-xxxx-small.png" width="25px" />
      <q-tooltip transition-show="flip-right" transition-hide="flip-left">
        <template v-if="hostDetails.whatsapp?.length">
          Send a WhatsApp message to the host
        </template>
        <template v-else>
          This company doesn't have a WhatsApp number.
        </template>
      </q-tooltip>
    </q-btn>
    <q-btn round icon="alternate_email" class="q-mr-md" size="sm" :disable="!hostDetails.email?.length"
      @click="onSendEmail">
      <q-tooltip transition-show="flip-right" transition-hide="flip-left">
        <template v-if="hostDetails.email?.length">
          Send an Email to the host
        </template>
        <template v-else>
          This company doesn't have an Email.
        </template>
      </q-tooltip>
    </q-btn>
    <q-btn round icon="phone" size="sm" class="q-mr-md" @click="onCall" :disable="!hostDetails.phone_number?.length">
      <q-tooltip transition-show="flip-right" transition-hide="flip-left">
        <template v-if="hostDetails.phone_number?.length">
          Call the host
        </template>
        <template v-else>
          This company doesn't have a Phone number.
        </template>
      </q-tooltip>
    </q-btn>
    <q-btn round icon="public" size="sm" :disable="hostDetails?.website ? hostDetails.website.length === 0 : false"
      @click="onHostCompanyLink">
      <q-tooltip transition-show="flip-right" transition-hide="flip-left">
        <template v-if="hostDetails.website?.length">
          Visit {{ hostDetails.name }}'s Website
        </template>
        <template v-else>
          Strangely, we don't have {{ hostDetails.name }}'s website (yet)
        </template>
      </q-tooltip>
    </q-btn>
  </div>

  <q-separator />

  <HostDetailsPanelMobile />
</template>

<script setup>
import { onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useQuasar } from 'quasar'

import { usePublicHost } from '@/composables/publicHost'
import HostDetailsPanelMobile from '@/components/hostDetailsPanel/mobile/HostDetailsPanelMobile.vue'
import { useEngagements } from '@/composables/engagements'
import { useBreadcrumbs } from '@/composables/breadcrumbs'

import { WHATSAPP_URL, EMAIL_SUBJECT, genContactHostCompanyMessage } from '@/constants/contact'

const { showHostDetailsBreadcrumbs } = useBreadcrumbs()

const route = useRoute()
const $q = useQuasar()
const router = useRouter()

const {
  hostUuid,
  hostDetails,
  fetchHostDetails,
} = usePublicHost()

const {
  createEngagementContactCompanyWhatsApp,
  createEngagementContactCompanyEmail,
  createEngagementContactCompanyPhone,
  createEngagementHostCompanyLink,
} = useEngagements()

onMounted(async () => {
  if (!$q.platform.is.mobile) {
    router.push({ name: 'publicHostDetailsView' })
  }

  showHostDetailsBreadcrumbs.value = true
  // First set the Host UUID
  hostUuid.value = route.params.uuid
  await fetchHostDetails()
})


const onSendWhatsApp = () => {
  const phoneNumber = hostDetails.value.whatsapp
  const domain = import.meta.env.VITE_ROOMBEE_BASE_DOMAIN

  const companyUrl = `${domain}/g/hosts/${route.params.uuid}`
  const message = encodeURIComponent(genContactHostCompanyMessage(companyUrl))

  const url = `${WHATSAPP_URL}${phoneNumber}?text=${message}`
  window.open(url, '_blank')

  createEngagementContactCompanyWhatsApp(route.params.uuid)
}

const onSendEmail = () => {
  const email = hostDetails.value.email
  const domain = import.meta.env.VITE_ROOMBEE_BASE_DOMAIN

  const companyUrl = `${domain}/g/hosts/${route.params.uuid}`
  const message = encodeURIComponent(genContactHostCompanyMessage(companyUrl))

  const url = `mailto:${email}?subject=${EMAIL_SUBJECT}&body=${message}`
  window.open(url, '_blank')

  createEngagementContactCompanyEmail(route.params.uuid)
}

const onCall = () => {
  const phoneNumber = hostDetails.value.phone_number

  const url = `tel:${phoneNumber}`
  window.open(url, '_blank')

  createEngagementContactCompanyPhone(route.params.uuid)
}

const onHostCompanyLink = () => {
  window.open(hostDetails.value.website, '_blank')

  createEngagementHostCompanyLink(route.params.uuid)
}
</script>
