<template>
  <q-step :name="1" prefix="1" title="1. Location" caption="&nbsp;Of Your Property" :done="createListingStep > 1">
    <div>
      This wizard will help you create your listing for maximum success!
    </div>
    <div class="q-mt-sm q-mb-sm">
      First, let's enter the location and basic details of your property.
    </div>

    <div class="text-h6 q-pl-sm">
      Location
    </div>

    <div class="row">
      <div class="col q-mr-sm">
        <q-select dense rounded outlined v-model="createListingForm.location.country" label="Country" disable
          readonly />
      </div>
      <div class="col q-mr-sm">
        <q-select dense ref="cityRef" :rules="CITY_RULES" rounded outlined v-model="createListingForm.location.city"
          :options="cities" label="Emirate" />

      </div>
      <div class="col">
        <q-select dense :disable="!createListingForm.location.city" ref="neighbourhoodRef" :rules="NEIGHBOURHOOD_RULES"
          rounded outlined v-model="createListingForm.location.neighbourhood" :options="neighbourhoodOptions"
          label="Neighbourhood" use-input input-debounce="0" @filter="filterNeighbourhoods" />
      </div>
    </div>

    <q-separator inset class="q-mt-lg" />

    <div class="text-h6 q-mt-sm q-pl-sm">
      Building & Bedrooms
    </div>

    <div class="row q-mt-sm">
      <div class="col-4 q-pr-sm">
        <q-select dense ref="propertyTypeRef" :rules="PROPERTY_TYPE_RULES" rounded outlined
          v-model="createListingForm.property_type" :options="propertyTypes" label="Property Type" />
      </div>
      <div class="col-4 q-pr-sm">
        <q-select dense ref="bedroomsRef" :rules="BEDROOMS_RULES" rounded outlined v-model="createListingForm.bedrooms"
          :options="BEDROOM_OPTIONS" label="Bedrooms" />
      </div>
      <div class="col-4">
        <q-select dense ref="bathroomsRef" :rules="BATHROOMS_RULES" rounded outlined
          v-model="createListingForm.bathrooms" :options="BATHROOM_OPTIONS" label="Bathrooms" />
      </div>
    </div>

    <q-stepper-navigation>
      <q-btn @click="submitStep1" color="primary" label="Next" />
    </q-stepper-navigation>

  </q-step>
</template>

<script setup>
import { onMounted, ref, watch } from 'vue'

import { useHostListings } from '@/composables/hostListings'
import { useMeta } from '@/composables/meta'
import {
  BATHROOM_OPTIONS,
  BEDROOM_OPTIONS,
  CITY_RULES,
  NEIGHBOURHOOD_RULES,
  PROPERTY_TYPE_RULES,
  BEDROOMS_RULES,
  BATHROOMS_RULES
} from '@/constants/listing.js'

const {
  cities,
  // neighbourhoods,
  fetchCities,
  fetchNeighbourhoods,
  fetchPropertyTypes,
  propertyTypes,
} = useMeta()
const {
  createListingForm,
  createListingStep,
} = useHostListings()

const cityRef = ref(null)
const neighbourhoodRef = ref(null)
const propertyTypeRef = ref(null)
const bedroomsRef = ref(null)
const bathroomsRef = ref(null)

// const createListingFormCity = computed((createListingForm) => {
//   return createListingForm.value.location.city
// })
// The initial options has to be a non reactive array
// otherwise the filtering won't work on `.value`
let neighbourhoodOptionsByCity = []
const neighbourhoodOptions = ref(neighbourhoodOptionsByCity)

onMounted(async () => {
  // await fetchCountries()
  // await fetchStates()
  await fetchCities()
  // await fetchNeighbourhoods()
  // neighbourhoodOptions.value = neighbourhoods
  await fetchPropertyTypes()
})




const filterNeighbourhoods = (val, update) => {
  if (val === '') {
    update(() => {
      neighbourhoodOptions.value = neighbourhoodOptionsByCity.value
    })
    return
  }

  update(() => {
    const needle = val.toLowerCase()
    neighbourhoodOptions.value = neighbourhoodOptionsByCity.value.filter(
      v => v.toLowerCase().indexOf(needle) > -1
    )
  })
}

watch(
  () => createListingForm.value.location.city, async (newVal, oldVal) => {
    if (newVal != oldVal) {
      const response = await fetchNeighbourhoods(newVal)
      if (response.status === 200) {
        neighbourhoodOptionsByCity.value = response.data
      }
    }
  },
  { deep: true },
)

const submitStep1 = () => {
  cityRef.value.validate()
  neighbourhoodRef.value.validate()
  propertyTypeRef.value.validate()
  bedroomsRef.value.validate()
  bathroomsRef.value.validate()

  if (
    cityRef.value.hasError ||
    neighbourhoodRef.value.hasError ||
    propertyTypeRef.value.hasError ||
    bedroomsRef.value.hasError ||
    bathroomsRef.value.hasError
  ) {
    return
  } else {
    createListingStep.value = 2
  }
}
</script>
