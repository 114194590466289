<template>
  <q-page>
    <div class="flex content-center q-mx-lg" style="min-height: inherit;">
      <div class="text-center" style="min-width: 100%;" v-if="isVerifying">
        <q-spinner-grid color="primary" size="2em" />
        <div class="text-h6 text-grey-10">Verifying your email...</div>
      </div>

      <div class="text-center" v-if="!isVerifying && !error">
        <q-icon name="check" class="text-green" size="4em" />
        <div class="text-h6 text-grey-10 q-ml-md self-center">Your email is verified!</div>
        <div class="text-body1 text-grey-10 q-ml-md self-center">
          You will now be redirected to the log in page.
        </div>
      </div>

      <div class="text-center" v-if="!isVerifying && error">
        <q-icon name="warning" class="text-orange" size="4em" />
        <div class="text-h6 text-grey-10 q-ml-md">Oops, there was a problem!</div>
        <div class="text-body1 text-grey-10 q-ml-md self-center">
          <div>The link doesn't appear to be valid.</div>
          <div>Please try resetting your password or contact {{ supportEmail }}</div>
        </div>
      </div>
    </div>
  </q-page>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useQuasar } from 'quasar'

import { useAuth } from '@/composables/auth'

const { verifyEmailKey } = useAuth()
const route = useRoute()
const $q = useQuasar()
const router = useRouter()

const isVerifying = ref(true)
const error = ref(false)
const supportEmail = import.meta.env.VITE_ROOMBEE_SUPPORT_EMAIL

onMounted(async () => {
  if (!$q.platform.is.mobile) {
    router.push({ name: 'publicVerifyEmailView' })
  }

  const result = await verifyEmailKey(route.params.verifyKey)

  isVerifying.value = false

  if (result.status === 200) {
    setTimeout(() => {
      router.push({ name: 'publicLoginView' })
    }, 2000)
  } else {
    error.value = true
  }
})
</script>
