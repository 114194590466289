<template>
  <q-layout view="lHh Lpr lFf">
    <q-drawer v-model="leftDrawerOpen" side="left" :width="250" style="border-right: 1px solid #ddd;">
      <q-img class="absolute-top text-center" fit="contain" src="@/assets/layout-host-logo.svg"
        style="height: 90px; width: 249px; background-color: rgb(50 74 107)">
        <!-- <div class="absolute-bottom bg-transparent">
          <q-avatar size="56px" class="q-mb-sm">
            <img src="https://cdn.quasar.dev/img/boy-avatar.png">
          </q-avatar>
          <div class="text-weight-bold">Mister Hostman</div>
          <div>Profile</div>
        </div> -->
      </q-img>
      <q-list padding class="menu-style">
        <q-item :active="link === 'hostListingsView'" active-class="drawer-active-link" clickable v-ripple
          @click="goToPage('hostListingsView')">
          <q-item-section avatar>
            <q-icon name="inbox" />
          </q-item-section>

          <q-item-section>
            My Listings
          </q-item-section>
        </q-item>

        <q-item :active="link === 'hostNewListingView'" active-class="drawer-active-link" clickable v-ripple
          @click="goToPage('hostNewListingView')">
          <q-item-section avatar>
            <q-icon name="star" />
          </q-item-section>

          <q-item-section>
            Create Listing
          </q-item-section>
        </q-item>

        <q-item :active="link === 'hostProfileView'" active-class="drawer-active-link" clickable v-ripple
          @click="goToPage('hostProfileView')">
          <q-item-section avatar>
            <q-icon name="manage_accounts" />
          </q-item-section>

          <q-item-section>
            Profile
          </q-item-section>
        </q-item>

        <q-item :active="link === 'hostHelpView'" active-class="drawer-active-link" clickable v-ripple
          @click="goToPage('hostHelpView')">
          <q-item-section avatar>
            <q-icon name="help_outline" />
          </q-item-section>

          <q-item-section>
            Help
          </q-item-section>
        </q-item>

        <q-item clickable v-ripple @click="logOutHost">
          <q-item-section avatar>
            <q-icon name="logout" />
          </q-item-section>

          <q-item-section>
            Log Out
          </q-item-section>
        </q-item>
      </q-list>



      <q-list v-if="user.is_ops" style="background-color: orange;">
        <q-item clickable v-ripple @click="logOutHost" class="bg-orange-3">
          <q-item-section avatar>
            <q-icon name="warning_amber" />
          </q-item-section>

          <q-item-section>
            Logged in as Ops
          </q-item-section>
        </q-item>
      </q-list>
    </q-drawer>

    <q-page-container>
      <q-page padding>
        <router-view />
      </q-page>
    </q-page-container>

    <q-footer class="bg-white">

      <div class="text-center text-grey-9 text-weight-regular q-mb-xs">
        <q-avatar size="30px">
          <img class="text-centers" src="@/assets/gold-bee-xxx-small.webp"
            style="background-color: rgb(38 78 134); padding: 4px;" />
        </q-avatar>
        RoomB Technologies <q-badge color="blue-10">v{{ roombeeVersion }}</q-badge>
      </div>

    </q-footer>

  </q-layout>
</template>

<script setup>
import { onMounted, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useQuasar } from 'quasar'
import { useAuth } from '@/composables/auth'
import { inject } from 'vue'

const roombeeVersion = inject('roombeeVersion')

const $q = useQuasar()
const route = useRoute()
const router = useRouter()
const { getToken, getUser, logOut } = useAuth()

const token = getToken()
const user = getUser()

onMounted(() => {
  if ($q.platform.is.mobile) {
    router.push({ name: 'hostPlaceholderMobileView' })
  }

  if (token && user.is_ops) {
    return
  }


  if (!token || !user.is_host) {
    // User is not logged in, or is logged in but not as host user account
    router.push({ name: 'hostLoginView' })
  }
})

/**
 * The route might be updated outside of the user clicking the menu links
 * on the left hand side. E.g. user clicks a router link in a page.
 */
watch(route, (newVal) => {
  if (newVal.name !== link.value) {
    link.value = newVal.name
  }
})

const link = ref('hostListingsView')
const leftDrawerOpen = ref(true)

const goToPage = (name) => {
  link.value = name
  router.push({ name })
}

const logOutHost = () => {
  logOut()
  router.push({ name: 'hostLoginView' })
}
</script>

<style scoped>
.drawer-active-link {
  color: white;
  background: rgb(76 98 130);
}

.menu-style {
  height: calc(100% - 130px);
  margin-top: 82px;
}

.menu-container {
  height: 100%;
  border-right: 1px solid #ddd;
  background-color: red !important;
}
</style>
