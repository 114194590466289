<template>
  <q-tab-panel>
    <q-card flat>
      <q-card-section>
        <div style="white-space: break-spaces;" class="text-body1" v-if="hostDetails.description.length">
          {{ hostDetails.description }}
        </div>
        <div v-else class="text-body1 text-center text-grey-8">
          {{ hostDetails.name }} hasn't updated their company description yet.
        </div>
      </q-card-section>
    </q-card>

  </q-tab-panel>
</template>


<script setup>
import { onMounted } from 'vue'

import { useRoute } from 'vue-router'

import { usePublicHost } from '@/composables/publicHost'

const route = useRoute()

const {
  hostDetails,
  tab,
} = usePublicHost()

onMounted(async () => {
  tab.value = 'about'
  window.history.replaceState(null, '', `${route.path}?tab=about`)
})
</script>
