import { defineStore } from 'pinia'

import { client } from '@/services/request'

export const useStoreVotes = defineStore('storeVotes', {
  state: () => {
    return {

    }
  },

  actions: {
    async castListingVote(uuid, form) {
      const url = `votes/listing/${uuid}/`
      return await client.authRequest(url, { method: 'POST', form })
    },

    async castListingReviewVote(uuid, form) {
      const url = `votes/review-listing/${uuid}/`
      return await client.authRequest(url, { method: 'POST', form })
    },
  },
})
