<template>
  <q-page>
    <div v-if="publicListingDetails?.images?.length > 0">
      <q-card flat square>
        <q-carousel arrows swipeable animated v-model="slide" infinite height="15em" control-color="black"
          control-type="regular">
          <q-carousel-slide v-for="(image, index) in publicListingDetails.images" :key="image.uuid" :name="index"
            :img-src="image.signed_url" />
          <template v-slot:control>
            <q-carousel-control position="bottom-right" :offset="[18, 18]">
              <q-btn push round dense color="black" text-color="white"
                :icon="fullscreen ? 'fullscreen_exit' : 'fullscreen'"
                @click="listingImagesDialog = !listingImagesDialog" />
            </q-carousel-control>
          </template>
        </q-carousel>
      </q-card>
    </div>
    <div v-else class="row text-h5 text-grey text-center" style="min-height: 200px; background-color: #efefef;">
      <div class="col self-center">
        No listing images to show.
      </div>
    </div>
    <q-card class="q-pa-xs" flat square>
      <q-chip v-if="showNewOpsChip(publicListingDetails)" dense color="red" text-color="white" icon="warning"
        label="New" />
      <div class="flex justify-between q-mt-xs">
        <div class="column rb-public-listing-details__title_container">
          <div>
            <div class="text-h5 q-ml-xs q-mb-sm">
              {{ publicListingDetails.title }}
              <template v-if="user.is_ops">
                <span v-if="!publicListingDetails.title">Click to Update Title</span>
                <q-popup-edit ref="titlePopupRef" :disable="opsUpdatingListing" v-model="publicListingDetails.title"
                  v-slot="scope" persistent>
                  <q-input maxlength="100" counter autofocus autogrow dense v-model="scope.value"
                    :model-value="scope.value" hint="Update Title for this listing.">
                    <template v-slot:after>
                      <q-btn :disable="opsUpdatingListing" flat dense color="negatives" icon="cancel"
                        @click="cancel(scope, 'title')" />
                      <q-btn flat dense color="green" icon="check_circle" @click="updateListing(scope, 'title')"
                        :disable="opsUpdatingListing" />
                    </template>
                  </q-input>
                </q-popup-edit>
              </template>
            </div>
            <div class="q-ml-xs q-mb-sm text-body1">
              {{ publicListingDetails.sub_title }}
              <template v-if="user.is_ops">
                <span v-if="!publicListingDetails.sub_title">Click to Update Subtitle</span>
                <q-popup-edit ref="subtitlePopupRef" :disable="opsUpdatingListing"
                  v-model="publicListingDetails.sub_title" v-slot="scope" persistent>
                  <q-input maxlength="200" counter autofocus autogrow dense v-model="scope.value"
                    :model-value="scope.value" hint="Update Subtitle for this listing.">
                    <template v-slot:after>
                      <q-btn :disable="opsUpdatingListing" flat dense color="negatives" icon="cancel"
                        @click="cancel(scope, 'sub_title')" />
                      <q-btn flat dense color="green" icon="check_circle" @click="updateListing(scope, 'sub_title')"
                        :disable="opsUpdatingListing" />
                    </template>
                  </q-input>
                </q-popup-edit>
              </template>
            </div>
          </div>

          <q-space />

          <div v-if="publicListingDetails.price">
            <ListingPriceChip :amount="publicListingDetails.price.totals.total_price"
              @click.capture="showPriceDialog = true" class="cursor-pointer" />
            <q-icon v-if="user.is_ops" @click="showPriceEditDialog = true" name="attach_money" color="red" size="sm"
              class="cursor-pointer self-center" />

            <ListingRatingChip class="q-ml-xs" :rating-overall="publicListingDetails.rating_overall" />
          </div>
        </div>

        <div class="rb-public-listing-details__logo_container-mobile">
          <div v-if="publicListingDetails.company?.logo" class="q-mr-sm">
            <q-card class="q-pa-xs">
              <router-link :to="{ name: 'publicHostDetailsView', params: { uuid: publicListingDetails.company?.uuid } }"
                style="color: inherit;">
                <q-img no-spinner class="rb-public-listing-details__logo" :src="publicListingDetails.company?.logo" />
              </router-link>
            </q-card>
          </div>

          <div v-else class="col q-py-md q-mx-sm text-body2 text-grey text-center" style="background-color: #efefef;">
            <router-link :to="{ name: 'publicHostDetailsView', params: { uuid: publicListingDetails.company?.uuid } }"
              style="color: inherit;">{{
                publicListingDetails.company?.name }}</router-link>
          </div>
        </div>
      </div>

      <q-separator class="q-my-sm" />

      <div class="flex justify-between">
        <div class="q-ml-sm">
          <q-icon @click="onVote('up')" name="thumb_up" size="sm" class="cursor-pointer"
            :class="publicListingDetailsSelf.up_vote_exists ? 'rb-public-icon__listing-thumbs-up' : 'rb-public-icon__neutral'" />
          <span class="text-body1 self-center q-ml-sm">
            {{ publicListingDetails.up_votes }}
          </span>

          <q-icon @click="onVote('down')" name="thumb_down" size="sm"
            :class="publicListingDetailsSelf.down_vote_exists ? 'rb-public-icon__listing-thumbs-down' : 'rb-public-icon__neutral'"
            class="q-ml-lg cursor-pointer" />
          <span class="text-body1 self-center q-ml-sm">
            {{ publicListingDetails.down_votes }}
          </span>

          <q-icon @click="onBookmark" name="bookmark" size="sm"
            :class="publicListingDetailsSelf.bookmark_exists ? 'rb-public-icon__listing-bookmark' : 'rb-public-icon__neutral'"
            class="q-ml-lg cursor-pointer">
            <q-tooltip>Add to my bookmarks</q-tooltip>
          </q-icon>

          <q-icon @click="onFlag" name="flag" size="sm"
            :class="publicListingDetailsSelf.flag_exists ? 'rb-public-icon__flag' : 'rb-public-icon__neutral'"
            class="q-ml-lg cursor-pointer">
            <q-tooltip>Flag/Report Listing</q-tooltip>
          </q-icon>

          <q-icon @click="onCopyLink" name="link" size="sm" color="grey-10" class="q-ml-lg cursor-pointer">
            <q-tooltip>Copy the URL of this Listing</q-tooltip>
          </q-icon>
        </div>

        <!-- TODO-FEATURE: If action is disabled, show a pop-up instead of disabling action. -->
        <div class="q-mr-sm">
          <q-fab v-model="contactFab" label-position="left" color="grey-8" icon="more_horiz" direction="down"
            padding="xs" vertical-actions-align="right" :persistent="false">
            <q-fab-action :disable="publicListingDetails.company?.whatsapp?.length === 0" color="grey-2"
              text-color="black" @click="onSendWhatsApp">
              <template v-slot:icon>
                <img src="@/assets/whats-app-logo-xxxx-small.png" width="25px" />
              </template>
              &nbsp;
              <span v-if="publicListingDetails.company?.whatsapp?.length">
                WhatsApp
              </span>
              <span v-else>
                <s>WhatsApp</s>
              </span>
            </q-fab-action>

            <q-fab-action :disable="!publicListingDetails.company?.email?.length === 0" color="grey-2"
              text-color="black" @click="onSendEmail" icon="alternate_email">
              &nbsp;
              <span v-if="publicListingDetails.company?.email?.length">
                Email
              </span>
              <span v-else>
                <s>Email</s>
              </span>
            </q-fab-action>

            <q-fab-action :disable="!publicListingDetails.company?.phone_number?.length === 0" color="grey-2"
              text-color="black" @click="onCall" icon="phone">
              &nbsp;
              <span v-if="publicListingDetails.company?.phone_number?.length">
                Call
              </span>
              <span v-else>
                <s>Call</s>
              </span>
            </q-fab-action>

            <q-fab-action :disable="!publicListingDetails?.original_url?.length === 0" color="grey-2" text-color="black"
              @click="onHostListingLink" icon="travel_explore">
              &nbsp;
              <span v-if="publicListingDetails.original_url?.length">
                Website
              </span>
              <span v-else>
                <s>Website</s>
              </span>
            </q-fab-action>
          </q-fab>
        </div>
      </div>

      <div v-if="user.is_ops" class="row q-mt-sm">
        <div class="col">
          <q-btn size="sm" :color="publicListingDetails.is_active ? 'red' : 'green'" @click="setListingStatus">
            <span v-if="publicListingDetails.is_active">Deactivate</span>
            <span v-else>Activate</span>
            &nbsp; Listing
          </q-btn>
        </div>
      </div>

      <q-separator class="q-my-sm" />

      <div class="flex q-mx-sm">
        <div class="text-body1 self-center">
          <q-icon name="bed" size="sm" /> {{ publicListingDetails.bedrooms }}
          <template v-if="user.is_ops">
            <span v-if="!publicListingDetails.bedrooms">Click to Update bedrooms</span>
            <q-popup-edit ref="bedroomsPopupRef" :disable="opsUpdatingListing" v-model="publicListingDetails.bedrooms"
              v-slot="scope" persistent>
              <q-input maxlength="1" counter autofocus autogrow dense v-model="scope.value" :model-value="scope.value"
                hint="Update bedrooms for this listing.">
                <template v-slot:after>
                  <q-btn :disable="opsUpdatingListing" flat dense color="negatives" icon="cancel"
                    @click="cancel(scope, 'bedrooms')" />
                  <q-btn flat dense color="green" icon="check_circle" @click="updateListing(scope, 'bedrooms')"
                    :disable="opsUpdatingListing" />
                </template>
              </q-input>
            </q-popup-edit>
          </template>
        </div>
        <div class="text-body1 q-ml-md self-center">
          <q-icon name="bathtub" size="sm" /> {{ publicListingDetails.bathrooms }}
          <template v-if="user.is_ops">
            <span v-if="!publicListingDetails.bathrooms">Click to Update bathrooms</span>
            <q-popup-edit ref="bathroomsPopupRef" :disable="opsUpdatingListing" v-model="publicListingDetails.bathrooms"
              v-slot="scope" persistent>
              <q-input maxlength="1" counter autofocus autogrow dense v-model="scope.value" :model-value="scope.value"
                hint="Update bathrooms for this listing.">
                <template v-slot:after>
                  <q-btn :disable="opsUpdatingListing" flat dense color="negatives" icon="cancel"
                    @click="cancel(scope, 'bathrooms')" />
                  <q-btn flat dense color="green" icon="check_circle" @click="updateListing(scope, 'bathrooms')"
                    :disable="opsUpdatingListing" />
                </template>
              </q-input>
            </q-popup-edit>
          </template>
        </div>
        <div class="text-body1 q-ml-md self-center">
          <q-icon name="square_foot" size="sm" /> <span class="self-center" style="margin-left: -5px;">{{
            publicListingDetails.property_sqft
            ||
            '-' }}</span>
          <template v-if="user.is_ops">
            <span v-if="!publicListingDetails.property_sqft">Click to Update Sqft</span>
            <q-popup-edit ref="propertySqftPopupRef" :disable="opsUpdatingListing"
              v-model="publicListingDetails.property_sqft" v-slot="scope" persistent>
              <q-input maxlength="5" counter autofocus autogrow dense v-model="scope.value" :model-value="scope.value"
                hint="Update sqft for this listing.">
                <template v-slot:after>
                  <q-btn :disable="opsUpdatingListing" flat dense color="negatives" icon="cancel"
                    @click="cancel(scope, 'property_sqft')" />
                  <q-btn flat dense color="green" icon="check_circle" @click="updateListing(scope, 'property_sqft')"
                    :disable="opsUpdatingListing" />
                </template>
              </q-input>
            </q-popup-edit>
          </template>
        </div>
        <q-space />

        <div v-if="publicListingDetails.amenities?.length" class="self-center text-grey-8"
          @click="expandedAmenities = !expandedAmenities">
          All amenities
        </div>
        <q-btn v-if="publicListingDetails.amenities?.length" color="grey" round flat dense
          :icon="expandedAmenities ? 'keyboard_arrow_up' : 'keyboard_arrow_down'"
          @click="expandedAmenities = !expandedAmenities" />
      </div>

      <q-slide-transition v-if="publicListingDetails.amenities?.length">
        <div v-show="expandedAmenities">
          <div class="flex q-mx-xs">
            <q-chip v-for="(amenity, index) in publicListingDetails.amenities" :key="index" size="md"
              :icon="amenity.icon" :label="amenity.name_friendly" color="grey-4" />
          </div>
        </div>
      </q-slide-transition>
    </q-card>

    <div class="q-mt-sm">
      <ListingDetailsPanelMobile />
    </div>

    <CreateReviewDialogMobile />
    <UpdateReviewDialog />
    <FlagListingDialog />
    <FlagReviewListingDialog />

    <q-dialog v-model="listingImagesDialog" maximized style="background-size: contain;">
      <q-card>
        <q-carousel class="fullscreenx" ref="listingImagesRef" thumbnails arrows swipeable animated v-model="slide"
          infinite style="width: 100%; height: 100%;" control-color="black" control-type="regular">
          <q-carousel-slide v-for="(image, index) in publicListingDetails.images" :key="image.uuid" :name="index"
            :img-src="image.signed_url" class="uncropped-image" />

          <template v-slot:control>
            <q-carousel-control position="bottom-right" :offset="[18, 18]">
              <q-btn push round dense color="black" text-color="white"
                :icon="fullscreen ? 'fullscreen_exit' : 'fullscreen'" @click="listingImagesDialog = null" />
            </q-carousel-control>
          </template>
        </q-carousel>
      </q-card>
    </q-dialog>

    <q-dialog ref="showPriceDialogRef" v-model="showPriceDialog">
      <PublicListingPriceCard :price="publicListingDetails.price" />
    </q-dialog>

    <q-dialog ref="showPriceEditDialogRef" v-model="showPriceEditDialog">
      <PricingEditCardOpsMobile />
    </q-dialog>
  </q-page>
</template>

<script setup>
import { onMounted, onBeforeMount, onUnmounted, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useQuasar } from 'quasar'

import { useAuth } from '@/composables/auth'
import { usePublicListings } from '@/composables/publicListings'
import { useVotes } from '@/composables/votes'
import { useBookmarks } from '@/composables/bookmarks'
import { useFlags } from '@/composables/flags'
import { useEngagements } from '@/composables/engagements'
import { useOpsListings } from '@/composables/opsListings'

import ListingDetailsPanelMobile from '@/components/listingDetailsPanel/mobile/ListingDetailsPanelMobile.vue'
import CreateReviewDialogMobile from '@/components/dialogs/mobile/CreateReviewDialogMobile.vue'
import UpdateReviewDialog from '@/components/dialogs/UpdateReviewDialog.vue'
import FlagListingDialog from '@/components/dialogs/FlagListingDialog.vue'
import FlagReviewListingDialog from '@/components/dialogs/FlagReviewListingDialog.vue'
import PublicListingPriceCard from '@/components/cards/PublicListingPriceCard.vue'
import PricingEditCardOpsMobile from '@/components/cards/mobile/PricingEditCardOpsMobile.vue'
import ListingPriceChip from '@/components/chips/ListingPriceChip.vue'

import { notifyLogIn, notifyInfo, notifySorry, notifySuccess } from '@/notifications/events'
import { WHATSAPP_URL, EMAIL_SUBJECT, genContactHostMessage } from '@/constants/contact'

import { useBreadcrumbs } from '@/composables/breadcrumbs'

import {
  FLAG_LISTING_REASONS,
} from '@/constants/flag'
import ListingRatingChip from '@/components/chips/ListingRatingChip.vue'

const route = useRoute()
const router = useRouter()
const $q = useQuasar()

// const fullscreen = ref(false)

const { user, token, showNewOpsChip } = useAuth()
const {
  fetchPublicListingDetails,
  getListingDetailsSelf,
  publicListingDetails,
  publicListingDetailsSelf,
} = usePublicListings()

const {
  opsUpdateListingForm,
  opsUpdatingListing,
  opsUpdateListing,
} = useOpsListings()

const {
  createEngagementContactWhatsApp,
  createEngagementContactEmail,
  createEngagementContactPhone,
  createEngagementHostListingLink,
} = useEngagements()

const { showListingsBreadcrumbs, showHostDetailsBreadcrumbs } = useBreadcrumbs()

const {
  castListingVote,
} = useVotes()

const {
  setListingBookmark
} = useBookmarks()

const {
  showFlagListingDialog,
  flagListing,
} = useFlags()

const slide = ref(0)
const expandedAmenities = ref(false)
const fullscreen = ref(false)
const listingImagesDialog = ref(null)
const showPriceDialogRef = ref(null)
const showPriceEditDialogRef = ref(null)

const titlePopupRef = ref(null)
const subtitlePopupRef = ref(null)

const showPriceDialog = ref(false)
const showPriceEditDialog = ref(false)

const contactFab = ref(false)

// To handle keyboard events to control the carousel
const listingImagesRef = ref(null)
let keyboardListener

onBeforeMount(() => {
  keyboardListener = (event) => {
    switch (event.key) {
      case 'Enter':
        if (listingImagesRef.value) {
          fullscreen.value = true
        }
        break
      case 'ArrowRight':
        if (listingImagesRef.value) {
          listingImagesRef.value.next()
        }
        break
      case 'ArrowLeft':
        if (listingImagesRef.value) {
          listingImagesRef.value.previous()
        }
        break
      case 'Escape':
        if (listingImagesRef.value) {
          listingImagesRef.value.exitFullscreen()
        } else if (showPriceDialogRef.value) {
          showPriceDialogRef.value.hide()
        }
        break
    }
  }
  window.addEventListener('keyup', keyboardListener)
})

onMounted(async () => {
  if (!$q.platform.is.mobile) {
    router.push({ name: 'publicListingsDetailsView' })
  }

  showListingsBreadcrumbs.value = true

  await fetchPublicListingDetails(route.params.uuid)
  if (token.value) {
    await getListingDetailsSelf()
  }

  // Go to top of page
  window.scrollTo(0, 0)
})

onUnmounted(() => {
  showListingsBreadcrumbs.value = false
  showHostDetailsBreadcrumbs.value = false
  window.removeEventListener('keyup', keyboardListener)

  // This is important, otherwise upon vising another listing, there
  // is a brief moment where the old listing details (images) are still
  // visible before the new one is fetched.
  publicListingDetails.value = {}
  publicListingDetailsSelf.value = {}
})

const cancel = (scope, field) => {
  // Set it back to the original value
  opsUpdateListingForm.value[field] = publicListingDetails.value[field]
  scope.cancel()
}

const updateListing = async (scope, field) => {
  let value = scope.value
  if (field === 'property_sqft') {
    value = value.split(" ")[0]
  }
  opsUpdateListingForm.value = { [field]: value }
  const result = await opsUpdateListing(route.params.uuid)

  if (result.status === 400) {
    const error = `Error while updating listing: ${result.data[field][0]}`
    notifySorry(error)
  } else {
    scope.set()
    const message = `Successfully updated ${field} to ${value}`
    notifySuccess(message)
    await fetchPublicListingDetails(route.params.uuid)
  }
}

const setListingStatus = async () => {
  opsUpdateListingForm.value = { is_active: !publicListingDetails.value.is_active }
  const result = await opsUpdateListing(route.params.uuid)

  if (result.status === 400) {
    const error = `Error while updating listing: ${JSON.stringify(result.data)}`
    notifySorry(error)
  } else {
    const message = `Successfully updated listing status`
    notifySuccess(message)
    await fetchPublicListingDetails(route.params.uuid)
  }
}

const onVote = async (direction) => {
  if (!token.value) {
    notifyLogIn()
    return
  }

  const resp = await castListingVote(route.params.uuid, direction)
  if (resp.status !== 200) {
    return
  }

  await fetchPublicListingDetails(route.params.uuid)
  if (token.value) {
    await getListingDetailsSelf()
  }
}

const onBookmark = async () => {
  if (!token.value) {
    notifyLogIn()
    return
  }

  const resp = await setListingBookmark(route.params.uuid)
  if (resp.status !== 200) {
    return
  }

  if (token.value) {
    await getListingDetailsSelf()
  }
}

const onSendWhatsApp = () => {
  const phoneNumber = publicListingDetails.value.company.whatsapp
  const domain = import.meta.env.VITE_ROOMBEE_BASE_DOMAIN

  const listingUrl = `${domain}/g/listings/${route.params.uuid}`
  const message = encodeURIComponent(genContactHostMessage(listingUrl))

  const url = `${WHATSAPP_URL}${phoneNumber}?text=${message}`
  window.open(url, '_blank')

  createEngagementContactWhatsApp(route.params.uuid)
}

const onSendEmail = () => {
  const email = publicListingDetails.value.company.email
  const domain = import.meta.env.VITE_ROOMBEE_BASE_DOMAIN

  const listingUrl = `${domain}/g/listings/${route.params.uuid}`
  const message = encodeURIComponent(genContactHostMessage(listingUrl))

  const url = `mailto:${email}?subject=${EMAIL_SUBJECT}&body=${message}`
  window.open(url, '_blank')

  createEngagementContactEmail(route.params.uuid)
}

const onCall = () => {
  const phoneNumber = publicListingDetails.value.company.phone_number

  const url = `tel:${phoneNumber}`
  window.open(url, '_blank')

  createEngagementContactPhone(route.params.uuid)
}

const onHostListingLink = () => {
  window.open(publicListingDetails.value.original_url, '_blank').focus()

  createEngagementHostListingLink(route.params.uuid)
}

const onCopyLink = () => {
  navigator.clipboard.writeText(window.location.href)
  notifyInfo('Copied the link to this Listing.')
}

const onFlag = async () => {
  if (!token.value) {
    notifyLogIn()
    return
  }

  if (publicListingDetailsSelf.value.flag_exists === true) {
    const resp = await flagListing(
      route.params.uuid,
      // Any reason will do, we are triggering a delete.
      FLAG_LISTING_REASONS[0],
      // Any description will do, we are triggering a delete.
      'foo'.repeat(10),
    )

    if (resp.status !== 200) {
      return
    } else if (token.value) {
      await getListingDetailsSelf()
    }
  } else {
    showFlagListingDialog.value = true
  }
}

const openListingImagesDialog = (index) => {
  listingImagesDialog.value = true
  slide.value = index
}
</script>

<style scoped>
.uncropped-image {
  background-size: contain;
  /* don't crop the image  */
  background-repeat: no-repeat;
  /* only show the image one time  */
  background-color: black;
  /* color to fill empty space with  */
}
</style>
