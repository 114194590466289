<template>
  <q-card dark bordered class="bg-red-4">
    <div class="text-overline q-ml-sm text-weight-bold">Operations</div>

    <q-separator dark />

    <slot name="ops-content" />

    <q-card-section>
      <slot name="ops-content-in-section" />
    </q-card-section>
  </q-card>
</template>
