<template>
  <q-card flat class="q-mb-md">
    <q-tabs v-model="profileTab">
      <q-tab name="profile" label="Profile" />
      <q-tab name="bookmarks" label="Bookmarks" />
      <q-tab name="reviews" label="Reviews" />
    </q-tabs>

    <q-separator />

    <q-tab-panels v-model="profileTab" animated class="no-margin">
      <ProfileTabPanelMobile name="profile" />
      <BookmarksTabPanelMobile name="bookmarks" />
      <ReviewsTabPanelMobile name="reviews" />
    </q-tab-panels>

  </q-card>

</template>

<script setup>
import { onMounted } from 'vue'
import { useQuasar } from 'quasar'
import { useRouter } from 'vue-router'

import { useGuestProfile } from '@/composables/guestProfile'

import ProfileTabPanelMobile from '@/components/guestProfilePanels/mobile/ProfileTabPanelMobile.vue'
import BookmarksTabPanelMobile from '@/components/guestProfilePanels/mobile/BookmarksTabPanelMobile.vue'
import ReviewsTabPanelMobile from '@/components/guestProfilePanels/mobile/ReviewsTabPanelMobile.vue'

const {
  profileTab,
} = useGuestProfile()

const $q = useQuasar()
const router = useRouter()

onMounted(async () => {
  if ($q.platform.is.desktop) {
    router.push({ name: 'guestProfileView' })
  }
})
</script>
