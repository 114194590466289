<template>
  <q-dialog v-model="showUploadImageDialog" persistent>
    <q-card style="width: 600px; max-width: 100%;">
      <q-card-section>
        <div class="row">
          <div class="col-9">
            <q-file :model-value="imagesToUpload" @update:model-value="updateFiles" label="Pick files" outlined multiple
              :clearable="!uploadingImages" style="max-width: 100%;">
              <template v-slot:file="{ index, file }">
                <q-chip class="full-width q-my-xs row" :removable="uploadingImages" square @remove="cancelFile(index)">
                  <div class="col-auto">
                    <q-avatar>
                      <q-icon :name="uploadProgress[index]?.icon" />
                    </q-avatar>
                  </div>

                  <div class="col-auto ellipsis relative-position" :class="'text-' + uploadProgress[index]?.color">
                    {{ file.name.substring(0, 44) }}
                  </div>

                  <div class="col text-right" v-if="uploadProgress[index]?.uploading">
                    <q-circular-progress indeterminate size="sm" :thickness="0.3" font-size="50px" color="blue-3"
                      track-color="grey-3" center-color="blue-7" class="q-mr-md" />
                  </div>

                  <div class="col text-right" v-if="uploadProgress[index]?.success">
                    <q-icon name="check_circle" color="green" size="sm" />
                  </div>
                </q-chip>
              </template>
            </q-file>
          </div>
          <div class="col q-ml-sm self-center">
            <q-btn color="primary" dense icon="cloud_upload" @click="uploadImages"
              :disable="!imagesToUpload || uploadingImages" :loading="uploadingImages" label="Upload Images" />
          </div>
        </div>

      </q-card-section>

      <q-card-actions align="right" class="bg-white text-orange">
        <q-btn flat label="Cancel" v-close-popup />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>


<script setup>
import { ref } from 'vue'
import { useRoute } from 'vue-router'

import { useImages } from '@/composables/images'

import { notifyHostUpdateValueError } from '@/notifications/events'

const route = useRoute()

const imagesToUpload = ref(null)
const uploadingImages = ref(false)
const uploadProgress = ref([])

const maxBytes = parseInt(Number(import.meta.env.VITE_ROOMBEE_IMAGE_MAX_BYTES))

const NOTIF_ERROR_COLOR = 'red'
const NOTIF_SUCCESS_COLOR = 'green'
const NOTIF_TEXT_COLOR = 'grey-10'

const {
  createPresignedUrl,
  updateListingImage,
  fetchListingImages,
  fetchListingImageInfo,
  showUploadImageDialog,
  listingImages,
} = useImages()

const updateFiles = async (newFiles) => {
  imagesToUpload.value = newFiles
  uploadProgress.value = (newFiles || []).map((file) => ({
    file: file,
    error: false,
    success: false,
    uploading: false,
    color: NOTIF_TEXT_COLOR,
    icon: 'photo',
  }))
}


const clearUploadProgress = () => {
  uploadProgress.value = (uploadProgress.value).map((file) => ({
    file: file,
    error: false,
    success: false,
    uploading: false,
    color: file.color,
    icon: 'photo',
  }))
}

const uploadImages = async () => {
  uploadingImages.value = true
  const errors = []
  const tooLargeFilenames = []

  let uploadedCount = 0
  for (const [index, file] of imagesToUpload.value.entries()) {
    if (file.size > maxBytes) {
      tooLargeFilenames.push(file.name)
      continue
    }

    const isSuccess = await _uploadImage(index, file)
    if (!isSuccess) {
      continue
    }
    uploadedCount++
  }

  uploadingImages.value = false
  clearUploadProgress()

  if (uploadedCount === 0) {
    return
  }

  if (!errors.length) {
    await fetchListingImages(route.params.uuid)
    // Ensure parent component's image info is refreshed
    await fetchListingImageInfo(route.params.uuid)
    imagesToUpload.value = null
    showUploadImageDialog.value = false
  }

  if (tooLargeFilenames.length > 0) {
    const maxAllowedMB = parseFloat(maxBytes / 1024 / 1024).toFixed(2)
    const error = `The max allowed file size is ${maxAllowedMB} MB. These files are too large and were not uploaded: ${tooLargeFilenames.join(', ')}`
    notifyHostUpdateValueError(error)
  }
}

const _uploadImage = async (index, file) => {
  const progress = [...uploadProgress.value]
  progress[index].uploading = true
  uploadProgress.value = progress

  // Calculate the next order of images. First get the current max order.
  // `index` is zero-indexed, thus the +1.
  const order = listingImages.value.length + (index + 1)
  const result = await fetchPresignedUrl(file.name, file.type, order)
  if (!result) {
    return false
  }

  const formdata = new FormData()
  formdata.append("image", file)
  const options = {
    method: 'PUT',
    body: file,
    headers: { 'Content-Type': "multipart/form-data" }
  }

  let resp
  try {
    resp = await fetch(result.presigned_url, options)
  } catch {
    notifyHostUpdateValueError('There was a issue uploading the image. Please try again later or contact support. Error: 101.')
    progress[index].uploading = false
    progress[index].color = 'red'
    uploadProgress.value = progress
    return false
  }

  if (resp.status !== 200) {
    notifyHostUpdateValueError('The image server is experiencing some problems. Please try again later or contact support. Error: 102.')
    progress[index].uploading = false
    progress[index].color = NOTIF_ERROR_COLOR
    uploadProgress.value = progress
    return false
  }

  const form = { is_uploaded: true }
  const updateResp = await updateListingImage(route.params.uuid, result.uuid, form)

  progress[index].uploading = false
  if (updateResp.status === 200) {
    progress[index].success = true
    progress[index].color = NOTIF_SUCCESS_COLOR
    uploadProgress.value = progress
    return true
  } else {
    notifyHostUpdateValueError('The image service is experiencing problems. Please try again later or contact support. Error: 103.')
    progress[index].success = false
    progress[index].color = NOTIF_ERROR_COLOR
    uploadProgress.value = progress
    return false
  }
}

const cancelFile = (index) => {
  uploadProgress.value[index] = {
    ...uploadProgress.value[index],
    error: true,
    color: 'orange-2'
  }
}

const fetchPresignedUrl = async (filename, mimeType, order) => {
  const form = {
    filename,
    mime_type: mimeType,
    order,
  }
  const result = await createPresignedUrl(route.params.uuid, form)

  if (result.status === 201) {
    return result.data
  } else {
    const error = result.data.error || `Error while preparing the upload! Error: 100.`
    notifyHostUpdateValueError(error)
    return null
  }
}
</script>
