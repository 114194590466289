<template>
  <q-tab-panel>
    <HostDetailsListingListItemMobile :listing="listing" v-for="listing in listings" :key="listing.uuid" />

    <div class="q-pb-sm flex flex-center" v-if="listings.length">
      <q-pagination v-model="listingsPage.pageCurrent" color="primary" :max="listingsPage.pageLast"
        :max-pages="listingsPage.pageMaxLinks" boundary-numbers />
    </div>
  </q-tab-panel>
</template>


<script setup>
import { onMounted } from 'vue'

import { useRoute } from 'vue-router'

import { usePublicHost } from '@/composables/publicHost'

import HostDetailsListingListItemMobile from '@/components/cards/mobile/HostDetailsListingListItemMobile.vue'

const route = useRoute()

const {
  listings,
  listingsPage,
  tab,
} = usePublicHost()

onMounted(async () => {
  tab.value = 'listings'
  window.history.replaceState(null, '', `${route.path}?tab=listings`)
})
</script>
