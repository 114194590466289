export const BATHROOM_OPTIONS = [1, 2, 3, 4, 5, 6]
export const BEDROOM_OPTIONS = [1, 2, 3, 4, 5, 6]

export const COMFORT_AMENITIES = [
  { label: 'Air Conditioner', value: 'has_air_conditioner' },
  { label: 'Blackout Curtains', value: 'has_blackout_shades' },
  { label: 'Chilled Water', value: 'has_chilled_water' },
  { label: 'Heated Water', value: 'has_heated_water' },
  { label: 'Heating', value: 'has_heating' },
]

export const KITCHEN_AMENITIES = [
  { label: 'Coffee Machine', value: 'has_coffee_machine' },
  { label: 'Fridge', value: 'has_fridge' },
  { label: 'Kettle', value: 'has_kettle' },
  { label: 'Stove', value: 'has_kitchen' },
  { label: 'Kitchen Utensils', value: 'has_kitchen_utensils' },
  { label: 'Kitchen Basics', value: 'has_kitchen_condiments' },
  { label: 'Microwave', value: 'has_microwave' },
  { label: 'Oven', value: 'has_oven' },
  { label: 'Rice Cooker', value: 'has_rice_cooker' },
  { label: 'Silverware', value: 'has_silverware' },
  { label: 'Toaster', value: 'has_toaster' },
]

export const LIVING_AMENITIES = [
  { label: 'Bedsheets', value: 'has_bedsheet' },
  { label: 'Dryer', value: 'has_dryer' },
  { label: 'Drying Rack', value: 'has_drying_rack' },
  { label: 'Clothes Hangers', value: 'has_hanger' },
  { label: 'Ironing Board', value: 'has_iron' },
  { label: 'Wardrobe', value: 'has_wardrobe' },
  { label: 'Washer', value: 'has_washing_machine' },
]

export const WASHROOM_AMENITIES = [
  { label: 'Bathtub', value: 'has_bathtub' },
  { label: 'Hair Dryer', value: 'has_hair_dryer' },
  { label: 'Toiletries', value: 'has_toiletry' },
  { label: 'Towels', value: 'has_towel' },
]

export const COMMUNITY_AMENITIES = [
  { label: 'Private Beach Access', value: 'has_beach_access' }
]
export const BUILDING_AMENITIES = [
  { label: 'Balcony', value: 'has_balcony' },
  { label: 'Elevator', value: 'has_elevator' },
  { label: 'Gym', value: 'has_gym' },
  { label: 'Jacuzzi', value: 'has_jacuzzi' },
  { label: 'Private Entrance', value: 'has_private_entrance' },
  { label: 'Private Pool', value: 'has_pool_private' },
  { label: 'Sauna', value: 'has_sauna' },
  { label: 'Steam Room', value: 'has_steam_room' },
  { label: 'Shared Pool', value: 'has_pool_shared' },
  { label: 'Roof Access', value: 'has_roof_access' },
  { label: 'Self Check-in', value: 'has_self_checkin' },
  { label: 'WiFi', value: 'has_wifi' },
  { label: 'Workspace/Desk', value: 'has_workspace' },
]
export const SAFETY_AMENITIES = [
  { label: 'Fire Extinguisher', value: 'has_fire_extinguisher' },
  { label: 'Fist Aid Kit', value: 'has_first_aid' },
  { label: 'Safe / Security Box', value: 'has_safe' },
]

export const CITY_RULES = [
  val => (val && val.length > 0) || 'Please select an Emirate'
]

export const NEIGHBOURHOOD_RULES = [
  val => (val && val.length > 0) || 'Please select a Neighbourhood'
]

export const PROPERTY_TYPE_RULES = [
  val => (val && val.length > 0) || 'Please select a Property Type'
]

export const BEDROOMS_RULES = [
  val => (typeof val === 'number' && val >= 0) || 'Please enter the number of Bedrooms'
]

export const BATHROOMS_RULES = [
  val => (typeof val === 'number' && val >= 0) || 'Please enter the number of Bathrooms'
]

export const UNITED_ARAB_EMIRATES = 'United Arab Emirates'
