import { defineStore } from 'pinia'

import { client } from '@/services/request'

const LOCAL_STORAGE_TOKEN_KEY = import.meta.env.VITE_ROOMBEE_LOCAL_STORAGE_TOKEN_NAME
const LOCAL_STORAGE_USER_KEY = import.meta.env.VITE_ROOMBEE_LOCAL_STORAGE_USER_NAME

export const useStoreAuth = defineStore('storeAuth', {
  state: () => {
    return {
      // To test locally, create backend dev users, then set the
      // token here manually.
      //
      // token: `${import.meta.env.VITE_ROOMBEE_DEV_USER_TOKEN}`
      token: null,
      user: {
        uuid: '',
        email: '',
        username: '',
        joined_on: '',
        is_host: false,
        is_ops: false,
      },
    }
  },

  actions: {
    /**
     * Use when reloading the app. The token and user data might be inside
     * localStorage already.
     */
    reloadTokenAndUser() {
      this.getToken()
      this.getUser()
    },

    setToken(token) {
      this.token = token
      // Also store the token in localStorage
      localStorage.setItem(LOCAL_STORAGE_TOKEN_KEY, token)
    },

    getToken() {
      // if token is null, try to load from localStorage
      if (!this.token) {
        this.token = localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY)
      }

      return this.token
    },

    getUser() {
      // if user is empty, try to load from localStorage
      if (!this.user.username) {
        const userJSON = localStorage.getItem(LOCAL_STORAGE_USER_KEY)
        if (userJSON) {
          this.user = JSON.parse(userJSON)
        }
      }

      return this.user
    },

    isOpsUser() {
      const token = this.getToken()
      if (!token) {
        return false
      }

      const user = this.getUser()
      if (user.is_ops) {
        return true
      }

      return false
    },

    showNewOpsChip(listing) {
      if (!listing) {
        return false
      }

      if (!this.isOpsUser()) {
        return false
      }

      if (listing.is_active === false) {
        return true
      }

      return false
    },

    async fetchUser() {
      const url = 'rb-auth/user/'
      const result = await client.authRequest(url)

      if (result.status === 200) {
        this.user.uuid = result.data.uuid
        this.user.email = result.data.email
        this.user.username = result.data.username
        this.user.joined_on = result.data.joined_on
        this.user.is_host = result.data.is_host
        this.user.is_ops = result.data.is_ops

        localStorage.setItem(LOCAL_STORAGE_USER_KEY, JSON.stringify(this.user))

        return { status: 200, data: 'Successfully fetched user.' }
      } else {
        return { status: 400, data: 'Error fetching user.' }
      }
    },

    async logIn(form) {
      const url = `rb-auth/login/`

      const result = await client.anonRequest(url, { method: 'POST', form })

      // Store the token
      this.setToken(result.data.key)

      if (result.status === 200) {
        const _result = await this.fetchUser()
        if (_result.status !== 200) {
          // Reset the token if we can't fetch the user, as that indicates
          // an auth/user security problem.
          this.logOut()
        } else {
          return { status: 200, data: 'Successfully logged in.' }
        }
      }

      return { status: 400, data: 'Unexpected error authenticating!' }
    },

    async logOut() {
      localStorage.removeItem(LOCAL_STORAGE_TOKEN_KEY)
      localStorage.removeItem(LOCAL_STORAGE_USER_KEY)
      this.token = null
      this.user = { uuid: '', username: '', email: '', joined_on: '', is_host: false, is_ops: false }
    },

    async createAccount(form, accountType) {
      const url = `rb-auth/${accountType}/registration/`

      return await client.anonRequest(url, { method: 'POST', form })
    },

    async verifyEmailKey(form) {
      const url = `rb-auth/registration/verify-email/`

      const result = await client.anonRequest(url, { method: 'POST', form })

      return result
    },

    async verifyOauthTokenGoogle(token) {
      const url = `rb-auth/google/`
      const form = { code: token }
      return await client.anonRequest(url, { method: 'POST', form })
    },

    async verifyOauthTokenFacebook(token, email) {
      const url = `rb-auth/facebook/`
      const form = {
        access_token: token,
        email,
      }
      return await client.anonRequest(url, { method: 'POST', form })
    },

    async setOpsCompany(companyUuid) {
      const url = `ops/impersonate/company/${companyUuid}/`
      const result = await client.authRequest(url, { method: 'PATCH', raiseErrors: false })
      return result.status === 200
    },
  },
})
