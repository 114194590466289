export const HOST_PAGE_SIZE_LISTINGS = 10
export const HOST_PAGE_SIZE_LISTINGS_DISPLAY_MAX = 10

export const HOST_PROFILE_PAGE_SIZE_LISTINGS = 10
export const HOST_PROFILE_PAGE_SIZE_LISTINGS_DISPLAY_MAX = 10

export const HOST_PROFILE_PAGE_SIZE_REVIEWS = 10
export const HOST_PROFILE_PAGE_SIZE_REVIEWS_DISPLAY_MAX = 10

export const PUBLIC_PAGE_SIZE_LISTINGS = 16
export const PUBLIC_PAGE_SIZE_LISTINGS_DISPLAY_MAX = 10

export const LISTING_REVIEWS_PAGE_SIZE = 10
export const LISTING_REVIEWS_PAGE_SIZE_DISPLAY_MAX = 10

export const GUEST_BOOKMARKS_PAGE_SIZE = 10
export const GUEST_BOOKMARKS_PAGE_SIZE_DISPLAY_MAX = 10

export const GUEST_REVIEWS_PAGE_SIZE = 10
export const GUEST_REVIEW_PAGE_SIZE_DISPLAY_MAX = 10

export const HOST_SEARCH_FILTER_OPTIONS = [
    'All',
    'Title',
    'Description',
]
export const HOST_SEARCH_ORDER_OPTIONS = [
    'Newest',
    'Oldest',
]
