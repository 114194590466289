<template>
  <q-card flat bordered>

    <q-tabs v-model="hostProfileTab" dense class="text-grey-8" active-color="primary" indicator-color="primary"
      align="center" narrow-indicator>
      <q-tab name="companyDetails" label="Company Details" />
      <!-- <q-tab name="plan" label="Plan" /> -->
    </q-tabs>

    <q-separator />

    <q-tab-panels v-model="hostProfileTab" animated>
      <company-tab-panel name="companyDetails" />
      <!-- <license-tab-panel name="plan" /> -->
    </q-tab-panels>
  </q-card>
</template>

<script setup>
import { useHostProfile } from '@/composables/hostProfile'

import CompanyTabPanel from '@/components/hostProfilePanel/panels/CompanyTabPanel.vue'
// TODO-FEATURE: Enable this once we are ready to turn on sales and prospecting pipeline
// import LicenseTabPanel from '@/components/hostProfilePanel/panels/LicenseTabPanel.vue'

const {
  hostProfileTab,
} = useHostProfile()


</script>
