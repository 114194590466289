<template>
  <div :class="headingClass">
    Terms of Service
    <div class="text-caption text-grey-8 text-center q-mb-md">Last updated: 24th October, 2024</div>
  </div>
  <div :class="paragraphClass">
    Thank you for using {{ roombDomain }}! We built this service to help you find the most affordable and transparently
    rated short-term rentals across the UAE. Since it is used by quite a number of people, and since we don't know them
    all personally, we have to put into place some Terms of Service to ensure the quality of our content and our
    usefulness to our users.
  </div>

  <div :class="paragraphClass">
    When we say “Company”, “we”, “our”, or “us” in this document, we are referring to {{ roombCompanyLegal }}.
  </div>

  <div :class="paragraphClass">
    When we say “Services”, we mean our website, including {{ roombDomain }} and any product created and maintained by
    {{ roombCompanyLegal }}. That includes all versions of {{ roombDomain }}, whether delivered within a web browser,
    desktop
    application, mobile application, or another format.
  </div>

  <div :class="paragraphClass">
    When we say “You” or “your”, we are referring to the people or organizations that own an account with one or more of
    our Services.
  </div>

  <div :class="paragraphClass">
    We may update these Terms of Service ("Terms") in the future. Typically these changes will be to clarify some of
    these terms by linking to an expanded related policy. Whenever we make a significant change to our policies, we will
    refresh the date at the top of this page and take any other appropriate steps to notify account holders.
  </div>

  <div :class="paragraphClass">
    When you use our Services, now or in the future, you are agreeing to the latest Terms. There may be times where we
    do not exercise or enforce a right or provision of the Terms; however, that does not mean we are waiving that right
    or provision. These Terms do contain a limitation of our liability.
  </div>

  <div :class="paragraphClass">
    If you violate any of the Terms, we may terminate your account. That's a broad statement and it means you need to
    place a lot of trust in us. We do our best to deserve that trust by being open about who we are, how we work, and
    keeping an open door to your feedback at {{ roombSupportEmail }}.
  </div>

  <q-separator class="q-mt-lg q-mb-md" inset />

  <div :class="headingClass">
    Account Terms
  </div>

  <div :class="paragraphClass">
    <ol>
      <li>
        You are responsible for maintaining the security of your account and password. The Company cannot and will not
        be liable for any loss or damage from your failure to comply with this security obligation.
      </li>
      <li>
        You may not use the Services for any purpose outlined in our Use Restrictions policy below, and you may not
        permit any
        of your users to do so, either.
      </li>
      <li>
        You are responsible for all content posted to and activity that occurs under your account, including content
        posted by and activity of any users in your account.
      </li>
      <li>
        You must be a human. Accounts registered by “bots” or other automated methods are not permitted.
      </li>
    </ol>
  </div>

  <q-separator class="q-mt-lg q-mb-md" inset />

  <div :class="headingClass">
    Use Restrictions
  </div>

  <div :class="paragraphClass">
    When you use any of {{ roombDomain }}'s Services, you acknowledge that you may not:
    <ul>
      <li>Collect or extract information and/or user data from accounts which do not belong to you.</li>
      <li>Circumvent, disable, or otherwise interfere with security-related features of the Services.</li>
      <li>Trick, defraud, or mislead us or other users, including but not limited to making false reports or
        impersonating another user.</li>
      <li>Upload or transmit (or attempt to upload or to transmit) viruses or any type of malware, or information
        collection mechanism, including 1x1 pixels, web bugs, cookies, or other similar devices.</li>
      <li>Interfere with, disrupt, or create an undue burden on the Services or the networks or the Services connected.
      </li>
      <li>Harass, annoy, intimidate, or threaten others, or any of our employees engaged in providing any portion of the
        Services to you.</li>
      <li>Disparage, tarnish, or otherwise harm, in our opinion, us and/or the Services.</li>
      <li>Use the Services in a manner inconsistent with any applicable laws or regulations.</li>
      <li>Accounts found to be in violation of any of the above are subject to cancellation without prior notice.</li>
    </ul>
  </div>

  <q-separator class="q-mt-lg q-mb-md" inset />

  <div :class="headingClass">
    Reporting Abuse
  </div>

  <div :class="paragraphClass">
    Violations can be reported by emailing {{ roombSupportEmail }} and should include detailed information about the
    account, the content or behavior you are reporting, and how you found it, including URLs or screenshots.
  </div>

  <q-separator class="q-mt-lg q-mb-md" inset />

  <div :class="headingClass">
    Cancellation and Termination
  </div>

  <div :class="paragraphClass">
    <ol>
      <li>
        You are solely responsible for properly canceling your account. If you need help canceling your account, you can
        always contact our Support team at {{ roombSupportEmail }}.
      </li>
      <li>
        All of your content will be inaccessible from the Services immediately upon account cancellation. Within 30
        days, all content will be permanently deleted from active systems and logs. Within 60 days, all content will be
        permanently deleted from our backups. We cannot recover this information once it has been permanently deleted.
      </li>
      <li>
        We have the right to suspend or terminate your account and refuse any and all current or future use of our
        Services for any reason at any time. Suspension means you and any other users on your account will not be able
        to access the account or any content in the account. Termination will furthermore result in the deletion of your
        account or your access to your account, and the forfeiture and relinquishment of all content in your account. We
        also reserve the right to refuse the use of the Services to anyone for any reason at any time. We have this
        clause because statistically speaking, out of the hundreds of thousands of accounts there is at least one doing
        something nefarious. There are some things we staunchly stand against and this clause is how we exercise that
        stance. For more details, see our Use Restrictions policy below.
      </li>
      <li>Verbal, physical, written or other abuse (including threats of abuse or retribution) of a Company employee or
        officer will result in immediate account termination.</li>
    </ol>
  </div>

  <q-separator class="q-mt-lg q-mb-md" inset />

  <div :class="headingClass">Uptime, Security, and Privacy</div>

  <div :class="paragraphClass">
    <ol>
      <li>
        Your use of the Services is at your sole risk. We provide these Services on an “as is” and “as available” basis.
      </li>
      <li>
        We reserve the right to temporarily disable your account if your usage significantly exceeds the average usage
        of other customers of the Services. Of course, we'll reach out to the account owner before taking any action
        except in rare cases where the level of use may negatively impact the performance of the Service for other
        customers.
      </li>
      <li>
        We take many measures to protect and secure your data through backups, redundancies, and encryption. We enforce
        encryption for data transmission from the public Internet.
      </li>
      <li>
        When you use our Services, you entrust us with your data. We take that trust to heart. You agree that {{
          roombDomain }} may process your data as described in our Privacy Policy and for no other purpose. We as humans
        can access your data for the following reasons:
        <ul>
          <li>
            To help you with support requests you make.
          </li>
          <li>
            To safeguard {{ roombDomain }}. We'll look at logs and metadata as part of our work to ensure the security
            of your data and the Services as a whole. If necessary, we may also access accounts as part of an abuse
            report investigation.
          </li>
          <li>
            To the extent required by applicable law.
          </li>
        </ul>
      </li>
      <li>
        We use third party vendors and hosting partners to provide the necessary hardware, software, networking,
        storage, and related technology required to run the Services.
      </li>
      <li>
        We process any data you share with us only for the purpose you signed up for and as described in these Terms and
        the Privacy policy. We do not retain, use, disclose, or sell any of that information for any other commercial
        purposes unless we have your explicit permission. And on the flip-side, you agree to not use {{ roombDomain }}
        in a way that violates any laws or regulations.
      </li>
    </ol>
  </div>

  <q-separator class="q-mt-lg q-mb-md" inset />

  <div :class="headingClass">
    Copyright and Content Ownership
  </div>

  <div :class="paragraphClass">
    <ol>
      <li>
        All content posted on the Services must comply with UAE copyright law.
      </li>
      <li>
        You give us a limited license to use the content posted by you and your users in order to provide the Services
        to you, but we claim no ownership rights over those materials. All materials you submit to the Services remain
        yours.
      </li>
      <li>
        We do not pre-screen content, but we reserve the right (but not the obligation) in our sole discretion to refuse
        or remove any content that is available via the Service.
      </li>
      <li>
        The Company or its licensors own all right, title, and interest in and to the Services, including all
        intellectual property rights therein, and you obtain no ownership rights in the Services as a result of your
        use. You may not duplicate, copy, or reuse any portion of the HTML, CSS, JavaScript, or visual design elements
        without express written permission from the Company. You must request permission to use the Company's logos or
        any Service logos for promotional purposes. Please email us at {{ roombSupportEmail }} for requests to use
        logos. We reserve the right to rescind any permissions if you violate these Terms.
      </li>
      <li>
        You agree not to reproduce, duplicate, copy, sell, resell or exploit any portion of the Services, use of the
        Services, or access to the Services without the express written permission of the Company.
      </li>
    </ol>
  </div>

  <q-separator class="q-mt-lg q-mb-md" inset />

  <div :class="headingClass">
    Features and Bugs
  </div>

  <div :class="paragraphClass">
    We design our Services with care, based on our own experience and the experiences of customers who share their time
    and feedback. However, there is no such thing as a service that pleases everybody. We make no guarantees that our
    Services will meet your specific requirements or expectations.
  </div>

  <div :class="paragraphClass">
    We also test all of our features extensively before shipping them. As with any software, our Services inevitably
    have some bugs. We track the bugs reported to us and work through priority ones, especially any related to security
    or privacy. Not all reported bugs will get fixed and we don't guarantee completely error-free Services.
  </div>

  <q-separator class="q-mt-lg q-mb-md" inset />

  <div :class="headingClass">
    Services Adaptations and API Terms
  </div>

  <div :class="paragraphClass">
    We offer Application Program Interfaces (“API”s) for some of our Services. Any use of the API, including through a
    third-party product that accesses the Services, is bound by these Terms plus the following specific terms:
    <ol>
      <li>
        You expressly understand and agree that we are not liable for any damages or losses resulting from your use of
        the API or third-party products that access data via the API.
      </li>
      <li>
        Third parties may not access and employ the API if the functionality is part of an application that remotely
        records, monitors, or reports a Service user's activity, both inside and outside the applications. The Company,
        in its sole discretion, will determine if an integration service violates this bylaw. A third party that has
        built and deployed an integration for the purpose of remote user surveillance will be required to remove that
        integration.
      </li>
      <li>
        Abuse or excessively frequent requests to the Services via the API may result in the temporary or permanent
        suspension of your account's access to the API. The Company, in its sole discretion, will determine abuse or
        excessive usage of the API. If we need to suspend your account's access, we will attempt to warn the account
        owner first. If your API usage could or has caused downtime, we may cut off access without prior notice.
      </li>
    </ol>
  </div>

  <q-separator class="q-mt-lg q-mb-md" inset />

  <div :class="headingClass">
    Liability
  </div>

  <div :class="paragraphClass">
    We mention liability throughout these Terms but to put it all in one section:
  </div>

  <div :class="paragraphClass">
    You expressly understand and agree that the Company shall not be liable, in law or in equity, to you or to any third
    party for any direct, indirect, incidental, lost profits, special, consequential, punitive or exemplary damages,
    including, but not limited to, damages for loss of profits, goodwill, use, data or other intangible losses (even if
    the Company has been advised of the possibility of such damages), resulting from: (i) the use or the inability to
    use the Services; (ii) the cost of procurement of substitute goods and services resulting from any goods, data,
    information or services purchased or obtained or messages received or transactions entered into through or from the
    Services; (iii) unauthorized access to or alteration of your transmissions or data; (iv) statements or conduct of
    any third party on the service; (v) or any other matter relating to these Terms or the Services, whether as a breach
    of contract, tort (including negligence whether active or passive), or any other theory of liability.
  </div>

  <div :class="paragraphClass">
    In other words: choosing to use our Services does mean you are making a bet on us. If the bet does not work out,
    that's on you, not us. We do our darnedest to be as safe a bet as possible through careful management of the
    business; investments in security, infrastructure, and talent; and in general giving a damn. If you choose to use
    our Services, thank you for betting on us.
  </div>

  <div :class="paragraphClass">
    If you have a question about any of these Terms, please contact our Support team at {{ roombSupportEmail }}
  </div>
</template>

<script setup>
const roombSupportEmail = import.meta.env.VITE_ROOMBEE_SUPPORT_EMAIL
const roombDomain = import.meta.env.VITE_ROOMBEE_DOMAIN
const roombCompanyLegal = import.meta.env.VITE_ROOMBEE_COMPANY_LEGAL

defineProps({
  headingClass: String,
  paragraphClass: String,
})
</script>
