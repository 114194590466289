<template>
  <div class="q-pb-lg">
    <q-card-section>
      <div class="row justify-center">
        <div class="text-center col-6">
          <q-input ref="emailRef" rounded outlined maxlength="50" counter v-model="email" :rules="emailRules"
            :error="emailError.length > 0" label="Your Email Address" dense />
        </div>
      </div>
    </q-card-section>

    <q-card-section>
      <div class="row justify-center">
        <div class="text-center col-6">
          <q-input ref="password1Ref" :type="isPassword ? 'password' : 'text'" rounded outlined maxlength="50" counter
            :rules="passwordRules1" v-model="password1" label="Set your Password" dense
            :error="errors.password1?.length > 0" :error-message="errors.password1">
            <template v-slot:append>
              <q-icon :name="isPassword ? 'visibility_off' : 'visibility'" class="cursor-pointer"
                @click="isPassword = !isPassword" />
            </template>
          </q-input>
        </div>
      </div>
    </q-card-section>

    <q-card-section>
      <div class="row justify-center">
        <div class="text-center col-6">
          <q-input ref="password2Ref" :type="isPassword ? 'password' : 'text'" rounded outlined maxlength="50" counter
            :rules="passwordRules2" v-model="password2" label="Confirm your Password" dense
            :error="errors.password2?.length > 0" :error-message="errors.password2">
            <template v-slot:append>
              <q-icon :name="isPassword ? 'visibility_off' : 'visibility'" class="cursor-pointer"
                @click="isPassword = !isPassword" />
            </template>
          </q-input>
        </div>
      </div>
    </q-card-section>

    <q-card-section v-if="error">
      <div class="row justify-center">
        <div class="col-6">
          <div class="text-h6 text-center text-red-10">Registration Error</div>
          <div class="q-mt-sm text-body1 text-center">
            Could not register with these credentials. Please check your email/password and try again. If the
            problem persists, please
            <router-link :to="{ name: 'publicContactView' }" target="_blank">Contact Us</router-link>
            or send us an email at {{ supportEmail }}.
          </div>
        </div>
      </div>

      <div class="row justify-center q-mt-md text-red-10 text-body1" v-if="emailError">
        {{ emailError }}
      </div>
    </q-card-section>

    <div class="row justify-center q-pb-md">
      <div class="text-center col-6">
        <q-btn color="primary" @click="onCreateAccount" :disable="!formIsValid" :loading="isCreatingAccount">Create
          Account</q-btn>
      </div>
    </div>

    <q-card-section>
      <div class="text-center text-grey-8 q-mb-sm">
        Already have an account?
        <router-link :to="{ name: 'hostLoginView' }">Log In</router-link>.
      </div>

      <q-separator inset class="q-my-md" />

      <div class="text-center text-grey-8 q-mb-sm">
        Are you a guest?
        <router-link :to="{ name: 'publicLoginView' }">Log In</router-link>
        or
        <router-link :to="{ name: 'publicRegisterSocialView' }">Register</router-link>
      </div>
    </q-card-section>
  </div>
</template>

<script setup>
import { computed, ref } from 'vue'
import { useRouter } from 'vue-router'

import { useAuth } from '@/composables/auth'

const { createAccount } = useAuth()
const router = useRouter()

const emailError = ref('')

const email = ref('')
const password1 = ref('')
const password2 = ref('')

const emailRef = ref()
const password1Ref = ref()
const password2Ref = ref()

const errors = ref({})

const emailRules = [
  val => (val && val.length > 0) || 'Please enter your Email',
]
const passwordRules1 = [val => (val && val.length > 0) || 'Please provide a Password']
const passwordRules2 = [
  val => (val && val.length > 0) || 'Please provide a Password',
  val => (val && val.length > 0 && val === password1.value) || 'Passwords do not match',

]

const isCreatingAccount = ref(false)
const isPassword = ref(true)
const error = ref(false)
const supportEmail = import.meta.env.VITE_ROOMBEE_SUPPORT_EMAIL

const formIsValid = computed(() => {
  return Boolean(
    email.value.length > 0 &&
    password1.value.length > 0 &&
    !isCreatingAccount.value
  )
})

const onCreateAccount = async () => {
  errors.value = {}
  emailError.value = ''
  error.value = false
  emailRef.value.validate()
  password1Ref.value.validate()
  password2Ref.value.validate()

  if (
    emailRef.value.hasError ||
    password1Ref.value.hasError ||
    password2Ref.value.hasError
  ) {
    return
  }

  const form = {
    email: email.value,
    password1: password1.value,
    password2: password2.value,
  }
  isCreatingAccount.value = true
  const resp = await createAccount(form, 'host')
  isCreatingAccount.value = false

  if (resp.status === 204) {
    router.push({ name: 'hostRegisterSuccessView' })
  } else {
    if (resp.status === 400) {
      if (resp.data.email) {
        emailError.value = resp.data.email[0]
      }
      if (resp.data.password1) {
        errors.value.password1 = resp.data.password1[0]
      }
      if (resp.data.password2) {
        errors.value.password2 = resp.data.password2[0]
      }
    }
    error.value = true
  }
}

</script>
