<template>
  <div>
    <q-card-section>
      <div class="row justify-center">
        <div class="text-center col-6">
          <q-input rounded outlined maxlength="50" counter v-model="email" :rules="emailRules"
            label="Enter your registered Email Address" hint="The Email Address you used to sign up." dense />
        </div>
      </div>
    </q-card-section>

    <q-card-section>
      <div class="row justify-center">
        <div class="text-center col-6">
          <q-input @keydown.enter="onLogIn" :type="isPassword ? 'password' : 'text'" rounded outlined maxlength="50"
            counter :rules="passwordRules" v-model="password" label="Enter your Password" dense>
            <template v-slot:append>
              <q-icon :name="isPassword ? 'visibility_off' : 'visibility'" class="cursor-pointer"
                @click="isPassword = !isPassword" />
            </template>
          </q-input>
        </div>
      </div>
    </q-card-section>

    <q-card-section v-if="error">
      <div class="row justify-center">
        <div class="col-6">
          <div class="text-h6 text-center text-red-10">Login Error</div>
          Could not log in with these credentials. Please check your email/password and try again.
          If the problem persists, please
          <router-link :to="{ name: 'publicContactView' }" target="_blank">Contact Us</router-link>
          or send us an email at {{ supportEmail }}
        </div>
      </div>
    </q-card-section>

    <div class="row justify-center q-pb-md">
      <div class="text-center col-6">
        <q-btn color="primary" @click="onLogIn" :disable="!formIsValid" :loading="isLoggingIn">Log In</q-btn>
      </div>
    </div>

    <q-card-section>
      <div class="text-center text-grey-8 q-mb-sm">
        Don't have a host account?
        <router-link :to="{ name: 'hostRegisterView' }">Register</router-link>.
      </div>

      <q-separator inset class="q-my-md" />

      <div class="text-center text-grey-8 q-mb-sm">
        Are you a guest?
        <router-link :to="{ name: 'publicLoginView' }">Log In</router-link>
        or
        <router-link :to="{ name: 'publicRegisterSocialView' }">Register</router-link>
        as a guest.
      </div>

      <div class="text-center text-grey-8 q-mb-sm">
        Or, go to the
        <router-link :to="{ name: 'publicListingsView' }">Home Page</router-link>
        without logging in.
      </div>
    </q-card-section>
  </div>
</template>

<script setup>
import { computed, ref } from 'vue'
import { useRouter } from 'vue-router'

import { useAuth } from '@/composables/auth'

const { logIn } = useAuth()
const router = useRouter()

const email = ref('')
const password = ref('')
const isPassword = ref(true)

const emailRules = [val => (val && val.length > 0) || 'Please enter your Email']
const passwordRules = [val => (val && val.length > 0) || 'Please enter your Password']

const isLoggingIn = ref(false)
const error = ref(false)
const supportEmail = import.meta.env.VITE_ROOMBEE_SUPPORT_EMAIL

const formIsValid = computed(() => {
  const result = Boolean(email.value.length > 0 && password.value.length > 0) && !isLoggingIn.value
  return result
})

const onLogIn = async () => {
  error.value = false
  const form = {
    email: email.value,
    password: password.value,
  }
  isLoggingIn.value = true
  const resp = await logIn(form)

  if (resp.status === 200) {
    router.push({ name: 'hostListingsView' })
    // We do not reset isLoggingIn since there can be a delay between
    // loading the next page. Thus, keeping the progress spinning indicates
    // to the user that something is still progressing.
  } else {
    isLoggingIn.value = false
    error.value = true
  }

}

</script>

<!--
Next:
- Add a card, show spinner, and make a POST request to /rb-auth/registration/verify-email/ send 'key' in POST
- If error, show error and a button
- If successful, redirect to log in page

Backend updates:
- log in by email
- set is_active = True


Register shoudl return 204 when success
/api/v0/rb-auth/registration/
email, password1, password2

-->
