export const MONEY_AMOUNT_REGEX = /^\d+(?:\.\d{0,2})?$/

export const PRICE_RULES = [
  val => (MONEY_AMOUNT_REGEX.test(val) && parseFloat(val) > 0) || 'Please enter a price'
]

export const DEPOSIT_RULES = [
  val => (MONEY_AMOUNT_REGEX.test(val) && parseFloat(val) >= 0) || 'Please enter a deposit amount'
]

export const TAX_RULES = [
  val => (MONEY_AMOUNT_REGEX.test(val) && parseFloat(val) >= 0) || 'Please enter a tax amount'
]

export const FEE_RULES = [
  val => (MONEY_AMOUNT_REGEX.test(val) && parseFloat(val) >= 0) || 'Please enter a fee amount'
]

export const DISCOUNT_RULES = [
  val => (MONEY_AMOUNT_REGEX.test(val) && parseFloat(val) >= 0) || 'Please enter a discount amount'
]

export const DISCOUNT_TYPE_RULES = [
  val => (val && val.length > 0) || 'Please select a Discount Type'
]

export const FEE_TYPE_RULES = [
  val => (val && val.length > 0) || 'Please select a Fee Type'
]
