<template>
  <LayoutPublicBase>
    <template v-slot:my-router-view>
      <q-page-container>
        <div class="row justify-center">
          <div class="col-md-12 col-lg-11 col-xl-8">
            <router-view />
          </div>
        </div>
      </q-page-container>
    </template>
  </LayoutPublicBase>
</template>

<script setup>
import LayoutPublicBase from '@/components/layouts/LayoutPublicBase.vue'

import { useBreadcrumbs } from '@/composables/breadcrumbs'
import { onMounted } from 'vue';

const {
  showListingsBreadcrumbs,
  showHostDetailsBreadcrumbs
} = useBreadcrumbs()

onMounted(() => {
  showListingsBreadcrumbs.value = false
  showHostDetailsBreadcrumbs.value = false
})
</script>
