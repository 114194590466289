<template>
  <q-rating v-model="rating" :readonly="isReadonly" no-reset no-dimming :max="5" :size="size" :icon="icons"
    :color="selectedColor">
    <template v-slot:tip-1>
      <q-tooltip>Unsatisfactory</q-tooltip>
    </template>
    <template v-slot:tip-2>
      <q-tooltip>Needs Improvement</q-tooltip>
    </template>
    <template v-slot:tip-3>
      <q-tooltip>Average</q-tooltip>
    </template>
    <template v-slot:tip-4>
      <q-tooltip>Great</q-tooltip>
    </template>
    <template v-slot:tip-5>
      <q-tooltip>Awesome</q-tooltip>
    </template>
  </q-rating>
  <span v-if="!descriptionOnNextLine" class="q-ml-sm text-caption text-grey-8">{{ name }} {{ selectedSentiment }}</span>
  <div v-else class="q-ml-sm text-caption text-grey-8">
    {{ name }} {{ selectedSentiment }}
  </div>
</template>

<script setup>
import { computed, ref } from 'vue';

const rating = defineModel()

defineProps({
  name: String,
  descriptionOnNextLine: {
    type: Boolean,
    default: false,
  },
  isReadonly: {
    type: Boolean,
    default: false,
  },
  size: {
    type: String,
    default: "2.5em",
  },
})

const icons = ref([
  'sym_o_sentiment_stressed',
  'sym_o_sentiment_dissatisfied',
  'sym_o_sentiment_neutral',
  'sym_o_sentiment_satisfied',
  'sym_o_sentiment_excited'
])

const colors = [
  'red-10',
  'deep-orange-4',
  'grey-8',
  'light-blue-8',
  'green-7'
]

const sentiments = [
  'is Unsatisfactory',
  'Needs Improvement',
  'is Average',
  'is Great',
  'is Awesome',
]

const selectedColor = computed(() => {
  const _colors = Array(5).fill('grey-5')
  // Index of currently selected
  const index = rating.value - 1
  _colors[index] = colors[index]
  return _colors
})

const selectedSentiment = computed(() => {
  // Index of currently selected
  const index = rating.value - 1
  return sentiments[index]
})
</script>
