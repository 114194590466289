import { defineStore } from 'pinia'

import { client } from '@/services/request'

import { refreshPaginationValues } from '@/utils/pagination'
import { useAuth } from '@/composables/auth'

export const useStorePublicListings = defineStore('storePublicListings', {
  state: () => {
    return {
      publicListings: [],
      publicListingDetails: {},
      publicListingDetailsSelf: {},
      publicPage: {
        pageCurrent: 1,
        pageLast: 1,
        pageMaxLinks: 1,
        pageTotal: 0,
      },
      fetchingPublicListings: false,
      fetchingPublicListingDetails: false,
      publicListingsSearch: '',
      publicListingsSearchOrder: '',
      publicListingsSearchFilter: {
        'property_type': [],
        'bedrooms': [],
        'bathrooms': [],
        'weekly_discount': false,
        'monthly_discount': false,
      },
      publicListingsSearchFilterOps: {
        'listing_status': [],
      },
      showSearchOrder: false,
      showSearchFilter: false,
    }
  },

  actions: {
    async fetchPublicListingDetails(uuid) {
      const url = `listings/public/${uuid}/`
      this.fetchingPublicListingDetails = true
      const response = await client.authOrAnonRequest(url)
      this.fetchingPublicListingDetails = false

      if (response.status == 200) {
        this.publicListingDetails = response.data
      } else {
        // TODO-FEATURE: Handle unsuccessful responses
      }
    },

    async fetchPublicListings(pageSize, pageMaxLinks) {
      const { user } = useAuth()

      this.showSearchOrder = false
      const params = new URLSearchParams({
        limit: pageSize,
        offset: (this.publicPage.pageCurrent * pageSize) - pageSize,
        property_type: this.publicListingsSearchFilter.property_type,
        bedrooms: this.publicListingsSearchFilter.bedrooms,
        bathrooms: this.publicListingsSearchFilter.bathrooms,
        weekly_discount: this.publicListingsSearchFilter.weekly_discount,
        monthly_discount: this.publicListingsSearchFilter.monthly_discount,
        order: this.publicListingsSearchOrder,
        search: this.publicListingsSearch,
      })

      let url = `listings/public/?${params}`
      if (user.value.is_ops) {
        const paramsOps = new URLSearchParams({
          listing_status: this.publicListingsSearchFilterOps.listing_status,
        })
        url = `${url}&${paramsOps}`
      }

      this.fetchingPublicListings = true
      const response = await client.authOrAnonRequest(url)
      this.fetchingPublicListings = false

      if ([200, 201].includes(response.status)) {
        const data = response.data
        this.publicListings = data.results
        refreshPaginationValues(this.publicPage, data.count, pageSize, pageMaxLinks)
      } else {
        // TODO-FEATURE: Handle unsuccessful responses
      }
    },

    async getListingDetailsSelf(uuid) {
      const url = `listings/public/${uuid}/self/`

      const result = await client.authRequest(url, { method: 'GET' })
      if (result.status === 200) {
        this.publicListingDetailsSelf = result.data
      }
    },
  }
})
