<template>
  <div class="flex justify-between q-mt-sm q-px-sm">
    <div style="width: 80%">
      <q-input :disable="fetchingPublicListings" dense v-model="publicListingsSearch" debounce="500" filled
        placeholder="Search Listings">
        <template v-slot:prepend>
          <q-icon name="search" />
        </template>

        <template v-slot:append>
          <q-icon name="close" @click="publicListingsSearch = ''" class="cursor-pointer" />
        </template>
      </q-input>
    </div>
    <div class="self-center q-px-xs" style="max-width: 10%">
      <q-btn dense :disable="fetchingPublicListings" @click="showSearchOrder = true" icon="swap_vert">
        <q-badge v-if="showSearchOrderBadge" color="orange" floating>!</q-badge>
      </q-btn>
    </div>
    <div class="self-center" style="max-width: 10%">
      <q-btn dense :disable="fetchingPublicListings" @click="showSearchFilter = true" icon="tune">
        <q-badge v-if="showSearchFilterBadge" color="orange" floating>!</q-badge>
      </q-btn>
    </div>
  </div>

  <div class="row q-ml-md q-my-xs">
    <div class="text-grey text-center">
      <template v-if="fetchingPublicListings">
        Getting listings...
      </template>
      <template v-else>
        Found {{ publicPage.pageTotal }} Listings
      </template>
    </div>
  </div>

  <div v-if="fetchingPublicListings">
    <LoadingListingsItemSkeletonMobile :count="2" :max-width="500" />
  </div>
  <div v-else>
    <div v-for="(listing, index) in publicListings" :key="index">
      <PublicListingItemMobile :listing="listing" />
    </div>

    <div class="q-pa-sm flex flex-center">
      <q-pagination v-model="publicPage.pageCurrent" color="primary" :max="publicPage.pageLast"
        :max-pages="publicPage.pageMaxLinks" boundary-numbers />
    </div>

    <div class="text-center q-my-sm rb-base__mobile_footer_privacy">
      <router-link style="text-decoration: none; color: inherit;" class="text-primary"
        :to="{ name: 'publicPrivacyPolicyMobileView' }">
        Privacy Policy
      </router-link>
      and
      <router-link style="text-decoration: none; color: inherit;" class="text-primary"
        :to="{ name: 'publicTermsOfServiceMobileView' }">
        Terms of Service
      </router-link>
    </div>

    <PublicListingSearchOrderDialog />
    <PublicListingSearchFilterDialog />
  </div>
</template>

<script setup>
import _ from 'lodash'

import { computed, onMounted } from 'vue'
import { useQuasar } from 'quasar'
import { useRouter } from 'vue-router'

import { usePublicListings } from '@/composables/publicListings'

import LoadingListingsItemSkeletonMobile from '@/components/skeletons/mobile/LoadingListingsItemSkeletonMobile.vue'
import PublicListingItemMobile from '@/components/cards/mobile/PublicListingItemMobile.vue'
import PublicListingSearchOrderDialog from '@/components/dialogs/PublicListingSearchOrderDialog.vue'
import PublicListingSearchFilterDialog from '@/components/dialogs/PublicListingSearchFilterDialog.vue'

const $q = useQuasar()
const router = useRouter()

const {
  fetchPublicListings,
  fetchingPublicListings,
  publicListings,
  publicPage,
  publicListingsSearch,
  showSearchOrder,
  showSearchFilter,
  publicListingsSearchFilter,
  publicListingsSearchOrder,
} = usePublicListings()

onMounted(async () => {
  // If user is on mobile, redirect to mobile view.
  if (!$q.platform.is.mobile) {
    router.push({ name: 'publicListingsView' })
  }

  if (publicListings.value.length === 0) {
    await fetchPublicListings()
  }
})

const chunkedListings = computed(() => {
  return _.chunk(publicListings.value, 4)
})

const showSearchOrderBadge = computed(() => {
  if (
    publicListingsSearchOrder.value.length
  ) {
    return true
  }
  return false
})

const showSearchFilterBadge = computed(() => {
  if (
    publicListingsSearchFilter.value.property_type.length
    || publicListingsSearchFilter.value.bedrooms.length
    || publicListingsSearchFilter.value.bathrooms.length
    || publicListingsSearchFilter.value.weekly_discount
    || publicListingsSearchFilter.value.monthly_discount
  ) {
    return true
  }
  return false
})
</script>
