<template>
  <q-dialog v-model="showDeleteImageDialog" persistent>
    <q-card>
      <q-card-section>
        <div class="q-mb-md">
          <div class="text-h6 text-center">
            <q-icon name="delete_outline" color="red-10" size="md" />
            Are you sure you want to delete this image?
          </div>
        </div>

        <q-card>
          <q-card-section>
            <q-img fit="contain" :src="imageToDelete.signed_url" style="height: 200px" />
          </q-card-section>
        </q-card>
      </q-card-section>

      <q-card-actions align="right">
        <q-btn :disabled="isDeletingImage" label="Cancel" color="primary" v-close-popup />
        <q-btn :disabled="isDeletingImage" :loading="isDeletingImage" @click="confirmDeleteImage" label="Delete"
          color="red-10" />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script setup>
import { useRoute } from 'vue-router'

import { useImages } from '@/composables/images'
import { notifyHostUpdateValueError } from '@/notifications/events'

const route = useRoute()

const {
  showDeleteImageDialog,
  imageToDelete,
  isDeletingImage,
  deleteImage,
  fetchListingImages,
  fetchListingImageInfo,
} = useImages()

const confirmDeleteImage = async () => {
  const resp = await deleteImage(route.params.uuid, imageToDelete.value.uuid)
  if (resp.status !== 204) {
    notifyHostUpdateValueError('Could not delete image. Please try again later or contact support.')
  } else {
    await fetchListingImages(route.params.uuid)
    await fetchListingImageInfo(route.params.uuid)
    showDeleteImageDialog.value = false
  }

}
</script>
