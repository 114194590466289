<template>
  <q-dialog v-model="showDeleteListingDialog">
    <q-card>
      <q-card-section>
        <div class="flex justify-center">
          <div class="self-center q-mr-sm">
            <q-icon name="delete_outline" color="red-10" size="sm" />
          </div>
          <div class="text-h6">Delete Listing</div>
        </div>

        <div class="text-body1 q-mx-md q-mt-md text-center">
          <p>Are you sure you want to delete this listing? <br> This action cannot be reversed!</p>
        </div>
      </q-card-section>

      <q-card-actions align="right" class="q-mr-sm q-mb-sm">
        <q-btn :disabled="isDeletingListing" label="Cancel" class="rb-public-buttons__cancel-color" v-close-popup />
        <q-btn class="rb-public-buttons__submit-color" :disabled="isDeletingListing" :loading="isDeletingListing"
          @click="confirmDeleteListing" label="Delete" />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script setup>
import { useRouter } from 'vue-router'

import { useHostListings } from '@/composables/hostListings'
import { notifyHostUpdateValueError } from '@/notifications/events'

const router = useRouter()

const {
  showDeleteListingDialog,
  listingToDelete,
  isDeletingListing,
  deleteListing,
} = useHostListings()

const confirmDeleteListing = async () => {
  const resp = await deleteListing(listingToDelete.value.uuid)
  if (resp.status !== 204) {
    notifyHostUpdateValueError('Could not delete listing. Please try again later or contact support.')
  } else {
    showDeleteListingDialog.value = false
    listingToDelete.value = {}
    router.push({ name: 'hostListingsView' })
  }
}
</script>
