<template>
  <q-card bordered class="q-mb-lg">
    <div class="col-8">
      <div class="flex justify-between q-mx-md q-mt-sm">
        <div class="self-center text-grey-8 text-body2">
          Bookmark created on {{ createdOn }}
        </div>
        <div>
          <q-btn @click="onDeleteBookmark()" dense label="Delete Bookmark" />
        </div>
      </div>
      <q-separator class="q-mt-sm" />
      <q-card flat>
        <q-card-section>
          <div class="row justify-center">
            <div class="col-5">
              <div class="row">
                <div class="col">
                  <div v-if="listing.images.length > 0">
                    <q-card class="q-pa-xs">
                      <q-carousel arrows swipeable animated v-model="slide" infinite height="13em">
                        <q-carousel-slide v-for="(image, index) in listing.images" :key="image.uuid" :name="index"
                          :img-src="image.signed_url" />
                      </q-carousel>
                    </q-card>
                  </div>
                  <div v-else class="row text-h5 text-grey text-center"
                    style="min-height: 200px; background-color: #efefef;">
                    <div class="col self-center">
                      No images to show.
                    </div>
                  </div>
                </div>
              </div>

              <div class="text-center q-mt-xs">
                <div>
                  <PropertyTypeChip :property-type="listing.property_type" />
                  <span class="text-grey-9">in {{ listing.location.neighbourhood }} {{ listing.location.city
                    }}</span>
                </div>
              </div>
            </div>

            <div class="col">
              <div class="row">
                <div class="col-10">
                  <div>
                    <div v-if="isDeleted" class="q-mx-md">
                      <span class="text-grey text-subtitle1 text-weight-bold">
                        <s>{{ truncate(listing.title, 40) }}</s>
                      </span>
                      <div class="text-subtitle q-mt-sm q-mb-xs">
                        This listing was deleted!
                      </div>
                    </div>
                    <div v-else class="q-mx-md">
                      <router-link style="text-decoration: none; color: inherit;" class="text-primary text-h6"
                        :to="{ name: 'publicListingsDetailsView', params: { uuid: listing.uuid, listingSlug: listing.slug } }">
                        {{ truncate(listing.title, 70) }}
                      </router-link>
                      <div class="text-subtitle q-mt-sm q-mb-xs">
                        {{ truncate(listing.sub_title, 160) }}
                      </div>
                    </div>
                  </div>

                </div>

                <div class="col">
                  <div class="row text-center">
                    <div class="col">
                      <div v-if="listing.company.logo">
                        <q-img :src="listing.company.logo" width="70px" />
                      </div>
                      <div v-else class="row text-h7 text-grey text-center"
                        style="min-height: 80px; background-color: #efefef;">
                        <div class="col self-center">
                          No Logo.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row text-center q-mt-sm">
                    <div class="col">
                      <ListingRatingChip :rating-overall="listing.rating_overall" unrated-text="-" :dense="true" />
                    </div>
                  </div>
                </div>
              </div>

              <q-separator class="q-my-sm" inset />

              <div class="text-body1 q-mx-sm">
                <BasicInfoChip :text="listing.bedrooms" icon="bed" />
                <BasicInfoChip :text="listing.bathrooms" icon="bathtub" />
                <BasicInfoChip :text="listing.property_sqft" icon="square_foot" />
                <ListingPriceChip class="q-ml-sm" :amount="listing.price.total_price" />
              </div>

            </div>
          </div>
        </q-card-section>
      </q-card>
    </div>
  </q-card>
</template>

<script setup>
import { ref } from 'vue'

import { useGuestProfile } from '@/composables/guestProfile'

import PropertyTypeChip from '@/components/chips/PropertyTypeChip.vue'
import ListingRatingChip from '@/components/chips/ListingRatingChip.vue'
import BasicInfoChip from '@/components/chips/BasicInfoChip.vue'
import ListingPriceChip from '@/components/chips/ListingPriceChip.vue'

import { truncate } from '@/utils/string'

const {
  deleteBookmarkListing,
  fetchBookmarksListing,
} = useGuestProfile()

const props = defineProps({
  listing: Object,
  createdOn: String,
  bookmarkUuid: String,
  isDeleted: Boolean,
})

const slide = ref(0)

const onDeleteBookmark = async () => {
  const result = await deleteBookmarkListing(props.bookmarkUuid)
  if (result.status === 204) {
    fetchBookmarksListing()
  }
}
</script>
