<template>
  <q-card flat bordered class="q-mt-md q-mx-md q-mb-md">
    <q-tabs v-model="profileTab">
      <q-tab name="profile" label="Profile" />
      <q-tab name="bookmarks" label="Bookmarks" />
      <q-tab name="reviews" label="Reviews" />
    </q-tabs>

    <q-separator class="q-mt-md" inset />

    <q-tab-panels v-model="profileTab" animated>
      <ProfileTabPanel name="profile" />
      <BookmarksTabPanel name="bookmarks" />
      <ReviewsTabPanel name="reviews" />
    </q-tab-panels>

  </q-card>

</template>

<script setup>
import { onMounted } from 'vue'
import { useQuasar } from 'quasar'
import { useRouter } from 'vue-router'

import { useAuth } from '@/composables/auth'
import { useGuestProfile } from '@/composables/guestProfile'

import ProfileTabPanel from '@/components/guestProfilePanels/ProfileTabPanel.vue'
import BookmarksTabPanel from '@/components/guestProfilePanels/BookmarksTabPanel.vue'
import ReviewsTabPanel from '@/components/guestProfilePanels/ReviewsTabPanel.vue'

const $q = useQuasar()
const router = useRouter()

const { getToken } = useAuth()

onMounted(async () => {
  if ($q.platform.is.mobile) {
    router.push({ name: 'guestProfileMobileView' })
  }

  // If user isn't logged in, redirect them to Login page
  const token = getToken()
  if (!token) {
    // User is not logged in, or is logged in but not as host user account
    router.push({ name: 'publicLoginView' })
  }
})

const {
  profileTab,
} = useGuestProfile()
</script>
