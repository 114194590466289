<template>
  <q-card class="q-mt-md">
    <q-card-section class="text-grey-8 text-center">
      <div class="text-h6">Contact Us</div>
      <div class="text-body2 q-mt-md text-center">
        Use this form to reach out to us <i>or</i> send us an email at <b>{{ supportEmail }}</b>
      </div>
    </q-card-section>

    <div class="row justify-center q-my-sm">
      <div class="text-center col-8">
        <q-input ref="nameRef" rounded outlined maxlength="50" counter v-model="name" :rules="nameRules"
          lazy-rules="ondemand" label="Enter your Name" hint="So that we can address you." dense />
      </div>
    </div>

    <div class="row justify-center q-mb-md">
      <div class="text-center col-8">
        <q-input ref="emailRef" :rules="emailRules" rounded outlined maxlength="100" counter v-model="email"
          lazy-rules="ondemand" label="Enter your Email Address" hint="If you'd like us to reply to you." dense />
      </div>
    </div>

    <div class="row justify-center q-mb-md">
      <div class="text-center col-8">
        <q-input ref="messageRef" class="q-mt-sm" :rules="messageRules" :input-style="{ minHeight: '200px' }"
          lazy-rules="ondemand" type="textarea" outlined maxlength="1000" counter dense v-model="message"
          label="Enter your message" hint="What's on your mind?">
        </q-input>
      </div>
    </div>


    <div class="row q-mb-md justify-center" v-if="Object.keys(errors).length">
      <div class="col-6">
        <div class="text-center rb-public-contact__title">Please fix these errors
        </div>
        <div class="text-center" v-for="(messages, name, index) in errors" :key="index">
          <span class="text-body1 text-grey-9 text-weight-bold">
            {{ name }}:
          </span>
          <span class="text-body1">
            {{ messages.join(' ') }}
          </span>
        </div>
      </div>
    </div>

    <div class="row justify-center q-py-md">
      <div class="text-center col-6">
        <q-btn @click="onSendMessage" :disable="sendingMessage" :loading="sendingMessage">Send Message</q-btn>
      </div>
    </div>
  </q-card>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import { useQuasar } from 'quasar'
import { useRouter } from 'vue-router'

import { useContact } from '@/composables/contact'
import { notifyThankYou } from '@/notifications/events'

const {
  sendingMessage,
  sendMessage,
} = useContact()

const $q = useQuasar()
const router = useRouter()

const supportEmail = import.meta.env.VITE_ROOMBEE_SUPPORT_EMAIL
const nameRules = [val => (val && val.length > 0) || 'Please enter your name']
const emailRules = [val => (val && val.length > 0) || 'Please enter your email']
const messageRules = [val => (val && val.length >= 20) || 'Type at least 20 characters']

const name = ref()
const email = ref()
const message = ref()

const errors = ref({ email: ['Plase enter a valid email'] })
const nameRef = ref(null)
const emailRef = ref(null)
const messageRef = ref(null)

onMounted(async () => {
  if ($q.platform.is.mobile) {
    router.push({ name: 'publicContactMobileView' })
  }
})

const onSendMessage = async () => {
  const nameValid = await nameRef.value.validate()
  const emailValid = await emailRef.value.validate()
  const messageValid = await messageRef.value.validate()

  if (!nameValid || !emailValid || !messageValid) {
    return
  }

  const resp = await sendMessage(name.value, email.value, message.value)
  if (resp.status === 201) {
    name.value = ''
    email.value = ''
    message.value = ''
    errors.value = {}

    notifyThankYou("Thanks for reaching out! We've received your message - if you provided an email, we'll get back to you soon.")
  } else if (resp.status === 400) {
    errors.value = resp.data
  }
}

</script>
