<template>
  <q-card class="my-card" flat bordered>
    <q-tabs v-model="listingDetailsTab" dense class="text-grey-8" active-color="primary" indicator-color="primary"
      align="justify" narrow-indicator>
      <q-tab name="general" label="General" />
      <q-tab name="description" label="Description" />
      <q-tab name="location" label="Location" />
      <q-tab name="amenities" label="Amenities" />
      <q-tab name="pricing" label="Pricing" />
      <q-tab name="images" label="Images" />
    </q-tabs>

    <q-separator />

    <q-tab-panels v-model="listingDetailsTab" animated>
      <general-tab-panel name="general" />
      <description-tab-panel name="description" />
      <location-tab-panel name="location" />
      <amenities-tab-panel name="amenities" />
      <pricing-tab-panel name="pricing" />
      <images-tab-panel name="images" />
    </q-tab-panels>
  </q-card>
</template>

<script setup>
import { useHostListings } from '@/composables/hostListings'

import GeneralTabPanel from '@/components/hostListingDetailsPanel/panels/GeneralTabPanel.vue'
import DescriptionTabPanel from '@/components/hostListingDetailsPanel/panels/DescriptionTabPanel.vue'
import LocationTabPanel from '@/components/hostListingDetailsPanel/panels/LocationTabPanel.vue'
import AmenitiesTabPanel from '@/components/hostListingDetailsPanel/panels/AmenitiesTabPanel.vue'
import PricingTabPanel from '@/components/hostListingDetailsPanel/panels/PricingTabPanel.vue'
import ImagesTabPanel from '@/components/hostListingDetailsPanel/panels/ImagesTabPanel.vue'

const {
  listingDetailsTab,
} = useHostListings()
</script>
