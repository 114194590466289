<template>
  <q-btn :disabled="!isReady || loggingIn" :loading="loggingIn" @click="() => onClick()">
    <q-icon class="q-mr-sm">
      <img src="@/assets/googleIcon.svg" />
    </q-icon>
    {{ prefix }} with Google
  </q-btn>
</template>

<script setup>
import { ref } from 'vue'
import { useRouter, useRoute } from 'vue-router'

import { notifySorry } from '@/notifications/events'

const route = useRoute()
const router = useRouter()

const supportEmail = import.meta.env.VITE_ROOMBEE_SUPPORT_EMAIL
import { useAuth } from '@/composables/auth'

import {
  useCodeClient,
} from "vue3-google-signin"

const loggingIn = ref(false)

defineProps({
  prefix: {
    type: String,
    default: 'Sign Up'
  }
})
const { fetchUser, setToken, verifyOauthTokenGoogle } = useAuth()

const showError = () => {
  loggingIn.value = false
  notifySorry(`Sorry, there was a problem signing in with Google. Please try again or contact ${supportEmail} for assistance.`)
}

const handleOnSuccess = async (response) => {
  loggingIn.value = true
  const resp = await verifyOauthTokenGoogle(response.code)
  if (resp.status !== 200) {
    showError()
    return
  }

  setToken(resp.data.key)

  const result = await fetchUser()
  if (result.status !== 200) {
    showError()
    return
  }

  const nextUrl = route?.query?.next
  if (nextUrl && nextUrl.length > 0) {
    window.location.replace(nextUrl)
  } else {
    router.push({ name: 'publicListingsView' })
  }
  loggingIn.value = false
}

const handleOnError = (errorResponse) => {
  // TODO-FEATURE: test for this scenario and handle accordingly
  loggingIn.value = false
  showError()
}

const { isReady, login } = useCodeClient({
  onSuccess: handleOnSuccess,
  onError: handleOnError,
})

const onClick = () => {
  login()
}
</script>
