<template>

  <q-card class="q-mt-md q-px-lg q-pa-md">
    <TermsOfService heading-class="rb-public-terms-of-service__heading_mobile text-center q-mb-md"
      paragraph-class="text-body1 q-mb-md" />
  </q-card>

</template>

<script setup>
import { onMounted } from 'vue'
import { useQuasar } from 'quasar'
import { useRouter } from 'vue-router'

import TermsOfService from '@/components/textBlocks/TermsOfService.vue'

const $q = useQuasar()
const router = useRouter()

onMounted(async () => {
  // If user is on desktop, redirect to desktop view.
  if (!$q.platform.is.desktop) {
    router.push({ name: 'publicTermsOfServiceMobileView' })
  } else {
    window.scrollTo(0, 0)
  }
})
</script>
