export const PUBLIC_LISTING_SEARCH_ORDER_OPTIONS = [
  'Newest',
  'Oldest',
]

export const PROPERTY_TYPE_OPTIONS = [
  "All",
  "Apartment",
  "Penthouse",
  "Studio",
  "Villa",
]

export const OPS_LISTING_STATUS_OPTIONS = [
  "Unlisted",
  "Listed",
]
