<template>
  <div :class="headingClass">
    Privacy Policy
    <div class="text-caption text-grey-8 text-center q-mb-md">Last updated: 24th October, 2024</div>
  </div>
  <div :class="paragraphClass">
    The privacy of your data is a big deal to us. In this policy, we lay out: what data we collect and why; how your
    data is handled; and your rights with respect to your data. We promise we never sell your data.
  </div>

  <div :class="paragraphClass">
    When we say “Company”, “we”, “our”, or “us” in this document, we are referring to {{ roombCompanyLegal }}.
  </div>

  <div :class="paragraphClass">
    This policy is split into sections. For your convenience, links to each of those sections is as follows:
    <ul>
      <li>
        <a href="" @click.prevent="goToSection('privacyPolicy')">Privacy policy</a>
      </li>
      <li>
        <a href="" @click.prevent="goToSection('whatWeCollect')">What we collect and why</a>
      </li>
      <li>
        <a href="" @click.prevent="goToSection('whatWeAccess')">When we access or disclose your information</a>
      </li>
      <li>
        <a href="" @click.prevent="goToSection('yourRights')">Your rights with respect to your information</a>
      </li>
      <li>
        <a href="" @click.prevent="goToSection('howWeSecure')">How we secure your data</a>
      </li>
      <li>
        <a href="" @click.prevent="goToSection('whatHappensDeleteContent')">What happens when you delete content in your
          accounts</a>
      </li>
      <li>
        <a href="" @click.prevent="goToSection('dataRetention')">Data retention</a>
      </li>
      <li>
        <a href="" @click.prevent="goToSection('dataDeletion')">Data deletion</a>
      </li>
      <li>
        <a href="" @click.prevent="goToSection('locationOfSite')">Location of site and data</a>
      </li>
      <li>
        <a href="" @click.prevent="goToSection('personalDataEU')">When transferring personal data from the EU</a>
      </li>
      <li>
        <a href="" @click.prevent="goToSection('changes')">Changes and questions</a>
      </li>
    </ul>
  </div>

  <div :class="paragraphClass" ref="privacyPolicy">
    This policy applies to all products built and maintained by {{ roombCompanyLegal }}.
  </div>

  <div :class="paragraphClass">
    This policy applies to our handling of information about site visitors, prospective customers, and customers and
    authorized users (in relation to their procurement of the services and management of their relationship with us). We
    refer collectively to these categories of individuals as "you" throughout this policy.
  </div>

  <div :class="paragraphClass">
    However, this policy does not cover information about a customer's end users that we receive from a customer, or
    otherwise processes on a customer's behalf, in connection with the services provided by the Company to the customer
    pursuant to an applicable services agreement (including the content of messages of customer end users ("End User
    Communications")). We process End User Communications under the instructions of the relevant customer, which is the
    "data controller" or "business" (or occupies a similar role as defined in applicable privacy laws), as described in
    the applicable services agreement between such customer and the Company. The Company's obligations as a "data
    processor" or "service provider" with respect to such information are defined in such services agreement and
    applicable data protection addendum and are not made part of this policy.
  </div>

  <div :class="headingClass" ref="whatWeCollect">
    What we collect and why
  </div>

  <div :class="paragraphClass">
    Our guiding principle is to collect only what we need. Here's what that means in practice:
  </div>

  <div :class="headingClass">
    Identity and access
  </div>

  <div :class="paragraphClass">
    When you sign up for an account, we ask for identifying information such as your name, email address, and maybe a
    company name. That's so you can personalize your new account, and we can send you product updates and other
    essential information. We may also send you optional surveys from time to time to help us understand how you use our
    products and to make improvements. With your consent, we will send you our newsletter and other updates. We
    sometimes also give you the option to add a profile picture that displays in our products.
  </div>

  <div :class="paragraphClass">
    We'll never sell your personal information to third parties, and we won't use your name or company in marketing
    statements without your permission either.
  </div>

  <div :class="headingClass">
    Product interactions
  </div>

  <div :class="paragraphClass">
    We store on our servers the content that you upload or receive or maintain in your {{ roombDomain }} account. This
    is so you can use our products as intended. We keep this content as long as your account is active. If you delete
    your account, we'll delete the content within 60 days.
  </div>

  <div :class="headingClass">
    General Geolocation data
  </div>

  <div :class="paragraphClass">
    We log the full IP address used to sign up a product account and retain that for use in mitigating future spammy
    signups. We also log all account access by full IP address for security and fraud prevention purposes, and we keep
    this login data for as long as your product account is active.
  </div>

  <div :class="headingClass">
    Website interactions
  </div>

  <div :class="paragraphClass">
    We collect information about your browsing activity for analytics and statistical purposes such as conversion rate
    testing and experimenting with new product designs. This includes, for example, your browser and operating system
    versions, your IP address, which web pages you visited and how long they took to load, and which website referred
    you to us. If you have an account and are signed in, these web analytics data are tied to your IP address and user
    account until your account is no longer active. The web analytics we use are described further in the Advertising
    and Cookies section.
  </div>

  <div :class="headingClass">
    Anti-bot assessments
  </div>

  <div :class="paragraphClass">
    We use CAPTCHA across our applications to mitigate brute force logins and as a means of spam protection. We have a
    legitimate interest in protecting our apps and the broader Internet community from credential stuffing attacks and
    spam. When you log into your account and when you fill in certain forms, the CAPTCHA service evaluates various
    information (e.g., IP address, how long the visitor has been on the app, mouse movements) to try to detect if the
    activity is from an automated program instead of a human. The CAPTCHA service then provides us with the spam score
    results; we do not have access to the evaluated information.
  </div>

  <div :class="headingClass">
    Advertising and Cookies
  </div>

  <div :class="paragraphClass">
    We run contextual ads on various third-party platforms such as Google, Reddit, and LinkedIn. Users who click on one
    of our ads will be sent to our marketing site. Where permissible under law, we may load an ad-company script on
    their browsers that sets a third-party cookie and sends information to the ad network to enable evaluation of the
    effectiveness of our ads, e.g., which ad they clicked and which keyword triggered the ad, and whether they performed
    certain actions such as clicking a button or submitting a form.
  </div>

  <div :class="paragraphClass">
    We also use persistent first-party cookies and some third-party cookies to store certain preferences, make it easier
    for you to use our applications, and perform A/B testing as well as support some analytics.
  </div>

  <div :class="paragraphClass">
    A cookie is a piece of text stored by your browser. It may help remember login information and site preferences. It
    might also collect information such as your browser type, operating system, web pages visited, duration of visit,
    content viewed, and other click-stream data. You can adjust cookie retention settings and accept or block individual
    cookies in your browser settings, although our apps won't work and other aspects of our service may not function
    properly if you turn cookies off.
  </div>

  <div :class="headingClass">
    Voluntary correspondence
  </div>

  <div :class="paragraphClass">
    When you email us with a question or to ask for help, we keep that correspondence, including your email address, so
    that we have a history of past correspondence to reference if you reach out in the future.
  </div>

  <div :class="paragraphClass">
    We also store information you may volunteer, for example, written responses to surveys. If you agree to a customer
    interview, we may ask for your permission to record the conversation for future reference or use. We will only do so
    with your express consent.
  </div>

  <div :class="headingClass" ref="whatWeAccess">
    When we access or disclose your information
  </div>

  <div :class="paragraphClass">
    No one at the Company looks at your content except for limited purposes with your express permission, for example,
    if an error occurs that stops an automated process from working and requires manual intervention to fix. These are
    rare cases, and when they happen, we look for root cause solutions as much as possible to avoid them recurring. We
    may also access your data if required in order to respond to legal process (see "When required under applicable law"
    below).
  </div>

  <div :class="paragraphClass">
    To help you troubleshoot or squash a software bug, with your permission. If at any point we need to access your
    content to help you with a support case, we will ask for your consent before proceeding.
  </div>

  <div :class="paragraphClass">
    To investigate, prevent, or take action regarding restricted uses. Accessing a customer's account when investigating
    potential abuse is a measure of last resort. We want to protect the privacy and safety of both our customers and the
    people reporting issues to us, and we do our best to balance those responsibilities throughout the process. If we
    discover you are using our products for a restricted purpose, we will take action as necessary, including notifying
    appropriate authorities where warranted.
  </div>

  <div :class="paragraphClass">
    Aggregated and de-identified data. We may aggregate and/or de-identify information collected through the services.
    We may use de-identified or aggregated data for any purpose, including marketing or analytics.
  </div>

  <div :class="paragraphClass">
    Finally, if {{ roombCompanyLegal }} is acquired by or merges with another company — we don't plan on that, but if it
    happens — we'll notify you well before any of your personal information is transferred or becomes subject to a
    different privacy policy.
  </div>

  <div :class="headingClass" ref="yourRights">
    Your rights with respect to your information
  </div>

  <div :class="paragraphClass">
    At {{ roombDomain }}, we strive to apply the same data rights to all customers, regardless of their location. Some
    of these rights include:

    <ul>
      <li>Right to Know. You have the right to know what personal information is collected, used, shared or sold. We
        outline both the categories and specific bits of data we collect, as well as how they are used, in this privacy
        policy.</li>
      <li>Right of Access. This includes your right to access the personal information we gather about you, and your
        right to obtain information about the sharing, storage, security and processing of that information.</li>
      <li>Right to Correction. You have the right to request correction of your personal information.</li>
      <li>Right to Erasure / “To Be Forgotten”. This is your right to request, subject to certain limitations under
        applicable law, that your personal information be erased from our possession and, by extension, from all of our
        service providers. Fulfillment of some data deletion requests may prevent you from using our services because
        our applications may then no longer work. In such cases, a data deletion request may result in closing your
        account.</li>
      <li>Right to Complain. You have the right to make a complaint regarding our handling of your personal information
        with the appropriate supervisory authority.</li>
      <li>Right to Restrict Processing. This is your right to request restriction of how and why your personal
        information is used or processed, including opting out of sale of your personal information. (Again: we never
        have and never will sell your personal data.)</li>
      <li>Right to Object. You have the right, in certain situations, to object to how or why your personal information
        is processed.</li>
      <li>Right to Portability. You have the right to receive the personal information we have about you and the right
        to transmit it to another party.</li>
      <li>Right to not Be Subject to Automated Decision-Making. You have the right to object to and prevent any decision
        that could have a legal or similarly significant effect on you from being made solely based on automated
        processes. This right is limited if the decision is necessary for performance of any contract between you and
        us, is allowed by applicable law, or is based on your explicit consent.</li>
      <li>Right to Non-Discrimination. We do not and will not charge you a different amount to use our products, offer
        you different discounts, or give you a lower level of customer service because you have exercised your data
        privacy rights. However, the exercise of certain rights may, by virtue of your exercising those rights, prevent
        you from using our Services.</li>
    </ul>
  </div>

  <div :class="paragraphClass">
    Many of these rights can be exercised by signing in and updating your account information. Please note that certain
    information may be exempt from such requests under applicable law. For example, we need to retain certain
    information in order to provide our services to you.
  </div>
  <div :class="paragraphClass">
    In some cases, we also need to take reasonable steps to verify your identity before responding to a request, which
    may include, at a minimum, depending on the sensitivity of the information you are requesting and the type of
    request you are making, verifying your name and email address. If we are unable to verify you, we may be unable to
    respond to your requests. If you have questions about exercising these rights or need assistance, please contact us
    at {{ roombSupportEmail }}. If an authorized agent is corresponding on your behalf, we will need written consent
    with a signature from the account holder before proceeding.
  </div>
  <div :class="paragraphClass">
    Depending on applicable law, you may have the right to appeal our decision to deny your request, if applicable. We
    will provide information about how to exercise that right in our response denying the request. You also have the
    right to lodge a complaint with a supervisory authority. If you are in the EU or UK, you can contact your data
    protection authority to file a complaint or learn more about local privacy laws.
  </div>

  <div :class="headingClass" ref="howWeSecure">
    How we secure your data
  </div>

  <div :class="paragraphClass">
    All data is encrypted via SSL/TLS when transmitted from our servers to your browser. The database backups are also
    encrypted. In addition, we go to great lengths to secure your data at rest. For more information about how we keep
    your information secure, please review our security overview.
  </div>

  <div :class="headingClass" ref="whatHappensDeleteContent">
    What happens when you delete content in your account
  </div>

  <div :class="paragraphClass">
    The deleted content may remain on our active servers for another 30 days, and copies of the content may be held in
    backups of our application databases for up to another 30 days after that. Altogether, any content deleted in your
    product accounts should be purged from all of our systems and logs within 90 days.
  </div>

  <div :class="headingClass" ref="dataRetention">
    Data retention
  </div>

  <div :class="paragraphClass">
    We keep your information for the time necessary for the purposes for which it is processed. The length of time for
    which we retain information depends on the purposes for which we collected and use it and your choices, after which
    time we may delete and/or aggregate it. We may also retain and use this information as necessary to comply with our
    legal obligations, resolve disputes, and enforce our agreements. Through this policy, we have provided specific
    retention periods for certain types of information.
  </div>

  <div :class="headingClass" ref="dataDeletion">
    Data deletion
  </div>

  <div :class="paragraphClass">
    If you wish to delete all your personal user data from {{ roombDomain }}, please send an email with your request to
    delete your personal user data at {{ roombSupportEmail }}. For security reasons, and because emails can be easily
    spoofed, we will reply to your request asking you to confirm that you truly want to delete all your personal user
    data. Upon receiving the confirmation response, we will permanently delete your personal user data with 48 business
    hours.
  </div>

  <div :class="headingClass" ref="locationOfSite">
    Location of site and data
  </div>

  <div :class="paragraphClass">
    Our products and other web properties are operated in the United Arab Emirates. If you are located in the European
    Union, UK, or elsewhere outside of the United Arab Emirates, please be aware that any information you provide to us
    will be transferred to and stored in the United Arab Emirates. By using our websites or Services and/or providing us
    with your personal information, you consent to this transfer.
  </div>

  <div :class="headingClass" ref="personalDataEU">
    When transferring personal data from the EU
  </div>

  <div :class="paragraphClass">
    The European Data Protection Board (EDPB) has issued guidance that personal data transferred out of the EU must be
    treated with the same level of protection that is granted under EU privacy law. UK law provides similar safeguards
    for UK user data that is transferred out of the UK. Accordingly, we have adopted a data processing addendum with
    Standard Contractual Clauses to help ensure this protection.
  </div>

  <div :class="headingClass" ref="changes">
    Changes and questions
  </div>

  <div :class="paragraphClass">
    We may update this policy as needed to comply with relevant regulations and reflect any new practices. Whenever we
    make a significant change to our policies, we will refresh the date at the top of this page and take any other
    appropriate steps to notify users.
  </div>

  <div :class="paragraphClass">
    Have any questions, comments, or concerns about this privacy policy, your data, or your rights with respect to your
    information? Please get in touch by emailing us at {{ roombSupportEmail }} and we'll be happy to try to answer them!
  </div>
</template>

<script setup>
import { nextTick, onMounted, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'

const route = useRoute()
const router = useRouter()

const roombSupportEmail = import.meta.env.VITE_ROOMBEE_SUPPORT_EMAIL
const roombDomain = import.meta.env.VITE_ROOMBEE_DOMAIN
const roombCompanyLegal = import.meta.env.VITE_ROOMBEE_COMPANY_LEGAL

defineProps({
  headingClass: String,
  paragraphClass: String,
})

const privacyPolicy = ref(null)
const whatWeCollect = ref(null)
const whatWeAccess = ref(null)
const yourRights = ref(null)
const howWeSecure = ref(null)
const whatHappensDeleteContent = ref(null)
const dataRetention = ref(null)
const dataDeletion = ref(null)
const locationOfSite = ref(null)
const personalDataEU = ref(null)
const changes = ref(null)

const refs = {
  privacyPolicy,
  whatWeCollect,
  whatWeAccess,
  yourRights,
  howWeSecure,
  whatHappensDeleteContent,
  dataRetention,
  dataDeletion,
  locationOfSite,
  personalDataEU,
  changes,
}

onMounted(async () => {
  const section = route.query.section
  if (section) {
    await nextTick()
    goToSection(section)
  }
})

const goToSection = (refName) => {
  // Set the section in the URL
  setQueryParam("section", refName)

  const top = refs[refName].value.getBoundingClientRect().top
  window.scrollTo({ top: top - 50, behavior: 'smooth' })
}

const setQueryParam = (key, value) => {
  if (route.query[key] === value) return
  const query = { ...route.query }
  if (value) query[key] = value
  else delete query[key]
  router.replace({ query })
}
</script>
