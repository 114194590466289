<template>
  <q-page>
    <div v-if="fetchingHostListings">
      <q-card>
        <q-card-section>
          <div class="row justify-center">
            <div class="col-md-6 col-sm-10">
              <LoadingHostListingItemSkeleton :count="2" />
            </div>
          </div>
        </q-card-section>
      </q-card>
    </div>
    <div v-else>
      <q-card>
        <div class="row justify-center q-pt-md">
          <div class="col-lg-6 col-md-8 col-sm-10">
            <div class="row">
              <div class="col-6">
                <q-input dense v-model="hostSearch" debounce="500" filled placeholder="Search Properties">
                  <template v-slot:prepend>
                    <q-icon name="search" />
                  </template>

                  <template v-slot:append>
                    <q-icon name="close" @click="hostSearch = ''" class="cursor-pointer" />
                  </template>
                </q-input>
              </div>
              <div class="col-3">
                <q-select class="q-px-sm" dense color="teal" filled v-model="hostSearchFilter"
                  :options="HOST_SEARCH_FILTER_OPTIONS">
                  <template v-slot:prepend>
                    <q-icon name="filter_alt" />
                  </template>
                </q-select>
              </div>
              <div class="col-3">
                <q-select dense color="teal" filled v-model="hostSearchOrder" :options="HOST_SEARCH_ORDER_OPTIONS">
                  <template v-slot:prepend>
                    <q-icon name="swap_vert" />
                  </template>
                </q-select>
              </div>
            </div>


            <div class="flex justify-between q-mx-xs q-my-xs text-grey text-subtitle2">
              <div>Found {{ hostPage.pageTotal }} listings.</div>
              <div>Page {{ hostPage.pageCurrent }} of {{ hostPage.pageLast }}</div>
            </div>


            <div v-for="listing in hostListings" :key="listing.uuid">
              <div class="col-lg-8 col-sm-10">
                <HostListingListItem :listing="listing" />
              </div>
            </div>

            <div class="q-pa-lg flex flex-center" v-if="hostListings.length">
              <q-pagination v-model="hostPage.pageCurrent" color="primary" :max="hostPage.pageLast"
                :max-pages="hostPage.pageMaxLinks" boundary-numbers />
            </div>
            <div v-else class="flex flex-center q-mt-md">
              There are no listings yet. Why don't you&nbsp;
              <router-link :to="{ name: 'hostNewListingView' }">create one</router-link>?
            </div>
          </div>
        </div>
      </q-card>
    </div>
  </q-page>
</template>

<script setup>
import { onMounted } from 'vue'

import { useHostListings } from '@/composables/hostListings'

import { HOST_SEARCH_FILTER_OPTIONS, HOST_SEARCH_ORDER_OPTIONS } from '@/constants/pagination'

import LoadingHostListingItemSkeleton from '@/components/skeletons/LoadingHostListingItemSkeleton.vue'
import HostListingListItem from '@/components/cards/HostListingListItem.vue'

const {
  fetchHostListings,
  fetchingHostListings,
  hostListings,
  hostPage,
  hostSearch,
  hostSearchFilter,
  hostSearchOrder,
} = useHostListings()

onMounted(async () => {
  await fetchHostListings()
})
</script>
