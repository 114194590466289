<template>
  <q-tab-panel>
    <div class="q-ml-md">
      <div class="text-subtitle1 text-grey">
        Description
      </div>
      <div class="row">
        <div class="col">
          <q-input ref="descriptionRef" :rules="descriptionRules" :input-style="{ minHeight: '300px' }" type="textarea"
            outlined maxlength="10000" counter dense v-model="updateListingForm.description"
            hint="Add a Description for your listing." :error-messages="errorMessages?.description"
            :error="errorMessages?.description?.length > 0 || false">
          </q-input>
        </div>
      </div>
      <div class="row justify-end q-mt-sm">
        <q-btn @click="saveDescription" class="rb-host-buttons__submit" :disable="updatingListing"
          :loading="updatingListing">
          Update Description
        </q-btn>
      </div>
    </div>
  </q-tab-panel>
</template>


<script setup>
import { onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'

import { useHostListings } from '@/composables/hostListings'
import { notifyHostUpdateValueError, notifyHostUpdateValueSuccess } from '@/notifications/events'

const route = useRoute()
const errorMessages = ref({})


const {
  fetchHostListingDetails,
  hostListingDetails,
  updateListingForm,
  updatingListing,
  updateListing,
} = useHostListings()

onMounted(async () => {
  await fetchHostListingDetails(route.params.uuid)
  updateListingForm.value.description = hostListingDetails.value.description
})

const descriptionRef = ref(null)
const descriptionRules = [
  val => (val && val.length > 0 && val.length <= 10000) || 'Please enter a description for your property.'
]

const saveDescription = async () => {
  if (descriptionRef.value.hasError) {
    return
  }

  const form = { description: updateListingForm.value.description }

  const result = await updateListing(route.params.uuid, form)

  if (result.status === 400) {
    const error = `Error while updating the description!`
    notifyHostUpdateValueError(error)
  } else {
    notifyHostUpdateValueSuccess('Successfully updated the description.')
    await fetchHostListingDetails(route.params.uuid)
  }
}
</script>
