/**
 * This composable are meant to be used by components that render
 * data for logged in hosts, i.e. this shouldn't be used by any
 * public facing components.
 */
import { watch } from 'vue'

import { storeToRefs } from 'pinia'

import {
  HOST_PAGE_SIZE_LISTINGS,
  HOST_PAGE_SIZE_LISTINGS_DISPLAY_MAX,
  PUBLIC_PAGE_SIZE_LISTINGS,
  PUBLIC_PAGE_SIZE_LISTINGS_DISPLAY_MAX,
} from '@/constants/pagination.js'
import { useStoreHostListings } from '@/stores/storeHostListings'

export function useHostListings() {
  const storeHostListings = useStoreHostListings()
  const {
    createListingForm,
    createListingFormErrors,
    createListingStep,
    fetchingPublicListings,
    fetchingHostListings,
    fetchingHostListingDetails,
    hostListingDetails,
    publicListings,
    hostListings,
    publicPage,
    hostPage,
    updateListingForm,
    updatingListing,
    listingDetailsTab,
    listingLocation,
    updatingLocation,
    hostListingPriceDetails,
    hostSearch,
    hostSearchFilter,
    hostSearchOrder,
    showDeleteListingDialog,
    listingToDelete,
    isDeletingListing,
  } = storeToRefs(storeHostListings)

  /**
   * Detect when the pagination has changed for Host Listings.
   */
  watch(() => hostPage.value.pageCurrent, async (newVal, oldVal) => {
    if (newVal !== oldVal && !fetchingHostListings.value) {
      await fetchHostListings()
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    }
  }, { deep: true })

  /**
  * Detect when the Search Text has changed for Host Listings.
  */
  watch(() => hostSearch.value, async (newVal, oldVal) => {
    if (newVal !== oldVal && !fetchingHostListings.value) {
      await fetchHostListings()
    }
  }, { deep: true })

  /**
  * Detect when the Search Filter has changed for Host Listings.
  */
  watch(() => hostSearchFilter.value, async (newVal, oldVal) => {
    if (newVal !== oldVal && !fetchingHostListings.value) {
      await fetchHostListings()
    }
  }, { deep: true })

  /**
  * Detect when the Search Order has changed for Host Listings.
  */
  watch(() => hostSearchOrder.value, async (newVal, oldVal) => {
    if (newVal !== oldVal && !fetchingHostListings.value) {
      await fetchHostListings()
    }
  }, { deep: true })

  const fetchPublicListings = async () => {
    await storeHostListings.fetchPublicListings(
      PUBLIC_PAGE_SIZE_LISTINGS,
      PUBLIC_PAGE_SIZE_LISTINGS_DISPLAY_MAX,
    )
  }

  const fetchHostListings = async () => {
    await storeHostListings.fetchHostListings(
      HOST_PAGE_SIZE_LISTINGS,
      HOST_PAGE_SIZE_LISTINGS_DISPLAY_MAX,
    )
  }

  const fetchHostListingDetails = async (uuid) => {
    await storeHostListings.fetchHostListingDetails(uuid)
  }

  const fetchHostListingPriceDetails = async (uuid) => {
    await storeHostListings.fetchHostListingPriceDetails(uuid)
  }

  const fetchListingLocation = async (uuid) => {
    await storeHostListings.fetchListingLocation(uuid)
  }

  const createListing = async () => {
    return await storeHostListings.createListing()
  }

  const updateListing = async (uuid, form) => {
    return await storeHostListings.updateListing(uuid, form)
  }

  const updateListingState = async (uuid, form) => {
    return await storeHostListings.updateListingState(uuid, form)
  }

  const updateLocation = async (uuid, form) => {
    return await storeHostListings.updateLocation(uuid, form)
  }

  const createPrice = async (uuid, form) => {
    return await storeHostListings.createPrice(uuid, form)
  }

  const createDiscount = async (uuid, form) => {
    return await storeHostListings.createDiscount(uuid, form)
  }

  const createFee = async (uuid, form) => {
    return await storeHostListings.createFee(uuid, form)
  }

  const deleteFee = async (listingUuid, feeUuid) => {
    return await storeHostListings.deleteFee(listingUuid, feeUuid)
  }

  const deleteDiscount = async (listingUuid, discountUuid) => {
    return await storeHostListings.deleteDiscount(listingUuid, discountUuid)
  }

  const deleteListing = async (uuid) => {
    return await storeHostListings.deleteListing(uuid)
  }

  const resetCreateListingForm = () => {
    createListingForm.value = {
      title: '',
      property_type: null,
      bedrooms: 2,
      bathrooms: 2,
      location: {
        country: "United Arab Emirates",
        state: '',
        city: '',
        neighbourhood: '',
      },
      building_amenities: [],
      comfort_amenities: [],
      community_amenities: [],
      kitchen_amenities: [],
      living_amenities: [],
      safety_amenities: [],
      washroom_amenities: [],
      parking_type: null,
      price: {
        base: null,
        security_deposit: 0,
        taxes: 0,
        fee: 0,
        fee_type: 'Cleaning',
      },
    }
    createListingStep.value = 1
  }

  return {
    createListing,
    createListingForm,
    createListingFormErrors,
    createListingStep,
    fetchPublicListings,
    fetchHostListings,
    fetchingHostListings,
    fetchHostListingDetails,
    fetchHostListingPriceDetails,
    fetchingPublicListings,
    fetchingHostListingDetails,
    publicListings,
    hostListings,
    hostListingDetails,
    hostPage,
    publicPage,
    updateListingForm,
    updatingListing,
    updateListing,
    updateListingState,
    listingDetailsTab,
    fetchListingLocation,
    listingLocation,
    updatingLocation,
    updateLocation,
    createPrice,
    createDiscount,
    createFee,
    deleteFee,
    deleteDiscount,
    hostListingPriceDetails,
    hostSearch,
    hostSearchFilter,
    hostSearchOrder,
    showDeleteListingDialog,
    listingToDelete,
    isDeletingListing,
    deleteListing,
    resetCreateListingForm,
  }
}
