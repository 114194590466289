import { defineStore } from 'pinia'

import { client } from '@/services/request'

import { refreshPaginationValues } from '@/utils/pagination'

import { HOST_SEARCH_FILTER_OPTIONS, HOST_SEARCH_ORDER_OPTIONS } from '@/constants/pagination'

export const useStoreHostListings = defineStore('storeHostListings', {
  state: () => {
    return {
      // Host Listing
      hostListings: [],
      hostPage: {
        pageCurrent: 1,
        pageLast: 1,
        pageMaxLinks: 1,
        pageTotal: 0,
      },
      fetchingHostListings: false,
      fetchingHostListingDetails: false,
      hostListingDetails: {},
      hostSearch: '',
      hostSearchFilter: HOST_SEARCH_FILTER_OPTIONS[0],
      hostSearchOrder: HOST_SEARCH_ORDER_OPTIONS[0],

      // Delete listing
      listingToDelete: {},
      showDeleteListingDialog: false,
      isDeletingListing: false,

      // Create Listing
      createListingStep: 1,
      // If you change this, make sure to update the resetCreateListingForm method
      // in the composable.
      createListingForm: {
        title: '',
        property_type: null,
        bedrooms: 2,
        bathrooms: 2,
        location: {
          country: "United Arab Emirates",
          state: '',
          city: '',
          neighbourhood: '',
        },
        building_amenities: [],
        comfort_amenities: [],
        community_amenities: [],
        kitchen_amenities: [],
        living_amenities: [],
        safety_amenities: [],
        washroom_amenities: [],
        parking_type: null,
        price: {
          base: null,
          security_deposit: 0,
          taxes: 0,
          fee: 0,
          fee_type: 'Cleaning',
        },
      },
      creatingListing: false,
      createListingFormErrors: {},

      // Listing Details
      // TODO-FEATURE: Use component-local form ref instead of this!
      updateListingForm: {
        location: {}
      },
      updatingListing: false,
      updatingListingState: false,
      listingDetailsTab: 'general',
      listingLocation: {},
      fetchingListingLocation: false,
      updatingLocation: false,
      fetchingHostListingPriceDetails: false,
      hostListingPriceDetails: {},
      creatingPrice: false,
      creatingDiscount: false,
      creatingFee: false,
      deletingFee: false,
      deletingDiscount: false,
    }
  },

  actions: {
    async fetchPublicListings(pageSize, pageDisplayMax) {
      const params = new URLSearchParams({
        limit: pageSize,
        offset: (this.publicPage.current * pageSize) - pageSize,
      })

      const url = `listings/?${params}`
      this.fetchingPublicListings = true
      const response = await client.anonRequest(url)
      this.fetchingPublicListings = false

      if ([200, 201].includes(response.status)) {
        const data = response.data
        this.publicListings = data.results
        refreshPaginationValues(this.publicPage, data.count, pageSize, pageDisplayMax)
      } else {
        // TODO-FEATURE: Handle unsuccessful responses
      }
    },

    async fetchHostListings(pageSize, pageDisplayMax) {
      const params = new URLSearchParams({
        limit: pageSize,
        offset: (this.hostPage.pageCurrent * pageSize) - pageSize,
        filter: this.hostSearchFilter,
        order: this.hostSearchOrder,
        search: this.hostSearch,
      })

      const url = `listings/host/?${params}`
      this.fetchingHostListings = true
      const response = await client.authRequest(url)
      this.fetchingHostListings = false

      if ([200, 201].includes(response.status)) {
        const data = response.data
        this.hostListings = data.results
        refreshPaginationValues(this.hostPage, data.count, pageSize, pageDisplayMax)
      } else {
        // TODO-FEATURE: Handle unsuccessful responses
      }
    },

    async fetchHostListingDetails(uuid) {
      const url = `listings/host/${uuid}/`
      this.fetchingHostListingDetails = true
      const response = await client.authRequest(url)
      this.fetchingHostListingDetails = false

      if (response.status == 200) {
        this.hostListingDetails = response.data
      } else {
        // TODO-FEATURE: Handle unsuccessful responses
      }
    },

    async fetchHostListingPriceDetails(uuid) {
      const url = `listings/host/${uuid}/price/`
      this.fetchingHostListingPriceDetails = true
      const response = await client.authRequest(url)
      this.fetchingHostListingPriceDetails = false

      if (response.status === 200) {
        this.hostListingPriceDetails = response.data
      } else {
        // TODO-FEATURE: Handle unsuccessful responses
      }
    },

    async fetchListingLocation(uuid) {
      const url = `listings/host/${uuid}/location/`
      this.fetchingListingLocation = true
      const response = await client.authRequest(url)
      this.fetchingListingLocation = false

      if (response.status == 200) {
        this.listingLocation = response.data
      } else {
        // TODO-FEATURE: Handle unsuccessful responses
      }
    },

    async createListing() {
      const url = `listings/host/new/`

      this.createListingFormErrors = []

      // For the UAE, let the Emirate be the "state" as well
      this.createListingForm.location.state = this.createListingForm.location.city

      const amenityTypes = [
        'building_amenities',
        'comfort_amenities',
        'community_amenities',
        'kitchen_amenities',
        'living_amenities',
        'washroom_amenities',
        'safety_amenities',
      ]

      const form = { ... this.createListingForm }
      const amenities = []
      for (const key of amenityTypes) {
        amenities.push(...form[key])
        delete form[key]
      }
      form.amenities = amenities

      this.creatingListing = true
      const result = await client.authRequest(url, { method: 'POST', form })
      this.creatingListing = false

      return result
    },

    async updateListing(uuid, form) {
      const url = `listings/host/${uuid}/update/`

      this.updatingListing = true
      const result = await client.authRequest(url, { method: 'PATCH', form })
      this.updatingListing = false

      return result
    },

    async updateListingState(uuid, form) {
      const url = `listings/host/${uuid}/update/`

      this.updatingListingState = true
      const result = await client.authRequest(url, { method: 'PATCH', form })
      this.updatingListingState = false

      return result
    },

    async updateLocation(uuid, form) {
      const url = `listings/host/${uuid}/location/update/`

      this.updatingLocation = true
      const result = await client.authRequest(url, { method: 'POST', form })
      this.updatingLocation = false

      return result
    },

    async createPrice(uuid, form) {
      const url = `listings/host/${uuid}/price/new/`

      this.creatingPrice = true
      const result = await client.authRequest(url, { method: 'POST', form })
      this.creatingPrice = false

      return result
    },

    async createDiscount(uuid, form) {
      const url = `listings/host/${uuid}/price/discount/new/`

      this.creatingDiscount = true
      const result = await client.authRequest(url, { method: 'POST', form })
      this.creatingDiscount = false

      return result
    },

    async createFee(uuid, form) {
      const url = `listings/host/${uuid}/price/fee/new/`

      this.creatingFee = true
      const result = await client.authRequest(url, { method: 'POST', form })
      this.creatingFee = false

      return result
    },

    async deleteFee(listingUuid, feeUuid) {
      const url = `listings/host/${listingUuid}/price/fee/${feeUuid}/delete/`

      this.deletingFee = true
      const result = await client.authRequest(url, { method: 'DELETE' })
      this.deletingFee = false

      return result
    },

    async deleteDiscount(listingUuid, discountUuid) {
      const url = `listings/host/${listingUuid}/price/discount/${discountUuid}/delete/`

      this.deletingDiscount = true
      const result = await client.authRequest(url, { method: 'DELETE' })
      this.deletingDiscount = false

      return result
    },

    async deleteListing(uuid) {
      const url = `listings/host/${uuid}/delete/`

      this.isDeletingListing = true
      const result = await client.authRequest(url, { method: 'DELETE' })
      this.isDeletingListing = false

      return result
    },
  },
})
