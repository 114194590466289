<template>
  <div>
    <q-card-section>
      <div class="flex justify-between q-mt-md q-px-md">
        <div style="width: 90%">
          <q-input :disable="fetchingPublicListings" dense v-model="publicListingsSearch" debounce="500" filled
            placeholder="Search Listings">
            <template v-slot:prepend>
              <q-icon name="search" />
            </template>

            <template v-slot:append>
              <q-icon name="close" @click="publicListingsSearch = ''" class="cursor-pointer" />
            </template>
          </q-input>
        </div>
        <div class="self-center" style="max-width: 5%">
          <q-btn :disable="fetchingPublicListings" @click="showSearchOrder = true" icon="swap_vert">
            <q-badge v-if="showSearchOrderBadge" color="orange" floating>!</q-badge>
          </q-btn>
        </div>
        <div class="self-center" style="max-width: 5%">
          <q-btn :disable="fetchingPublicListings" @click="showSearchFilter = true" icon="tune">
            <q-badge v-if="showSearchFilterBadge" color="orange" floating>!</q-badge>
          </q-btn>
        </div>
      </div>

      <div class="row q-ml-md q-mt-sm">
        <div class="text-grey q-ml-lgs text-center">
          <template v-if="fetchingPublicListings">
            Getting listings...
          </template>
          <template v-else>
            Found {{ publicPage.pageTotal }} Listings
          </template>
        </div>
      </div>
    </q-card-section>
  </div>
  <div v-if="fetchingPublicListings">
    <q-card-section>
      <LoadingListingGridItemSkeleton :count="4" />
    </q-card-section>
  </div>
  <div v-else>
    <q-card-section>
      <div class="row" v-for="(row, index) in chunkedListings" :key="index">
        <div class="col-3" v-for="listing in row" :key="listing.uuid">
          <PublicListingGridItem :listing="listing" />
        </div>
      </div>
    </q-card-section>


    <div class="q-pt-md q-pb-sm flex flex-center">
      <q-pagination v-model="publicPage.pageCurrent" color="primary" :max="publicPage.pageLast"
        :max-pages="publicPage.pageMaxLinks" boundary-numbers />
    </div>

    <div class="text-center text-caption">
      <router-link style="text-decoration: none; color: inherit;" class="text-primary"
        :to="{ name: 'publicPrivacyPolicyView' }">
        Privacy Policy
      </router-link>
      and
      <router-link style="text-decoration: none; color: inherit;" class="text-primary"
        :to="{ name: 'publicTermsOfServiceView' }">
        Terms of Service
      </router-link>
    </div>

    <PublicListingSearchOrderDialog />
    <PublicListingSearchFilterDialog />
  </div>
</template>

<script setup>
import _ from 'lodash'

import { computed, onMounted } from 'vue'
import { useQuasar } from 'quasar'
import { useRouter } from 'vue-router'

import { usePublicListings } from '@/composables/publicListings'

import LoadingListingGridItemSkeleton from '@/components/skeletons/LoadingListingGridItemSkeleton.vue'
import PublicListingGridItem from '@/components/cards/PublicListingGridItem.vue'
import PublicListingSearchOrderDialog from '@/components/dialogs/PublicListingSearchOrderDialog.vue'
import PublicListingSearchFilterDialog from '@/components/dialogs/PublicListingSearchFilterDialog.vue'

const $q = useQuasar()
const router = useRouter()

const {
  fetchPublicListings,
  fetchingPublicListings,
  publicListings,
  publicPage,
  publicListingsSearch,
  showSearchOrder,
  showSearchFilter,
  publicListingsSearchFilter,
  publicListingsSearchOrder,
} = usePublicListings()

onMounted(async () => {
  // If user is on mobile, redirect to mobile view.
  if ($q.platform.is.mobile) {
    router.push({ name: 'publicListingsMobileView' })
  }

  if (publicListings.value.length === 0) {
    await fetchPublicListings()
  }
})

const chunkedListings = computed(() => {
  return _.chunk(publicListings.value, 4)
})

const showSearchOrderBadge = computed(() => {
  if (
    publicListingsSearchOrder.value.length
  ) {
    return true
  }
  return false
})

const showSearchFilterBadge = computed(() => {
  if (
    publicListingsSearchFilter.value.property_type.length
    || publicListingsSearchFilter.value.bedrooms.length
    || publicListingsSearchFilter.value.bathrooms.length
    || publicListingsSearchFilter.value.weekly_discount
    || publicListingsSearchFilter.value.monthly_discount
  ) {
    return true
  }
  return false
})
</script>
