import { storeToRefs } from 'pinia'

import { client } from '@/services/request'

import { useStoreHostProfile } from '@/stores/storeHostProfile'

export function useHostProfile() {
  const storeHostProfile = useStoreHostProfile()
  const {
    hostProfile,
    hostProfileTab,
    fetchingHostProfile,
    showUploadLogoDialog,
  } = storeToRefs(storeHostProfile)

  const fetchHostProfile = async () => {
    await storeHostProfile.fetchHostProfile()
  }

  const createLogoPresignedUrl = async (form) => {
    const url = `host/profile/logo/presigned-url/`
    const result = await client.authRequest(url, { method: 'POST', form })
    return result
  }

  const updateLogoImage = async (imageUuid, form) => {
    return await storeHostProfile.updateLogoImage(imageUuid, form)
  }

  const updateHostProfile = async (form) => {
    return await storeHostProfile.updateHostProfile(form)
  }

  return {
    fetchHostProfile,
    createLogoPresignedUrl,
    updateLogoImage,
    updateHostProfile,
    hostProfile,
    hostProfileTab,
    fetchingHostProfile,
    showUploadLogoDialog,
  }
}
