import router from '@/router/index'

export const CLOSE = {
  label: 'Close', color: 'white', outline: true, handler: () => { }
}

export const CONTACT_SUPPORT = {
  label: 'Contact Us',
  color: 'white',
  handler: () => {
    router.push({ name: 'publicContactView' })
  },
}

export const LOG_IN = {
  label: 'Log In',
  color: 'white',
  outline: true,
  handler: () => {
    router.push({ name: 'publicLoginView', query: { next: window.location.href } })
  }
}
