<template>
  <q-dialog v-model="showSearchFilter">
    <q-card style="width: 400px" class="q-px-sm q-pb-md">
      <q-card-section>
        <div class="text-h6 text-center">
          Filter Listings
        </div>
      </q-card-section>

      <q-item dense class="q-mb-sm">
        <q-item-section avatar>
          <q-icon name="house" size="md" />
        </q-item-section>
        <q-item-section>
          <q-select dense rounded use-chips multiple outlined v-model="publicListingsSearchFilter.property_type"
            :options="PROPERTY_TYPE_OPTIONS" label="Property Type" />
        </q-item-section>
      </q-item>

      <q-item dense class="q-mb-sm">
        <q-item-section avatar>
          <q-icon name="bed" size="md" />
        </q-item-section>
        <q-item-section>
          <q-select dense use-chips rounded multiple outlined v-model="publicListingsSearchFilter.bedrooms"
            :options="BEDROOM_OPTIONS" label="Bedrooms" />
        </q-item-section>
      </q-item>

      <q-item dense class="q-mb-sm">
        <q-item-section avatar>
          <q-icon name="shower" size="md" />
        </q-item-section>
        <q-item-section>
          <q-select dense use-chips rounded multiple outlined v-model="publicListingsSearchFilter.bathrooms"
            :options="BATHROOM_OPTIONS" label="Bathrooms" />
        </q-item-section>
      </q-item>

      <q-item dense class="q-mb-sm">
        <q-item-section avatar>
          <q-icon name="attach_money" size="md" />
        </q-item-section>
        <q-item-section>
          <div class="flex justify-evenlys">
            <div class="text-caption self-center text-grey-10">
              Discounts:
            </div>
            <div>
              <q-checkbox v-model="publicListingsSearchFilter.weekly_discount" label="Weekly" />
            </div>
            <div>
              <q-checkbox v-model="publicListingsSearchFilter.monthly_discount" label="Monthly" />
            </div>
          </div>

        </q-item-section>
      </q-item>

      <template v-if="user.is_ops === true">
        <OpsCard>
          <template v-slot:ops-content>
            <q-item dense class="q-mt-md">
              <q-item-section avatar>
                <q-icon name="view_list" size="md" />
              </q-item-section>
              <q-item-section>
                <q-select label-color="grey-1" color="grey-1" dense use-chips rounded multiple outlined
                  v-model="publicListingsSearchFilterOps.listing_status" :options="OPS_LISTING_STATUS_OPTIONS"
                  label="Listing Status">
                  <template v-slot:selected-item="scope">
                    <q-chip removable @remove="scope.removeAtIndex(scope.index)" :tabindex="scope.tabindex"
                      text-color="white" class="q-ma-xs" size="md" style="background-color: #ae0000;">
                      {{ scope.opt }}
                    </q-chip>
                  </template>
                </q-select>
              </q-item-section>
            </q-item>
          </template>
        </OpsCard>
      </template>

      <q-item dense>
        <q-item-section>
          <div class="flex justify-center q-mt-sm">
            <div>
              <q-btn @click="onClear" :disable="fetchingPublicListings" :loading="fetchingPublicListings" label="Clear"
                class="q-ml-md" />
            </div>
            <div>
              <q-btn @click="onApply" :disable="fetchingPublicListings" :loading="fetchingPublicListings" label="Apply"
                class="q-ml-md" />
            </div>
          </div>
        </q-item-section>
      </q-item>
    </q-card>
  </q-dialog>
</template>


<script setup>
import { usePublicListings } from '@/composables/publicListings'

import { PROPERTY_TYPE_OPTIONS, OPS_LISTING_STATUS_OPTIONS } from '@/constants/publicListing'
import { BEDROOM_OPTIONS, BATHROOM_OPTIONS } from '@/constants/listing'

import OpsCard from '@/components/cards/OpsCard.vue'

import { useAuth } from '@/composables/auth'

const { user } = useAuth()

const {
  showSearchFilter,
  publicListingsSearchFilter,
  publicListingsSearchFilterOps,
  fetchingPublicListings,
  fetchPublicListings,
} = usePublicListings()

const onApply = async () => {
  // Reset the current page to 1 because the limit and offset values could
  // have previous values.
  await fetchPublicListings(true)
  showSearchFilter.value = false
}

const onClear = async () => {
  publicListingsSearchFilter.value.property_type = []
  publicListingsSearchFilter.value.bedrooms = []
  publicListingsSearchFilter.value.bathrooms = []
  publicListingsSearchFilter.value.weekly_discount = false
  publicListingsSearchFilter.value.monthly_discount = false
  showSearchFilter.value = false
  await fetchPublicListings(true)
}
</script>
