import { useStoreAuth } from '@/stores/storeAuth'

import {
  NETWORK_ERROR,
  notifyNetworkError,
  notifyLogIn,
  notifySorry,
  notifyUnexpectedError,
} from '@/notifications/events'

export const useRequestService = (baseURL) => {
  /**
   * Prefer to use authenticated request when token exists, otherwise
   * just send an anon request.
   */
  async function authOrAnonRequest(url, options) {
    const storeAuth = useStoreAuth()
    const token = storeAuth.getToken()

    if (token) {
      return this.authRequest(url, options)
    } else {
      return this.anonRequest(url, options)
    }
  }

  async function authRequest(url, options) {
    const storeAuth = useStoreAuth()

    const method = options?.method ? options.method : 'GET'
    const form = options?.form ? options.form : null
    const flat = options?.flat ? options.flat : false
    const token = options?.token ? options.token : storeAuth.getToken()
    const raiseErrors = options?.raiseErrors ? options.raiseErrors : false

    /**
     * This is a safety check. Ideally, the caller should check
     * if the user is logged in or not.
     */
    if (!token) {
      const message = 'Please log in to perform this action.'
      if (raiseErrors) {
        throw new Error(message)
      } else {
        notifyLogIn()
        return { status: 403, data: {} }
      }
    }

    return _makeRequest(url, method, form, flat, token, raiseErrors)
  }

  async function anonRequest(url, options) {
    const method = options?.method ? options.method : 'GET'
    const form = options?.form ? options.form : null
    const flat = options?.flat ? options.flat : false
    const raiseErrors = options?.raiseErrors ? options.raiseErrors : false
    const token = null

    return _makeRequest(url, method, form, flat, token, raiseErrors)
  }

  async function _makeRequest(url, method, form, flat, token, raiseErrors) {
    url = `${baseURL}${url}`

    const options = {
      method: method,
      headers: {
        'Content-Type': 'application/json',
      },
    }

    if (token) {
      options.headers['Authorization'] = `Token ${token}`
    }

    if (["PATCH", "POST", "PUT"].includes(method)) {
      options.body = JSON.stringify(form)
    }

    let response
    try {
      response = await fetch(url, options)
    } catch (error) {
      const message = NETWORK_ERROR
      if (raiseErrors) {
        throw new Error(message)
      } else {
        notifyNetworkError()
        return { status: 500, data: message }
      }
    }

    const status = response.status

    if (status === 204) {
      // Successful delete, no content, so return early
      return { status, data: {} }
    }

    let data
    try {
      data = await response.json()
    } catch (error) {
      // Could be a 404 or other unexpected response
      const msg = `Unexpected server error: ${response.status}`
      if (raiseErrors) {
        throw new Error(msg)
      } else {
        notifyUnexpectedError(response.status)
        return { status: response.status, data: [] }
      }
    }

    if (status === 200 && flat === true) {
      return { status, data }
    }

    if ([200, 201, 204].includes(status)) {
      return { status, data }
    }

    let errorMessage = ''

    if (status === 400) {
      return { status, data }
    } else if (status === 401) {
      const storeAuth = useStoreAuth()

      // Token not being accepted is unusal, so let's log out the user
      // for safety.
      storeAuth.logOut()
      notifyLogIn()
    } else if (status === 403) {
      notifySorry("Sorry, you're not allowed to access this resource.")
    } else {
      notifySorry('Sorry, there was an unexpected error.')
    }

    if (raiseErrors) {
      throw new Error(errorMessage)
    }
    return { status, 'data': errorMessage }
  }


  return {
    anonRequest,
    authRequest,
    authOrAnonRequest,
  }
}

export const client = useRequestService(import.meta.env.VITE_ROOMBEE_API_URL)
