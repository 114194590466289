<template>
  <q-step :name="3" prefix="3" title="3. Amenities" caption="&nbsp;Outdoor" :done="createListingStep > 3">
    <p>What <span class="text-weight-medium">outdoor</span> amenities does your property have?</p>

    <div class="row justify-start">
      <div class="col-auto q-mr-md">
        <span class="text-h6 q-pl-sm">Community</span>

        <div class="row">
          <q-checkbox v-for="option in communityAmenities" :key="option.value"
            v-model="createListingForm.community_amenities" :label="option.label" :val="option.value" class="q-mr-sm" />
        </div>
      </div>

      <div class="col-5">
        <span class="text-h6 q-pl-sm">Parking</span>
        <q-select ref="parkingRef" :rules="parkingRules" dense rounded outlined v-model="createListingForm.parking_type"
          :options="parkingTypes" label="Parking" />
      </div>

    </div>

    <q-separator inset class="q-my-sm" />

    <span class="text-h6 q-pl-sm">Building</span>
    <q-btn @click="setFormArray('building_amenities', buildingAmenities)" flat dense no-caps
      class="text-caption q-ml-sm text-blue-10">Select All</q-btn>
    <q-btn @click="setFormArray('building_amenities', [])" flat dense no-caps
      class="text-caption text-green-10">Unselect
      All</q-btn>

    <div class="row">
      <q-checkbox v-for="option in buildingAmenities" :key="option.value" v-model="createListingForm.building_amenities"
        :label="option.label" :val="option.value" class="q-mr-sm" />
    </div>

    <q-separator inset class="q-my-sm" />

    <span class="text-h6 q-pl-sm">Safety</span>
    <q-btn @click="setFormArray('safety_amenities', safetyAmenities)" flat dense no-caps
      class="text-caption q-ml-sm text-blue-10">Select All</q-btn>
    <q-btn @click="setFormArray('safety_amenities', [])" flat dense no-caps class="text-caption text-green-10">Unselect
      All</q-btn>

    <div class="row">
      <q-checkbox v-for="option in safetyAmenities" :key="option.value" v-model="createListingForm.safety_amenities"
        :label="option.label" :val="option.value" class="q-mr-sm" />
    </div>

    <q-stepper-navigation>
      <q-btn @click="submitStep3" color="primary" label="Next" />
      <q-btn flat @click="createListingStep = 2" color="secondary" label="Back" class="q-ml-sm" />
    </q-stepper-navigation>
  </q-step>
</template>

<script setup>
import { ref } from 'vue'

import { useHostListings } from '@/composables/hostListings'
import { useMeta } from '@/composables/meta'

const {
  parkingTypes,
} = useMeta()
const {
  createListingForm,
  createListingStep,
} = useHostListings()

const parkingRef = ref(null)
const parkingRules = [
  val => (val && val.length > 0) || 'Please select the Parking Type'
]

const communityAmenities = [
  { label: 'Private Beach Access', value: 'has_beach_access' }
]
const buildingAmenities = [
  { label: 'Balcony', value: 'has_balcony' },
  { label: 'Elevator', value: 'has_elevator' },
  { label: 'Gym', value: 'has_gym' },
  { label: 'Jacuzzi', value: 'has_jacuzzi' },
  { label: 'Private Entrance', value: 'has_private_entrance' },
  { label: 'Private Pool', value: 'has_pool_private' },
  { label: 'Sauna', value: 'has_sauna' },
  { label: 'Steam Room', value: 'has_steam_room' },
  { label: 'Shared Pool', value: 'has_pool_shared' },
  { label: 'Roof Access', value: 'has_roof_access' },
  { label: 'Self Check-in', value: 'has_self_checkin' },
  { label: 'WiFi', value: 'has_wifi' },
  { label: 'Workspace/Desk', value: 'has_workspace' },
]
const safetyAmenities = [
  { label: 'Fire Extinguisher', value: 'has_fire_extinguisher' },
  { label: 'Fist Aid Kit', value: 'has_first_aid' },
  { label: 'Safe / Security Box', value: 'has_safe' },
]

const setFormArray = (key, data) => {
  createListingForm.value[key] = data.map((item) => { return item.value })
}

const submitStep3 = () => {
  parkingRef.value.validate()

  if (
    parkingRef.value.hasError
  ) {
    return
  } else {
    createListingStep.value = 4
  }
}
</script>
