<template>
  <div>
    <q-card-section>
      <div class="row justify-center">
        <div class="col-6">
          <div class="flex justify-center">
            <SocialGoogle prefix="Log In" />
          </div>
        </div>
      </div>
    </q-card-section>

    <!-- <q-card-section>
      <div class="row justify-center">
        <div class="col-6">
          <div class="flex justify-center">
            <SocialFacebook prefix="Log In" />
          </div>
        </div>
      </div>
    </q-card-section> -->

    <q-card-section>
      <div class="row justify-center">
        <div class="col-6">
          <div class="row fit no-wrap items-center">
            <q-separator class="col" />
            <div class="col-grow q-mx-sm text-body1 text-grey-8">Or Log In Manually</div>
            <q-separator class="col" />
          </div>
        </div>
      </div>

    </q-card-section>

    <q-card-section>
      <div class="row justify-center">
        <div class="text-center col-6">
          <q-input ref="emailRef" rounded outlined maxlength="50" counter v-model="email" :rules="emailRules"
            label="Enter your registered Email Address" hint="The Email Address you used to sign up." dense />
        </div>
      </div>
    </q-card-section>

    <q-card-section>
      <div class="row justify-center">
        <div class="text-center col-6">
          <q-input ref="passwordRef" @keyup.enter="onLogIn" :type="isPassword ? 'password' : 'text'" rounded outlined
            maxlength="50" counter :rules="passwordRules" v-model="password" label="Enter your Password" dense>
            <template v-slot:append>
              <q-icon :name="isPassword ? 'visibility_off' : 'visibility'" class="cursor-pointer"
                @click="isPassword = !isPassword" />
            </template>
          </q-input>
        </div>
      </div>
    </q-card-section>

    <q-card-section v-if="error">
      <div class="row justify-center text-center">
        <div class="col-6">
          <div class="text-h6 text-red-10">Login Error</div>
          Could not log in with these credentials. Please check your email/password and try again. If the problem
          persists, please
          <router-link :to="{ name: 'publicContactView' }" target="_blank">Contact Us</router-link>
          or send us an email at {{ supportEmail }}
        </div>
      </div>
    </q-card-section>

    <div class="row justify-center q-pb-md">
      <div class="text-center col-6">
        <q-btn color="primary" @click="onLogIn" :disable="!formIsValid" :loading="isLoggingIn">Log In</q-btn>
      </div>
    </div>

    <q-card-section>
      <div class="text-center text-grey-8 q-mb-sm">
        If you don't have an account, you can
        <router-link :to="{ name: 'publicRegisterSocialView' }">Sign Up here</router-link>.
      </div>
      <div class="text-center text-grey-8 q-mb-sm">
        Or, go to the
        <router-link :to="{ name: 'publicListingsView' }">Home Page</router-link>
        without logging in.
      </div>
    </q-card-section>
  </div>
</template>

<script setup>
import { computed, onMounted, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useQuasar } from 'quasar'

import SocialGoogle from '@/components/registration/SocialGoogle.vue'
// Disable until we register Roomb.io as a business - unable to get
// facebook business verification.
// import SocialFacebook from '@/components/registration/SocialFacebook.vue'

import { useAuth } from '@/composables/auth'

const { logIn } = useAuth()
const route = useRoute()
const $q = useQuasar()
const router = useRouter()

const email = ref('')
const password = ref('')
const isPassword = ref(true)
const emailRef = ref(null)
const passwordRef = ref(null)

const emailRules = [val => (val && val.length > 0) || 'Please enter your Email']
const passwordRules = [val => (val && val.length > 0) || 'Please enter your Password']

const isLoggingIn = ref(false)
const error = ref(false)
const supportEmail = import.meta.env.VITE_ROOMBEE_SUPPORT_EMAIL

const formIsValid = computed(() => {
  const result = Boolean(email.value.length > 0 && password.value.length > 0) && !isLoggingIn.value
  return result
})

onMounted(async () => {
  if ($q.platform.is.mobile) {
    router.push({ name: 'publicLoginMobileView' })
  }
})

const onLogIn = async () => {
  emailRef.value.validate()
  passwordRef.value.validate()
  if (emailRef.value.hasError || passwordRef.value.hasError) {
    return
  }

  error.value = false
  const form = {
    email: email.value,
    password: password.value,
  }
  isLoggingIn.value = true
  const resp = await logIn(form)
  isLoggingIn.value = false

  if (resp.status === 200) {
    const nextUrl = route?.query?.next
    if (nextUrl && nextUrl.length > 0) {
      window.location.replace(nextUrl)
    } else {
      router.push({ name: 'publicListingsView' })
    }
  } else {
    error.value = true
  }
}
</script>
