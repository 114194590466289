import { defineStore } from 'pinia'

import { client } from '@/services/request'

export const useStoreHostProfile = defineStore('storeHostProfile', {
  state: () => {
    return {
      hostProfile: {},
      fetchingHostProfile: false,
      hostProfileTab: 'companyDetails',
      showUploadLogoDialog: false,
    }
  },

  actions: {
    async fetchHostProfile() {
      const url = `host/profile/`
      this.fetchingHostProfile = true
      const response = await client.authRequest(url)
      this.fetchingHostProfile = false

      if (response.status == 200) {
        this.hostProfile = response.data
      } else {
        // TODO-FEATURE: Handle unsuccessful responses
      }
    },

    async updateLogoImage(imageUuid, form) {
      const url = `host/profile/logo/${imageUuid}/update/`

      const result = await client.authRequest(url, { method: 'PATCH', form })

      return result
    },

    async updateHostProfile(form) {
      const url = `host/profile/update/`
      return await client.authRequest(url, { method: 'PATCH', form })
    },
  },
})
