<template>
  <q-tab-panel name="profile">
    <div class="flex">
      <q-card>
        <q-avatar square>
          <img :src='getGravatarUrl(user.uuid)' />
        </q-avatar>
      </q-card>
      <div class="text-body1 q-ml-sm">
        Hi there <b>{{ user.username }}</b> <q-icon name="waving_hand" color="yellow-9" size="sm" />
        <div class="text-grey-10 text-caption">
          {{ user.email }}
        </div>
        <div class="text-grey-8 text-caption">
          Joined on {{ user.joined_on }}
        </div>
      </div>
    </div>

    <q-card class="rb-public-profile__email-disclaimer-panel q-pa-sm q-my-md" flat bordered>
      <q-icon name="info" color="grey-8" size="xs" />
      Your email is <b>private</b> and is never visible to users. Only your
      username is
      visible
      to other users, e.g. when you post a review for a property.
    </q-card>

    <div class="text-body1 q-mt-md">
      This is your profile page. There isn't much going on here at the moment,
      but we're at a super early stage right now. Some cool features are on
      the horizon, so keep an eye on this page!
    </div>
    <div class="text-body2 q-mt-md">
      If you have any suggestions on how we can make this page more useful to you,
      please
      <router-link style="text-decoration: none; color: inherit;" class="text-primary"
        :to="{ name: 'publicContactView' }">
        send us a message!
      </router-link>
    </div>
  </q-tab-panel>
  <!-- hack to push the footer to the bottom. -->
  <!-- <div style="min-height: 10em;"></div> -->
</template>

<script setup>
import { getGravatarUrl } from '@/utils/avatar'
import { useAuth } from '@/composables/auth'

const { user } = useAuth()
</script>
