<template>
  <q-card bordered class="q-mx-sm q-mb-sm" flat>
    <div class="row">
      <div class="col">
        <div v-if="listing.images.length > 0">
          <q-card flat square>
            <q-carousel arrows swipeable animated v-model="slide" infinite height="15em">
              <q-carousel-slide v-for="(image, index) in listing.images" :key="image.uuid" :name="index"
                class="no-padding">
                <div class="image-wrapper">
                  <q-inner-loading :showing="!loadedImages[index]" v-if="!someImagesLoaded">
                    <q-spinner-grid size="50px" color="blue-10" />
                  </q-inner-loading>
                  <img :src="image.signed_url" @load="handleImageLoad(index)" @error="handleImageError(index)"
                    :class="{ 'image-loaded': loadedImages[index] }" />
                </div>
              </q-carousel-slide>
            </q-carousel>
          </q-card>
        </div>
        <div v-else class="row text-h5 text-grey text-center" style="min-height: 10em; background-color: #efefef;">
          <div class="col self-center">
            No images to show.
          </div>
        </div>
      </div>
    </div>

    <q-chip v-if="showNewOpsChip(listing)" class="q-ml-sm" dense color="red" text-color="white" icon="warning"
      label="New" />

    <div class="q-mx-sm q-mt-xs q-mb-xs" @click.stop="goToDetails">
      <div class="row">
        <div class="col-10">
          <div>
            <router-link style="text-decoration: none; color: inherit;"
              class="text-primary rb-public-listing-card__title"
              :to="{ name: 'publicListingDetailsMobileView', params: { uuid: listing.uuid, listingSlug: listing.slug } }">
              <span class="">{{ truncate(listing.title, 50) }}</span>
            </router-link>
            <span class="q-ml-sm text-grey-9 rb-public-listing-card__subtitle">
              <template v-if="listing.sub_title.length">
                {{ truncate(listing.sub_title, 55) }}
              </template>
              <template v-else>
                {{ truncate(listing.title, 50) }}
              </template>
            </span>
          </div>
        </div>

        <div class="col">
          <div class="row text-center">
            <div class="col">
              <router-link :to="{ name: 'publicHostDetailsView', params: { uuid: listing.company?.uuid } }">
                <q-img no-spinner :src="listing.company.logo" />
              </router-link>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <PropertyTypeChip :property-type="listing.property_type" />
        <BasicInfoChip :text="listing.bedrooms" icon="bed" />
        <BasicInfoChip :text="listing.bathrooms" icon="bathtub" />
        <BasicInfoChip :text="listing.property_sqft" icon="square_foot" />
      </div>

      <q-separator class="q-my-xs" />

      <div class="text-body2">
        <ListingPriceChip :amount="listing.price.total_price" />
        <ListingRatingChip :rating-overall="listing.rating_overall" unrated-text="-" />
      </div>
    </div>
  </q-card>
</template>

<script setup>
import { ref, computed } from 'vue'
import { useRouter } from "vue-router"
import { useAuth } from '@/composables/auth'

import { truncate } from '@/utils/string'

import PropertyTypeChip from '@/components/chips/PropertyTypeChip.vue'
import ListingRatingChip from '@/components/chips/ListingRatingChip.vue'
import BasicInfoChip from '@/components/chips/BasicInfoChip.vue'
import ListingPriceChip from '@/components/chips/ListingPriceChip.vue'

const router = useRouter()

const props = defineProps({
  listing: Object
})

const { showNewOpsChip } = useAuth()

const slide = ref(0)
const loadedImages = ref([])

/**
 * Only display the inner loading on initial page load. We don't want to
 * display it during navigating between slides.
 */
const someImagesLoaded = computed(() => {
  return loadedImages.value[0] === true
})

const goToDetails = () => {
  router.push({
    name: 'publicListingDetailsMobileView',
    params: { uuid: props.listing.uuid, listingSlug: props.listing.slug }
  })
}

const handleImageLoad = (index) => {
  // Mark image as loaded
  loadedImages.value[index] = true
}

const handleImageError = (index) => {
  // Handle load error if needed
  loadedImages.value[index] = false
  console.error(`Image failed to load at index ${index}`)
}
</script>

<style scoped>
.image-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
}

img {
  width: 100%;
  height: 96%;
  object-fit: cover;
  opacity: 0;
  transition: opacity 0.3s ease;
}

img.image-loaded {
  opacity: 1;
}
</style>
