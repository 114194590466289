import { storeToRefs } from 'pinia'

import { useStoreBreadcrumbs } from '@/stores/storeBreadcrumbs'

export function useBreadcrumbs() {
  const storeBreadcrumbs = useStoreBreadcrumbs()
  const {
    showListingsBreadcrumbs,
    showHostDetailsBreadcrumbs,
  } = storeToRefs(storeBreadcrumbs)

  return {
    showListingsBreadcrumbs,
    showHostDetailsBreadcrumbs,
  }
}
