import { storeToRefs } from 'pinia'

import { useStoreImages } from '@/stores/storeImages'

export function useImages() {
  const storeImages = useStoreImages()
  const {
    listingImages,
    listingImageInfo,
    showUploadImageDialog,
    isPublishing,
    isDeletingImage,
    showDeleteImageDialog,
    imageToDelete,
  } = storeToRefs(storeImages)

  const createPresignedUrl = async (uuid, form) => {
    return await storeImages.createPresignedUrl(uuid, form)
  }

  const updateListingImage = async (listingUuid, imageUuid, form) => {
    return await storeImages.updateListingImage(listingUuid, imageUuid, form)
  }

  const fetchListingImages = async (uuid) => {
    return await storeImages.fetchListingImages(uuid)
  }

  const fetchListingImageInfo = async (uuid) => {
    return await storeImages.fetchListingImageInfo(uuid)
  }

  const deleteImage = async (listingUuid, imageUuid) => {
    return await storeImages.deleteImage(listingUuid, imageUuid)
  }

  return {
    createPresignedUrl,
    updateListingImage,
    fetchListingImages,
    fetchListingImageInfo,
    deleteImage,
    isDeletingImage,
    listingImageInfo,
    listingImages,
    showUploadImageDialog,
    isPublishing,
    showDeleteImageDialog,
    imageToDelete,
  }
}
