import { createApp } from 'vue'

import { createPinia } from 'pinia'
import { Notify, Quasar } from 'quasar'
import Rollbar from 'rollbar'

import App from './App.vue'
import router from './router'

import * as Sentry from "@sentry/vue"
import GoogleSignInPlugin from "vue3-google-signin"
import { createGtm } from '@gtm-support/vue-gtm'

import { VERSION } from '../version'

// Import Material icons
import '@quasar/extras/material-icons/material-icons.css'
import '@quasar/extras/material-symbols-outlined/material-symbols-outlined.css'

// Import Quasar css
import 'quasar/src/css/index.sass'

// Import custom css
import '@/assets/css/rb-base.scss'

import '@/assets/css/rb-public-listing-card.scss'
import '@/assets/css/rb-public-listing-details.scss'
import '@/assets/css/rb-public-icon.scss'
import '@/assets/css/rb-public-host-listing-details.scss'
import '@/assets/css/rb-public-chips.scss'
import '@/assets/css/rb-public-contact.scss'
import '@/assets/css/rb-public-buttons.scss'
import '@/assets/css/rb-public-profile.scss'
import '@/assets/css/rb-public-terms-of-service.scss'

import '@/assets/css/rb-host-buttons.scss'
import '@/assets/css/rb-host-chips.scss'
import '@/assets/css/rb-host-listing-preview-card.scss'

import '@/assets/css/rb-note.scss'
import '@/assets/css/rb-notify.scss'

// I thought this was needed to enable flex (justify-start css class, etc)
// but apparently not?
//
// import "quasar/src/css/flex-addon.sass"

const rollbar = new Rollbar({
  accessToken: import.meta.env.VITE_ROLLBAR_TOKEN,
  captureUncaught: true,
  captureUnhandledRejections: true,
  payload: {
    environment: import.meta.env.VITE_ROLLBAR_ENV,
    client: {
      javascript: {
        code_version: VERSION,
      },
    },
  },
})

const app = createApp(App)

app.use(router)

app.provide('roombeeVersion', VERSION)

app.provide('rollbar', rollbar)

app.config.errorHandler = (error, vm, info) => {
  if (import.meta.env.VITE_ROLLBAR_ENV === 'localdev') {
    console.error(error)
  } else {
    if (import.meta.env.VITE_ROLLBAR_ENV === 'staging') {
      console.error(error)
    }
    rollbar.error(error, { vueComponent: vm, info })
  }
}
app.config.globalProperties.$rollbar = rollbar

Sentry.init({
  app,
  dsn: import.meta.env.VITE_SENTRY_DSN,
  integrations: [
    Sentry.browserTracingIntegration({ router }),
    // Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", "http://localhost:5173", "https://api.roombee.net/api", "https://api.roomb.io/api"],
  // Session Replay
  // replaysSessionSampleRate: 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  // replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  environment: import.meta.env.VITE_ROLLBAR_ENV,
  trackComponents: true,
  // Enable to hook into the sentry error
  // beforeSend(event, hint) {
  //   return event
  // },
})

const pinia = createPinia()
pinia.use(
  ({ store }) => {
    store.$rollbar = rollbar
  }
)
app.use(pinia)

app.use(
  createGtm({
    id: import.meta.env.VITE_GOOGLE_ANALYTICS_GTM_ID,
    vueRouter: router
  })
)
app.use(Quasar, {
  plugins: { Notify },
  config: {},
})
app.use(GoogleSignInPlugin, {
  clientId: import.meta.env.VITE_GOOGLE_OAUTH_CLIENT_ID,
})


app.mount('#app')
