<template>
  <q-tab-panel>
    <div class="row">
      <div class="col-3">
        <div v-if="hostProfile?.logo?.length > 0">
          <q-card class="q-pa-sm">
            <q-img :src="hostProfile.logo" />
          </q-card>
        </div>
        <div v-else class="row text-h5 text-grey text-center" style="min-height: 200px; background-color: #efefef;">
          <div class="col self-center">
            No Logo
            <p class="text-body1 q-mt-sm q-mx-sm">Please upload a logo. This logo will appear for every listing you
              create.</p>
          </div>
        </div>
        <div class="text-center q-mt-sm">
          <q-btn class="rb-host-buttons__submit" @click="showUploadLogoDialog = true">Upload Logo</q-btn>
        </div>
      </div>

      <div class="col q-ml-lg">
        <div class="text-h6 text-grey">
          Company Name
          <q-icon size="medium" class="cursor-pointer" color="grey-6" name="mode_edit" @click="namePopupRef.show()" />
        </div>
        <div class="text-h5">
          {{ hostProfile.name || 'Click to update Company Name' }}
          <q-popup-edit @before-show="clearExistingPopup('namePopupRef')" ref="namePopupRef" v-model="name"
            v-slot="scope" persistent>
            <q-input maxlength="50" counter autofocus dense v-model="scope.value" :model-value="scope.value"
              hint="Your Company Name." :error-message="errorMessages?.name"
              :error="errorMessages?.name?.length > 0 || false">
              <template v-slot:after>
                <q-btn :disable="updatingCompanyProfile" flat dense color="negatives" icon="cancel"
                  @click="cancel(scope, 'name')" />
                <q-btn flat dense color="green" icon="check_circle" @click="update(scope, 'name')"
                  :disable="updatingCompanyProfile" />
              </template>
            </q-input>
          </q-popup-edit>
        </div>


        <div class="row q-mt-md">
          <div class="col-6">
            <div class="text-h6 text-grey">
              Email
              <q-icon size="medium" class="cursor-pointer" color="grey-6" name="mode_edit"
                @click="emailPopupRef.show()" />
            </div>
            <div class="text-body-1">
              {{ hostProfile.email || 'Click to update your email' }}
              <q-popup-edit @before-show="clearExistingPopup('emailPopupRef')" ref="emailPopupRef" v-model="email"
                v-slot="scope" persistent>
                <q-input maxlength="50" counter autofocus dense v-model="scope.value" :model-value="scope.value"
                  hint="Your Company Email." :error-message="errorMessages?.email"
                  :error="errorMessages?.email?.length > 0 || false">
                  <template v-slot:after>
                    <q-btn :disable="updatingCompanyProfile" flat dense color="negatives" icon="cancel"
                      @click="cancel(scope, 'email')" />
                    <q-btn flat dense color="green" icon="check_circle" @click="update(scope, 'email')"
                      :disable="updatingCompanyProfile" />
                  </template>
                </q-input>
              </q-popup-edit>
            </div>
          </div>


          <div class="col-3">
            <div class="text-h6 text-grey">
              Phone
              <q-icon size="medium" class="cursor-pointer" color="grey-6" name="mode_edit"
                @click="phoneNumberPopupRef.show()" />
            </div>
            <div class="text-body-1">
              {{ hostProfile.phone_number || 'Click to update your phone number' }}
              <q-popup-edit @before-show="clearExistingPopup('phoneNumberPopupRef')" ref="phoneNumberPopupRef"
                v-model="phoneNumber" v-slot="scope" persistent>
                <q-input ref="phoneNumberInputRef" :rules="PHONE_NUMBER_RULES" type="tel" maxlength="20" counter
                  autofocus dense v-model="scope.value" :model-value="scope.value" hint="Your Company Phone Number."
                  :error-message="errorMessages?.phone_number"
                  :error="errorMessages?.phone_number?.length > 0 || false">
                  <template v-slot:after>
                    <q-btn :disable="updatingCompanyProfile" flat dense color="negatives" icon="cancel"
                      @click="cancel(scope, 'phone_number')" />
                    <q-btn flat dense color="green" icon="check_circle" @click="update(scope, 'phone_number')"
                      :disable="updatingCompanyProfile" />
                  </template>
                </q-input>
              </q-popup-edit>
            </div>
          </div>

          <div class="col-3">
            <div class="text-h6 text-grey">
              WhatsApp
              <q-icon size="medium" class="cursor-pointer" color="grey-6" name="mode_edit"
                @click="whatsAppPopupRef.show()" />
            </div>
            <div class="text-body-1">
              {{ hostProfile.whatsapp || 'Click to update your phone number' }}
              <q-popup-edit @before-show="clearExistingPopup('whatsAppPopupRef')" ref="whatsAppPopupRef"
                v-model="whatsApp" v-slot="scope" persistent>
                <q-input ref="whatsAppInputRef" :rules="PHONE_NUMBER_RULES" type="tel" maxlength="20" counter autofocus
                  dense v-model="scope.value" :model-value="scope.value" hint="Your WhatsApp Number."
                  :error-message="errorMessages?.whatsapp" :error="errorMessages?.whatsapp?.length > 0 || false">
                  <template v-slot:after>
                    <q-btn :disable="updatingCompanyProfile" flat dense color="negatives" icon="cancel"
                      @click="cancel(scope, 'whatsapp')" />
                    <q-btn flat dense color="green" icon="check_circle" @click="update(scope, 'whatsapp')"
                      :disable="updatingCompanyProfile" />
                  </template>
                </q-input>
              </q-popup-edit>
            </div>
          </div>
        </div>

        <div class="row q-mt-md">
          <div class="col">
            <div class="text-h6 text-grey">
              Website
              <q-icon size="medium" class="cursor-pointer" color="grey-6" name="mode_edit"
                @click="websitePopupRef.show()" />
            </div>
            <div class="text-body-1">
              {{ hostProfile.website || 'Click to update your website' }}
              <q-popup-edit @before-show="clearExistingPopup('websitePopupRef')" ref="websitePopupRef" v-model="website"
                v-slot="scope" persistent>
                <q-input type="url" maxlength="100" counter autofocus dense v-model="scope.value"
                  :model-value="scope.value" hint="Your Website URL." :error-message="errorMessages?.website"
                  :error="errorMessages?.website?.length > 0 || false">
                  <template v-slot:after>
                    <q-btn :disable="updatingCompanyProfile" flat dense color="negatives" icon="cancel"
                      @click="cancel(scope, 'website')" />
                    <q-btn flat dense color="green" icon="check_circle" @click="update(scope, 'website')"
                      :disable="updatingCompanyProfile" />
                  </template>
                </q-input>
              </q-popup-edit>
            </div>
          </div>
        </div>

        <div class="text-h6 text-grey q-mt-md">
          Title
          <q-icon size="medium" class="cursor-pointer" color="grey-6" name="mode_edit" @click="titlePopupRef.show()" />
        </div>
        <div class="text-body1">
          <template v-if="hostProfile.title">
            <div class="text-body1 q-mb-sm">
              {{ hostProfile.title }}
            </div>
          </template>
          <template v-else>
            <div class="text-grey">Click to add a Title to your profile.</div>
          </template>
          <q-popup-edit @before-show="clearExistingPopup('titlePopupRef')" ref="titlePopupRef" v-model="title"
            v-slot="scope" persistent>
            <q-input maxlength="200" counter autofocus autogrow dense v-model="scope.value" :model-value="scope.value"
              hint="Add a title for your company profile." :error-message="errorMessages?.title"
              :error="errorMessages?.title?.length > 0 || false">
              <template v-slot:after>
                <q-btn :disable="updatingCompanyProfile" flat dense color="negatives" icon="cancel"
                  @click="cancel(scope, 'title')" />
                <q-btn flat dense color="green" icon="check_circle" @click="update(scope, 'title')"
                  :disable="updatingCompanyProfile" />
              </template>
            </q-input>
          </q-popup-edit>
        </div>

        <div class="text-h6 text-grey q-mt-md">
          Description
          <q-icon size="medium" class="cursor-pointer" color="grey-6" name="mode_edit"
            @click="descriptionPopupRef.show()" />
        </div>
        <div class="text-body1">
          <template v-if="hostProfile.description">
            <div v-for="(para, index) in hostProfile.description.split('\n')" :key="index" class="text-body1 q-mb-sm">
              {{ para }}
            </div>
          </template>
          <template v-else>
            <div class="text-grey">Click to add a description to your profile.</div>
          </template>
          <q-popup-edit @before-show="clearExistingPopup('descriptionPopupRef')" ref="descriptionPopupRef"
            v-model="description" v-slot="scope" persistent>
            <q-input maxlength="2000" counter autofocus autogrow dense v-model="scope.value" :model-value="scope.value"
              hint="Add a description for your company profile." :error-message="errorMessages?.description"
              :error="errorMessages?.description?.length > 0 || false">
              <template v-slot:after>
                <q-btn :disable="updatingCompanyProfile" flat dense color="negatives" icon="cancel"
                  @click="cancel(scope, 'description')" />
                <q-btn flat dense color="green" icon="check_circle" @click="update(scope, 'description')"
                  :disable="updatingCompanyProfile" />
              </template>
            </q-input>
          </q-popup-edit>
        </div>
      </div>
    </div>

    <AddLogoImageDialog />
  </q-tab-panel>
</template>


<script setup>
import { ref, watch, onMounted } from 'vue'

import { useHostProfile } from '@/composables/hostProfile'

import AddLogoImageDialog from '@/components/dialogs/AddLogoImageDialog.vue'
import { PHONE_NUMBER_RULES } from '@/constants/company'
import { notifyHostUpdateValueError, notifyHostUpdateValueSuccess } from '@/notifications/events'

const {
  fetchHostProfile,
  hostProfile,
  showUploadLogoDialog,
  updateHostProfile,
} = useHostProfile()

onMounted(async () => {
  await fetchHostProfile()
})

const name = ref('')
const title = ref('')
const description = ref('')
const email = ref('')
const phoneNumber = ref('')
const whatsApp = ref('')
const website = ref('')

const errorMessages = ref({})
const updatingCompanyProfile = ref(false)

const namePopupRef = ref(null)
const titlePopupRef = ref(null)
const descriptionPopupRef = ref(null)
const emailPopupRef = ref(null)
const phoneNumberPopupRef = ref(null)
const whatsAppPopupRef = ref(null)
const websitePopupRef = ref(null)

// Validation specific
const phoneNumberInputRef = ref(null)
const whatsAppInputRef = ref(null)

const refs = {
  'namePopupRef': namePopupRef,
  'titlePopupRef': titlePopupRef,
  'emailPopupRef': emailPopupRef,
  'phoneNumberPopupRef': phoneNumberPopupRef,
  'whatsAppPopupRef': whatsAppPopupRef,
  'websitePopupRef': websitePopupRef,
  'descriptionPopupRef': descriptionPopupRef,
}

watch(
  () => hostProfile.value.name, async (newVal, oldVal) => {
    // Set the default value for the name.
    if (!name.value && newVal) {
      name.value = hostProfile.value.name
    }
  },
  { deep: true },
)

watch(
  () => hostProfile.value.title, async (newVal, oldVal) => {
    // Set the default value for the title.
    if (!title.value && newVal) {
      title.value = hostProfile.value.title
    }
  },
  { deep: true },
)

watch(
  () => hostProfile.value.description, async (newVal, oldVal) => {
    // Set the default value for the description.
    if (!description.value && newVal) {
      description.value = hostProfile.value.description
    }
  },
  { deep: true },
)

watch(
  () => hostProfile.value.email, async (newVal, oldVal) => {
    // Set the default value for the email.
    if (!email.value && newVal) {
      email.value = hostProfile.value.email
    }
  },
  { deep: true },
)

watch(
  () => hostProfile.value.phone_number, async (newVal, oldVal) => {
    // Set the default value for the phone number.
    if (!phoneNumber.value && newVal) {
      phoneNumber.value = hostProfile.value.phone_number
    }
  },
  { deep: true },
)

watch(
  () => hostProfile.value.whatsapp, async (newVal, oldVal) => {
    // Set the default value for the WhatsApp number.
    if (!whatsApp.value && newVal) {
      whatsApp.value = hostProfile.value.whatsapp
    }
  },
  { deep: true },
)

watch(
  () => hostProfile.value.website, async (newVal, oldVal) => {
    // Set the default value for the website.
    if (!website.value && newVal) {
      website.value = hostProfile.value.website
    }
  },
  { deep: true },
)
const clearExistingPopup = (exceptRef) => {
  for (const refName in refs) {
    if (refName === exceptRef) {
      continue
    }
    refs[refName].value.hide()
  }
}

const cancel = (scope, field) => {
  // Set it back to the original value
  // updateListingForm.value[field] = hostListingDetails.value[field]
  scope.cancel()
}

const update = async (scope, field) => {
  if (field === "phone_number") {
    phoneNumberInputRef.value.validate()
    if (phoneNumberInputRef.value.hasError) {
      return
    }
  } else if (field === "whatsapp") {
    whatsAppInputRef.value.validate()
    if (whatsAppInputRef.value.hasError) {
      return
    }
  }

  const form = {}
  form[field] = scope.value

  errorMessages.value = {}
  const result = await updateHostProfile(form)

  if (result.status === 400) {
    const fieldErrors = result.data[field] || []
    if (fieldErrors.length) {
      errorMessages.value = {
        [field]: fieldErrors.join(' ')
      }
    } else {
      const error = `Error while updating Profile ${field}.`
      notifyHostUpdateValueError(error)
    }
  } else {
    scope.set()
    errorMessages.value = {}
    notifyHostUpdateValueSuccess(`Successfully updated Profile ${field}.`)
    await fetchHostProfile()
  }
}

</script>
