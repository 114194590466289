<template>
  <q-layout view="lHh Lpr lFf">
    <q-header reveal elevated class="rb-base__mobile_header_host text-white">
      <q-toolbar>
        <q-toolbar-title>
          <q-avatar size="45px">
            <q-img src="@/assets/logo-4-x-small.webp" />
          </q-avatar>
        </q-toolbar-title>
      </q-toolbar>
    </q-header>

    <q-page-container>
      <q-page padding>
        <router-view />
      </q-page>
    </q-page-container>

    <q-footer class="q-mt-md bg-white">
      <q-separator />
      <div class="q-my-xs text-center text-grey-9 text-weight-regular">
        <div>
          <div class="q-avatar" style="font-size: 20px;">
            <div class="q-avatar__content row flex-center overflow-hidden">
              <img src="@/assets/logo-4-x-small.webp">
            </div>
          </div>
          RoomB Technologies
        </div>
      </div>
    </q-footer>
  </q-layout>
</template>

<script setup>
import { onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { useQuasar } from 'quasar'

const $q = useQuasar()
const router = useRouter()

onMounted(() => {
  if ($q.platform.is.desktop) {
    router.push({ name: 'hostListingsView' })
  }
})
</script>
