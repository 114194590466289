import { useStoreVotes } from '@/stores/storeVotes'

export function useVotes() {
  const storeVotes = useStoreVotes()

  const castListingVote = async (uuid, direction) => {
    const form = { direction }
    return await storeVotes.castListingVote(uuid, form)
  }

  const castListingReviewVote = async (uuid, direction) => {
    const form = { direction }
    return await storeVotes.castListingReviewVote(uuid, form)
  }

  return {
    castListingVote,
    castListingReviewVote,
  }
}
