import { defineStore } from 'pinia'

import { client } from '@/services/request'

export const useStoreImages = defineStore('storeImages', {
  state: () => {
    return {
      creatingPresignedUrl: false,
      updatingListingImage: false,
      fetchingListingImages: false,
      fetchingListingImageInfo: false,
      listingImages: [],
      listingImageInfo: {},
      showUploadImageDialog: false,
      isPublishing: false,
      isDeletingImage: false,
      showDeleteImageDialog: false,
      imageToDelete: {},
    }
  },

  actions: {
    async createPresignedUrl(uuid, form) {
      const url = `listings/host/${uuid}/images/presigned-url/`

      this.creatingPresignedUrl = true
      const result = await client.authRequest(url, { method: 'POST', form })
      this.creatingPresignedUrl = false

      return result
    },

    async updateListingImage(listingUuid, imageUuid, form) {
      const url = `listings/host/${listingUuid}/images/${imageUuid}/update/`

      this.updatingListingImage = true
      const result = await client.authRequest(url, { method: 'PATCH', form })
      this.updatingListingImage = false

      return result
    },

    async fetchListingImages(uuid) {
      const url = `listings/host/${uuid}/images/`
      this.fetchingListingImages = true
      const response = await client.authRequest(url)
      this.fetchingListingImages = false

      if (response.status == 200) {
        this.listingImages = response.data
      } else {
        // TODO-FEATURE: Handle unsuccessful responses
      }
    },

    async fetchListingImageInfo(uuid) {
      const url = `listings/host/${uuid}/images/info/`
      this.fetchingListingImageInfo = true
      const response = await client.authRequest(url)
      this.fetchingListingImageInfo = false

      if (response.status == 200) {
        this.listingImageInfo = response.data
      } else {
        // TODO-FEATURE: Handle unsuccessful responses
      }
    },

    async deleteImage(listingUuid, imageUuid) {
      const url = `listings/host/${listingUuid}/images/${imageUuid}/delete/`
      this.isDeletingImage = true
      const response = await client.authRequest(url, { method: 'DELETE' })
      this.isDeletingImage = false

      return { status: response.status, data: {} }
    },
  },
})
