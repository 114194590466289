<template>
  <q-step :name="2" prefix="2" title="2. Amenities" caption="&nbsp;Indoor" :done="createListingStep > 2">
    <p>What <span class="text-weight-medium">indoor</span> amenities does your property have?</p>

    <span class="text-h6 q-pl-sm">Comfort</span>
    <q-btn @click="setFormArray('comfort_amenities', comfortAmenities)" flat dense no-caps
      class="text-caption q-ml-sm text-blue-10">Select All</q-btn>
    <q-btn @click="setFormArray('comfort_amenities', [])" flat dense no-caps class="text-caption text-green-10">Unselect
      All</q-btn>

    <div class="row">
      <div class="col">
        <q-checkbox v-for="option in comfortAmenities" :key="option.value" v-model="createListingForm.comfort_amenities"
          :label="option.label" :val="option.value" class="q-mr-sm" />
      </div>
    </div>

    <q-separator inset class="q-my-sm" />

    <span class="text-h6 q-pl-sm">Living</span>
    <q-btn @click="setFormArray('living_amenities', livingAmenities)" flat dense no-caps
      class="text-caption q-ml-sm text-blue-10">Select All</q-btn>
    <q-btn @click="setFormArray('living_amenities', [])" flat dense no-caps class="text-caption text-green-10">Unselect
      All</q-btn>
    <div class="row">
      <q-checkbox v-for="option in livingAmenities" :key="option.value" v-model="createListingForm.living_amenities"
        :label="option.label" :val="option.value" class="q-mr-sm" />
    </div>

    <q-separator inset class="q-my-sm" />

    <span class="text-h6 q-pl-sm">Kitchen</span>
    <q-btn @click="setFormArray('kitchen_amenities', kitchenAmenities)" flat dense no-caps
      class="text-caption q-ml-sm text-blue-10">Select All</q-btn>
    <q-btn @click="setFormArray('kitchen_amenities', [])" flat dense no-caps class="text-caption text-green-10">Unselect
      All</q-btn>

    <div class="row">
      <q-checkbox v-for="option in kitchenAmenities" :key="option.value" v-model="createListingForm.kitchen_amenities"
        :label="option.label" :val="option.value" class="q-mr-sm" />
    </div>

    <q-separator inset class="q-my-sm" />

    <span class="text-h6 q-pl-sm">Washroom</span>
    <q-btn @click="setFormArray('washroom_amenities', washroomAmenities)" flat dense no-caps
      class="text-caption q-ml-sm text-blue-10">Select All</q-btn>
    <q-btn @click="setFormArray('washroom_amenities', [])" flat dense no-caps
      class="text-caption text-green-10">Unselect
      All</q-btn>

    <div class="row">
      <q-checkbox v-for="option in washroomAmenities" :key="option.value" v-model="createListingForm.washroom_amenities"
        :label="option.label" :val="option.value" class="q-mr-sm" />
    </div>

    <q-stepper-navigation>
      <q-btn @click="submitStep2" color="primary" label="Next" />
      <q-btn flat @click="createListingStep = 1" color="secondary" label="Back" class="q-ml-sm" />
    </q-stepper-navigation>
  </q-step>
</template>

<script setup>
import { onMounted } from 'vue'

import { useHostListings } from '@/composables/hostListings'
import { useMeta } from '@/composables/meta'

const {
  fetchPropertyAttributeAvailabilityTypes,
  fetchParkingTypes,
} = useMeta()
const {
  createListingForm,
  createListingStep,
} = useHostListings()

onMounted(async () => {
  await fetchPropertyAttributeAvailabilityTypes()
  await fetchParkingTypes()
})

const comfortAmenities = [
  { label: 'Air Conditioner', value: 'has_air_conditioner' },
  { label: 'Blackout Curtains', value: 'has_blackout_shades' },
  { label: 'Chilled Water', value: 'has_chilled_water' },
  { label: 'Heated Water', value: 'has_heated_water' },
  { label: 'Heating', value: 'has_heating' },
]
const kitchenAmenities = [
  { label: 'Coffee Machine', value: 'has_coffee_machine' },
  { label: 'Fridge', value: 'has_fridge' },
  { label: 'Kettle', value: 'has_kettle' },
  { label: 'Stove', value: 'has_kitchen' },
  { label: 'Kitchen Utensils', value: 'has_kitchen_utensils' },
  { label: 'Kitchen Basics', value: 'has_kitchen_condiments' },
  { label: 'Microwave', value: 'has_microwave' },
  { label: 'Oven', value: 'has_oven' },
  { label: 'Rice Cooker', value: 'has_rice_cooker' },
  { label: 'Silverware', value: 'has_silverware' },
  { label: 'Toaster', value: 'has_toaster' },
]
const livingAmenities = [
  { label: 'Bedsheets', value: 'has_bedsheet' },
  { label: 'Dryer', value: 'has_dryer' },
  { label: 'Drying Rack', value: 'has_drying_rack' },
  { label: 'Clothes Hangers', value: 'has_hanger' },
  { label: 'Ironing Board', value: 'has_iron' },
  { label: 'Wardrobe', value: 'has_wardrobe' },
  { label: 'Washer', value: 'has_washing_machine' },
]
const washroomAmenities = [
  { label: 'Bathtub', value: 'has_bathtub' },
  { label: 'Hair Dryer', value: 'has_hair_dryer' },
  { label: 'Toiletries', value: 'has_toiletry' },
  { label: 'Towels', value: 'has_towel' },
]

const setFormArray = (key, data) => {
  createListingForm.value[key] = data.map((item) => { return item.value })
}

const submitStep2 = () => {
  createListingStep.value = 3
}
</script>
