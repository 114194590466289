<template>
  <q-layout view="hHh lpR fff">
    <q-header reveal elevated class="rb-base__mobile_header text-white">
      <q-toolbar>
        <q-btn dense flat round icon="menu" @click="toggleLeftDrawer" />
        <q-toolbar-title>
          <router-link :to="{ name: 'publicListingsView' }">
            <q-avatar size="45px">
              <q-img src="@/assets/gold-logo-circle-xxx-small.webp" />
            </q-avatar>
          </router-link>
        </q-toolbar-title>
        <q-btn dense flat round icon="person" @click="toggleRightDrawer" :color="token?.length ? 'blue' : 'white'" />
      </q-toolbar>
    </q-header>

    <q-drawer v-model="leftDrawerOpen" side="left" behavior="mobile" elevated :width="200">
      <q-scroll-area class="fit">
        <q-list>
          <q-item clickable v-ripple :to="{ name: 'publicListingsView' }">
            <q-item-section avatar>
              <q-icon name="list" />
            </q-item-section>
            <q-item-section>
              Listings
            </q-item-section>
          </q-item>
          <q-separator inset />

          <q-item clickable v-ripple href="https://info.roomb.io" target="_blank">
            <q-item-section avatar>
              <q-icon name="info" />
            </q-item-section>
            <q-item-section>
              About
            </q-item-section>
          </q-item>

          <q-separator inset />

          <q-item clickable v-ripple :to="{ name: 'publicContactView' }">
            <q-item-section avatar>
              <q-icon name="contact_support" />
            </q-item-section>
            <q-item-section>
              Contact
            </q-item-section>
          </q-item>
          <q-separator inset />
        </q-list>
      </q-scroll-area>
    </q-drawer>

    <q-drawer v-model="rightDrawerOpen" side="right" behavior="mobile" elevated :width="200">
      <q-scroll-area class="fit">
        <q-list>
          <template v-if="!token?.length">
            <q-item v-if="!token?.length" clickable v-ripple :to="{ name: 'publicLoginView' }">
              <q-item-section avatar>
                <q-icon name="logout" color="grey-8" />
              </q-item-section>
              <q-item-section>
                Log In
              </q-item-section>
            </q-item>

            <q-item v-else clickable v-ripple :to="{ name: 'publicRegisterSocialView' }">
              <q-item-section avatar>
                <q-icon name="person_add_alt" color="grey-8" />
              </q-item-section>
              <q-item-section>
                Sign Up
              </q-item-section>
            </q-item>

            <q-separator inset />
          </template>

          <template v-else>
            <q-item v-ripple>
              <div class="row">
                <div class="col text-left">
                  <q-avatar size="md">
                    <img :src='getGravatarUrl(user.uuid)' />
                  </q-avatar>
                </div>
                <div class="col-9 self-center">
                  <div class="text-caption text-grey-8">Logged in as</div>
                  <span class="text-weight-bold">{{ truncate(user.username, 20) }}</span>
                </div>
              </div>
            </q-item>
            <q-separator />

            <q-item clickable v-ripple @click="onNavigateToProfile('profile')">
              <q-item-section avatar>
                <q-icon name="info" color="grey-8" />
              </q-item-section>
              <q-item-section>
                Profile
              </q-item-section>
            </q-item>
            <q-separator inset />

            <q-item clickable v-ripple @click="onNavigateToProfile('bookmarks')">
              <q-item-section avatar>
                <q-icon name="bookmark" color="grey-8" />
              </q-item-section>
              <q-item-section>
                Bookmarks
              </q-item-section>
            </q-item>
            <q-separator inset />

            <q-item clickable v-ripple @click="onNavigateToProfile('reviews')">
              <q-item-section avatar>
                <q-icon name="rate_review" color="grey-8" />
              </q-item-section>
              <q-item-section>
                Reviews
              </q-item-section>
            </q-item>
            <q-separator inset />

            <q-item clickable v-ripple @click="logout">
              <q-item-section avatar>
                <q-icon name="logout" color="grey-8" />
              </q-item-section>
              <q-item-section>
                Log Out
              </q-item-section>
            </q-item>
            <q-separator inset />

          </template>
        </q-list>
      </q-scroll-area>
    </q-drawer>


    <div>
      <slot name="my-router-view"></slot>
    </div>


    <q-footer class="q-mt-md bg-white">
      <q-separator />
      <div class="q-my-xs text-center text-grey-9 text-weight-regular">
        <div>
          <div class="q-avatar" style="font-size: 20px;">
            <div class="q-avatar__content row flex-center overflow-hidden">
              <img src="@/assets/gold-logo-circle-xxx-small.webp">
            </div>
          </div>
          RoomB Technologies <q-badge color="brown-10">v{{ roombeeVersion }}</q-badge>
        </div>
      </div>
    </q-footer>
  </q-layout>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import { useAuth } from '@/composables/auth'
import { useRouter } from 'vue-router'

import { useGuestProfile } from '@/composables/guestProfile'
import { getGravatarUrl } from '@/utils/avatar'

import { truncate } from '@/utils/string'
import { inject } from 'vue'

const roombeeVersion = inject('roombeeVersion')

const router = useRouter()

const { profileTab } = useGuestProfile()

const { logOut, getToken, getUser, token, user } = useAuth()

const leftDrawerOpen = ref(false)
const rightDrawerOpen = ref(false)

const toggleLeftDrawer = () => {
  leftDrawerOpen.value = !leftDrawerOpen.value
}

const toggleRightDrawer = () => {
  rightDrawerOpen.value = !rightDrawerOpen.value
}

const logout = async () => {
  await logOut()
  window.location.reload()
}

onMounted(() => {
  getToken()
  getUser()
})

const onNavigateToProfile = (tabName) => {
  profileTab.value = tabName
  router.push({ 'name': 'guestProfileView' })
}

// Useful to debug responsiveness
//
// import { useQuasar } from 'quasar'
// import { watch } from 'vue'
// const $q = useQuasar()
// watch(() => $q.screen.name, (newValue, oldValue) => {
//   console.log('screen: ', newValue)
// })
</script>
