<template>
  <q-tab-panel>

    <div class="row">
      <div class="text-subtitle1 text-grey">
        Comfort
      </div>
      <q-btn @click="setFormArray('comfort_amenities', COMFORT_AMENITIES)" flat dense no-caps
        class="text-caption q-ml-sm text-blue-10">Select All</q-btn>
      <q-btn @click="setFormArray('comfort_amenities', [])" flat dense no-caps
        class="text-caption text-green-10">Unselect
        All</q-btn>
    </div>

    <div class="row">
      <div class="col">
        <q-checkbox v-for="option in COMFORT_AMENITIES" :key="option.value"
          v-model="updateListingForm.comfort_amenities" :label="option.label" :val="option.value" class="q-mr-sm" />
      </div>
    </div>

    <div class="row justify-end">
      <q-btn class="rb-host-buttons__submit" @click="updateAmenities('comfort_amenities')">Update Comfort</q-btn>
    </div>

    <q-separator class="q-my-md" />

    <div class="row">
      <div class="text-subtitle1 text-grey">
        Living
      </div>
      <q-btn @click="setFormArray('living_amenities', LIVING_AMENITIES)" flat dense no-caps
        class="text-caption q-ml-sm text-blue-10">Select All</q-btn>
      <q-btn @click="setFormArray('living_amenities', [])" flat dense no-caps
        class="text-caption text-green-10">Unselect
        All</q-btn>
    </div>

    <div class="row">
      <div class="col">
        <q-checkbox v-for="option in LIVING_AMENITIES" :key="option.value" v-model="updateListingForm.living_amenities"
          :label="option.label" :val="option.value" class="q-mr-sm" />
      </div>
    </div>

    <div class="row justify-end">
      <q-btn class="rb-host-buttons__submit" @click="updateAmenities('living_amenities')">Update Living</q-btn>
    </div>

    <q-separator class="q-my-md" />

    <div class="row">
      <div class="text-subtitle1 text-grey">
        Kitchen
      </div>
      <q-btn @click="setFormArray('kitchen_amenities', KITCHEN_AMENITIES)" flat dense no-caps
        class="text-caption q-ml-sm text-blue-10">Select All</q-btn>
      <q-btn @click="setFormArray('kitchen_amenities', [])" flat dense no-caps
        class="text-caption text-green-10">Unselect
        All</q-btn>
    </div>

    <div class="row">
      <div class="col">
        <q-checkbox v-for="option in KITCHEN_AMENITIES" :key="option.value"
          v-model="updateListingForm.kitchen_amenities" :label="option.label" :val="option.value" class="q-mr-sm" />
      </div>
    </div>

    <div class="row justify-end">
      <q-btn class="rb-host-buttons__submit" @click="updateAmenities('kitchen_amenities')">Update Kitchen</q-btn>
    </div>

    <q-separator class="q-my-md" />

    <div class="row">
      <div class="text-subtitle1 text-grey">
        Washroom
      </div>
      <q-btn @click="setFormArray('washroom_amenities', WASHROOM_AMENITIES)" flat dense no-caps
        class="text-caption q-ml-sm text-blue-10">Select All</q-btn>
      <q-btn @click="setFormArray('washroom_amenities', [])" flat dense no-caps
        class="text-caption text-green-10">Unselect
        All</q-btn>
    </div>

    <div class="row">
      <div class="col">
        <q-checkbox v-for="option in WASHROOM_AMENITIES" :key="option.value"
          v-model="updateListingForm.washroom_amenities" :label="option.label" :val="option.value" class="q-mr-sm" />
      </div>
    </div>

    <div class="row justify-end">
      <q-btn class="rb-host-buttons__submit" @click="updateAmenities('washroom_amenities')">Update Washroom</q-btn>
    </div>

    <q-separator class="q-my-md" />

    <div class="row">
      <div class="text-subtitle1 text-grey">
        Community
      </div>
      <q-btn @click="setFormArray('community_amenities', COMMUNITY_AMENITIES)" flat dense no-caps
        class="text-caption q-ml-sm text-blue-10">Select All</q-btn>
      <q-btn @click="setFormArray('community_amenities', [])" flat dense no-caps
        class="text-caption text-green-10">Unselect
        All</q-btn>
    </div>

    <div class="row">
      <div class="col">
        <q-checkbox v-for="option in COMMUNITY_AMENITIES" :key="option.value"
          v-model="updateListingForm.community_amenities" :label="option.label" :val="option.value" class="q-mr-sm" />
      </div>
    </div>

    <div class="row justify-end">
      <q-btn class="rb-host-buttons__submit" @click="updateAmenities('community_amenities')">Update Community</q-btn>
    </div>

    <q-separator class="q-my-md" />

    <div class="row">
      <div class="text-subtitle1 text-grey">
        Building
      </div>
      <q-btn @click="setFormArray('building_amenities', BUILDING_AMENITIES)" flat dense no-caps
        class="text-caption q-ml-sm text-blue-10">Select All</q-btn>
      <q-btn @click="setFormArray('building_amenities', [])" flat dense no-caps
        class="text-caption text-green-10">Unselect
        All</q-btn>
    </div>

    <div class="row">
      <div class="col">
        <q-checkbox v-for="option in BUILDING_AMENITIES" :key="option.value"
          v-model="updateListingForm.building_amenities" :label="option.label" :val="option.value" class="q-mr-sm" />
      </div>
    </div>

    <div class="row justify-end">
      <q-btn class="rb-host-buttons__submit" @click="updateAmenities('building_amenities')">Update Building</q-btn>
    </div>

    <q-separator class="q-my-md" />

    <div class="row">
      <div class="text-subtitle1 text-grey">
        Safety
      </div>
      <q-btn @click="setFormArray('safety_amenities', SAFETY_AMENITIES)" flat dense no-caps
        class="text-caption q-ml-sm text-blue-10">Select All</q-btn>
      <q-btn @click="setFormArray('safety_amenities', [])" flat dense no-caps
        class="text-caption text-green-10">Unselect
        All</q-btn>
    </div>

    <div class="row">
      <div class="col">
        <q-checkbox v-for="option in SAFETY_AMENITIES" :key="option.value" v-model="updateListingForm.safety_amenities"
          :label="option.label" :val="option.value" class="q-mr-sm" />
      </div>
    </div>

    <div class="row justify-end">
      <q-btn class="rb-host-buttons__submit" @click="updateAmenities('safety_amenities')">Update Safety</q-btn>
    </div>
  </q-tab-panel>
</template>


<script setup>
import { onMounted } from 'vue'
import { useRoute } from 'vue-router'

import { notifyHostUpdateValueError, notifyHostUpdateValueSuccess } from '@/notifications/events'
import { useHostListings } from '@/composables/hostListings'
import {
  COMFORT_AMENITIES,
  LIVING_AMENITIES,
  KITCHEN_AMENITIES,
  WASHROOM_AMENITIES,
  COMMUNITY_AMENITIES,
  BUILDING_AMENITIES,
  SAFETY_AMENITIES
} from '@/constants/listing'

const route = useRoute()

const amenityGroups = {
  comfort_amenities: COMFORT_AMENITIES,
  living_amenities: LIVING_AMENITIES,
  kitchen_amenities: KITCHEN_AMENITIES,
  washroom_amenities: WASHROOM_AMENITIES,
  community_amenities: COMMUNITY_AMENITIES,
  building_amenities: BUILDING_AMENITIES,
  safety_amenities: SAFETY_AMENITIES,
}

const {
  fetchHostListingDetails,
  hostListingDetails,
  updateListingForm,
  updateListing,
} = useHostListings()

onMounted(async () => {
  await fetchHostListingDetails(route.params.uuid)
  const _comfortAmenities = []
  COMFORT_AMENITIES.map((item) => {
    if (hostListingDetails.value[item.value] === 'Available') {
      _comfortAmenities.push(item.value)
    }
  })
  updateListingForm.value.comfort_amenities = _comfortAmenities

  const _livingAmenities = []
  LIVING_AMENITIES.map((item) => {
    if (hostListingDetails.value[item.value] === 'Available') {
      _livingAmenities.push(item.value)
    }
  })
  updateListingForm.value.living_amenities = _livingAmenities

  const _kitchenAmenities = []
  KITCHEN_AMENITIES.map((item) => {
    if (hostListingDetails.value[item.value] === 'Available') {
      _kitchenAmenities.push(item.value)
    }
  })
  updateListingForm.value.kitchen_amenities = _kitchenAmenities

  const _washroomAmenities = []
  WASHROOM_AMENITIES.map((item) => {
    if (hostListingDetails.value[item.value] === 'Available') {
      _washroomAmenities.push(item.value)
    }
  })
  updateListingForm.value.washroom_amenities = _washroomAmenities

  const _communityAmenities = []
  COMMUNITY_AMENITIES.map((item) => {
    if (hostListingDetails.value[item.value] === 'Available') {
      _communityAmenities.push(item.value)
    }
  })
  updateListingForm.value.community_amenities = _communityAmenities

  const _buildingAmenities = []
  BUILDING_AMENITIES.map((item) => {
    if (hostListingDetails.value[item.value] === 'Available') {
      _buildingAmenities.push(item.value)
    }
  })
  updateListingForm.value.building_amenities = _buildingAmenities

  const _safetyAmenities = []
  SAFETY_AMENITIES.map((item) => {
    if (hostListingDetails.value[item.value] === 'Available') {
      _safetyAmenities.push(item.value)
    }
  })
  updateListingForm.value.safety_amenities = _safetyAmenities
})

const setFormArray = (key, data) => {
  updateListingForm.value[key] = data.map((item) => { return item.value })
}

const updateAmenities = async (amenityGroup) => {
  const form = {}
  for (const amenity of amenityGroups[amenityGroup]) {
    if (updateListingForm.value[amenityGroup].includes(amenity.value)) {
      form[amenity.value] = "Available"
    } else {
      form[amenity.value] = "Not Available"
    }
  }

  const result = await updateListing(route.params.uuid, form)

  if (result.status === 400) {
    const error = `Error while updating amenities!`
    notifyHostUpdateValueError(error)
  } else {
    notifyHostUpdateValueSuccess('Successfully updated amenities.')
    await fetchHostListingDetails(route.params.uuid)
  }
}
</script>
