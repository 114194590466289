<template>
  <q-dialog v-model="showUpdateReviewDialog" persistent>
    <q-card style="width: 700px; max-width: 100%;">
      <q-card-section>
        <div class="text-h6 text-grey-8 text-center">
          Update your review for this Listing
        </div>
      </q-card-section>
      <q-card-section>
        <q-card flat bordered>
          <q-card-section>
            <div class="row">
              <div class="col-3 self-center text-body1">
                Clean
              </div>
              <div class="col-9">
                <ReviewRating v-model="ratingClean" name="Cleanliness" />
              </div>
            </div>

            <div class="row">
              <div class="col-3 self-center text-body1">
                Location
              </div>
              <div class="col-9">
                <ReviewRating v-model="ratingLocation" name="Location" />
              </div>
            </div>

            <div class="row">
              <div class="col-3 self-center text-body1">
                Helpful Host
              </div>
              <div class="col-9">
                <ReviewRating v-model="ratingHelpful" name="Helpfulness" />
              </div>
            </div>

            <div class="row">
              <div class="col-3 self-center text-body1">
                Responsive Host
              </div>
              <div class="col-9">
                <ReviewRating v-model="ratingResponsive" name="Responsiveness" />
              </div>
            </div>

            <div class="row">
              <div class="col-3 self-center text-body1">
                Overall Value
              </div>
              <div class="col-9">
                <ReviewRating v-model="ratingValue" name="Overall Value" />
              </div>
            </div>
          </q-card-section>
        </q-card>

        <q-input ref="reviewTextRef" class="q-mt-sm" :rules="REVIEW_RULES" :input-style="{ minHeight: '200px' }"
          type="textarea" outlined maxlength="2000" counter dense v-model="reviewText" :error-message="errors"
          hint="Add your review for this listing." :error="errors.length > 0">
        </q-input>

      </q-card-section>

      <q-card-actions align="right" class="bg-white text-grey-10 q-mr-sm q-mb-sm">
        <q-btn label="Cancel" v-close-popup :disable="updatingReview" class="rb-public-buttons__cancel-color" />
        <q-btn @click="updateReview" :disable="updatingReview" :loading="updatingReview"
          class="rb-public-buttons__submit-color" label="Save" />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>


<script setup>
import { ref, watch } from 'vue'

import { useReviews } from '@/composables/reviews'
import ReviewRating from '@/components/ratings/ReviewRating.vue'
import { REVIEW_RULES } from '@/constants/review'
import { notifyHostUpdateValueError, notifyHostUpdateValueSuccess } from '@/notifications/events'

const {
  updateListingReview,
  fetchListingReviews,
  getListingReviewSelf,
  selectedReviewForUpdate,
  updatingReview,
  showUpdateReviewDialog,
  reviewSelf,
} = useReviews()

const reviewTextRef = ref(null)
const reviewText = ref('')
const ratingClean = ref(3)
const ratingLocation = ref(3)
const ratingHelpful = ref(3)
const ratingResponsive = ref(3)
const ratingValue = ref(3)

const errors = ref('')

watch(() => showUpdateReviewDialog.value, (newVal, oldVal) => {
  if (newVal !== oldVal && newVal === true) {
    reviewText.value = reviewSelf.value.review
    ratingClean.value = reviewSelf.value.rating_clean
    ratingLocation.value = reviewSelf.value.rating_location
    ratingHelpful.value = reviewSelf.value.rating_helpful
    ratingResponsive.value = reviewSelf.value.rating_responsive
    ratingValue.value = reviewSelf.value.rating_value
  }
})

const updateReview = async () => {
  reviewTextRef.value.validate()
  if (reviewTextRef.value.hasError) {
    return
  }

  const form = {
    review: reviewText.value,
    rating_clean: ratingClean.value,
    rating_location: ratingLocation.value,
    rating_helpful: ratingHelpful.value,
    rating_responsive: ratingResponsive.value,
    rating_value: ratingValue.value,
  }

  const result = await updateListingReview(selectedReviewForUpdate.value.listing_uuid, form)

  if (result.status === 200) {
    errors.value = ''

    notifyHostUpdateValueSuccess('Successfully updated the Review.')

    await fetchListingReviews()
    await getListingReviewSelf()
    showUpdateReviewDialog.value = false
    selectedReviewForUpdate.value = {}
  } else {
    const error = `Error while updating the Review!`
    notifyHostUpdateValueError(error)
  }
}
</script>
