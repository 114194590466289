<template>
  <q-dialog v-model="showSearchOrder">
    <q-card style="width: 400px" class="q-px-sm q-pb-md">
      <q-card-section>
        <div class="text-h6 text-center">
          Sort Listings
        </div>
      </q-card-section>

      <q-item dense>
        <q-item-section avatar>
          <q-icon name="swap_vert" size="md" />
        </q-item-section>
        <q-item-section>
          <q-select dense rounded outlined v-model="publicListingsSearchOrder"
            :options="PUBLIC_LISTING_SEARCH_ORDER_OPTIONS" label="Sort listings by" />
        </q-item-section>
      </q-item>

      <div class="row justify-center q-mt-md">
        <q-btn @click="onClear" :disable="fetchingPublicListings" :loading="fetchingPublicListings" label="Clear"
          class="q-ml-md" />
      </div>
    </q-card>
  </q-dialog>
</template>


<script setup>
import { usePublicListings } from '@/composables/publicListings'

import { PUBLIC_LISTING_SEARCH_ORDER_OPTIONS } from '@/constants/publicListing'

const {
  showSearchOrder,
  publicListingsSearchOrder,
  fetchingPublicListings,
  fetchPublicListings,
} = usePublicListings()

const onClear = async () => {
  await fetchPublicListings(true)
  publicListingsSearchOrder.value = ''
  showSearchOrder.value = false
}
</script>
