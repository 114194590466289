<template>
  <div class="q-pb-md">
    <q-card-section>
      <div class="row justify-center text-body1">
        <div class="text-center col-6">
          <div>
            We just sent you an email with an activation link.
          </div>
          <div class="q-mt-sm">Please check your email and click the link to activate your account.</div>
          <div class="q-mt-sm text-weight-bold text-grey-8">
            Tip! Make sure to check your Spam/Junk folder.
          </div>
        </div>
      </div>
    </q-card-section>

    <!-- TODO-FEATURE: Wire this up in a later release.
    <q-card-section>
      <div class="row justify-center">
        <div class="text-center col-6">
          Didn't receive an email? Click the button below to re-send the activation link.
        </div>
      </div>

      <div class="text-center col-6 q-mt-sm">
        <q-btn color="primary">Re-send Activation Email</q-btn>
      </div>
    </q-card-section> -->

    <q-separator />

    <q-card-section>
      <div class="row justify-center">
        <div class="text-center col-6">
          If you're still having problems, please
          <router-link :to="{ name: 'publicContactView' }" target="_blank">Contact Us</router-link>
          or send us an email at {{ supportEmail }}
        </div>
      </div>
    </q-card-section>
  </div>
</template>

<script setup>
const supportEmail = import.meta.env.VITE_ROOMBEE_SUPPORT_EMAIL
</script>
