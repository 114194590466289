<template>
  <q-card bordered class="q-mb-md">
    <div class="col"></div>
    <div class="col-6">
      <div class="row justify-center">
        <div class="col">
          <div class="row">
            <div class="col">
              <div v-if="listing.images.length > 0">
                <q-card class="q-pa-sm" flat>
                  <q-carousel arrows swipeable animated v-model="slide" infinite height="13em">
                    <q-carousel-slide v-for="(image, index) in listing.images" :key="image.uuid" :name="index"
                      :img-src="image.signed_url" />
                  </q-carousel>
                </q-card>
              </div>
              <div v-else class="q-pa-sm">
                <div class="row text-h5 text-grey text-center" style="min-height: 200px; background-color: #efefef;">
                  <div class="col self-center">
                    No images to show.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-7">
          <q-card-section>
            <div>
              <q-btn @click="onClickListing()" flat padding='none' no-caps
                class="text-primary text-subtitle1 text-weight-bold" style="width: 100%;">
                <span class="full-width text-left">{{ truncate(listing.title, 50) }}</span>
              </q-btn>
            </div>
            <div class="text-subtitle q-mt-sm q-mb-xs">
              {{ truncate(listing.sub_title, 170) }}
            </div>

            <div class="row q-mt-md">
              <PropertyTypeChip :property-type="listing.property_type" :dense="false" />
              <ListingRatingChip class="q-ml-sm self-center" :rating-overall="listing.rating_overall" />
            </div>

            <div class="row text-subtitle2 text-grey-8 q-ml-xs q-mt-sm">
              <div class="col">
                {{ listing.location.neighbourhood ? listing.location.neighbourhood : 'Dubai' }} - {{
                  listing.location.city }}
              </div>
            </div>

            <q-separator class="q-my-sm" />

            <div class="text-body1">
              <BasicInfoChip :text="listing.bedrooms" icon="bed" :dense="false" />
              <BasicInfoChip :text="listing.bathrooms" icon="bathtub" :dense="false" />
              <ListingPriceChip :amount="listing.price.total_price" />
            </div>
          </q-card-section>
        </div>
      </div>
    </div>
    <div class="col"></div>
  </q-card>
</template>

<script setup>
import { ref } from 'vue'

import { useRouter } from 'vue-router'

import { useBreadcrumbs } from '@/composables/breadcrumbs'

const router = useRouter()

import { truncate } from '@/utils/string'

import PropertyTypeChip from '@/components/chips/PropertyTypeChip.vue'
import ListingRatingChip from '@/components/chips/ListingRatingChip.vue'
import BasicInfoChip from '@/components/chips/BasicInfoChip.vue'
import ListingPriceChip from '@/components/chips/ListingPriceChip.vue'

const {
  showHostDetailsBreadcrumbs
} = useBreadcrumbs()

const props = defineProps({
  listing: Object
})

const slide = ref(0)

const onClickListing = async () => {
  showHostDetailsBreadcrumbs.value = true

  router.push({
    name: 'publicListingsDetailsView',
    params: {
      uuid: props.listing.uuid,
      listingSlug: props.listing.slug
    }
  })
}
</script>
