<template>
  <q-page>
    <div class="flex content-center q-mx-lg" style="min-height: inherit;">
      <div class="justify-center text-body1">
        <div>
          We just sent you an email with an activation link.
          Please check your email and click the link to activate your account.
        </div>

        <div class="q-mt-md text-center text-grey-8">
          Tip! Check your Spam/Junk folder.
        </div>
      </div>

      <div class="q-mt-md text-body2">
        If you haven't received an email or you're still having problems, please
        <router-link :to="{ name: 'publicContactView' }" target="_blank">Contact Us</router-link>
        or send us an email at {{ supportEmail }}
      </div>
    </div>


    <!-- TODO-FEATURE: Wire this up in a later release.
    <q-card-section>
      <div class="row justify-center">
        <div class="text-center col-6">
          Didn't receive an email? Click the button below to re-send the activation link.
        </div>
      </div>

      <div class="text-center col-6 q-mt-sm">
        <q-btn color="primary">Re-send Activation Email</q-btn>
      </div>
    </q-card-section> -->
  </q-page>
</template>

<script setup>
import { onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { useQuasar } from 'quasar'

const $q = useQuasar()
const router = useRouter()

onMounted(async () => {
  if (!$q.platform.is.mobile) {
    router.push({ name: 'publicRegisterSuccessView' })
  }
})

const supportEmail = import.meta.env.VITE_ROOMBEE_SUPPORT_EMAIL
</script>
