import { defineStore } from 'pinia'

import { client } from '@/services/request'

import { refreshPaginationValues } from '@/utils/pagination'

export const useStorePublicHost = defineStore('storePublicHost', {
  state: () => {
    return {
      hostUuid: null,
      // The default tab to show in Public Host Details page
      tab: 'listings',
      fetchingHostDetails: false,
      fetchingListings: false,
      hostDetails: {},
      listings: [],
      listingsPage: {
        pageCurrent: 1,
        pageLast: 1,
        pageMaxLinks: 1,
        pageTotal: 0,
      },
      fetchingReviews: false,
      reviews: [],
      reviewsPage: {
        pageCurrent: 1,
        pageLast: 1,
        pageMaxLinks: 1,
        pageTotal: 0,
      },
    }
  },

  actions: {
    async fetchHostDetails() {
      const url = `hosts/${this.hostUuid}/`
      this.fetchingHostDetails = true
      const response = await client.anonRequest(url)
      this.fetchingHostDetails = false

      if (response.status == 200) {
        this.hostDetails = response.data
      } else {
        // TODO-FEATURE: Handle unsuccessful responses
      }
    },

    async fetchListings(pageSize, pageMaxLinks) {
      const params = new URLSearchParams({
        limit: pageSize,
        offset: (this.listingsPage.pageCurrent * pageSize) - pageSize,
      })

      const url = `hosts/${this.hostUuid}/listings/?${params}`
      this.fetchingListings = true
      const response = await client.anonRequest(url)
      this.fetchingListings = false

      if ([200, 201].includes(response.status)) {
        const data = response.data
        this.listings = data.results
        refreshPaginationValues(this.listingsPage, data.count, pageSize, pageMaxLinks)
      } else {
        // TODO-FEATURE: Handle unsuccessful responses
      }
    },

    async fetchHostProfileListingReviews(pageSize, pageMaxLinks) {
      const params = new URLSearchParams({
        limit: pageSize,
        offset: (this.reviewsPage.pageCurrent * pageSize) - pageSize,
      })

      const url = `hosts/${this.hostUuid}/reviews/?${params}`
      this.fetchingReviews = true
      const response = await client.authOrAnonRequest(url)
      this.fetchingReviews = false

      if ([200, 201].includes(response.status)) {
        const data = response.data
        this.reviews = data.results
        refreshPaginationValues(this.reviewsPage, data.count, pageSize, pageMaxLinks)
      } else {
        // TODO-FEATURE: Handle unsuccessful responses
      }
    },
  },
})
