<template>
  <q-card flat>
    <q-tabs ref="hostDetailsTabRef" v-model="tab" dense class="text-grey" active-color="primary"
      indicator-color="primary" align="justify" narrow-indicator>
      <q-tab name="listings" :label="'Listings (' + listingsPage.pageTotal + ')'" />
      <q-tab name="reviews" :label="'Reviews (' + reviewsPage.pageTotal + ')'" />
      <q-tab name="about" :label="'About ' + hostDetails.name" />
    </q-tabs>


    <q-tab-panels v-model="tab">
      <ListingsTabPanel name="listings" />
      <ReviewsTabPanel name="reviews" />
      <AboutTabPanel name="about" />
    </q-tab-panels>
  </q-card>
</template>

<script setup>
import { onMounted } from 'vue'

import { useRoute } from 'vue-router'

import { usePublicHost } from '@/composables/publicHost'

import ListingsTabPanel from '@/components/hostDetailsPanel/panels/ListingsTabPanel.vue'
import ReviewsTabPanel from '@/components/hostDetailsPanel/panels/ReviewsTabPanel.vue'
import AboutTabPanel from '@/components/hostDetailsPanel/panels/AboutTabPanel.vue'

const route = useRoute()

const {
  fetchHostProfileListings,
  listingsPage,
  fetchHostProfileListingReviews,
  reviewsPage,
  hostDetails,
  tab,
} = usePublicHost()


onMounted(async () => {
  await fetchHostProfileListings()
  await fetchHostProfileListingReviews()

  // If Route doesn't have a param, set the default
  if (!route.query.tab) {
    window.history.replaceState(null, '', `${route.path}?tab=listings`)
    tab.value = 'listings'
  } else {
    tab.value = route.query.tab
  }
})
</script>
