import { defineStore } from 'pinia'

import { client } from '@/services/request'

export const useStoreFlags = defineStore('storeFlags', {
  state: () => {
    return {
      showFlagListingDialog: false,
      showFlagReviewListingDialog: false,
      isFlaggingListing: false,
      isFlaggingReviewListing: false,
      reviewlistingUuidToFlag: '',
    }
  },

  actions: {
    async flagListing(uuid, form) {
      const url = `flags/listing/${uuid}/`
      this.isFlaggingListing = true
      const response = await client.authRequest(url, { method: 'POST', form })
      this.isFlaggingListing = false

      return response
    },

    async flagReviewListing(uuid, form) {
      const url = `flags/review-listing/${uuid}/`
      this.isFlaggingReviewListing = true
      const response = await client.authRequest(url, { method: 'POST', form })
      this.isFlaggingReviewListing = false

      return response
    },
  },
})
