<template>
  <q-card class="q-mx-sm q-mb-sm">

    <div v-if="review.listing.images.length > 0">
      <q-card flat square>
        <q-carousel arrows swipeable animated v-model="slide" infinite height="15em">
          <q-carousel-slide v-for="(image, index) in review.listing.images" :key="image.uuid" :name="index"
            :img-src="image.signed_url" />
        </q-carousel>
      </q-card>
    </div>
    <div v-else class="flex text-h5 text-grey text-center" style="min-height: 5em; background-color: #efefef;">
      <div class="col self-center">
        No images to show.
      </div>
    </div>

    <div class="q-mx-sm">
      <router-link style="text-decoration: none; color: inherit;" class="text-primary text-body1 text-weight-bold"
        :to="{ name: 'publicListingsDetailsView', params: { uuid: review.listing.uuid, listingSlug: review.listing.slug } }">
        {{ truncate(review.listing.title, 65) }}
      </router-link>
    </div>

    <div class="q-mt-md">
      <div class="row text-center">
        <div class="col">
          <ReviewRating size="1.9em" v-model="ratingClean" name="Cleanliness" :is-readonly="true"
            :description-on-next-line="true" />
        </div>
        <div class="col">
          <ReviewRating size="1.9em" v-model="ratingLocation" name="Location" :is-readonly="true"
            :description-on-next-line="true" />
        </div>
      </div>

      <div class="row text-center q-mt-sm">
        <div class="col">
          <ReviewRating size="1.9em" v-model="ratingHelpful" name="Helpfulness" :is-readonly="true"
            :description-on-next-line="true" />
        </div>

        <div class="col">
          <ReviewRating size="1.9em" v-model="ratingResponsive" name="Responsiveness" :is-readonly="true"
            :description-on-next-line="true" />
        </div>
      </div>

      <div class="text-center q-mt-sm">
        <ReviewRating size="1.9em" v-model="ratingValue" name="Overall Value" :is-readonly="true"
          :description-on-next-line="true" />
      </div>
    </div>

    <q-separator class="q-my-sm" />
    <div class="q-mx-md">
      <div class="text-h6 q-mt-sm q-mb-xs">
        {{ review.subject }}
      </div>

      <div class="text-body1 q-mt-sm q-mb-xs">
        <div style="white-space: break-spaces;">
          {{ review.review }}
        </div>
      </div>
    </div>


    <q-separator />
    <div class="flex justify-between q-mx-sm q-my-xs">
      <div class="text-caption self-center text-grey-8">
        Reviewed on {{ createdOn }}
      </div>
      <q-btn class="rb-public-profile__delete_bookmark q-mb-xs" size="sm" @click="showDeleteConfirmDialog = true" dense
        label="Delete Review" />
    </div>



    <q-dialog v-model="showDeleteConfirmDialog" persistent>
      <q-card style="width: 500px; max-width: 100%;">
        <q-card-section>
          <div class="text-h6 text-grey-8 text-center">
            Are you sure you want to delete your review?
          </div>
        </q-card-section>
        <q-card-section>
          <q-card flat bordered>
            <q-card-section>
              <div class="text-body1 text-center">
                Deleting your review is a <b>permanent</b> action and cannot be reversed!
              </div>
            </q-card-section>
          </q-card>
        </q-card-section>

        <q-card-actions align="right" class="bg-white text-grey-10 q-mr-sm q-mb-sm">
          <q-btn class="rb-public-buttons__cancel-color" label="Cancel" v-close-popup />
          <q-btn @click="confirmDeleteReview" label="Delete" class="rb-public-buttons__submit-color" />
        </q-card-actions>
      </q-card>
    </q-dialog>
  </q-card>
</template>

<script setup>
import { ref } from 'vue'

import ReviewRating from '@/components/ratings/ReviewRating.vue'

import { useGuestProfile } from '@/composables/guestProfile'

import { truncate } from '@/utils/string'

const {
  deleteReviewListing,
  fetchReviewsListing,
} = useGuestProfile()

const props = defineProps({
  subject: String,
  review: Object,
  createdOn: String,
  reviewUuid: String,
})

const showDeleteConfirmDialog = ref(false)
const slide = ref(0)
const ratingClean = ref(props.review.rating_clean)
const ratingLocation = ref(props.review.rating_location)
const ratingHelpful = ref(props.review.rating_helpful)
const ratingResponsive = ref(props.review.rating_responsive)
const ratingValue = ref(props.review.rating_value)

const confirmDeleteReview = async () => {
  // We're sending the listing UUID to be consistent with the Public Reviews
  // interface. Although, we should probably just use Review UUID across
  // the board.
  const result = await deleteReviewListing(props.review.listing.uuid)
  if (result.status === 204) {
    fetchReviewsListing()
  }
}
</script>
