import { watch } from 'vue'
import { storeToRefs } from 'pinia'

import { useStoreGuestProfile } from '@/stores/storeGuestProfile'

import {
  GUEST_BOOKMARKS_PAGE_SIZE,
  GUEST_BOOKMARKS_PAGE_SIZE_DISPLAY_MAX,
  GUEST_REVIEWS_PAGE_SIZE,
  GUEST_REVIEW_PAGE_SIZE_DISPLAY_MAX,
} from '@/constants/pagination'
import { useStoreReviews } from '@/stores/storeReviews'

export function useGuestProfile() {
  const storeProfile = useStoreGuestProfile()
  const storeReviews = useStoreReviews()

  const {
    profileTab,
    fetchingProfile,
    fetchingBookmarksListing,
    bookmarksListing,
    pageBookmarksListing,
    fetchingReviewsListing,
    reviewsListing,
    pageReviewsListing,
  } = storeToRefs(storeProfile)

  /**
  * Detect when the Pagination has changed for My Bookmarks List.
  */
  watch(() => pageBookmarksListing.value.pageCurrent, async (newVal, oldVal) => {
    if (newVal !== oldVal && !fetchingBookmarksListing.value) {
      await fetchBookmarksListing()
    }
  }, { deep: true })

  /**
  * Detect when the Pagination has changed for My Reviews List.
  */
  watch(() => pageReviewsListing.value.pageCurrent, async (newVal, oldVal) => {
    if (newVal !== oldVal && !fetchingReviewsListing.value) {
      await fetchReviewsListing()
    }
  }, { deep: true })

  const fetchProfile = async () => {
    await storeProfile.fetchProfile()
  }

  const fetchBookmarksListing = async () => {
    await storeProfile.fetchBookmarksListing(
      GUEST_BOOKMARKS_PAGE_SIZE,
      GUEST_BOOKMARKS_PAGE_SIZE_DISPLAY_MAX,
    )
  }

  const deleteBookmarkListing = async (uuid) => {
    return await storeProfile.deleteBookmarkListing(uuid)
  }

  const fetchReviewsListing = async () => {
    await storeProfile.fetchReviewsListing(
      GUEST_REVIEWS_PAGE_SIZE,
      GUEST_REVIEW_PAGE_SIZE_DISPLAY_MAX,
    )
  }

  const deleteReviewListing = async (uuid) => {
    return await storeProfile.deleteReviewListing(uuid)
  }

  return {
    fetchProfile,
    profileTab,
    fetchingProfile,
    bookmarksListing,
    pageBookmarksListing,
    fetchingReviewsListing,
    reviewsListing,
    pageReviewsListing,
    fetchBookmarksListing,
    deleteBookmarkListing,
    fetchReviewsListing,
    deleteReviewListing,
  }
}
