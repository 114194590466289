import { defineStore } from 'pinia'

import { client } from '@/services/request'

export const useStoreLicense = defineStore('storeLicense', {
  state: () => {
    return {
      license: {},
      fetchingLicense: false,
      plans: [],
      fetchingPlans: false,
    }
  },

  actions: {
    async fetchLicense(companyUuid) {
      const url = `host/${companyUuid}/license/`
      this.fetchingLicense = true
      const response = await client.authRequest(url)
      this.fetchingLicense = false

      if (response.status == 200) {
        this.license = response.data
      } else {
        // TODO-FEATURE: Handle unsuccessful responses
      }
    },

    async fetchPlans() {
      const url = `licenses/plans/`
      this.fetchingPlans = true
      const response = await client.authRequest(url)
      this.fetchingPlans = false

      if (response.status == 200) {
        this.plans = response.data
      } else {
        // TODO-FEATURE: Handle unsuccessful responses
      }
    },
  },
})
