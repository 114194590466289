<template>
  <q-card bordered class="q-mb-md">
    <div class="row">
      <div class="col">
        <div v-if="listing.images.length > 0">
          <q-card class="q-pa-xs" flat>
            <q-carousel arrows swipeable animated v-model="slide" infinite height="13em">
              <q-carousel-slide v-for="(image, index) in listing.images" :key="image.uuid" :name="index"
                :img-src="image.signed_url" />
            </q-carousel>
          </q-card>
        </div>
        <div v-else class="row text-h5 text-grey text-center" style="min-height: 170px; background-color: #efefef;">
          <div class="col self-center">
            No images to show.
          </div>
        </div>
      </div>

      <div class="col-7 q-ma-sm">
        <div>
          <router-link style="text-decoration: none; color: inherit;"
            class="text-primary text-subtitle1 text-weight-bold"
            :to="{ name: 'hostListingsDetailsView', params: { uuid: listing.uuid } }">
            {{ truncate(listing.title, 50) }}
          </router-link>
        </div>


        <div class="flex">
          <div class="text-caption text-grey q-mb-xs">
            Created: {{ listing.created_on }}
          </div>
          <q-space />
          <div>
            <q-badge v-if="listing.is_active" class="rb-host-chips__active">Active</q-badge>
            <q-badge v-else class="rb-host-chips__inactive" label="In-active" />
          </div>
        </div>

        <div class="row">
          <BasicInfoChip :text="listing.property_type" icon="house" :dense="false" />
          <ListingPriceChip :amount="listing.price.total_price" />
        </div>

        <q-separator class="q-my-sm" />

        <div class="rb-host-listing-preview-card__description" v-if="listing.description.length">
          {{ truncate(listing.description, 155) }}
        </div>
        <div v-else class="text-body1 text-grey">
          No Description Provided
        </div>

      </div>
    </div>
  </q-card>
</template>

<script setup>
import { ref } from 'vue'

import BasicInfoChip from '@/components/chips/BasicInfoChip.vue'
import ListingPriceChip from '@/components/chips/ListingPriceChip.vue'

import { truncate } from '@/utils/string'

defineProps({
  listing: Object
})

const slide = ref(0)
</script>
