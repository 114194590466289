import md5 from "md5"

/**
 * Given a piece of text, convert it to a md5 hash and return a Gravatar URL.
 *
 * https://docs.gravatar.com/api/avatars/images/
 */
export function getGravatarUrl(text, options) {
  const hash = md5(text)
  const avatarType = options?.avatarType ? options.avatarType : 'robohash'
  return `https://gravatar.com/avatar/${hash}?size=64&d=${avatarType}`
}
