import { useStoreBookmarks } from '@/stores/storeBookmarks'

export function useBookmarks() {
  const storeBookmarks = useStoreBookmarks()

  const setListingBookmark = async (uuid) => {
    return await storeBookmarks.setListingBookmark(uuid)
  }

  return {
    setListingBookmark,
  }
}
