<template>
  <q-tab-panel name="bookmarks" class="no-padding">
    <div class="flex justify-center text-grey-8 q-mt-sm q-mb-sm">
      Found {{ pageBookmarksListing.pageTotal }}
      <template v-if="pageBookmarksListing.pageTotal === 1">
        Bookmark
      </template>
      <template v-else>
        Bookmarks
      </template>
    </div>

    <BookmarkListingListItemMobile v-for="bookmark in bookmarksListing" :key="bookmark.uuid" :listing="bookmark.listing"
      :created-on="bookmark.created_on" :bookmark-uuid="bookmark.uuid" :is-deleted="bookmark.is_deleted" />

    <div class="flex flex-center" v-if="pageBookmarksListing.pageTotal">
      <q-pagination v-model="pageBookmarksListing.pageCurrent" color="blue" :max="pageBookmarksListing.pageDisplayMax"
        :max-pages="pageBookmarksListing.pageTotal" boundary-numbers />
    </div>
  </q-tab-panel>
</template>

<script setup>
import { onMounted } from 'vue'

import { useGuestProfile } from '@/composables/guestProfile'

import BookmarkListingListItemMobile from '@/components/cards/mobile/BookmarkListingListItemMobile.vue'

const {
  fetchBookmarksListing,
  bookmarksListing,
  pageBookmarksListing,
} = useGuestProfile()

onMounted(async () => {
  await fetchBookmarksListing()
})
</script>
