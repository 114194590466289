export const FLAG_LISTING_REASONS = [
  'Duplicate',
  'Misleading',
]
export const FLAG_LISTING_REASON_RULES = [
  val => (val && val.length > 0) || 'Please select a reason for flagging this listing.'
]
export const FLAG_LISTING_DESCRIPTION_RULES = [
  val => (val && val.length > 30) || 'Please provide some context for flagging this listing.'
]

export const FLAG_REVIEW_LISTING_REASONS = [
  'duplicate',
  'fake',
  'offensive',
]
export const FLAG_REVIEW_LISTING_REASON_RULES = [
  val => (val && val.length > 0) || 'Please select a reason for flagging this review.'
]
export const FLAG_REVIEW_LISTING_DESCRIPTION_RULES = [
  val => (val && val.length > 30) || 'Please provide some context for flagging this review.'
]
