import { defineStore } from 'pinia'

import { client } from '@/services/request'

export const useStoreOpsListings = defineStore('storeOpsListings', {
  state: () => {
    return {
      opsUpdateListingForm: {},
      opsUpdatingListing: false,
      opsUpdateLocationForm: {},
      opsUpdatingLocation: false,
    }
  },

  actions: {
    async updateListing(uuid) {
      const url = `listings/ops/${uuid}/update/`

      this.opsUpdatingListing = true
      const result = await client.authRequest(url, { method: 'PATCH', form: this.opsUpdateListingForm })
      this.opsUpdatingListing = false

      return result
    },

    async updateLocation(form, uuid) {
      const url = `listings/ops/${uuid}/location/update/`

      this.opsUpdatingLocation = true
      const result = await client.authRequest(url, { method: 'PATCH', form })
      this.opsUpdatingLocation = false

      return result
    },
  }
})
