<template>
  <q-dialog v-model="showFlagReviewListingDialog" persistent>
    <q-card style="width: 600px">
      <q-card-section>
        <div class="text-h6 q-pl-sm">
          Reason
        </div>

        <div class="row">
          <div class="col q-mr-sm">
            <q-select dense ref="reasonRef" :rules="FLAG_REVIEW_LISTING_REASON_RULES" rounded outlined v-model="reason"
              :options="FLAG_REVIEW_LISTING_REASONS" label="Please select a reason" :error-message="errors.reason"
              :error="errors.reason?.length > 0" />
          </div>
        </div>

        <div class="text-h6 q-pl-sm">
          Description
        </div>

        <div class="row">
          <div class="col q-mr-sm">
            <q-input ref="descriptionRef" class="q-mt-sm" :rules="FLAG_REVIEW_LISTING_DESCRIPTION_RULES"
              :input-style="{ minHeight: '200px' }" type="textarea" outlined maxlength="2000" counter dense
              v-model="description" hint="Provide some context around why you're flagging this review."
              :error-message="errors.description" :error="errors.description?.length > 0">
            </q-input>
          </div>
        </div>
      </q-card-section>

      <q-card-actions align="right" class="q-mr-md q-mb-sm">
        <q-btn :disabled="isFlaggingReviewListing" label="Cancel" v-close-popup
          class="rb-public-buttons__cancel-color" />
        <q-btn :disabled="isFlaggingReviewListing" :loading="isFlaggingReviewListing"
          class="rb-public-buttons__submit-color" @click="confirmFlagReviewListing" label="Submit" />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script setup>
import { ref } from 'vue'

import { useFlags } from '@/composables/flags'
import { useAuth } from '@/composables/auth'
import { useReviews } from '@/composables/reviews'

import {
  FLAG_REVIEW_LISTING_REASONS,
  FLAG_REVIEW_LISTING_REASON_RULES,
  FLAG_REVIEW_LISTING_DESCRIPTION_RULES,
} from '@/constants/flag'

const { token } = useAuth()
const {
  flagReviewListing,
  showFlagReviewListingDialog,
  isFlaggingReviewListing,
  reviewlistingUuidToFlag,
} = useFlags()

const {
  fetchListingReviews,
} = useReviews()

const reason = ref('')
const description = ref('')
const reasonRef = ref()
const descriptionRef = ref()
const errors = ref({})

const confirmFlagReviewListing = async () => {
  reasonRef.value.validate()
  descriptionRef.value.validate()

  if (reasonRef.value.hasError || descriptionRef.value.hasError) {
    return
  }

  await flagReviewListing(reviewlistingUuidToFlag.value, reason.value, description.value)
  showFlagReviewListingDialog.value = false
  reason.value = ''
  description.value = ''
  if (token.value) {
    fetchListingReviews()
  }
}
</script>
