<template>
  <q-page>
    <div class="flex content-center" style="min-height: inherit;">
      <div class="q-mx-md">
        <div class="flex q-mt-lg justify-center">
          <SocialGoogle />
          <SocialFacebook class="q-mt-md" />
        </div>

        <div class="text-center text-grey-8 q-mt-lg q-mb-sm">
          You can also
          <router-link :to="{ name: 'publicRegisterManualMobileView' }">Register Manually</router-link>
          with your email.
        </div>
        <div class="text-center text-grey-8 q-mb-sm">
          Already have an account?
          <router-link :to="{ name: 'publicLoginMobileView' }">Log In</router-link>.
        </div>

        <!-- <q-separator inset class="q-my-md" />

        <div class="text-center text-grey-8 q-mb-sm">
          Are you a host or property management company?<br>
          <router-link :to="{ name: 'hostLoginView' }">Log In</router-link>
          or
          <router-link :to="{ name: 'hostRegisterView' }">Register</router-link>
        </div> -->
      </div>
    </div>
  </q-page>
</template>

<script setup>
import { onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { useQuasar } from 'quasar'

const $q = useQuasar()
const router = useRouter()

import SocialGoogle from '@/components/registration/SocialGoogle.vue'
import SocialFacebook from '@/components/registration/SocialFacebook.vue'

onMounted(async () => {
  if (!$q.platform.is.mobile) {
    router.push({ name: 'publicRegisterSocialView' })
  }
})
</script>
