<template>
  <q-dialog v-model="showSearchReviewsOrder">
    <q-card style="width: 400px" class="q-px-sm q-pb-md">
      <q-card-section>
        <div class="text-h6 text-center">
          Sort Reviews
        </div>
      </q-card-section>

      <q-item dense>
        <q-item-section avatar>
          <q-icon name="swap_vert" size="md" />
        </q-item-section>
        <q-item-section>
          <q-select dense rounded outlined v-model="reviewSearchOrder" :options="REVIEW_SEARCH_ORDER_OPTIONS"
            label="Sort listings by" />
        </q-item-section>
      </q-item>

      <div class="row justify-center q-mt-md">
        <q-btn @click="onClear" label="Clear" class="q-ml-md" />
      </div>
    </q-card>
  </q-dialog>
</template>


<script setup>
import { useReviews } from '@/composables/reviews'

import { REVIEW_SEARCH_ORDER_OPTIONS } from '@/constants/review.js'

const {
  showSearchReviewsOrder,
  reviewSearchOrder,
  fetchListingReviews,
} = useReviews()

const onClear = async () => {
  await fetchListingReviews()
  reviewSearchOrder.value = ''
  showSearchReviewsOrder.value = false
}
</script>
