<template>
  <q-card style="width: 100%; background-color: grey;">
    <q-card bordered>
      <q-card-section>
        <div class="text-subtitle1 text-grey">
          Pricing
        </div>
        <div class="text-h6">
          Base Price
        </div>

        <q-input ref="priceRef" :rules="PRICE_RULES" maxlength="9" v-model="basePrice" label="Price" placeholder="0.0"
          prefix="AED" dense style="font-size: 16px;" @focus="onFocusPrice" @blur="onBlurPrice" />
        <div class="text-caption text-grey-8">
          Set the <b>daily</b> price for your property.
        </div>

        <div class="text-h6">
          Deposit
        </div>

        <q-input ref="depositRef" :rules="DEPOSIT_RULES" maxlength="9" v-model="deposit" label="Security Deposit"
          placeholder="0.0" prefix="AED" dense style="font-size: 16px;" @focus="onFocusDeposit" @blur="onBlurDeposit" />
        <div class="text-caption text-grey-8">
          Refundable Security Deposit.
        </div>

        <div class="text-h6">
          Tax
        </div>

        <q-input ref="taxRef" :rules="TAX_RULES" maxlength="9" v-model="tax" label="Tax" placeholder="0.0" prefix="AED"
          dense style="font-size: 16px;" @focus="onFocusTax" @blur="onBlurTax" />
        <div class="text-caption text-grey-8">
          Total Tax Amount.
        </div>

        <div class="text-right">
          <q-btn class="rb-host-buttons__submit" @click="savePrice" size="md">Save</q-btn>
        </div>
      </q-card-section>
    </q-card>

    <q-card bordered class="q-mt-sm">
      <q-card-section>
        <div class="text-subtitle1 text-grey">
          Fees & Extra Charges
        </div>

        <div class="text-h6">
          Amount
        </div>

        <div class="row">
          <div class="col">
            <q-input ref="feeRef" :rules="FEE_RULES" maxlength="9" v-model="fee" label="Fee Amount" placeholder="0.0"
              prefix="AED" hint="Fee amount." dense @blur="onBlurFee" @focus="onFocusFee" style="font-size: 16px;" />
          </div>
          <div class="col">
            <q-input ref="feeRefPercent" maxlength="2" v-model="feePercent" label="Fee %" placeholder="0%" prefix="%"
              hint="Fee percent." dense @focus="onFocusFeePercent" @blur="onBlurFeePercent" style="font-size: 16px;" />
          </div>
        </div>

        <div class="text-h6">
          Fee Type
        </div>

        <q-select ref="feeTypeRef" :rules="FEE_TYPE_RULES" v-model="feeType" :options="feeTypes" label="Fee Type"
          dense />

        <div class="text-right">
          <q-btn class="rb-host-buttons__submit" @click="saveFee" size="md">Add Fee</q-btn>
        </div>
      </q-card-section>
    </q-card>

    <q-card bordered class="q-mt-sm">
      <q-card-section>
        <div class="text-subtitle1 text-grey">
          Discounts & Savings
        </div>

        <div class="text-h6">
          Amount
        </div>

        <div class="row">
          <div class="col">
            <q-input ref="discountRef" :rules="DISCOUNT_RULES" maxlength="9" v-model="discount" label="Price"
              placeholder="0.0" prefix="AED" hint="Discounts amount, if available." dense style="font-size: 16px;"
              @blur="onBlurDiscount" @focus="onFocusDiscount" />
          </div>

          <div class="col">
            <q-input ref="discountRefPercent" maxlength="2" v-model="discountPercent" label="Discount %"
              placeholder="0%" prefix="%" hint="Discount percent." dense @focus="onFocusDiscountPercent"
              @blur="onBlurDiscountPercent" style="font-size: 16px;" />
          </div>
        </div>


        <div class="text-h6">
          Discount Type
        </div>

        <q-select ref="discountTypeRef" :rules="DISCOUNT_TYPE_RULES" v-model="discountType" :options="discountTypes"
          label="Discount Type" dense />

        <div class="text-right">
          <q-btn class="rb-host-buttons__submit" @click="saveDiscount" size="md">Add Discount</q-btn>
        </div>
      </q-card-section>
    </q-card>

    <q-card bordered class="q-mt-sm" v-if="hostListingPriceDetails?.amount">
      <q-card-section>
        <div class="row">
          <div class="text-h6 text-grey">
            Price
          </div>
        </div>

        <div class="row text-grey-8 q-mb-sm">
          <div class="col">
            <b>Type</b>
          </div>
          <div class="col-3">
            <b>Amount</b>
          </div>
        </div>

        <div class="row">
          <div class="col-auto">
            Base Price
          </div>
          <div class="col">
            <hr class="dotted-line" />
          </div>
          <div class="col-3">
            {{ hostListingPriceDetails.amount.currency }} {{ hostListingPriceDetails.amount.amount }}
          </div>
        </div>

        <div class="row">
          <div class="col-auto">
            Deposit
          </div>
          <div class="col">
            <hr class="dotted-line" />
          </div>
          <div class="col-3">
            {{ hostListingPriceDetails.amount.currency }} {{ hostListingPriceDetails.security_deposit.amount
            }}
          </div>
        </div>

        <div v-if="hostListingPriceDetails?.tax?.tax_type" class="row q-mb-sm">
          <div class="col-auto">
            {{ hostListingPriceDetails.tax.tax_type }}
          </div>
          <div class="col">
            <hr class="dotted-line" />
          </div>
          <div class="col-3">
            {{ hostListingPriceDetails.amount.currency }}
            {{ hostListingPriceDetails.tax.amount }}
          </div>
        </div>

        <div class="row q-mt-md">
          <div class="text-h6 text-grey">
            Fees
          </div>
        </div>

        <template v-if="hostListingPriceDetails.fees.length">
          <div class="row" v-for="fee in hostListingPriceDetails.fees" :key="fee.uuid">
            <div class="col-auto">
              {{ fee.fee_type }}
              <q-icon @click="removeFee(fee.uuid)" size="xs" name="remove_circle_outline" class="cursor-pointer"
                color="red-5" />
            </div>
            <div class="col">
              <hr class="dotted-line" />
            </div>
            <div class="col-3">
              {{ hostListingPriceDetails.amount.currency }} {{ fee.amount }}
            </div>
          </div>
        </template>
        <template v-else>
          <span class="text-grey text-weight-bold">No Fees</span>
        </template>

        <div class="row q-mt-md">
          <div class="text-h6 text-grey">
            Discounts
          </div>
        </div>

        <template v-if="hostListingPriceDetails.discounts.length">
          <div class="row" v-for="discount in hostListingPriceDetails.discounts" :key="discount.uuid">
            <div class="col-auto">
              {{ discount.discount_type }}
              <q-icon @click="removeDiscount(discount.uuid)" size="xs" name="remove_circle_outline"
                class="cursor-pointer" color="red-5" />
            </div>
            <div class="col">
              <hr class="dotted-line" />
            </div>
            <div class="col-3">
              {{ hostListingPriceDetails.amount.currency }} {{ discount.amount }}
            </div>
          </div>
        </template>
        <template v-else>
          <span class="text-grey text-weight-bold">No Discounts</span>
        </template>


        <div class="row q-mt-md">
          <div class="text-h6 text-grey">
            Totals
          </div>
        </div>

        <div class="row">
          <div class="col-auto">
            Total Fees
          </div>
          <div class="col">
            <hr class="dotted-line" />
          </div>
          <div class="col-3">
            {{ hostListingPriceDetails.amount.currency }} {{ hostListingPriceDetails.totals.total_fees }}
          </div>
        </div>

        <div class="row">
          <div class="col-auto">
            Total Discounts
          </div>
          <div class="col">
            <hr class="dotted-line" />
          </div>
          <div class="col-3 text-red-10">
            {{ hostListingPriceDetails.amount.currency }} {{ hostListingPriceDetails.totals.total_discounts }}
          </div>
        </div>

        <div class="row">
          <div class="col-auto">
            Taxes
          </div>
          <div class="col">
            <hr class="dotted-line" />
          </div>
          <div class="col-3">
            {{ hostListingPriceDetails.amount.currency }} {{ hostListingPriceDetails.totals.total_taxes }}
          </div>
        </div>

        <div class="flex justify-around">
          <div>
            <h6>Total Daily Price:</h6>
          </div>

          <div>
            <h6>{{ hostListingPriceDetails.amount.currency }} {{ hostListingPriceDetails.totals.total_price }}</h6>
          </div>
        </div>
      </q-card-section>
    </q-card>
  </q-card>
</template>


<script setup>
/**
 * Reminders:
 *
 * Remember to also update PublicListingPriceCard.vue
 */
import { onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'

import { useHostListings } from '@/composables/hostListings'
import { usePublicListings } from '@/composables/publicListings'
import { useMeta } from '@/composables/meta'
import { DEPOSIT_RULES, DISCOUNT_RULES, DISCOUNT_TYPE_RULES, FEE_RULES, FEE_TYPE_RULES, PRICE_RULES, TAX_RULES } from '@/constants/price'
import { notifyHostUpdateValueSuccess, notifyHostUpdateValueError } from '@/notifications/events'

defineProps({
  width: {
    type: Number,
    required: false,
    default: 0,
  },
})

const route = useRoute()

const {
  fetchDiscountTypes,
  discountTypes,
  fetchFeeTypes,
  feeTypes,
} = useMeta()

const locationFormErrors = ref({})

const {
  fetchHostListingPriceDetails,
  hostListingPriceDetails,
  createPrice,
  createDiscount,
  createFee,
  deleteFee,
  deleteDiscount,
} = useHostListings()

const {
  fetchPublicListingDetails,
} = usePublicListings()


onMounted(async () => {
  await fetchHostListingPriceDetails(route.params.uuid)
  await fetchDiscountTypes()
  await fetchFeeTypes()

  basePrice.value = hostListingPriceDetails.value.amount.amount
  tax.value = hostListingPriceDetails.value.tax.amount | 0
  deposit.value = hostListingPriceDetails.value.security_deposit.amount | 0
})

const basePrice = ref()
const tax = ref()
const deposit = ref()
const discount = ref()
const discountPercent = ref()
const discountType = ref()
const fee = ref()
const feeType = ref()

const priceRef = ref(null)
const depositRef = ref(null)
const taxRef = ref(null)
const discountRef = ref(null)
const discountRefPercent = ref(null)
const discountTypeRef = ref(null)
const feeRef = ref(null)
const feeTypeRef = ref(null)
const feePercent = ref()


const refreshData = async () => {
  await fetchHostListingPriceDetails(route.params.uuid)
  await fetchPublicListingDetails(route.params.uuid)
}

const onFocusPrice = () => {
  if (!basePrice.value) {
    basePrice.value = null
  }
}

const onBlurPrice = () => {
  if (!basePrice.value) {
    basePrice.value = 0
  }
}

const onFocusDeposit = () => {
  if (!deposit.value) {
    deposit.value = null
  }
}

const onBlurDeposit = () => {
  if (!deposit.value) {
    deposit.value = 0
  }
}

const onFocusTax = () => {
  if (!tax.value) {
    tax.value = null
  }
}

const onBlurTax = () => {
  if (!tax.value) {
    tax.value = 0
  }
}

const onFocusFee = () => {
  if (!fee.value) {
    fee.value = null
  }
}

const onFocusDiscountPercent = () => {
  if (!discountPercent.value) {
    discountPercent.value = null
  }
}

const onBlurDiscountPercent = () => {
  if (discountPercent.value > 0) {
    // Calculate the percentage relative to listing price
    discount.value = (basePrice.value * (discountPercent.value / 100)).toFixed(2)
  }

  if (!discountPercent.value || discountPercent.value === "" || discount.value === "0") {
    discountPercent.value = null
    discount.value = 0
  }
}

const onBlurFee = () => {
  if (fee.value > 0) {
    feePercent.value = (fee.value / basePrice.value * 100).toFixed(2)
  }

  if (!fee.value || fee.value === "" || fee.value === "0") {
    fee.value = 0
    feePercent.value = null
  }
}

const onFocusFeePercent = () => {
  if (!feePercent.value) {
    feePercent.value = null
  }
}

const onBlurFeePercent = () => {
  if (feePercent.value > 0) {
    // Calculate the percentage relative to listing price
    fee.value = (basePrice.value * (feePercent.value / 100)).toFixed(2)
  }

  if (!feePercent.value || feePercent.value === "" || fee.value === "0") {
    feePercent.value = null
    fee.value = 0
  }
}

const onFocusDiscount = () => {
  if (!discount.value) {
    discount.value = null
  }
}

const onBlurDiscount = () => {
  if (discount.value > 0) {
    discountPercent.value = (discount.value / basePrice.value * 100).toFixed(2)
  }

  if (!discount.value || discount.value === "" || discount.value === "0") {
    discount.value = 0
    discountPercent.value = null
  }
}

/**
 * TODO-FEATURE: Do not save price if it hasn't changed.
 */
const savePrice = async () => {
  priceRef.value.validate()
  depositRef.value.validate()
  taxRef.value.validate()

  if (
    priceRef.value.hasError ||
    depositRef.value.hasError ||
    taxRef.value.hasError
  ) {
    return
  }

  const form = {
    price: basePrice.value,
    deposit: deposit.value,
    tax: tax.value,
  }

  const result = await createPrice(route.params.uuid, form)

  if (result.status === 201) {
    notifyHostUpdateValueSuccess('Successfully updated the Price.')
    await refreshData()
  } else {
    notifyHostUpdateValueError('Error while updating the Price!')

    const errors = {}
    for (const key of Object.keys(result.data)) {
      if (Array.isArray(result.data[key])) {
        errors[key] = result.data[key]
      } else {
        const _errors = []
        for (const innerKey of Object.keys(result.data[key])) {
          _errors.push(
            `${innerKey} -> ${result.data[key][innerKey].join(' ')}`
          )
        }
        errors[key] = _errors
      }
    }

    locationFormErrors.value = errors
  }
}

const saveDiscount = async () => {
  discountRef.value.validate()
  discountTypeRef.value.validate()

  if (
    discountRef.value.hasError ||
    discountTypeRef.value.hasError
  ) {
    return
  }

  const form = {
    amount: discount.value,
    discount_type: discountType.value,
  }

  const result = await createDiscount(route.params.uuid, form)

  if (result.status === 201) {
    notifyHostUpdateValueSuccess('Successfully added Discount.')
    await refreshData()
  } else {
    notifyHostUpdateValueError('Error while adding Discount!')

    const errors = {}
    for (const key of Object.keys(result.data)) {
      if (Array.isArray(result.data[key])) {
        errors[key] = result.data[key]
      } else {
        const _errors = []
        for (const innerKey of Object.keys(result.data[key])) {
          _errors.push(
            `${innerKey} -> ${result.data[key][innerKey].join(' ')}`
          )
        }
        errors[key] = _errors
      }
    }

    locationFormErrors.value = errors
  }
}

const removeFee = async (uuid) => {
  const result = await deleteFee(route.params.uuid, uuid)

  if (result.status === 204) {
    notifyHostUpdateValueSuccess('Successfully deleted Fee.')
    await refreshData()
  } else {
    notifyHostUpdateValueError('Error while deleting Fee!')
  }
}

const removeDiscount = async (uuid) => {
  const result = await deleteDiscount(route.params.uuid, uuid)

  if (result.status === 204) {
    notifyHostUpdateValueSuccess('Successfully deleted Discount.')
    await refreshData()
  } else {
    notifyHostUpdateValueError('Error while deleting Discount!')
  }
}


const saveFee = async () => {
  feeRef.value.validate()
  feeTypeRef.value.validate()

  if (
    feeRef.value.hasError ||
    feeTypeRef.value.hasError
  ) {
    return
  }

  const form = {
    amount: fee.value,
    fee_type: feeType.value,
  }

  const result = await createFee(route.params.uuid, form)

  if (result.status === 201) {
    notifyHostUpdateValueSuccess('Successfully added Fee.')
    await refreshData()
  } else {
    notifyHostUpdateValueError('Error while adding Fee!')

    const errors = {}
    for (const key of Object.keys(result.data)) {
      if (Array.isArray(result.data[key])) {
        errors[key] = result.data[key]
      } else {
        const _errors = []
        for (const innerKey of Object.keys(result.data[key])) {
          _errors.push(
            `${innerKey} -> ${result.data[key][innerKey].join(' ')}`
          )
        }
        errors[key] = _errors
      }
    }

    locationFormErrors.value = errors
  }
}
</script>

<style scoped>
hr.dotted-line {
  border: none;
  border-bottom: 2px dotted #c7c3c3;
  padding-bottom: 4px;
  margin-left: 8px;
  margin-right: 8px;
}
</style>
