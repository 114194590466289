<template>
  <q-dialog v-model="showCreateReviewDialog" persistent>
    <q-card style="width: 700px; max-width: 100%;">

      <div class="text-body1 text-grey-8 text-center q-mb-sm">
        Create a review for this Listing
      </div>

      <q-card flat>
        <div class="text-center">
          <ReviewRating v-model="ratingClean" name="Cleanliness" :descriptionOnNextLine="true" sizes="3em" />
        </div>

        <q-separator inset class="q-my-xs" />

        <div class="text-center">
          <ReviewRating v-model="ratingLocation" name="Location" :descriptionOnNextLine="true" sizes="3.5em" />
        </div>

        <q-separator inset class="q-my-xs" />

        <div class="text-center">
          <ReviewRating v-model="ratingHelpful" name="Host's Helpfulness" :descriptionOnNextLine="true" sizes="3.5em" />
        </div>

        <q-separator inset class="q-my-xs" />

        <div class="text-center">
          <ReviewRating v-model="ratingResponsive" name="Host's Responsiveness" :descriptionOnNextLine="true"
            sizes="3.5em" />
        </div>

        <q-separator inset class="q-my-xs" />

        <div class="text-center">
          <ReviewRating v-model="ratingValue" name="Overall Value" :descriptionOnNextLine="true" sizes="3.5em" />
        </div>

      </q-card>

      <q-input ref="reviewTextRef" class="q-mt-sm q-mx-xs" :rules="reviewRules" :input-style="{ minHeight: '200px' }"
        type="textarea" outlined maxlength="2000" counter dense v-model="reviewText" :error-message="errors"
        hint="Add your review for this listing." :error="errors.length > 0">
      </q-input>



      <q-card-actions align="right" class="bg-white text-grey-10 q-mr-sm q-mb-sm">
        <q-btn label="Cancel" v-close-popup :disable="creatingReview" class="rb-public-buttons__cancel-color" />
        <q-btn @click="createReview" :disable="creatingReview" class="rb-public-buttons__submit-color"
          :loading="creatingReview" label="Submit" />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>


<script setup>
import { ref } from 'vue';
import { useRoute } from 'vue-router'

import { useReviews } from '@/composables/reviews'
import ReviewRating from '@/components/ratings/ReviewRating.vue'
import { notifyHostUpdateValueError, notifyHostUpdateValueSuccess } from '@/notifications/events'

const route = useRoute()

const {
  createListingReview,
  fetchListingReviews,
  getListingReviewSelf,
  creatingReview,
  showCreateReviewDialog,
  reviewText,
  ratingClean,
  ratingLocation,
  ratingHelpful,
  ratingResponsive,
  ratingValue,
} = useReviews()

const reviewTextRef = ref(null)
const reviewRules = [
  val => (val && val.length > 10 && val.length <= 2000) || 'Please type a few paragraphs.'
]

const errors = ref('')


const createReview = async () => {
  reviewTextRef.value.validate()
  if (reviewTextRef.value.hasError) {
    return
  }

  const form = {
    review: reviewText.value,
    rating_clean: ratingClean.value,
    rating_location: ratingLocation.value,
    rating_helpful: ratingHelpful.value,
    rating_responsive: ratingResponsive.value,
    rating_value: ratingValue.value,
  }

  const result = await createListingReview(route.params.uuid, form)

  if (result.status === 201) {
    notifyHostUpdateValueSuccess('Successfully created the Review.')

    await fetchListingReviews()
    await getListingReviewSelf()
    showCreateReviewDialog.value = false

    errors.value = ''
    reviewText.value = ''
    ratingClean.value = 3
    ratingLocation.value = 3
    ratingHelpful.value = 3
    ratingResponsive.value = 3
    ratingValue.value = 3
  } else {
    const error = `Error while creating the Review!`
    notifyHostUpdateValueError(error)
  }
}
</script>
