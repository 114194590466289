<template>
  <q-card class="q-mt-xl" style="height: 500px;">
    <q-card-section class="text-grey-8 text-center">
      <div class="flex justify-center q-mt-lg">
        <q-avatar size="100px">
          <img src="@/assets/gold-logo-circle-xx-small.webp" />
        </q-avatar>
        <!-- <q-img src="@/assets/color-logo-horizontal-gold.webp" /> -->
      </div>

      <div class="row justify-center q-mt-md">
        <div class="col text-grey-8 text-body1">
          RoomB is your free to use search engine to find short-term rentals <br />across the United Arab Emirates!
        </div>
      </div>
    </q-card-section>

    <div class="row justify-center q-mt-xl">
      <div class="text-center text-grey-8 text-body-1 q-mb-sm">
        If you'd like to get in touch, use our
        <router-link style="text-decoration: none; color: inherit;" class="text-primary"
          :to="{ name: 'publicContactView' }">
          Contact Page
        </router-link>
        to reach out to us!
      </div>
    </div>

    <div class="row justify-center text-grey-8">
      View our
      <router-link style="text-decoration: none; color: inherit;" class="text-primary"
        :to="{ name: 'publicTermsOfServiceView' }">
        &nbsp;Terms of Service
      </router-link>
      &nbsp;and
      <router-link style="text-decoration: none; color: inherit;" class="text-primary"
        :to="{ name: 'publicPrivacyPolicyView' }">
        &nbsp;Privacy Policy
      </router-link>.
    </div>
  </q-card>
</template>

<script setup>
import { onMounted } from 'vue'
import { useQuasar } from 'quasar'
import { useRouter } from 'vue-router'

const $q = useQuasar()
const router = useRouter()

onMounted(async () => {
  // If user is on mobile, redirect to mobile view.
  if ($q.platform.is.mobile) {
    router.push({ name: 'publicAboutMobileView' })
  }
})
</script>
