<template>
  <q-page>
    <div>
      <div class="row q-mb-md justify-center">
        <div class="col-8">
          <q-card>
            <q-card-section>
              <div class="text-h5 text-center text-grey-8">
                Help and Support
              </div>
            </q-card-section>
            <q-card-section>
              <div class="text-body1 text-center">
                If you require assistance or have any questions, please reach out to us via email at {{ supportEmail }}
              </div>
            </q-card-section>
            <q-card-section>
              <div class="text-body1 text-center">
                Note that we only provide support on Advanced and Professional plans.
                <div>You are currently on the <b>{{ license?.plan?.name }}</b> plan.</div>
              </div>
            </q-card-section>
          </q-card>
        </div>
      </div>
    </div>
  </q-page>
</template>

<script setup>
import { onMounted } from 'vue'

import { useLicense } from '@/composables/license'
import { useHostProfile } from '@/composables/hostProfile'

const supportEmail = import.meta.env.VITE_ROOMBEE_SUPPORT_EMAIL

const {
  hostProfile,
  fetchHostProfile,
} = useHostProfile()


const {
  fetchLicense,
  license,
} = useLicense()

onMounted(async () => {
  await fetchHostProfile()
  await fetchLicense(hostProfile.value.uuid)
})
</script>
