<template>
  <div class="q-pb-lg">
    <q-card-section>
      <div class="row justify-center">
        <div class="col-8">
          <div class="flex justify-center">
            <SocialGoogle />
          </div>
        </div>
      </div>
    </q-card-section>

    <q-card-section>
      <div class="row justify-center">
        <div class="col-8">
          <div class="flex justify-center">
            <SocialFacebook />
          </div>
        </div>
      </div>
    </q-card-section>

    <div class="row justify-center">
      <div class="col-3">
        <q-separator />
      </div>
    </div>

    <q-card-section>
      <div class="text-center text-grey-8 q-mb-sm">
        You can also
        <router-link :to="{ name: 'publicRegisterManualView' }">Register Manually</router-link>
        with your email.
      </div>
      <div class="text-center text-grey-8 q-mb-sm">
        Already have an account?
        <router-link :to="{ name: 'publicLoginView' }">Log In</router-link>.
      </div>
      <div class="text-center text-grey-8 q-mb-sm">
        Or, go to the
        <router-link :to="{ name: 'publicListingsView' }">Home Page</router-link>
        without logging in.
      </div>
      <q-separator inset class="q-my-md" />
      <div class="text-center text-grey-8">
        Are you a host or property management company?
        <router-link :to="{ name: 'hostLoginView' }">Log In</router-link>
        or
        <router-link :to="{ name: 'hostRegisterView' }">Register</router-link>
        as a host.
      </div>
    </q-card-section>
  </div>
</template>

<script setup>
import { onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { useQuasar } from 'quasar'

const $q = useQuasar()
const router = useRouter()

import SocialGoogle from '@/components/registration/SocialGoogle.vue'
import SocialFacebook from '@/components/registration/SocialFacebook.vue'

onMounted(async () => {
  if ($q.platform.is.mobile) {
    router.push({ name: 'publicRegisterSocialMobileView' })
  }
})
</script>
