<template>
  <q-tab-panel name="profile">
    <div class="row">
      <div class="col-3 text-center">
        <div class="q-mb-sm">
          <q-avatar>
            <img :src='getGravatarUrl(user.uuid)' />
          </q-avatar>
        </div>
        <div class="text-body1">
          {{ truncate(user.username, 20) }}
        </div>
        <div class="text-grey-10 text-caption">
          Joined on {{ user.joined_on }}
        </div>

        <q-separator class="q-my-sm" inset />
        <div class="text-grey-8 text-caption q-mx-md">
          <q-icon name="info" color="grey-6" size="sm" />
          Your email is <b>private</b> and is never visible to users. Only your username is visible
          to other users, e.g. when you post a review for a property.
        </div>
      </div>

      <div class="col">
        <div class="text-body1">
          Hi there <b>{{ user.username }}</b> <q-icon name="waving_hand" color="yellow-9" size="sm" />
        </div>
        <div class="text-grey-8 text-caption">
          {{ user.email }}
        </div>
        <div class="text-body1 q-mt-md">
          This is your profile page. There isn't much going on here at the moment,
          but we're at a super early stage right now. Some cool features are on
          the horizon, so keep an eye on this page!
        </div>
        <div class="text-body2 q-mt-md">
          If you have any suggestions on how we can make this page more useful to you,
          please
          <router-link style="text-decoration: none; color: inherit;" class="text-primary"
            :to="{ name: 'publicContactView' }">
            send us a message!
          </router-link>
        </div>
      </div>
    </div>
  </q-tab-panel>
</template>

<script setup>
import { getGravatarUrl } from '@/utils/avatar'
import { useAuth } from '@/composables/auth'
import { truncate } from '@/utils/string'

const { user } = useAuth()
</script>
