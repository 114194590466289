<template>
  <q-card :style="width ? 'min-width: ' + width + 'px;' : 'auto'">
    <div class="row">
      <div class="col-7">
        <q-card>
          <q-card-section>
            <div class="row">
              <div class="text-subtitle1 text-grey">
                Pricing
              </div>
            </div>

            <div class="row">
              <div class="col">
                <div class="text-h6">
                  Base Price
                </div>
                <q-input ref="priceRef" :rules="PRICE_RULES" mask="#.##" reverse-fill-mask maxlength="9"
                  v-model="basePrice" label="Price" placeholder="0.0" prefix="AED" dense />
                <div class="text-caption text-grey-8">
                  Set the <b>daily</b> price for your property.
                </div>
              </div>
              <div class="col q-ml-sm">
                <div class="text-h6">
                  Deposit
                </div>
                <q-input ref="depositRef" :rules="DEPOSIT_RULES" mask="#.##" reverse-fill-mask maxlength="9"
                  v-model="deposit" label="Security Deposit" placeholder="0.0" prefix="AED" dense />
                <div class="text-caption text-grey-8">
                  Refundable Security Deposit.
                </div>
              </div>
              <div class="col q-ml-sm">
                <div class="text-h6">
                  Tax
                </div>
                <q-input ref="taxRef" :rules="TAX_RULES" mask="#.##" reverse-fill-mask maxlength="9" v-model="tax"
                  label="Tax" placeholder="0.0" prefix="AED" dense />
                <div class="text-caption text-grey-8">
                  Total Tax Amount.
                </div>
              </div>
            </div>

            <div class="row q-mb-md">
              <div class="col text-right">
                <q-btn class="rb-host-buttons__submit" @click="savePrice" size="md">Save</q-btn>
              </div>
            </div>

            <q-separator class="q-mt-sm q-mb-md" />

            <div class="row">
              <div class="text-subtitle1 text-grey">
                Discounts & Savings
              </div>
            </div>

            <div class="row">
              <div class="col-4">
                <div class="text-h6">
                  Amount
                </div>
                <q-input ref="discountRef" :rules="DISCOUNT_RULES" mask="#.##" reverse-fill-mask maxlength="9"
                  v-model="discount" label="Price" placeholder="0.0" prefix="AED" hint="Discounts amount, if available."
                  dense />
              </div>

              <div class="col-5 q-ml-sm">
                <div class="text-h6">
                  Discount Type
                </div>
                <q-select ref="discountTypeRef" :rules="DISCOUNT_TYPE_RULES" v-model="discountType"
                  :options="discountTypes" label="Discount Type" dense />
              </div>
            </div>

            <div class="row q-mb-md">
              <div class="col text-right">
                <q-btn class="rb-host-buttons__submit" @click="saveDiscount" size="md">Add Discount</q-btn>
              </div>
            </div>

            <q-separator class="q-mt-sm q-mb-md" />

            <div class="row">
              <div class="text-subtitle1 text-grey">
                Fees & Extra Charges
              </div>
            </div>

            <div class="row">
              <div class="col-4">
                <div class="text-h6">
                  Amount
                </div>
                <q-input ref="feeRef" :rules="FEE_RULES" mask="#.##" reverse-fill-mask maxlength="9" v-model="fee"
                  label="Price" placeholder="0.0" prefix="AED" hint="Discounts amount, if available." dense />
              </div>

              <div class="col-4 q-ml-sm">
                <div class="text-h6">
                  Fee Type
                </div>
                <q-select ref="feeTypeRef" :rules="FEE_TYPE_RULES" v-model="feeType" :options="feeTypes"
                  label="Fee Type" dense />
              </div>
            </div>
            <div class="row">
              <div class="col text-right">
                <q-btn class="rb-host-buttons__submit" @click="saveFee" size="md">Add Fee</q-btn>
              </div>
            </div>
          </q-card-section>
        </q-card>
      </div>


      <div class="col q-ml-md" v-if="hostListingPriceDetails?.amount">
        <q-card class="q-pl-sms" style="min-height: 100%;">
          <q-card-section>
            <div class="row">
              <div class="text-h6 text-grey">
                Price
              </div>
            </div>

            <div class="row text-grey-8 q-mb-sm">
              <div class="col">
                <b>Type</b>
              </div>
              <div class="col-3">
                <b>Amount</b>
              </div>
            </div>

            <div class="row">
              <div class="col-auto">
                Base Price
              </div>
              <div class="col">
                <hr class="dotted-line" />
              </div>
              <div class="col-3">
                {{ hostListingPriceDetails.amount.currency }} {{ hostListingPriceDetails.amount.amount }}
              </div>
            </div>

            <div class="row">
              <div class="col-auto">
                Deposit
              </div>
              <div class="col">
                <hr class="dotted-line" />
              </div>
              <div class="col-3">
                {{ hostListingPriceDetails.amount.currency }} {{ hostListingPriceDetails.security_deposit.amount
                }}
              </div>
            </div>

            <div v-if="hostListingPriceDetails?.tax?.tax_type" class="row q-mb-sm">
              <div class="col-auto">
                {{ hostListingPriceDetails.tax.tax_type }}
              </div>
              <div class="col">
                <hr class="dotted-line" />
              </div>
              <div class="col-3">
                {{ hostListingPriceDetails.amount.currency }}
                {{ hostListingPriceDetails.tax.amount }}
              </div>
            </div>

            <div class="row q-mt-md">
              <div class="text-h6 text-grey">
                Fees
              </div>
            </div>

            <template v-if="hostListingPriceDetails.fees.length">
              <div class="row" v-for="fee in hostListingPriceDetails.fees" :key="fee.uuid">
                <div class="col-auto">
                  {{ fee.fee_type }}
                  <q-icon @click="removeFee(fee.uuid)" size="xs" name="remove_circle_outline" class="cursor-pointer"
                    color="red-5" />
                </div>
                <div class="col">
                  <hr class="dotted-line" />
                </div>
                <div class="col-3">
                  {{ hostListingPriceDetails.amount.currency }} {{ fee.amount }}
                </div>
              </div>
            </template>
            <template v-else>
              <span class="text-grey text-weight-bold">No Fees</span>
            </template>

            <div class="row q-mt-md">
              <div class="text-h6 text-grey">
                Discounts
              </div>
            </div>

            <template v-if="hostListingPriceDetails.discounts.length">
              <div class="row" v-for="discount in hostListingPriceDetails.discounts" :key="discount.uuid">
                <div class="col-auto">
                  {{ discount.discount_type }}
                  <q-icon @click="removeDiscount(discount.uuid)" size="xs" name="remove_circle_outline"
                    class="cursor-pointer" color="red-5" />
                </div>
                <div class="col">
                  <hr class="dotted-line" />
                </div>
                <div class="col-3">
                  {{ hostListingPriceDetails.amount.currency }} {{ discount.amount }}
                </div>
              </div>
            </template>
            <template v-else>
              <span class="text-grey text-weight-bold">No Discounts</span>
            </template>


            <div class="row q-mt-md">
              <div class="text-h6 text-grey">
                Totals
              </div>
            </div>

            <div class="row">
              <div class="col-auto">
                Total Fees
              </div>
              <div class="col">
                <hr class="dotted-line" />
              </div>
              <div class="col-3">
                {{ hostListingPriceDetails.amount.currency }} {{ hostListingPriceDetails.totals.total_fees }}
              </div>
            </div>

            <div class="row">
              <div class="col-auto">
                Total Discounts
              </div>
              <div class="col">
                <hr class="dotted-line" />
              </div>
              <div class="col-3 text-red-10">
                {{ hostListingPriceDetails.amount.currency }} {{ hostListingPriceDetails.totals.total_discounts }}
              </div>
            </div>

            <div class="row">
              <div class="col-auto">
                Taxes
              </div>
              <div class="col">
                <hr class="dotted-line" />
              </div>
              <div class="col-3">
                {{ hostListingPriceDetails.amount.currency }} {{ hostListingPriceDetails.totals.total_taxes }}
              </div>
            </div>

            <div class="flex justify-around">
              <div>
                <h6>Total Daily Price:</h6>
              </div>

              <div>
                <h6>{{ hostListingPriceDetails.amount.currency }} {{ hostListingPriceDetails.totals.total_price }}</h6>
              </div>
            </div>
          </q-card-section>
        </q-card>
      </div>
    </div>
  </q-card>
</template>


<script setup>
/**
 * Reminders:
 *
 * Remember to also update PublicListingPriceCard.vue
 */
import { onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'

import { useHostListings } from '@/composables/hostListings'
import { useMeta } from '@/composables/meta'
import { DEPOSIT_RULES, DISCOUNT_RULES, DISCOUNT_TYPE_RULES, FEE_RULES, FEE_TYPE_RULES, PRICE_RULES, TAX_RULES } from '@/constants/price'
import { notifyHostUpdateValueSuccess, notifyHostUpdateValueError } from '@/notifications/events'

defineProps({
  width: {
    type: Number,
    required: false,
    default: 0,
  },
})

const route = useRoute()

const {
  fetchDiscountTypes,
  discountTypes,
  fetchFeeTypes,
  feeTypes,
} = useMeta()

const locationFormErrors = ref({})

const {
  fetchHostListingPriceDetails,
  hostListingPriceDetails,
  createPrice,
  createDiscount,
  createFee,
  deleteFee,
  deleteDiscount,
} = useHostListings()

onMounted(async () => {
  await fetchHostListingPriceDetails(route.params.uuid)
  await fetchDiscountTypes()
  await fetchFeeTypes()

  basePrice.value = hostListingPriceDetails.value.amount.amount
  tax.value = hostListingPriceDetails.value.tax.amount
  deposit.value = hostListingPriceDetails.value.security_deposit.amount
})

const basePrice = ref()
const tax = ref()
const deposit = ref()
const discount = ref()
const discountType = ref()
const fee = ref()
const feeType = ref()

const priceRef = ref(null)
const depositRef = ref(null)
const taxRef = ref(null)
const discountRef = ref(null)
const discountTypeRef = ref(null)
const feeRef = ref(null)
const feeTypeRef = ref(null)

/**
 * TODO-FEATURE: Do not save price if it hasn't changed.
 */
const savePrice = async () => {
  priceRef.value.validate()
  depositRef.value.validate()
  taxRef.value.validate()

  if (
    priceRef.value.hasError ||
    depositRef.value.hasError ||
    taxRef.value.hasError
  ) {
    return
  }

  const form = {
    price: basePrice.value,
    deposit: deposit.value,
    tax: tax.value,
  }

  const result = await createPrice(route.params.uuid, form)

  if (result.status === 201) {
    notifyHostUpdateValueSuccess('Successfully updated the Price.')
    await fetchHostListingPriceDetails(route.params.uuid)
  } else {
    notifyHostUpdateValueError('Error while updating the Price!')

    const errors = {}
    for (const key of Object.keys(result.data)) {
      if (Array.isArray(result.data[key])) {
        errors[key] = result.data[key]
      } else {
        const _errors = []
        for (const innerKey of Object.keys(result.data[key])) {
          _errors.push(
            `${innerKey} -> ${result.data[key][innerKey].join(' ')}`
          )
        }
        errors[key] = _errors
      }
    }

    locationFormErrors.value = errors
  }
}

const saveDiscount = async () => {
  discountRef.value.validate()
  discountTypeRef.value.validate()

  if (
    discountRef.value.hasError ||
    discountTypeRef.value.hasError
  ) {
    return
  }

  const form = {
    amount: discount.value,
    discount_type: discountType.value,
  }

  const result = await createDiscount(route.params.uuid, form)

  if (result.status === 201) {
    notifyHostUpdateValueSuccess('Successfully added Discount.')
    await fetchHostListingPriceDetails(route.params.uuid)
  } else {
    notifyHostUpdateValueError('Error while adding Discount!')

    const errors = {}
    for (const key of Object.keys(result.data)) {
      if (Array.isArray(result.data[key])) {
        errors[key] = result.data[key]
      } else {
        const _errors = []
        for (const innerKey of Object.keys(result.data[key])) {
          _errors.push(
            `${innerKey} -> ${result.data[key][innerKey].join(' ')}`
          )
        }
        errors[key] = _errors
      }
    }

    locationFormErrors.value = errors
  }
}

const removeFee = async (uuid) => {
  const result = await deleteFee(route.params.uuid, uuid)

  if (result.status === 204) {
    notifyHostUpdateValueSuccess('Successfully deleted Fee.')
    await fetchHostListingPriceDetails(route.params.uuid)
  } else {
    notifyHostUpdateValueError('Error while deleting Fee!')
  }
}

const removeDiscount = async (uuid) => {
  const result = await deleteDiscount(route.params.uuid, uuid)

  if (result.status === 204) {
    notifyHostUpdateValueSuccess('Successfully deleted Discount.')
    await fetchHostListingPriceDetails(route.params.uuid)
  } else {
    notifyHostUpdateValueError('Error while deleting Discount!')
  }
}


const saveFee = async () => {
  feeRef.value.validate()
  feeTypeRef.value.validate()

  if (
    feeRef.value.hasError ||
    feeTypeRef.value.hasError
  ) {
    return
  }

  const form = {
    amount: fee.value,
    fee_type: feeType.value,
  }

  const result = await createFee(route.params.uuid, form)

  if (result.status === 201) {
    notifyHostUpdateValueSuccess('Successfully added Fee.')
    await fetchHostListingPriceDetails(route.params.uuid)
  } else {
    notifyHostUpdateValueError('Error while adding Fee!')

    const errors = {}
    for (const key of Object.keys(result.data)) {
      if (Array.isArray(result.data[key])) {
        errors[key] = result.data[key]
      } else {
        const _errors = []
        for (const innerKey of Object.keys(result.data[key])) {
          _errors.push(
            `${innerKey} -> ${result.data[key][innerKey].join(' ')}`
          )
        }
        errors[key] = _errors
      }
    }

    locationFormErrors.value = errors
  }
}
</script>

<style scoped>
hr.dotted-line {
  border: none;
  border-bottom: 2px dotted #c7c3c3;
  padding-bottom: 4px;
  margin-left: 8px;
  margin-right: 8px;
}
</style>
