import { watch } from 'vue'
import { storeToRefs } from 'pinia'

import { useRoute } from 'vue-router'

import {
  LISTING_REVIEWS_PAGE_SIZE,
  LISTING_REVIEWS_PAGE_SIZE_DISPLAY_MAX,
} from '@/constants/pagination.js'

import { useStoreReviews } from '@/stores/storeReviews'

export function useReviews() {
  const storeReviews = useStoreReviews()
  const route = useRoute()

  const {
    fetchingListingReviews,
    listingReviews,
    reviewsPage,
    reviewsTabRef,
    reviewSearch,
    reviewSearchOrder,
    creatingReview,
    showCreateReviewDialog,
    updatingReview,
    showUpdateReviewDialog,
    selectedReviewForUpdate,
    reviewText,
    ratingClean,
    ratingLocation,
    ratingHelpful,
    ratingResponsive,
    ratingValue,
    reviewSelf,
    searchReviewsOrder,
    showSearchReviewsOrder,
  } = storeToRefs(storeReviews)

  /**
   * Detect when the pagination has changed for Reviews.
   */
  watch(() => reviewsPage.value.pageCurrent, async (newVal, oldVal) => {
    if (newVal !== oldVal && !fetchingListingReviews.value) {
      await fetchListingReviews()

      // Go to the top of the Reviews Tab
      reviewsTabRef.value.$el.scrollIntoView({
        behavior: 'smooth',
      })
    }
  }, { deep: true })

  /**
  * Detect when the Search Text has changed for Reviews.
  */
  watch(() => reviewSearchOrder.value, async (newVal, oldVal) => {
    if (newVal !== oldVal && !fetchingListingReviews.value) {
      await fetchListingReviews()
    }
  }, { deep: true })

  /**
  * Detect when the Search Text has changed for Reviews (for mobile).
  */
  watch(() => reviewSearch.value, async (newVal, oldVal) => {
    if (newVal !== oldVal && !fetchingListingReviews.value) {
      await fetchListingReviews()
    }
  }, { deep: true })

  /**
  * Detect when the Search Order has changed for Reviews.
  */
  watch(() => reviewSearchOrder.value, async (newVal, oldVal) => {
    if (newVal !== oldVal && !fetchingListingReviews.value) {
      await fetchListingReviews()
      showSearchReviewsOrder.value = false
    }
  }, { deep: true })

  /**
  * Detect when the Search Order has changed for Reviews (for mobile).
  */
  watch(() => searchReviewsOrder.value, async (newVal, oldVal) => {
    if (newVal !== oldVal && !fetchingListingReviews.value) {
      await fetchListingReviews()
      showSearchReviewsOrder.value = false
    }
  }, { deep: true })

  const fetchListingReviews = async () => {
    await storeReviews.fetchListingReviews(
      route.params.uuid,
      LISTING_REVIEWS_PAGE_SIZE,
      LISTING_REVIEWS_PAGE_SIZE_DISPLAY_MAX,
    )
  }

  const createListingReview = async (uuid, form) => {
    return await storeReviews.createListingReview(uuid, form)
  }

  const updateListingReview = async (listingUuid, form) => {
    return await storeReviews.updateListingReview(listingUuid, form)
  }

  const getListingReviewSelf = async () => {
    return await storeReviews.getListingReviewSelf(route.params.uuid)
  }

  const deleteReview = async () => {
    return await storeReviews.deleteReview(route.params.uuid)
  }

  return {
    createListingReview,
    updateListingReview,
    fetchListingReviews,
    getListingReviewSelf,
    deleteReview,
    fetchingListingReviews,
    listingReviews,
    reviewsPage,
    reviewSearch,
    reviewSearchOrder,
    showCreateReviewDialog,
    creatingReview,
    updatingReview,
    showUpdateReviewDialog,
    selectedReviewForUpdate,
    reviewText,
    ratingClean,
    ratingLocation,
    ratingHelpful,
    ratingResponsive,
    ratingValue,
    reviewSelf,
    searchReviewsOrder,
    showSearchReviewsOrder,
  }
}
