<template>
  <q-layout view="hHh lpR fff">
    <q-header elevated class="bg-white">
      <div class="row justify-center">
        <div class="col-md-12 col-lg-11 col-xl-8">
          <q-toolbar>
            <q-toolbar-title>
              <div class="flex">
                <router-link :to="{ name: 'publicListingsView' }">
                  <q-avatar size="45px">
                    <!-- <q-img src="@/assets/gold-no-bg-logo-circle.webp" /> -->
                    <q-img src="@/assets/gold-logo-circle-xxx-small.webp" />
                  </q-avatar>
                </router-link>
                <q-btn flat round dense icon="menu" color="grey-6" class="q-ml-sm">
                  <q-menu :offset="[0, 0]">
                    <div class="row text-left no-wrap q-ml-md q-py-sm" style="width: 150px">
                      <div class="col">
                        <div>
                          <q-btn flat dense icon="list" label="Listings" color="grey-10"
                            :to="{ name: 'publicListingsView' }" />
                        </div>
                        <q-separator class="q-my-sm" />
                        <div>
                          <q-btn flat dense icon="info" label="About" color="grey-10" href="https://info.roomb.io"
                            target="_blank" />
                        </div>
                        <q-separator class="q-my-sm" />
                        <div>
                          <q-btn flat dense icon="contact_support" label="Contact" color="grey-10"
                            :to="{ name: 'publicContactView' }" />
                        </div>
                        <!--
                        <q-separator class="q-my-sm" />
                        <div>
                          <q-btn flat dense icon="price_change" label="Pricing" color="grey-10"
                            :to="{ name: 'publicListingsView' }" />
                        </div>
                        -->
                      </div>
                    </div>
                  </q-menu>
                </q-btn>
              </div>


            </q-toolbar-title>

            <q-btn flat round dense icon="person" :color="token?.length ? 'blue' : 'grey-8'">
              <q-menu :offset="[150, 5]">
                <template v-if="!token?.length">
                  <div class="row text-center no-wrap q-py-sm" style="width: 120px">
                    <div class="col">
                      <div>
                        <q-btn flat dense icon="logout" label="Log In" color="grey-10"
                          :to="{ name: 'publicLoginView' }" />
                      </div>
                      <div>
                        <q-btn class="q-mt-sm" flat dense icon="person_add_alt" label="Sign Up" color="grey-10"
                          :to="{ name: 'publicRegisterSocialView' }" />
                      </div>
                    </div>
                  </div>
                </template>

                <template v-else>
                  <div class="row justify-center no-wrap q-py-sm" style="width: 350px">
                    <div class="col q-ml-sm">
                      <q-btn class="q-mt-sm" flat dense icon="info" label="Profile" color="grey-8"
                        @click="onNavigateToProfile('profile')" />
                      <q-btn @click="onNavigateToProfile('bookmarks')" class="q-mt-sm" flat dense icon="bookmark"
                        label="Bookmarks" color="grey-8" />
                      <q-btn @click="onNavigateToProfile('reviews')" class="q-mt-sm" flat dense icon="rate_review"
                        label="Reviews" color="grey-8" />
                    </div>
                    <q-separator vertical inset class="q-mx-sm" />
                    <div class="col-auto text-center self-center q-mx-sm q-my-sm">
                      <q-avatar>
                        <img :src='getGravatarUrl(user.uuid)' />
                      </q-avatar>
                      <div class="text-caption q-mt-md q-mb-xs">{{ truncate(user.username, 20) }}</div>
                      <q-btn color="primary" outline label="Logout" push size="sm" @click="logout" />
                    </div>
                  </div>
                </template>
              </q-menu>
            </q-btn>
          </q-toolbar>
        </div>
      </div>
    </q-header>
    <div class="q-pb-md">
      <slot name="my-router-view"></slot>
    </div>

    <q-footer class="bg-white">
      <q-separator />
      <div class="q-my-xs text-center text-grey-9 text-weight-regular">
        <div>
          <q-avatar size="30px">
            <img class="text-centers" src="@/assets/gold-logo-circle-xxx-small.webp" />
          </q-avatar>
          RoomB Technologies <q-badge color="brown-10">v{{ roombeeVersion }}</q-badge>
        </div>
      </div>
    </q-footer>
  </q-layout>
</template>

<script setup>
import { onMounted } from 'vue'
import { useAuth } from '@/composables/auth'
import { useRouter } from 'vue-router'

import { useGuestProfile } from '@/composables/guestProfile'
import { getGravatarUrl } from '@/utils/avatar'

import { truncate } from '@/utils/string'
import { inject } from 'vue'

const roombeeVersion = inject('roombeeVersion')

const router = useRouter()

const { profileTab } = useGuestProfile()

const { logOut, getToken, getUser, token, user } = useAuth()

const logout = async () => {
  await logOut()
  window.location.reload()
}

onMounted(() => {
  getToken()
  getUser()
})

const onNavigateToProfile = (tabName) => {
  profileTab.value = tabName
  router.push({ 'name': 'guestProfileView' })
}

// Useful to debug responsiveness
//
// import { useQuasar } from 'quasar'
// import { watch } from 'vue'
// const $q = useQuasar()
// watch(() => $q.screen.name, (newValue, oldValue) => {
//   console.log('screen: ', newValue)
// })
</script>
