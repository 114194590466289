import { defineStore } from 'pinia'

import { client } from '@/services/request'

export const useStoreMeta = defineStore('storeMeta', {
  state: () => {
    return {
      countries: [],
      states: [],
      cities: [],
      // neighbourhoods: [],
      propertyTypes: [],
      propertyAttributeAvailabilityTypes: [],
      parkingTypes: [],
      currencyTypes: [],
      feeTypes: [],
      taxTypes: [],
      discountTypes: [],
      fetchingCountries: false,
      fetchingDiscountTypes: false,
      fetchingStates: false,
      fetchingCities: false,
      fetchingNeighbourhoods: false,
      fetchingPropertyTypes: false,
      fetchingPropertyAttributeAvailabilityTypes: false,
      fetchingParkingTypes: false,
      fetchingCurrencyTypes: false,
      fetchingFeeTypes: false,
      fetchingTaxTypes: false,
    }
  },

  actions: {
    async fetchCountries() {
      const url = `meta/countries/`

      this.fetchingCountries = true
      const response = await client.anonRequest(url, { flat: true })
      this.fetchingCountries = false

      if (response.status === 200) {
        this.countries = response.data.map(c => c.name)
      } else {
        // TODO-FEATURE: Handle unsuccessful responses
      }
    },

    async fetchStates() {
      const url = `meta/states/`

      this.fetchingStates = true
      const response = await client.anonRequest(url, { flat: true })
      this.fetchingStates = false
      if (response.status === 200) {
        this.states = response.data.map(s => s.name)
      } else {
        // TODO-FEATURE: Handle unsuccessful responses
      }
    },

    async fetchCities() {
      const url = `meta/cities/`

      this.fetchingCities = true
      const response = await client.anonRequest(url, { flat: true })
      this.fetchingCities = false

      if (response.status === 200) {
        this.cities = response.data.map(c => c.name)
      } else {
        // TODO-FEATURE: Handle unsuccessful responses
      }
    },

    async fetchNeighbourhoods(cityName) {
      const url = `meta/neighbourhoods/?city_name=${cityName}`

      this.fetchingNeighbourhoods = true
      const response = await client.anonRequest(url, { flat: true })
      this.fetchingNeighbourhoods = false

      if (response.status === 200) {
        return { status: response.status, data: response.data.map(n => n.name) }
      } else {
        // TODO-FEATURE: Handle unsuccessful responses
      }
    },

    async fetchPropertyTypes() {
      const url = `meta/property_types/`

      this.fetchingPropertyTypes = true
      const response = await client.anonRequest(url, { flat: true })
      this.fetchingPropertyTypes = false

      if (response.status === 200) {
        this.propertyTypes = response.data
      } else {
        // TODO-FEATURE: Handle unsuccessful responses
      }
    },

    async fetchPropertyAttributeAvailabilityTypes() {
      const url = `meta/property_attribute_availability_types/`

      this.fetchingPropertyAttributeAvailabilityTypes = true
      const response = await client.anonRequest(url, { flat: true })
      this.fetchingPropertyAttributeAvailabilityTypes = false

      if (response.status === 200) {
        this.propertyAttributeAvailabilityTypes = response.data.map(d => d.name)
      } else {
        // TODO-FEATURE: Handle unsuccessful responses
      }
    },

    async fetchParkingTypes() {
      const url = `meta/parking_types/`

      this.fetchingParkingTypes = true
      const response = await client.anonRequest(url, { flat: true })
      this.fetchingParkingTypes = false

      if (response.status === 200) {
        this.parkingTypes = response.data
      } else {
        // TODO-FEATURE: Handle unsuccessful responses
      }
    },

    async fetchCurrencyTypes() {
      const url = `meta/currency_types/`

      this.fetchingCurrencyTypes = true
      const response = await client.anonRequest(url, { flat: true })
      this.fetchingCurrencyTypes = false

      if (response.status === 200) {
        this.currencyTypes = response.data.map(d => d.name)
      } else {
        // TODO-FEATURE: Handle unsuccessful responses
      }
    },

    async fetchFeeTypes() {
      const url = `meta/fee_types/`

      this.fetchingFeeTypes = true
      const response = await client.anonRequest(url, { flat: true })
      this.fetchingFeeTypes = false

      if (response.status === 200) {
        this.feeTypes = response.data
      } else {
        // TODO-FEATURE: Handle unsuccessful responses
      }
    },

    async fetchDiscountTypes() {
      const url = `meta/discount_types/`

      this.fetchingDiscountTypes = true
      const response = await client.anonRequest(url, { flat: true })
      this.fetchingDiscountTypes = false

      if (response.status === 200) {
        this.discountTypes = response.data
      } else {
        // TODO-FEATURE: Handle unsuccessful responses
      }
    },

    async fetchTaxTypes() {
      const url = `meta/tax_types/`

      this.fetchingTaxTypes = true
      const response = await client.anonRequest(url, { flat: true })
      this.fetchingTaxTypes = false

      if (response.status === 200) {
        this.taxTypes = response.data.map(d => d.name)
      } else {
        // TODO-FEATURE: Handle unsuccessful responses
      }
    },
  }
})
