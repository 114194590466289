import { watch } from 'vue'
import { storeToRefs } from 'pinia'
import { useRoute } from 'vue-router'
import { useStorePublicHost } from '@/stores/storePublicHost'

import {
  HOST_PROFILE_PAGE_SIZE_LISTINGS,
  HOST_PROFILE_PAGE_SIZE_LISTINGS_DISPLAY_MAX,
  HOST_PROFILE_PAGE_SIZE_REVIEWS,
  HOST_PROFILE_PAGE_SIZE_REVIEWS_DISPLAY_MAX,
} from '@/constants/pagination.js'

export function usePublicHost() {
  const route = useRoute()
  const storePublicHost = useStorePublicHost()
  const {
    tab,
    hostUuid,
    hostDetails,
    fetchingHostDetails,
    fetchingListings,
    fetchingReviews,
    listings,
    listingsPage,
    reviews,
    reviewsPage,
  } = storeToRefs(storePublicHost)

  /**
   * Detect when the pagination has changed for Host Listings.
   */
  watch(() => listingsPage.value.pageCurrent, async (newVal, oldVal) => {
    if (newVal !== oldVal && !fetchingListings.value) {
      await fetchHostProfileListings()

      window.scrollTo({ top: 100, left: 0, behavior: 'smooth' })
    }
  }, { deep: true })

  /**
   * Detect when the pagination has changed for Host Listing Reviews.
   */
  watch(() => reviewsPage.value.pageCurrent, async (newVal, oldVal) => {
    if (newVal !== oldVal && !fetchingListings.value) {
      await fetchHostProfileListingReviews()

      window.scrollTo({ top: 100, left: 0, behavior: 'smooth' })
    }
  }, { deep: true })

  const fetchHostDetails = async () => {
    hostUuid.value = route.params.uuid
    await storePublicHost.fetchHostDetails()
  }

  const fetchHostProfileListings = async () => {
    hostUuid.value = route.params.uuid
    await storePublicHost.fetchListings(
      HOST_PROFILE_PAGE_SIZE_LISTINGS,
      HOST_PROFILE_PAGE_SIZE_LISTINGS_DISPLAY_MAX,
    )
  }

  const fetchHostProfileListingReviews = async () => {
    hostUuid.value = route.params.uuid
    await storePublicHost.fetchHostProfileListingReviews(
      HOST_PROFILE_PAGE_SIZE_REVIEWS,
      HOST_PROFILE_PAGE_SIZE_REVIEWS_DISPLAY_MAX,
    )
  }

  return {
    fetchHostDetails,
    fetchHostProfileListings,
    fetchHostProfileListingReviews,
    tab,
    hostUuid,
    hostDetails,
    fetchingHostDetails,
    fetchingReviews,
    fetchingListings,
    listings,
    listingsPage,
    reviews,
    reviewsPage,
  }
}
