<template>
  <q-tab-panel ref="mapTabPanelRef">
    <div class="row">
      <!-- <div v-if="user.is_ops">
        <div class="row q-mb-md">
          <div class="col-4 q-pr-sm">
            <q-input maxlength="9" counter autofocus dense type='number'
              v-model.number="publicListingDetails.location.latitude" filled hint="Latitude Coordinate"
              @blur="updateCoordinates" />
          </div>
          <div class="col-4">
            <q-input maxlength="9" counter autofocus dense type='number'
              v-model.number="publicListingDetails.location.longitude" filled hint="Longitude Coordinate"
              @blur="updateCoordinates" />
          </div>
          <div class="col q-mt-sm text-right">
            <q-btn dense @click="saveLocation" :disable="opsUpdatingLocation" :loading="opsUpdatingLocation">Save
              Location</q-btn>
          </div>
        </div>
      </div> -->
      <div class="text-body1" v-if="!user.is_ops">
        This property is located in <b class="text-grey-8">
          {{ publicListingDetails.location.city }}, {{ publicListingDetails.location.country }}</b>.
        <span v-if="publicListingDetails.location.neighbourhood">
          It is in the neighbourhood of <b class="text-grey-8">{{ publicListingDetails.location.neighbourhood }}</b>.
        </span>
      </div>

      <div v-if="user.is_ops" class="text-body1">
        This property is located in
        <b class="text-grey-8" @click="showCity">
          {{ publicListingDetails.location.city }}, {{ publicListingDetails.location.country }}
        </b>
        <span>
          <q-popup-edit ref="cityPopupRef" :disable="opsUpdatingLocation" v-model="city" v-slot="scope" persistent
            style="width: 400px;">
            <q-select label="Emirate" :options="cities" autofocus autogrow dense v-model="scope.value"
              :model-value="scope.value" hint="Update location city for this listing.">
              <template v-slot:after>
                <q-btn :disable="opsUpdatingLocation" flat dense color="negatives" icon="cancel"
                  @click="cancel(scope, 'city')" />
                <q-btn flat dense color="green" icon="check_circle" @click="setValue(scope, 'city')"
                  :disable="opsUpdatingLocation" />
              </template>
            </q-select>
          </q-popup-edit>
        </span>.

        <span v-if="publicListingDetails.location.neighbourhood">
          It is in the neighbourhood of
          <b class="text-grey-8" @click="showNeighbourhood">{{ publicListingDetails.location.neighbourhood }}</b>
          <span>
            <q-popup-edit ref="neighbourhoodPopupRef" :disable="opsUpdatingLocation" v-model="neighbourhood"
              v-slot="scope" persistent style="width: 400px;">
              <q-select label="Neighbourhood" :options="neighbourhoodOptions" autofocus autogrow dense
                v-model="scope.value" :model-value="scope.value" hint="Update location neighbourhood for this listing.">
                <template v-slot:after>
                  <q-btn :disable="opsUpdatingLocation" flat dense color="negatives" icon="cancel"
                    @click="cancel(scope, 'neighbourhood')" />
                  <q-btn flat dense color="green" icon="check_circle" @click="setValue(scope, 'neighbourhood')"
                    :disable="opsUpdatingLocation" />
                </template>
              </q-select>
            </q-popup-edit>
          </span>.
        </span>
        <span v-else @click="showCity">Click to Update location city</span>
      </div>

      <div v-if="!user.is_ops && publicListingDetails.location.building_name" class="text-body1 q-my-sm">
        The property is in the <b class="text-grey-8">{{ publicListingDetails.location.building_name }}</b> building.
      </div>

      <div v-if="user.is_ops">
        <div v-if="publicListingDetails.location.building_name" class="text-body1 q-my-sm" @click="showBuildingEdit">
          The property is in the <b class="text-grey-8">{{ publicListingDetails.location.building_name }}</b> building.
        </div>
        <div v-else class="text-body1 q-my-sm  text-red-5" @click="showBuildingEdit">
          Click to Update location building
        </div>
        <div>
          <q-popup-edit ref="buildingNamePopupRef" :disable="opsUpdatingLocation" v-model="building_name" v-slot="scope"
            persistent style="width: 400px;">
            <q-input maxlength="100" counter autofocus autogrow dense v-model="scope.value" :model-value="scope.value"
              hint="Update building name for this listing.">
              <template v-slot:after>
                <q-btn :disable="opsUpdatingLocation" flat dense color="negatives" icon="cancel"
                  @click="cancel(scope, 'building_name')" />
                <q-btn flat dense color="green" icon="check_circle" @click="setValue(scope, 'building_name')"
                  :disable="opsUpdatingLocation" />
              </template>
            </q-input>
          </q-popup-edit>
        </div>
      </div>

      <div v-if="!user.is_ops && publicListingDetails.location.landmark_name" class="text-body1 q-my-sm">
        The nearest landmark is <b class="text-grey-8">{{ publicListingDetails.location.landmark_name }}</b>.
      </div>

      <div v-if="user.is_ops" class="text-body1 q-ml-sm q-my-sm">
        <div v-if="publicListingDetails.location.landmark_name">
          The nearest landmark is
          <b class="text-grey-8" @click="showLandmarkEdit">{{ publicListingDetails.location.landmark_name }}</b>
        </div>
        <div v-else class="text-red-5" @click="showLandmarkEdit">
          Click to update landmark.
        </div>
        <div>
          <q-popup-edit ref="landmarkNamePopupRef" v-model="landmark_name" :disable="opsUpdatingLocation" v-slot="scope"
            persistent style="width: 400px;">
            <q-input maxlength="100" counter autofocus autogrow dense v-model="scope.value" :model-value="scope.value"
              hint="Update landmark name for this listing.">
              <template v-slot:after>
                <q-btn :disable="opsUpdatingLocation" flat dense color="negatives" icon="cancel"
                  @click="cancel(scope, 'landmark_name')" />
                <q-btn flat dense color="green" icon="check_circle" @click="setValue(scope, 'landmark_name')"
                  :disable="opsUpdatingLocation" />
              </template>
            </q-input>
          </q-popup-edit>
        </div>.
      </div>


    </div>
    <div class="row" v-if="center.lat && center.lng">
      <GoogleMap :api-key="apiKey" style="width: 100%; height: 500px" :center="center" :zoom="12">
        <Marker :options="{ position: center, draggable: true }" @dragend="dragEnd">
        </Marker>
      </GoogleMap>
    </div>
  </q-tab-panel>
</template>


<script setup>
import { onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'

import { GoogleMap, Marker } from "vue3-google-map"

import { DEFAULT_COORDINATES } from '@/constants/location'

const apiKey = import.meta.env.VITE_ROOMBEE_GOOGLE_MAPS_API_KEY

import { usePublicListings } from '@/composables/publicListings'
import { useAuth } from '@/composables/auth'
import { useOpsListings } from '@/composables/opsListings'
import { useMeta } from '@/composables/meta'

const route = useRoute()

const {
  cities,
  fetchCities,
  fetchNeighbourhoods,
} = useMeta()

const {
  opsUpdateLocation,
  opsUpdatingLocation,
} = useOpsListings()

const { user } = useAuth()

import { notifySorry, notifySuccess } from '@/notifications/events'

const center = ref({})
const latitude = ref()
const longitude = ref()
const mapTabPanelRef = ref(null)

const city = ref('')
const neighbourhood = ref('')
const building_name = ref('')
const landmark_name = ref('')

const buildingNamePopupRef = ref()
const landmarkNamePopupRef = ref()
const cityPopupRef = ref()
const neighbourhoodPopupRef = ref()

const {
  publicListingDetails,
  fetchPublicListingDetails,
} = usePublicListings()

const neighbourhoodOptions = ref([])


onMounted(async () => {
  // Try to center the map view
  // mapTabPanelRef.value.$el.scrollIntoView({ behavior: 'smooth' })
  const top = mapTabPanelRef.value.$el.getBoundingClientRect().top
  // offset the header
  window.scrollTo({ top: top - 50, behavior: 'smooth' })

  const latitude = Number(publicListingDetails.value.location.latitude) || DEFAULT_COORDINATES.lat
  const longitude = Number(publicListingDetails.value.location.longitude) || DEFAULT_COORDINATES.lng
  center.value = { lat: latitude, lng: longitude }

  await fetchCities()
  city.value = publicListingDetails.value.location.city

  await updateNeighbourhoodsData()

  building_name.value = publicListingDetails.value.location.building_name
  landmark_name.value = publicListingDetails.value.location.landmark_name
})

const showBuildingEdit = () => {
  buildingNamePopupRef.value.show()
}
const showLandmarkEdit = () => {
  landmarkNamePopupRef.value.show()
}
const showCity = () => {
  cityPopupRef.value.show()
}
const showNeighbourhood = () => {
  neighbourhoodPopupRef.value.show()
}

const updateNeighbourhoodsData = async () => {
  const neighbourhoodData = await fetchNeighbourhoods(publicListingDetails.value.location.city)
  if (neighbourhoodData.status === 200) {
    neighbourhoodOptions.value = neighbourhoodData.data
  }
  neighbourhood.value = publicListingDetails.value.location.neighbourhood
}

const dragEnd = async (e) => {
  if (!user.value.is_ops) {
    return
  }

  latitude.value = Number(e.latLng.lat().toString().slice(0, 9))
  longitude.value = Number(e.latLng.lng().toString().slice(0, 9))

  if (user.value.is_ops) {
    await updateCoordinates()
  }
}

const setValue = (scope, field) => {
  scope.set()

  // On certain fields, save immediately. Coordinates are saved differently (via dragend())
  if (['city', 'state', 'building_name', 'landmark_name', 'neighbourhood'].includes(field)) {
    const form = { [field]: scope.value }
    saveLocation(form)
  }
}

const cancel = (scope, field) => {
  scope.cancel()
}

/**
 * Max len for coords is 9 digits + 1 for the period.
 */
const updateCoordinates = async () => {
  if (!latitude.value || !longitude.value) {
    return
  }

  if (latitude.value) {
    latitude.value = Number(latitude.value.toString().slice(0, 9))
  }

  if (longitude.value) {
    longitude.value = Number(longitude.value.toString().slice(0, 9))
  }

  center.value = { lat: latitude.value, lng: longitude.value }

  const form = { latitude: latitude.value, longitude: longitude.value }
  await saveLocation(form)
}


const saveLocation = async (form) => {
  if (!user.value.is_ops) {
    return
  }

  const result = await opsUpdateLocation(form, route.params.uuid)

  if (result.status === 200) {
    notifySuccess('Successfully updated the Location.')
    await fetchPublicListingDetails(route.params.uuid)
    await updateNeighbourhoodsData()
  } else {
    notifySorry(`Error while updating the Location: ${JSON.stringify(result.data)}`)
  }
}
</script>
