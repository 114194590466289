import { defineStore } from 'pinia'

import { client } from '@/services/request'

export const useStoreContact = defineStore('storeContact', {
  state: () => {
    return {
      sendingMessage: false,
    }
  },

  actions: {
    async sendMessage(form) {
      const url = `contacts/`

      this.sendingMessage = true
      const result = await client.anonRequest(url, { method: 'POST', form })
      this.sendingMessage = false

      return result
    },
  }
})
