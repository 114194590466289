<template>
  <q-layout>
    <q-page-container>
      <q-page padding>
        <div class="row justify-center">
          <div class="col-6 col-sm-8 col-xs-12">
            <q-card>
              <q-card-section>
                <div class="text-center">
                  <q-img style="width: 500px" src="@/assets/login-logo-horizontal-x-small.png"></q-img>
                </div>
              </q-card-section>

              <router-view />
            </q-card>
          </div>
        </div>
      </q-page>
    </q-page-container>

    <q-footer class="bg-white">
      <div class="text-center text-grey-9 text-weight-regular">
        <q-avatar size="30px">
          <img class="text-centers" src="@/assets/gold-bee-xxx-small.webp" style="background-color: rgb(0 76 113);" />
        </q-avatar>
        RoomB Technologies L.L.C
      </div>

    </q-footer>

  </q-layout>
</template>

<script setup>
import { onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { useQuasar } from 'quasar'

const $q = useQuasar()
const router = useRouter()

onMounted(() => {
  if ($q.platform.is.mobile) {
    router.push({ name: 'hostPlaceholderMobileView' })
  }
})
</script>

<style scoped>
.drawer-active-link {
  color: white;
  background: #026791;
}
</style>
