<template>
  <q-card class="q-mt-sm" flat>
    <div class="row justify-center text-grey-8 text-center q-my-xl">
      <div class="col text-grey-8 text-h6 text-weight-light">
        RoomB is your free to use search <br>engine to find short-term rentals <br>across the United Arab Emirates!
      </div>
    </div>

    <div class="row justify-center q-mt-xl">
      <div class="text-center text-grey-8 text-body1 q-mb-md q-mx-sm">
        If you'd like to get in touch, use our<br>
        <router-link style="text-decoration: none; color: inherit;" class="text-primary"
          :to="{ name: 'publicContactMobileView' }">
          Contact Page
        </router-link>
        to reach out to us!
      </div>
    </div>

    <div class="row justify-center q-mt-md q-mb-md">
      View our
      <router-link style="text-decoration: none; color: inherit;" class="text-primary"
        :to="{ name: 'publicTermsOfServiceMobileView' }">
        &nbsp;Terms of Service
      </router-link>
      &nbsp;and
      <router-link style="text-decoration: none; color: inherit;" class="text-primary"
        :to="{ name: 'publicPrivacyPolicyMobileView' }">
        &nbsp;Privacy Policy
      </router-link>.
    </div>
  </q-card>
</template>

<script setup>
import { onMounted } from 'vue'
import { useQuasar } from 'quasar'
import { useRouter } from 'vue-router'

const $q = useQuasar()
const router = useRouter()

onMounted(async () => {
  // If user is on desktop, redirect to desktop view.
  if ($q.platform.is.desktop) {
    router.push({ name: 'publicAboutView' })
  }
})
</script>
