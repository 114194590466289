<template>
  <div v-for="(row, index) in chunkedListings" :key="index">
    <q-card class="q-mx-sm q-mb-md" :style="'max-width: ' + maxWidth + 'px;'">
      <q-skeleton height="200px" square />
      <q-card-section>
        <q-item-section>
          <q-item-label>
            <div class="row">
              <div class="col-10 self-center q-mr-sm">
                <q-skeleton type="rect" />
              </div>
              <div class="col">
                <q-skeleton type="QAvatar" />
              </div>
            </div>
          </q-item-label>
          <q-item-label caption>
            <q-skeleton type="text" />
            <q-skeleton type="text" />
          </q-item-label>
        </q-item-section>
      </q-card-section>
    </q-card>
  </div>
</template>

<script setup>
import { computed } from 'vue'

const props = defineProps({
  maxWidth: {
    type: Number,
    required: false,
    default: 400,
  },
  count: {
    type: Number,
    required: false,
    default: 1,
  },
})

const chunkedListings = computed(() => {
  return Array.from(Array(props.count).keys())
})
</script>
