<template>
  <q-page>
    <div>
      <div class="row q-mt-lg q-mb-md justify-center">
        <div class="col-11">
          <q-card>
            <q-card-section>
              <div class="text-h5 text-center text-grey-8">
                Please view this page on your Laptop or Computer.
              </div>
            </q-card-section>
            <q-card-section>
              <div class="text-body1 text-center">
                The host listing management portal is currently only available on a laptop or
                desktop computer.
              </div>
              <div class="text-body1 text-center q-mt-sm">
                The mobile version of the host portal is currently under development,
                until then, please view this page on a laptop or desktop.
              </div>
              <div class="text-body2 text-center q-mt-md">
                If you require assistance or have any questions, please
                <router-link style="text-decoration: none; color: inherit;" class="text-primary"
                  :to="{ name: 'publicContactMobileView' }">
                  reach out to us
                </router-link>
                or send us an email at {{ supportEmail }}
              </div>
            </q-card-section>
            <q-card-section>
              <div class="text-center">
                <router-link style="text-decoration: none; color: inherit;" class="text-primary"
                  :to="{ name: 'publicContactView' }">
                  Go to Contact Page
                </router-link>
              </div>
            </q-card-section>
          </q-card>
        </div>
      </div>
    </div>
  </q-page>
</template>

<script setup>
const supportEmail = import.meta.env.VITE_ROOMBEE_SUPPORT_EMAIL
</script>
