import { Notify } from 'quasar'

import {
  CLOSE,
  CONTACT_SUPPORT,
  LOG_IN,
} from '@/notifications/actions'

export const TRY_LATER_OR_CONTACT_US = 'Please try again later. If the issue persists, reach out to us for help.'
export const NETWORK_ERROR = `There was a network error. ${TRY_LATER_OR_CONTACT_US}`
export const SERVER_ERROR = `Sorry, there was an unexpected server error. ${TRY_LATER_OR_CONTACT_US}`

export function notifyNetworkError() {
  Notify.create({
    message: NETWORK_ERROR,
    color: 'rb-notify__error-background-color',
    multiLine: true,
    icon: 'error',
    actions: [CONTACT_SUPPORT]
  })
}

export function notifyLogIn() {
  Notify.create({
    message: 'Please log in to perform this action.',
    color: 'rb-notify__info-background-color',
    icon: 'warning',
    actions: [LOG_IN]
  })
}

export function notifySorry(message) {
  Notify.create({
    message,
    color: 'rb-notify__not-allowed-background-color',
    icon: 'error',
    actions: [CLOSE]
  })
}

export function notifySuccess(message) {
  Notify.create({
    message,
    color: 'rb-notify__success-background-color',
    icon: 'check_cirle',
    actions: [CLOSE]
  })
}

export function notifyInfo(message) {
  Notify.create({
    message,
    color: 'rb-notify__info-background-color',
    icon: 'check',
    actions: [CLOSE]
  })
}

export function notifyThankYou(message) {
  Notify.create({
    message,
    color: 'rb-notify__success-background-color',
    icon: 'check',
    actions: [CLOSE]
  })
}

export function notifyUnexpectedError(statusCode) {
  const message = `${SERVER_ERROR} Error: ${statusCode}`
  Notify.create({
    message,
    color: 'rb-notify__error-background-color',
    multiLine: true,
    icon: 'error',
    actions: [CONTACT_SUPPORT]
  })
}

export function notifyHostUpdateValueSuccess(message) {
  Notify.create({
    message,
    color: 'rb-notify__success-background-color',
    multiLine: true,
    icon: 'check',
    position: 'center',
    timeout: 2000,
    actions: [CLOSE]
  })
}

export function notifyHostUpdateValueError(message) {
  Notify.create({
    message,
    color: 'rb-notify__soft-error-background-color',
    multiLine: true,
    icon: 'warning',
    position: 'center',
    timeout: 0,
    actions: [CLOSE],
    attrs: {
      style: 'max-width: 600px'
    },
  })
}
