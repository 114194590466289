<template>
  <q-card>
    <q-tabs ref="reviewsTabRef" v-model="tab" dense class="text-grey" active-color="primary" indicator-color="primary"
      align="justify" narrow-indicator>
      <q-tab name="description" label="Description" />
      <q-tab name="reviews" :label="'Reviews (' + reviewsPage.pageTotal + ')'" />
      <q-tab name="map" label="Map" />
    </q-tabs>

    <q-separator />

    <q-tab-panels v-model="tab" animated>
      <DescriptionTabPanel name="description" />
      <ReviewItemsTabPanel name="reviews" />
      <MapTabPanel name="map" />
    </q-tab-panels>
  </q-card>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import { useRoute } from 'vue-router'

import { storeToRefs } from 'pinia'
import { useStoreReviews } from '@/stores/storeReviews'
import { useReviews } from '@/composables/reviews'
import DescriptionTabPanel from '@/components/listingDetailsPanel/panels/DescriptionTabPanel.vue'
import ReviewItemsTabPanel from '@/components/listingDetailsPanel/panels/ReviewItemsTabPanel.vue'
import MapTabPanel from '@/components/listingDetailsPanel/panels/MapTabPanel.vue'

const route = useRoute()
// const router = useRouter()

const storeReviews = useStoreReviews()
const { reviewsTabRef } = storeToRefs(storeReviews)

const {
  fetchListingReviews,
  reviewsPage,
} = useReviews()


const tab = ref('description')

// onBeforeMount(() => {
//   if (!route.query?.tab) {
//     router.push({ name: 'publicListingsDetailsView', query: { tab: 'description' } })
//   }
// })

onMounted(async () => {
  await fetchListingReviews()

  const tabName = route.query?.tab

  if (tabName) {
    tab.value = tabName
    window.scrollBy({ top: 600, left: 0, behavior: 'smooth' })
  }
})
</script>
