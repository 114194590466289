import { createRouter, createWebHistory } from 'vue-router'

import LayoutHost from '@/components/layouts/LayoutHost.vue'
import LayoutPublicListing from '@/components/layouts/LayoutPublicListing.vue'
import LayoutHostAccount from '@/components/layouts/LayoutHostAccount.vue'
import LayoutPublicAccount from '@/components/layouts/LayoutPublicAccount.vue'
import LayoutPublicHost from '@/components/layouts/LayoutPublicHost.vue'
import LayoutPublicListingGrid from '@/components/layouts/LayoutPublicListingGrid.vue'

import HostListingsView from '../views/host/HostListingsView.vue'
import HostNewListingView from '../views/host/HostNewListingView.vue'
import HostListingsDetailsView from '../views/host/HostListingsDetailsView.vue'
import HostProfileView from '@/views/host/HostProfileView.vue'
import HostHelpView from '@/views/host/HostHelpView.vue'
import HostVerifyEmailView from '@/views/host/HostVerifyEmailView.vue'
import HostLoginView from '@/views/host/HostLoginView.vue'
import HostRegisterView from '@/views/host/HostRegisterView.vue'
import HostRegisterSuccessView from '@/views/host/HostRegisterSuccessView.vue'

import PublicRegisterSocialView from '@/views/public/PublicRegisterSocialView.vue'
import PublicRegisterManualView from '@/views/public/PublicRegisterManualView.vue'
import PublicRegisterSuccessView from '@/views/public/PublicRegisterSuccessView.vue'
import PublicListingsGridView from '@/views/public/PublicListingsGridView.vue'
import PublicListingDetailsView from '@/views/public/PublicListingDetailsView.vue'
import PublicVerifyEmailView from '@/views/public/PublicVerifyEmailView.vue'
import PublicLoginView from '@/views/public/PublicLoginView.vue'
import PublicContactView from '@/views/public/PublicContactView.vue'
import PublicAboutView from '@/views/public/PublicAboutView.vue'
import PublicHostDetailsView from '@/views/public/PublicHostDetailsView.vue'
import PublicTermsOfServiceView from '@/views/public/PublicTermsOfServiceView.vue'
import PublicPrivacyPolicyView from '@/views/public/PublicPrivacyPolicyView.vue'

/** Mobile layouts and views */
import LayoutPublicListingMobile from '@/components/layouts/mobile/LayoutPublicListingMobile.vue'
import LayoutHostMobile from '@/components/layouts/mobile/LayoutHostMobile.vue'

import PublicListingsMobileView from '@/views/public/mobile/PublicListingsMobileView.vue'
import PublicListingDetailsMobileView from '@/views/public/mobile/PublicListingDetailsMobileView.vue'
import PublicAboutMobileView from '@/views/public/mobile/PublicAboutMobileView.vue'
import PublicContactMobileView from '@/views/public/mobile/PublicContactMobileView.vue'
import GuestProfileView from '@/views/guest/GuestProfileView.vue'
import GuestProfileMobileView from '@/views/guest/mobile/GuestProfileMobileView.vue'
import PublicLoginMobileView from '@/views/public/mobile/PublicLoginMobileView.vue'
import PublicRegisterSocialMobileView from '@/views/public/mobile/PublicRegisterSocialMobileView.vue'
import PublicRegisterManualMobileView from '@/views/public/mobile/PublicRegisterManualMobileView.vue'
import PublicRegisterSuccessMobileView from '@/views/public/mobile/PublicRegisterSuccessMobileView.vue'
import PublicVerifyEmailMobileView from '@/views/public/mobile/PublicVerifyEmailMobileView.vue'
import PublicHostDetailsMobileView from '@/views/public/mobile/PublicHostDetailsMobileView.vue'
import PublicTermsOfServiceMobileView from '@/views/public/mobile/PublicTermsOfServiceMobileView.vue'
import PublicPrivacyPolicyMobileView from '@/views/public/mobile/PublicPrivacyPolicyMobileView.vue'
import HostPlaceholderMobileView from '@/views/host/mobile/HostPlaceholderMobileView.vue'

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '',
      component: LayoutPublicListing,
      redirect: { name: 'publicListingsView' },
    },
    {
      path: '/g',
      children: [
        {
          path: 'listings',
          children: [
            {
              path: '',
              component: LayoutPublicListingGrid,
              children: [
                {
                  path: '',
                  name: 'publicListingsView',
                  component: PublicListingsGridView
                }
              ]
            },
            {
              path: ':uuid([a-f\\d-]{36})/:listingSlug([a-z0-9-]+)?',
              component: LayoutPublicListing,
              children: [
                {
                  path: '',
                  name: 'publicListingsDetailsView',
                  component: PublicListingDetailsView,
                }
              ]
            },
          ],
        },
        {
          path: 'hosts',
          component: LayoutPublicHost,
          children: [
            {
              path: ':uuid([a-f\\d-]{36})',
              name: 'publicHostDetailsView',
              component: PublicHostDetailsView,
            },
          ],
        },
        {
          path: 'register',
          component: LayoutPublicAccount,
          children: [
            {
              path: 'social',
              name: 'publicRegisterSocialView',
              component: PublicRegisterSocialView,
            },
            {
              path: 'manual',
              name: 'publicRegisterManualView',
              component: PublicRegisterManualView,
            },
            {
              path: 'register-success',
              name: 'publicRegisterSuccessView',
              component: PublicRegisterSuccessView,
            },
            {
              path: 'verify-email/:verifyKey(.*)',
              name: 'publicVerifyEmailView',
              component: PublicVerifyEmailView,
            },
          ]
        },
        {
          path: 'login',
          component: LayoutPublicAccount,
          children: [
            {
              path: '',
              name: 'publicLoginView',
              component: PublicLoginView,
            },
          ]
        },
        {
          path: 'profile',
          component: LayoutPublicListing,
          children: [
            {
              path: '',
              name: 'guestProfileView',
              component: GuestProfileView,
            },
          ]
        },
        {
          path: 'contact',
          component: LayoutPublicListing,
          children: [
            {
              path: '',
              name: 'publicContactView',
              component: PublicContactView,
            },
          ]
        },
        {
          path: 'about',
          component: LayoutPublicListing,
          children: [
            {
              path: '',
              name: 'publicAboutView',
              component: PublicAboutView,
            },
          ]
        },
        {
          path: 'terms-of-service',
          component: LayoutPublicListing,
          children: [
            {
              path: '',
              name: 'publicTermsOfServiceView',
              component: PublicTermsOfServiceView,
            },
          ]
        },
        {
          path: 'privacy-policy',
          component: LayoutPublicListing,
          children: [
            {
              path: '',
              name: 'publicPrivacyPolicyView',
              component: PublicPrivacyPolicyView,
            },
          ]
        },
      ]
    },
    {
      path: '/gm',
      children: [
        {
          path: 'listings',
          children: [
            {
              path: '',
              component: LayoutPublicListingMobile,
              children: [
                {
                  path: '',
                  name: 'publicListingsMobileView',
                  component: PublicListingsMobileView
                }
              ]
            }
          ],
        },
        {
          path: 'hosts',
          component: LayoutPublicListingMobile,
          children: [
            {
              path: ':uuid([a-f\\d-]{36})',
              name: 'publicHostDetailsMobileView',
              component: PublicHostDetailsMobileView,
            },
          ],
        },
        {
          path: ':uuid([a-f\\d-]{36})/:listingSlug([a-z0-9-]+)',
          component: LayoutPublicListingMobile,
          children: [
            {
              path: '',
              name: 'publicListingDetailsMobileView',
              component: PublicListingDetailsMobileView,
            }
          ]
        },
        {
          path: 'about',
          component: LayoutPublicListingMobile,
          children: [
            {
              path: '',
              name: 'publicAboutMobileView',
              component: PublicAboutMobileView,
            },
          ]
        },
        {
          path: 'terms-of-service',
          component: LayoutPublicListingMobile,
          children: [
            {
              path: '',
              name: 'publicTermsOfServiceMobileView',
              component: PublicTermsOfServiceMobileView,
            },
          ]
        },
        {
          path: 'privacy-policy',
          component: LayoutPublicListingMobile,
          children: [
            {
              path: '',
              name: 'publicPrivacyPolicyMobileView',
              component: PublicPrivacyPolicyMobileView,
            },
          ]
        },
        {
          path: 'contact',
          component: LayoutPublicListingMobile,
          children: [
            {
              path: '',
              name: 'publicContactMobileView',
              component: PublicContactMobileView,
            },
          ]
        },
        {
          path: 'profile',
          component: LayoutPublicListingMobile,
          children: [
            {
              path: '',
              name: 'guestProfileMobileView',
              component: GuestProfileMobileView,
            },
          ]
        },
        {
          path: 'login',
          component: LayoutPublicListingMobile,
          children: [
            {
              path: '',
              name: 'publicLoginMobileView',
              component: PublicLoginMobileView,
            },
          ]
        },
        {
          path: 'register',
          component: LayoutPublicListingMobile,
          children: [
            {
              path: 'social',
              name: 'publicRegisterSocialMobileView',
              component: PublicRegisterSocialMobileView,
            },
            {
              path: 'manual',
              name: 'publicRegisterManualMobileView',
              component: PublicRegisterManualMobileView,
            },
            {
              path: 'register-success',
              name: 'publicRegisterSuccessMobileView',
              component: PublicRegisterSuccessMobileView,
            },
            {
              path: 'verify-email/:verifyKey(.*)',
              name: 'publicVerifyEmailMobileView',
              component: PublicVerifyEmailMobileView,
            },
          ]
        },
      ],
    },
    {
      path: '/h',
      children: [
        {
          path: 'listings',
          component: LayoutHost,
          children: [
            {
              path: '',
              name: 'hostListingsView',
              component: HostListingsView,
            },
            {
              path: 'new',
              name: 'hostNewListingView',
              component: HostNewListingView,
            },
            {
              path: ':uuid([a-f\\d-]{36})',
              name: 'hostListingsDetailsView',
              component: HostListingsDetailsView,
            },
          ],
        },
        {
          path: 'register',
          component: LayoutHostAccount,
          children: [
            {
              path: 'manual',
              name: 'hostRegisterView',
              component: HostRegisterView,
            },
            {
              path: 'register-success',
              name: 'hostRegisterSuccessView',
              component: HostRegisterSuccessView,
            },
            {
              path: 'verify-email/:verifyKey(.*)',
              name: 'hostAccountEmailVerifyView',
              component: HostVerifyEmailView,
            },
          ]
        },
        {
          path: 'login',
          component: LayoutHostAccount,
          children: [
            {
              path: '',
              name: 'hostLoginView',
              component: HostLoginView,
            },
          ]
        },
        {
          path: 'profile',
          component: LayoutHost,
          children: [
            {
              path: '',
              name: 'hostProfileView',
              component: HostProfileView,
            },
          ],
        },
        {
          path: 'help',
          component: LayoutHost,
          children: [
            {
              path: '',
              name: 'hostHelpView',
              component: HostHelpView,
            },
          ],
        },
      ],
    },
    {
      path: '/hm',
      children: [
        {
          path: '',
          component: LayoutHostMobile,
          children: [
            {
              path: 'use-desktop',
              name: 'hostPlaceholderMobileView',
              component: HostPlaceholderMobileView,
            },
          ],
        },
      ],
    }
  ]
})

export default router
