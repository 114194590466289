<template>
  <q-card bordered class="q-mx-sm q-mb-md" flat>
    <div class="row">
      <div class="col">
        <div v-if="listing.images.length > 0" class="q-pa-xs">
          <q-card class="q-pa-xs" flat>
            <q-carousel arrows swipeable animated v-model="slide" infinite height="13em">
              <q-carousel-slide v-for="(image, index) in listing.images" :key="image.uuid" :name="index"
                class="no-padding">
                <q-inner-loading :showing="!loadedImages[index]" v-if="!someImagesLoaded">
                  <q-spinner-grid size="50px" color="blue-10" />
                </q-inner-loading>
                <img :src="image.signed_url" @load="handleImageLoad(index)" @error="handleImageError(index)"
                  :class="{ 'image-loaded': loadedImages[index] }" />
              </q-carousel-slide>
            </q-carousel>
          </q-card>
        </div>
        <div v-else class="row text-h5 text-grey text-center" style="min-height: 200px; background-color: #efefef;">
          <div class="col self-center">
            No images to show.
          </div>
        </div>
      </div>
    </div>

    <q-chip v-if="showNewOpsChip(listing)" class="q-ml-md" dense color="red" text-color="white" icon="warning"
      label="New" />

    <div class="q-mx-md q-mt-sm q-mb-xs">
      <div class="row">
        <div class="col-10">
          <div>
            <router-link style="text-decoration: none; color: inherit;"
              class="text-primary rb-public-listing-card__title"
              :to="{ name: 'publicListingsDetailsView', params: { uuid: listing.uuid, listingSlug: listing.slug } }">
              <span class="">{{ truncate(listing.title, 55) }}</span>
            </router-link>
            <span class="q-ml-sm text-grey-9 rb-public-listing-card__subtitle">
              <template v-if="listing.sub_title.length">
                {{ truncate(listing.sub_title, 90) }}
              </template>
              <template v-else>
                {{ truncate(listing.title, 90) }}
              </template>
            </span>
          </div>
        </div>

        <div class="col">
          <div class="row text-center">
            <div class="col">
              <router-link :to="{ name: 'publicHostDetailsView', params: { uuid: listing.company?.uuid } }">
                <q-img :src="listing.company.logo" />
              </router-link>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <PropertyTypeChip :property-type="listing.property_type" />
        <BasicInfoChip :text="listing.bedrooms" icon="bed" />
        <BasicInfoChip :text="listing.bathrooms" icon="bathtub" />
        <BasicInfoChip :text="listing.property_sqft" icon="square_foot" />

        <!-- Lets hide the rating until we actually have user reviews to compute ratings.
        <q-chip dense outline color="blue-grey-8" text-color="white" icon="star">
          4.5
        </q-chip> -->
      </div>

      <q-separator class="q-my-sm" />

      <div class="text-body2">
        <ListingPriceChip :amount="listing.price.total_price" />
        <ListingRatingChip :rating-overall="listing.rating_overall" unrated-text="-" />
      </div>
    </div>
  </q-card>
</template>

<script setup>
import { computed, ref } from 'vue'

import { useAuth } from '@/composables/auth'

import { truncate } from '@/utils/string'

import PropertyTypeChip from '@/components/chips/PropertyTypeChip.vue'
import ListingRatingChip from '@/components/chips/ListingRatingChip.vue'
import BasicInfoChip from '@/components/chips/BasicInfoChip.vue'
import ListingPriceChip from '@/components/chips/ListingPriceChip.vue'

const { showNewOpsChip } = useAuth()

defineProps({
  listing: Object
})

const slide = ref(0)
const loadedImages = ref([])

/**
 * Only display the inner loading on initial page load. We don't want to
 * display it during navigating between slides.
 */
const someImagesLoaded = computed(() => {
  return loadedImages.value[0] === true
})

const handleImageLoad = (index) => {
  // Mark image as loaded
  loadedImages.value[index] = true
}

const handleImageError = (index) => {
  // Handle load error if needed
  loadedImages.value[index] = false
  console.error(`Image failed to load at index ${index}`)
}
</script>

<style scoped>
img {
  width: 100%;
  /** Investigate why setting height to 100% causes scrolling */
  height: 96%;
  object-fit: cover;
  opacity: 0;
  transition: opacity 0.3s ease;
}

img.image-loaded {
  opacity: 1;
}
</style>
