<template>
  <q-tab-panel>
    <div class="row">
      <div class="col">
        <div class="row">
          <div class="text-subtitle1 text-grey">
            Location
          </div>
        </div>
        <div class="row">
          <div class="col q-mr-sm">
            <q-select dense ref="cityRef" :rules="CITY_RULES" rounded outlined v-model="city" :options="cities"
              label="Emirate" />

          </div>
          <div class="col">
            <q-select :disable="!city.length" dense ref="neighbourhoodRef" :rules="NEIGHBOURHOOD_RULES" rounded outlined
              v-model="neighbourhood" :options="neighbourhoodOptions" label="Neighbourhood" use-input input-debounce="0"
              @filter="filterNeighbourhoods">
              <template v-slot:no-option>
                <q-item>
                  <q-item-section class="text-grey">
                    No results
                  </q-item-section>
                </q-item>
              </template>
            </q-select>
          </div>
        </div>
        <div class="row">
          <div class="col q-pr-sm">
            <q-input maxlength="100" counter autofocus dense v-model="buildingName"
              hint="Building Name, e.g.: Burj Khalifa" label="Building Name"
              :error-messages="errorMessages?.building_name"
              :error="errorMessages?.building_name?.length > 0 || false" />
          </div>
          <div class="col">
            <q-input maxlength="100" counter autofocus dense v-model="landmarkName"
              hint="Landmark Name, e.g.: Dubai Frame" label="Landmark Name"
              :error-messages="errorMessages?.landmark_name"
              :error="errorMessages?.landmark_name?.length > 0 || false" />
          </div>
        </div>
      </div>
      <!-- <q-separator inset vertical class="q-mx-md" /> -->
      <div class="col q-ml-lg">
        <div class="text-subtitle1 text-grey">
          Coordinates
          <div class="text-caption text-bold">Drag the pin in the Map below, or enter the coordinates manually.</div>
        </div>
        <div class="row">
          <div class="col q-pr-sm">
            <q-input maxlength="9" counter autofocus dense type='number' v-model.number="latitude" filled
              hint="Latitude Coordinate" :error-messages="errorMessages?.latitude"
              :error="errorMessages?.latitude?.length > 0 || false" @blur="updateCoordinates" />
          </div>
          <div class="col">
            <q-input maxlength="9" counter autofocus dense type='number' v-model.number="longitude" filled
              hint="Longitude Coordinate" :error-messages="errorMessages?.longitude"
              :error="errorMessages?.longitude?.length > 0 || false" @blur="updateCoordinates" />
          </div>
        </div>
        <div class="row">
          <div class="col q-pt-md text-right">
            <q-btn class="rb-host-buttons__submit" @click="saveLocation" :disable="updatingLocation"
              :loading="updatingLocation">Save
              Location</q-btn>
          </div>
        </div>
      </div>
    </div>

    <q-separator inset class="q-mt-md q-mb-md" />

    <div class="row" v-if="center.lat && center.lng">
      <GoogleMap :api-key="apiKey" style="width: 100%; height: 500px" :center="center" :zoom="12">
        <Marker :options="{ position: center, draggable: true }" @dragend="dragEnd">
        </Marker>
      </GoogleMap>
    </div>

  </q-tab-panel>
</template>


<script setup>
import { onMounted, ref, watch } from 'vue'
import { useRoute } from 'vue-router'

import { GoogleMap, Marker } from "vue3-google-map"

import { useHostListings } from '@/composables/hostListings'
import { useMeta } from '@/composables/meta'
import { CITY_RULES, NEIGHBOURHOOD_RULES, UNITED_ARAB_EMIRATES } from '@/constants/listing'
import { DEFAULT_COORDINATES } from '@/constants/location'
import { notifyHostUpdateValueError, notifyHostUpdateValueSuccess } from '@/notifications/events'

const route = useRoute()
const {
  fetchHostListingDetails,
  fetchListingLocation,
  hostListingDetails,
  updatingLocation,
  updateLocation,
} = useHostListings()

const apiKey = import.meta.env.VITE_ROOMBEE_GOOGLE_MAPS_API_KEY
const errorMessages = ref({})

// The initial options has to be a non reactive array
// otherwise the filtering won't work on `.value`
let neighbourhoodOptionsByCity = []
const neighbourhoodOptions = ref(neighbourhoodOptionsByCity)

const center = ref({})

const city = ref('initial')
const neighbourhood = ref(null)
const latitude = ref()
const longitude = ref()
const buildingName = ref('')
const landmarkName = ref('')

const {
  cities,
  fetchCities,
  fetchNeighbourhoods,
} = useMeta()

const locationFormErrors = ref({})



onMounted(async () => {
  await fetchCities()
  await fetchListingLocation(route.params.uuid)
  await fetchHostListingDetails(route.params.uuid)

  city.value = hostListingDetails.value.location.city
  latitude.value = Number(hostListingDetails.value.location.latitude) || DEFAULT_COORDINATES.lat
  longitude.value = Number(hostListingDetails.value.location.longitude) || DEFAULT_COORDINATES.lng
  center.value = { lat: latitude.value, lng: longitude.value }
  buildingName.value = hostListingDetails.value.location.building_name
  landmarkName.value = hostListingDetails.value.location.landmark_name

  if (hostListingDetails.value.location.city) {
    const response = await fetchNeighbourhoods(hostListingDetails.value.location.city)
    if (response.status === 200) {
      neighbourhoodOptionsByCity = response.data
      neighbourhood.value = hostListingDetails.value.location.neighbourhood
    }
  }
})


const filterNeighbourhoods = (val, update) => {
  if (val === '') {
    update(() => {
      neighbourhoodOptions.value = neighbourhoodOptionsByCity
    })
    return
  }

  update(() => {
    const needle = val.toLowerCase()
    neighbourhoodOptions.value = neighbourhoodOptionsByCity.filter(
      v => v.toLowerCase().indexOf(needle) > -1
    )
  })
}

watch(
  city, async (newVal, oldVal) => {
    if (oldVal === 'initial') {
      return
    }

    if (newVal != oldVal) {
      const response = await fetchNeighbourhoods(newVal)
      if (response.status === 200) {
        neighbourhoodOptionsByCity = response.data
        neighbourhood.value = ''
      }
    }

  }
)

const dragEnd = (e) => {
  latitude.value = Number(e.latLng.lat().toString().slice(0, 9))
  longitude.value = Number(e.latLng.lng().toString().slice(0, 9))
}

/**
 * Max len for coords is 9 digits + 1 for the period.
 */
const updateCoordinates = () => {
  if (!latitude.value || !longitude.value) {
    return
  }

  if (latitude.value) {
    latitude.value = Number(latitude.value.toString().slice(0, 9))
  }

  if (longitude.value) {
    longitude.value = Number(longitude.value.toString().slice(0, 9))
  }

  center.value = { lat: latitude.value, lng: longitude.value }
}

const saveLocation = async () => {
  locationFormErrors.value = {}

  const form = {
    country: UNITED_ARAB_EMIRATES,
    city: city.value,
    state: city.value,
    neighbourhood: neighbourhood.value,
    latitude: latitude.value,
    longitude: longitude.value,
    building_name: buildingName.value,
    landmark_name: landmarkName.value,
  }

  const result = await updateLocation(route.params.uuid, form)

  if (result.status === 201) {
    notifyHostUpdateValueSuccess('Successfully updated the Location.')
    await fetchHostListingDetails(route.params.uuid)
  } else {
    const error = `Error while updating the Location!`
    notifyHostUpdateValueError(error)

    const errors = {}
    for (const key of Object.keys(result.data)) {
      if (Array.isArray(result.data[key])) {
        errors[key] = result.data[key]
      } else {
        const _errors = []
        for (const innerKey of Object.keys(result.data[key])) {
          _errors.push(
            `${innerKey} -> ${result.data[key][innerKey].join(' ')}`
          )
        }
        errors[key] = _errors
      }
    }

    locationFormErrors.value = errors
  }
}
</script>
