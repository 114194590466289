<template>
  <q-tab-panel name="reviews" class="no-padding">
    <div class="flex justify-center text-grey-8 q-mt-sm q-mb-sm">
      Found {{ pageReviewsListing.pageTotal }}
      <template v-if="pageReviewsListing.pageTotal === 1">
        Review
      </template>
      <template v-else>
        Reviews
      </template>
    </div>

    <MyReviewListingListItemMobile v-for="review in reviewsListing" :key="review.uuid" :created-on="review.created_on"
      :subject="review.subject" :review="review" :review-uuid="review.uuid" />

    <div class="flex flex-center" v-if="pageReviewsListing.pageTotal">
      <q-pagination v-model="pageReviewsListing.pageCurrent" color="blue" :max="pageReviewsListing.pageDisplayMax"
        :max-pages="pageReviewsListing.pageTotal" boundary-numbers />
    </div>
  </q-tab-panel>
</template>

<script setup>
import { onMounted } from 'vue'

import { useGuestProfile } from '@/composables/guestProfile'

import MyReviewListingListItemMobile from '@/components/cards/mobile/MyReviewListingListItemMobile.vue'

const {
  fetchReviewsListing,
  reviewsListing,
  pageReviewsListing,
} = useGuestProfile()

onMounted(async () => {
  await fetchReviewsListing()
})
</script>
