<template>
  <HFaceBookLogin v-slot="fbLogin" :app-id="appId" @onSuccess="handleOnSuccess" @onFailure="handleOnError"
    scope="email,public_profile" fields="id,name,email,first_name,last_name,birthday">
    <q-btn :disabled="loggingIn" :loading="loggingIn" @click="fbLogin.initFBLogin">
      <q-icon class="q-mr-sm">
        <img src="@/assets/facebookIcon.svg" />
      </q-icon>
      {{ prefix }} with Facebook
    </q-btn>
  </HFaceBookLogin>
</template>

<script setup>
import { ref } from 'vue'
import { useRouter } from 'vue-router'

import { notifySorry } from '@/notifications/events'

const router = useRouter()

const appId = import.meta.env.VITE_FACEBOOK_OAUTH_CLIENT_ID

import { useAuth } from '@/composables/auth'

import { HFaceBookLogin } from '@healerlab/vue3-facebook-login';

const loggingIn = ref(false)

defineProps({
  prefix: {
    type: String,
    default: 'Sign Up'
  }
})
const { fetchUser, setToken, verifyOauthTokenFacebook } = useAuth()

const showError = () => {
  loggingIn.value = false
  notifySorry('Sorry, there was a problem signing in with Facebook. Please try again or contact support@roomb.io for assistance.')
}

const handleOnSuccess = async (response) => {
  loggingIn.value = true

  const resp = await verifyOauthTokenFacebook(
    response.authResponse.accessToken,
    response.authInfo.email,
  )
  if (resp.status !== 200) {
    showError()
    return
  }

  setToken(resp.data.key)
  const result = await fetchUser()
  if (result.status !== 200) {
    showError()
    return
  }

  // Finally redirect to logged in view
  // TODO-FEATURE: re-direct to where user left-off
  router.push({ name: 'publicListingsView' })

  loggingIn.value = false
}

const handleOnError = (errorResponse) => {
  // TODO-FEATURE: test for this scenario and handle accordingly
  loggingIn.value = false
  showError()
}
</script>

<style scoped lang="scss">
.fb-button {
  display: inline-block;
  margin: 10px 0 10 0;
  color: white;
  background-color: #1967d2;
  border-radius: 8px;
  padding: 16px;
  cursor: pointer;
}
</style>
