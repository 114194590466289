<template>
  <q-tab-panel>
    <HostDetailsListingReviewItem :review="review" v-for="review in reviews" :key="review.uuid" />
    <div class="q-pa-lg flex flex-center">
      <q-pagination v-model="reviewsPage.pageCurrent" color="primary" :max="reviewsPage.pageLast"
        :max-pages="reviewsPage.pageMaxLinks" boundary-numbers />
    </div>
    <FlagReviewListingDialog />
  </q-tab-panel>
</template>


<script setup>
import { onMounted } from 'vue'

import { useRoute } from 'vue-router'

import { usePublicHost } from '@/composables/publicHost'

import HostDetailsListingReviewItem from '@/components/cards/HostDetailsListingReviewItem.vue';
import FlagReviewListingDialog from '@/components/dialogs/FlagReviewListingDialog.vue'

const route = useRoute()

const {
  reviews,
  reviewsPage,
  tab,
} = usePublicHost()

onMounted(async () => {
  tab.value = 'reviews'
  window.history.replaceState(null, '', `${route.path}?tab=reviews`)
})
</script>
