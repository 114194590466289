<template>
  <q-tab-panel>
    <HostDetailsListingReviewItemMobile :review="review" v-for="review in reviews" :key="review.uuid" />
    <div class="flex flex-center" v-if="reviews.length">
      <q-pagination v-model="reviewsPage.pageCurrent" color="primary" :max="reviewsPage.pageLast"
        :max-pages="reviewsPage.pageMaxLinks" boundary-numbers />
    </div>
    <FlagReviewListingDialog />
  </q-tab-panel>
</template>


<script setup>
import { onMounted } from 'vue'

import { useRoute } from 'vue-router'

import { usePublicHost } from '@/composables/publicHost'

import HostDetailsListingReviewItemMobile from '@/components/cards/mobile/HostDetailsListingReviewItemMobile.vue'
import FlagReviewListingDialog from '@/components/dialogs/FlagReviewListingDialog.vue'


const route = useRoute()

const {
  reviews,
  reviewsPage,
  tab,
} = usePublicHost()

onMounted(async () => {
  tab.value = 'reviews'
  window.history.replaceState(null, '', `${route.path}?tab=reviews`)
})
</script>
