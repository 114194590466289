import { storeToRefs } from 'pinia'

import { useStoreLicense } from '@/stores/storeLicense'

export function useLicense() {
  const storeLicense = useStoreLicense()

  const {
    license,
    fetchingLicense,
    plans,
    fetchingPlans,
  } = storeToRefs(storeLicense)

  const fetchLicense = async (companyUuid) => {
    await storeLicense.fetchLicense(companyUuid)
  }

  const fetchPlans = async () => {
    await storeLicense.fetchPlans()
  }

  return {
    fetchLicense,
    fetchingLicense,
    license,
    fetchPlans,
    plans,
    fetchingPlans,
  }
}
