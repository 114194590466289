<template>
  <q-tab-panel>
    <div class="row">
      <div class="col">
        <div v-if="listingImages?.length > 0">
          <q-card class="q-pa-sm">
            <q-carousel ref="listingImagesRef" arrows swipeable animated v-model="slide" v-model:fullscreen="fullscreen"
              infinite height="27em">
              <q-carousel-slide v-for="(image, index) in listingImages" :key="index" :name="index"
                :img-src="image.signed_url" />

              <template v-slot:control>
                <q-carousel-control position="bottom-right" :offset="[18, 18]">
                  <q-btn push round dense color="white" text-color="primary"
                    :icon="fullscreen ? 'fullscreen_exit' : 'fullscreen'" @click="fullscreen = !fullscreen" />
                </q-carousel-control>
              </template>
            </q-carousel>
          </q-card>
        </div>
        <div v-else class="row text-h5 text-grey text-center" style="min-height: 300px; background-color: #efefef;">
          <div class="col self-center">
            No images to show.
          </div>
        </div>
        <div class="text-center q-mt-sm">
          <span class="text-grey-10">To manage your images, go to the</span>
          <LinkChip text="Images tab" icon="photo" clickable @click="listingDetailsTab = 'images'" />
        </div>
      </div>

      <div class="col-6">
        <q-card-section>
          <div class="q-ml-md">
            <div class="text-subtitle1 text-grey">
              Title
            </div>
            <div class="row">
              <div class="col">
                {{ updateListingForm.title || 'Click to add Title' }}
                <q-popup-edit @before-show="clearExistingPopup('titlePopupRef')" ref="titlePopupRef"
                  v-model="updateListingForm.title" v-slot="scope" persistent>
                  <q-input maxlength="100" counter autofocus autogrow dense v-model="scope.value"
                    :model-value="scope.value" hint="Add a Title for your listing."
                    :error-messages="errorMessages?.title" :error="errorMessages?.title?.length > 0 || false">
                    <template v-slot:after>
                      <q-btn :disable="updatingListing" flat dense color="negatives" icon="cancel"
                        @click="cancel(scope, 'title')" />
                      <q-btn flat dense color="green" icon="check_circle" @click="update(scope, 'title')"
                        :disable="updatingListing" />
                    </template>
                  </q-input>
                </q-popup-edit>
              </div>
            </div>
          </div>

          <q-separator inset class="q-my-sm" />

          <div class="q-ml-md q-mt-sm">
            <div class="text-subtitle1 text-grey">
              Subtitle
            </div>
            <div class="row">
              <div class="col">
                {{ updateListingForm.sub_title || 'Click to add Subtitle' }}
                <q-popup-edit @before-show="clearExistingPopup('subTitlePopupRef')" ref="subTitlePopupRef"
                  v-model="updateListingForm.sub_title" v-slot="scope" persistent>
                  <q-input maxlength="200" counter autofocus autogrow dense v-model="scope.value"
                    :model-value="scope.value" hint="Add a Subtitle for your listing."
                    :error-messages="errorMessages?.sub_title" :error="errorMessages?.sub_title?.length > 0 || false">
                    <template v-slot:after>
                      <q-btn :disable="updatingListing" flat dense color="negatives" icon="cancel"
                        @click="cancel(scope, 'sub_title')" />
                      <q-btn flat dense color="green" icon="check_circle" @click="update(scope, 'sub_title')"
                        :disable="updatingListing" />
                    </template>
                  </q-input>
                </q-popup-edit>
              </div>
            </div>
          </div>

          <q-separator inset class="q-my-sm" />

          <div class="q-ml-md q-mt-sm">
            <div class="text-subtitle1 text-grey">
              Property Type
            </div>
            <div class="row">
              <div class="col">
                {{ updateListingForm.property_type || 'Select a Property Type' }}
                <q-popup-edit @before-show="clearExistingPopup('propertyTypePopupRef')" ref="propertyTypePopupRef"
                  v-model="updateListingForm.property_type" v-slot="scope" persistent>

                  <q-select dense outlined v-model="scope.value" :model-value="scope.value" :options="propertyTypes"
                    label="Property Type">
                    <template v-slot:after>
                      <q-btn :disable="updatingListing" flat dense color="negatives" icon="cancel"
                        @click="cancel(scope, 'property_type')" />
                      <q-btn flat dense color="green" icon="check_circle" @click="update(scope, 'property_type')"
                        :disable="updatingListing" />
                    </template>
                  </q-select>
                </q-popup-edit>
              </div>
            </div>
          </div>

          <q-separator inset class="q-my-sm" />

          <div class="row q-ml-md q-mt-sm">
            <div class="col">
              <div class="text-subtitle1 text-grey">
                Bedrooms
              </div>
              <div class="row">
                <div class="col" style="min-width: 230px !important;">
                  {{ updateListingForm.bedrooms || 'Bedrooms' }}
                  <q-popup-edit @before-show=" clearExistingPopup('bedroomsPopupRef')" ref="bedroomsPopupRef"
                    v-model="updateListingForm.bedrooms" v-slot="scope" persistent>

                    <q-select dense outlined v-model="scope.value" :model-value="scope.value" :options="BEDROOM_OPTIONS"
                      label="Bedrooms">
                      <template v-slot:after>
                        <q-btn :disable="updatingListing" flat dense color="negatives" icon="cancel"
                          @click="cancel(scope, 'bedrooms')" />
                        <q-btn flat dense color="green" icon="check_circle" @click="update(scope, 'bedrooms')"
                          :disable="updatingListing" />
                      </template>
                    </q-select>
                  </q-popup-edit>
                </div>
              </div>
            </div>

            <div class="col">
              <div class="text-subtitle1 text-grey">
                Bathrooms
              </div>
              <div class="row">
                <div class="col" style="min-width: 230px !important;">
                  {{ updateListingForm.bathrooms || 'Bathrooms' }}
                  <q-popup-edit @before-show="clearExistingPopup('bathroomsPopupRef')" ref="bathroomsPopupRef"
                    v-model="updateListingForm.bathrooms" v-slot="scope" persistent>

                    <q-select dense outlined v-model="scope.value" :model-value="scope.value"
                      :options="BATHROOM_OPTIONS" label="Bathrooms">
                      <template v-slot:after>
                        <q-btn :disable="updatingListing" flat dense color="negatives" icon="cancel"
                          @click="cancel(scope, 'bathrooms')" />
                        <q-btn flat dense color="green" icon="check_circle" @click="update(scope, 'bathrooms')"
                          :disable="updatingListing" />
                      </template>
                    </q-select>
                  </q-popup-edit>
                </div>
              </div>
            </div>
          </div>



          <q-card class="q-ma-sm q-pa-sm q-mt-lg">
            <div class="row text-center">
              <div class="col">
                <div class="text-h6 text-grey">
                  Listing Status
                </div>
                <div class="row justify-center q-mb-sm">
                  Listing is currently&nbsp; <b>{{ hostListingDetails.is_active ? 'active' : 'in-active' }}</b>
                </div>
                <div class="row justify-center">
                  <q-btn @click="_updateListingState" :disable="updatingListingState" :loading="updatingListingState"
                    size="sm"
                    :class="hostListingDetails.is_active ? 'rb-host-buttons__deactivate' : 'rb-host-buttons__activate'">{{
                      hostListingDetails.is_active ? 'Deactivate Listing'
                        : 'Activate Listing'
                    }}</q-btn>
                </div>
              </div>

              <q-separator vertical class="q-mx-md" />

              <div class="col self">
                <div class="text-h6 text-grey">
                  Delete Listing
                </div>
                <div class="row justify-center q-mb-sm">
                  Deletion is permanent!
                </div>
                <div class="row justify-center">
                  <q-btn @click="_showDeleteListingDialog()" :disable="isDeletingListing" :loading="isDeletingListing"
                    size="sm" color="red-10">Delete Listing</q-btn>
                </div>
              </div>
            </div>
          </q-card>
          <div class="text-grey-10 text-center">
            View your
            <LinkChip text="Public listing" icon="open_in_browser" clickable @click="goToListing" />
          </div>
        </q-card-section>
      </div>
    </div>

    <ConfirmDeleteListingDialog />
  </q-tab-panel>
</template>


<script setup>
import { onBeforeMount, onMounted, onUnmounted, ref } from 'vue'

import { useRoute, useRouter } from 'vue-router'

import { useImages } from '@/composables/images'
import { useHostListings } from '@/composables/hostListings'
import { useMeta } from '@/composables/meta'

import ConfirmDeleteListingDialog from '@/components/dialogs/ConfirmDeleteListingDialog.vue'
import LinkChip from '@/components/chips/LinkChip.vue'
import { notifyHostUpdateValueError, notifyHostUpdateValueSuccess } from '@/notifications/events'

import {
  BATHROOM_OPTIONS,
  BEDROOM_OPTIONS,
} from '@/constants/listing.js'

const {
  fetchPropertyTypes,
  propertyTypes,
} = useMeta()

// To handle keyboard events to control the carousel
const listingImagesRef = ref(null)
let keyboardListener

onBeforeMount(() => {
  keyboardListener = (event) => {
    switch (event.key) {
      case 'Enter':
        fullscreen.value = true
        break
      case 'ArrowRight':
        listingImagesRef.value.next()
        break
      case 'ArrowLeft':
        listingImagesRef.value.previous()
        break
      case 'Escape':
        listingImagesRef.value.exitFullscreen()
        break
    }
  }
  window.addEventListener('keyup', keyboardListener)
})

onUnmounted(() => {
  window.removeEventListener('keyup', keyboardListener)
})

const { fetchListingImages, listingImages } = useImages()
const route = useRoute()
const router = useRouter()

const errorMessages = ref({})

const friendlyFieldNames = {
  bedrooms: 'Bedrooms',
  bathrooms: 'Bathrooms',
  sub_title: 'Subtitle',
  title: 'Title',
  property_type: 'Property Type',
}

const {
  fetchHostListingDetails,
  hostListingDetails,
  updateListingForm,
  updateListingState,
  updatingListing,
  updatingListingState,
  updateListing,
  listingDetailsTab,
  showDeleteListingDialog,
  isDeletingListing,
  listingToDelete,
} = useHostListings()

onMounted(async () => {
  await fetchPropertyTypes()
  await fetchHostListingDetails(route.params.uuid)
  await fetchListingImages(route.params.uuid)
  updateListingForm.value.sub_title = hostListingDetails.value.sub_title
  updateListingForm.value.title = hostListingDetails.value.title
  updateListingForm.value.property_type = hostListingDetails.value.property_type
  updateListingForm.value.bedrooms = hostListingDetails.value.bedrooms
  updateListingForm.value.bathrooms = hostListingDetails.value.bathrooms
})

const titlePopupRef = ref(null)
const subTitlePopupRef = ref(null)
const propertyTypePopupRef = ref(null)
const bedroomsPopupRef = ref(null)
const bathroomsPopupRef = ref(null)
const slide = ref(0)
const fullscreen = ref(false)

const refs = {
  'titlePopupRef': titlePopupRef,
  'subTitlePopupRef': subTitlePopupRef,
  'propertyTypePopupRef': propertyTypePopupRef,
  'bedroomsPopupRef': bedroomsPopupRef,
  'bathroomsPopupRef': bathroomsPopupRef,
}

const clearExistingPopup = (exceptRef) => {
  for (const refName in refs) {
    if (refName === exceptRef) {
      continue
    }
    refs[refName].value.hide()
  }
}

const cancel = (scope, field) => {
  // Set it back to the original value
  updateListingForm.value[field] = hostListingDetails.value[field]
  scope.cancel()
}

const update = async (scope, field) => {
  const form = {}
  form[field] = scope.value

  errorMessages.value = {}
  const result = await updateListing(route.params.uuid, form)

  if (result.status === 400) {
    const error = `Error while updating ${friendlyFieldNames[field]}: ${result.data[field].join(' ')}`
    notifyHostUpdateValueError(error)
  } else {
    scope.set()
    errorMessages.value = {}
    notifyHostUpdateValueSuccess(`Successfully updated ${friendlyFieldNames[field]} to ${scope.value}.`)
    await fetchHostListingDetails(route.params.uuid)
  }
}

const goToListing = () => {
  const listingRoute = router.resolve({ name: 'publicListingsDetailsView', params: { uuid: route.params.uuid, listingSlug: 'view' } });
  window.open(listingRoute.href, "_blank")
}

const _updateListingState = async () => {
  const nextState = !hostListingDetails.value.is_active
  const form = { is_active: nextState }

  const result = await updateListingState(route.params.uuid, form)

  if (result.status === 400) {
    const reason = result.data['is_active'][0]
    notifyHostUpdateValueError(reason)
  } else {
    const state = nextState ? 'activated' : 'deactivated'
    notifyHostUpdateValueSuccess(`Successfully ${state} the listing.`)
    await fetchHostListingDetails(route.params.uuid)
  }
}

const _showDeleteListingDialog = () => {
  listingToDelete.value = hostListingDetails
  showDeleteListingDialog.value = true
}
</script>
