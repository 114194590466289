import { storeToRefs } from 'pinia'

import { useStoreOpsListings } from '@/stores/storeOpsListings'

export function useOpsListings() {
  const storeOpsListings = useStoreOpsListings()
  const {
    opsUpdateListingForm,
    opsUpdatingListing,
    opsUpdateLocationForm,
    opsUpdatingLocation,
  } = storeToRefs(storeOpsListings)

  const opsUpdateListing = async (listingUuid) => {
    return await storeOpsListings.updateListing(listingUuid)
  }

  const opsUpdateLocation = async (form, listingUuid) => {
    return await storeOpsListings.updateLocation(form, listingUuid)
  }

  return {
    opsUpdateListing,
    opsUpdateLocation,
    opsUpdateListingForm,
    opsUpdatingListing,
    opsUpdateLocationForm,
    opsUpdatingLocation,
  }
}
