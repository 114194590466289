import { watch } from 'vue'
import { storeToRefs } from 'pinia'
import { useRoute } from 'vue-router'

import {
  PUBLIC_PAGE_SIZE_LISTINGS,
  PUBLIC_PAGE_SIZE_LISTINGS_DISPLAY_MAX,
} from '@/constants/pagination.js'
import { useStorePublicListings } from '@/stores/storePublicListings'

export function usePublicListings() {
  const route = useRoute()
  const storePublicListings = useStorePublicListings()
  const {
    fetchingPublicListings,
    fetchingPublicListingDetails,
    publicListings,
    publicListingDetails,
    publicListingDetailsSelf,
    publicPage,
    publicListingsSearch,
    publicListingsSearchOrder,
    showSearchOrder,
    publicListingsSearchFilter,
    publicListingsSearchFilterOps,
    showSearchFilter,
  } = storeToRefs(storePublicListings)

  /**
   * Detect when the pagination has changed for Public Listings.
   */
  watch(() => publicPage.value.pageCurrent, async (newVal, oldVal) => {
    if (newVal !== oldVal && !fetchingPublicListings.value) {
      await fetchPublicListings()
    }
  }, { deep: true })

  /**
  * Detect when the Search Text has changed for Public Listings.
  */
  watch(() => publicListingsSearch.value, async (newVal, oldVal) => {
    if (newVal !== oldVal && !fetchingPublicListings.value) {
      await fetchPublicListings()
    }
  }, { deep: true })

  /**
  * Detect when the Search Order has changed for Public Listings.
  */
  watch(() => publicListingsSearchOrder.value, async (newVal, oldVal) => {
    if (newVal !== oldVal && !fetchingPublicListings.value) {
      await fetchPublicListings()
    }
  }, { deep: true })

  const fetchPublicListings = async (resetPagination = false) => {
    if (resetPagination) {
      publicPage.value.pageCurrent = 1
    }
    await storePublicListings.fetchPublicListings(
      PUBLIC_PAGE_SIZE_LISTINGS,
      PUBLIC_PAGE_SIZE_LISTINGS_DISPLAY_MAX,
    )
  }

  const fetchPublicListingDetails = async (uuid) => {
    await storePublicListings.fetchPublicListingDetails(uuid)
  }

  const getListingDetailsSelf = async () => {
    return await storePublicListings.getListingDetailsSelf(route.params.uuid)
  }

  return {
    fetchingPublicListings,
    fetchingPublicListingDetails,
    fetchPublicListingDetails,
    fetchPublicListings,
    getListingDetailsSelf,
    publicListings,
    publicListingDetails,
    publicListingDetailsSelf,
    publicPage,
    publicListingsSearch,
    publicListingsSearchOrder,
    showSearchOrder,
    publicListingsSearchFilter,
    publicListingsSearchFilterOps,
    showSearchFilter,
  }
}
