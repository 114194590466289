export const WHATSAPP_URL = 'https://wa.me/'
export const EMAIL_SUBJECT = "I'm interested in renting your property"

export function genContactHostMessage(listingUrl) {
    return `Hi, I found this property on RoomB.io and I'm interested in booking it: ${listingUrl}`
}

export function genContactHostCompanyMessage(companyUrl) {
    return `Hi, I found your company on RoomB.io and I'm interested in having a chat: ${companyUrl}`
}
