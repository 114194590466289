<template>
  <q-card class="q-mt-sm q-px-md" flat>
    <div class="text-h6 text-center text-grey-8">Contact Us</div>
    <div class="text-body1 q-mt-md text-center">
      Use this form to reach out to us or send us an email at {{ supportEmail }}
    </div>

    <q-input class="q-mt-md" ref="nameRef" lazy-rules='ondemand' rounded outlined maxlength="50" counter v-model="name"
      :rules="nameRules" label="Enter your Name" hint="So that we can address you." dense />

    <q-input class="q-mt-md" ref="emailRef" lazy-rules='ondemand' :rules="emailRules" rounded outlined maxlength="100"
      counter v-model="email" label="Enter your Email Address" hint="If you'd like us to reply to you." dense />

    <q-input class="q-mt-md" lazy-rules='ondemand' ref="messageRef" :rules="messageRules"
      :input-style="{ minHeight: '200px' }" type="textarea" outlined maxlength="1000" counter dense v-model="message"
      label="Enter your message." hint="What's on your mind?">
    </q-input>

    <q-card-section v-if="Object.keys(errors).length">
      <div class="text-center rb-public-contact__title">There were some errors</div>
      <div class="text-center" v-for="(messages, name, index) in errors" :key="index">
        <span class="text-body1 text-grey-9 text-weight-bold">
          {{ name }}:
        </span>
        <span class="text-body1">
          {{ messages.join(' ') }}
        </span>
      </div>
    </q-card-section>

    <div class="q-mt-md text-center">
      <q-btn @click="onSendMessage" :disable="sendingMessage" :loading="sendingMessage">Send
        Message</q-btn>
    </div>
  </q-card>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import { useQuasar } from 'quasar'
import { useRouter } from 'vue-router'

import { useContact } from '@/composables/contact'
import { notifyThankYou } from '@/notifications/events'

const $q = useQuasar()
const router = useRouter()

onMounted(async () => {
  // If user is on desktop, redirect to desktop view.
  if ($q.platform.is.desktop) {
    router.push({ name: 'publicContactView' })
  }
})

const {
  sendingMessage,
  sendMessage,
} = useContact()

const supportEmail = import.meta.env.VITE_ROOMBEE_SUPPORT_EMAIL
const nameRules = [val => (val && val.length > 0) || 'Please enter your name']
const emailRules = [val => (val && val.length > 0) || 'Please enter your email']
const messageRules = [val => (val && val.length >= 20) || 'Please enter your message']

const name = ref('')
const email = ref('')
const message = ref('')

const errors = ref({})
const nameRef = ref(null)
const emailRef = ref(null)
const messageRef = ref(null)

const onSendMessage = async () => {
  nameRef.value.validate()
  emailRef.value.validate()
  messageRef.value.validate()

  if (nameRef.value.hasError || messageRef.value.hasError) {
    return
  }

  const resp = await sendMessage(name.value, email.value, message.value)
  if (resp.status === 201) {
    name.value = ''
    email.value = ''
    message.value = ''
    errors.value = {}
    nameRef.value.resetValidation()
    messageRef.value.resetValidation()
    emailRef.value.resetValidation()
    notifyThankYou("Thanks for reaching out! We've received your message - if you provided an email, we'll get back to you soon.")
  } else if (resp.status === 400) {
    errors.value = resp.data
  }
}

</script>
