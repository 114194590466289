<template>
  <q-tab-panel ref="reviewItemsTabPanelRef">
    <div class="row">
      <div class="col-auto q-mr-sm text-center self-center">
        <q-btn :disable="reviewSelf.review_exists === true" @click="handleCreateReview" no-caps color="black" outline
          class="text-caption">
          Create Review
          <q-tooltip v-if="reviewSelf.review_exists === true">
            You have already created a review for this listing.
          </q-tooltip>
        </q-btn>
      </div>
      <div class="col-8">
        <q-input dense v-model="reviewSearch" debounce="500" filled placeholder="Search Reviews">
          <template v-slot:prepend>
            <q-icon name="search" />
          </template>

          <template v-slot:append>
            <q-icon name="close" @click="reviewSearch = ''" class="cursor-pointer" />
          </template>
        </q-input>
      </div>
      <div class="col q-ml-sm">
        <q-select dense color=" teal" filled v-model="reviewSearchOrder" :options="REVIEW_SEARCH_ORDER_OPTIONS">
          <template v-slot:prepend>
            <q-icon name="swap_vert" />
          </template>
        </q-select>
      </div>
    </div>

    <div class="text-grey text-subtitle2 text-center">
      Found {{ reviewsPage.pageTotal }} reviews.
    </div>

    <div v-for="review in listingReviews" :key="review.uuid">
      <ListingReviewItem :review="review" />
    </div>

    <div class="q-pa-lg flex flex-center">
      <q-pagination v-model="reviewsPage.pageCurrent" color="blue" :max="reviewsPage.pageDisplayMax"
        :max-pages="reviewsPage.pageTotal" boundary-numbers />
    </div>
  </q-tab-panel>
</template>


<script setup>
import { onMounted, ref } from 'vue'

import { useReviews } from '@/composables/reviews'
import { useAuth } from '@/composables/auth'
import { notifyLogIn } from '@/notifications/events'

import ListingReviewItem from '@/components/cards/ListingReviewItem.vue'

import { REVIEW_SEARCH_ORDER_OPTIONS } from '@/constants/review.js'

const reviewItemsTabPanelRef = ref(null)

const { token } = useAuth()
const {
  getListingReviewSelf,
  listingReviews,
  reviewsPage,
  reviewSearch,
  reviewSearchOrder,
  showCreateReviewDialog,
  reviewSelf,
} = useReviews()

onMounted(async () => {
  const top = reviewItemsTabPanelRef.value.$el.getBoundingClientRect().top
  // offset the header
  window.scrollTo({ top: top - 50, behavior: 'smooth' })

  if (token.value) {
    await getListingReviewSelf()
  }
})

const handleCreateReview = () => {
  if (!token.value) {
    notifyLogIn()
    return
  }

  showCreateReviewDialog.value = true
}
</script>
