import { defineStore } from 'pinia'

import { client } from '@/services/request'

export const useStoreBookmarks = defineStore('storeBookmarks', {
  state: () => {
    return {
      fetchingBookmarksListing: false,
    }
  },

  actions: {
    async setListingBookmark(uuid) {
      const url = `bookmarks/listing/${uuid}/`
      return await client.authRequest(url, { method: 'POST' })
    },
  },
})
