<template>
  <q-card bordered class="q-mx-sm q-my-sm" flat>
    <div class="row">
      <div class="col">
        <div v-if="listing.images.length > 0">
          <q-card flat square>
            <q-carousel arrows swipeable animated v-model="slide" infinite height="10em">
              <q-carousel-slide v-for="(image, index) in listing.images" :key="image.uuid" :name="index"
                :img-src="image.signed_url" />
            </q-carousel>
          </q-card>
        </div>
        <div v-else class="row text-h5 text-grey text-center" style="min-height: 10em; background-color: #efefef;">
          <div class="col self-center">
            No images to show.
          </div>
        </div>
      </div>
    </div>

    <div class="q-mx-sm q-mt-xs q-mb-xs">
      <div>
        <router-link style="text-decoration: none; color: inherit;" class="text-primary rb-public-listing-card__title"
          :to="{ name: 'publicListingDetailsMobileView', params: { uuid: listing.uuid, listingSlug: listing.slug } }">
          <span class="">{{ truncate(listing.title, 50) }}</span>
        </router-link>
        <span class="q-ml-sm text-grey-9 rb-public-listing-card__subtitle">
          <template v-if="listing.sub_title.length">
            {{ truncate(listing.sub_title, 55) }}
          </template>
          <template v-else>
            {{ truncate(listing.title, 50) }}
          </template>
        </span>
      </div>

      <div class="row">
        <PropertyTypeChip :property-type="listing.property_type" />
        <BasicInfoChip :text="listing.bedrooms" icon="bed" />
        <BasicInfoChip :text="listing.bathrooms" icon="bathtub" />
        <BasicInfoChip :text="listing.property_sqft" icon="square_foot" />
      </div>

      <q-separator class="q-my-xs" />

      <div class="text-body2">
        <ListingPriceChip :amount="listing.price.total_price" />
        <ListingRatingChip :rating-overall="listing.rating_overall" unrated-text="-" />
      </div>
    </div>
  </q-card>
</template>

<script setup>
import { ref } from 'vue'

import { truncate } from '@/utils/string'

import PropertyTypeChip from '@/components/chips/PropertyTypeChip.vue'
import ListingRatingChip from '@/components/chips/ListingRatingChip.vue'
import BasicInfoChip from '@/components/chips/BasicInfoChip.vue'
import ListingPriceChip from '@/components/chips/ListingPriceChip.vue'

defineProps({
  listing: Object
})

const slide = ref(0)
</script>
