<template>
  <div class="q-mt-sm q-mx-md">
    <PrivacyPolicy heading-class="rb-public-terms-of-service__heading_mobile text-center q-mb-md"
      paragraph-class="text-body1 q-mb-md" />
  </div>
</template>

<script setup>
import { onMounted } from 'vue'
import { useQuasar } from 'quasar'
import { useRouter } from 'vue-router'

import PrivacyPolicy from '@/components/textBlocks/PrivacyPolicy.vue'

const $q = useQuasar()
const router = useRouter()

onMounted(async () => {
  // If user is on desktop, redirect to desktop view.
  if ($q.platform.is.desktop) {
    router.push({ name: 'publicPrivacyPolicyView' })
  } else {
    window.scrollTo(0, 0)
  }
})
</script>
