import { storeToRefs } from 'pinia'

import { useStoreFlags } from '@/stores/storeFlags'

export function useFlags() {
  const storeFlags = useStoreFlags()

  const {
    showFlagListingDialog,
    showFlagReviewListingDialog,
    isFlaggingListing,
    isFlaggingReviewListing,
    reviewlistingUuidToFlag,
  } = storeToRefs(storeFlags)

  const flagListing = async (uuid, reason, description) => {
    const form = { reason, description }
    return await storeFlags.flagListing(uuid, form)
  }

  const flagReviewListing = async (uuid, reason, description) => {
    const form = { reason, description }
    return await storeFlags.flagReviewListing(uuid, form)
  }

  return {
    flagListing,
    flagReviewListing,
    showFlagListingDialog,
    showFlagReviewListingDialog,
    isFlaggingListing,
    isFlaggingReviewListing,
    reviewlistingUuidToFlag
  }
}
