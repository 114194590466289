import { storeToRefs } from 'pinia'

import { useStoreMeta } from '@/stores/storeMeta'

export function useMeta() {
  const storeMeta = useStoreMeta()
  const {
    countries,
    cities,
    discountTypes,
    states,
    neighbourhoods,
    propertyTypes,
    propertyAttributeAvailabilityTypes,
    parkingTypes,
    currencyTypes,
    feeTypes,
    taxTypes,
    fetchingCountries,
    fetchingStates,
    fetchingCities,
    fetchingNeighbourhoods,
    fetchingPropertyTypes,
    fetchingPropertyAttributeAvailabilityTypes,
    fetchingParkingTypes,
    fetchingCurrencyTypes,
    fetchingFeeTypes,
    fetchingTaxTypes,
  } = storeToRefs(storeMeta)

  const fetchCountries = async () => {
    await storeMeta.fetchCountries()
  }

  const fetchCities = async () => {
    await storeMeta.fetchCities()
  }

  const fetchStates = async () => {
    await storeMeta.fetchStates()
  }

  const fetchNeighbourhoods = async (stateName) => {
    return await storeMeta.fetchNeighbourhoods(stateName)
  }

  const fetchPropertyTypes = async () => {
    await storeMeta.fetchPropertyTypes()
  }

  const fetchPropertyAttributeAvailabilityTypes = async () => {
    await storeMeta.fetchPropertyAttributeAvailabilityTypes()
  }

  const fetchParkingTypes = async () => {
    await storeMeta.fetchParkingTypes()
  }

  const fetchCurrencyTypes = async () => {
    await storeMeta.fetchCurrencyTypes()
  }

  const fetchFeeTypes = async () => {
    await storeMeta.fetchFeeTypes()
  }

  const fetchTaxTypes = async () => {
    await storeMeta.fetchTaxTypes()
  }

  const fetchDiscountTypes = async () => {
    await storeMeta.fetchDiscountTypes()
  }

  return {
    countries,
    cities,
    states,
    neighbourhoods,
    propertyTypes,
    propertyAttributeAvailabilityTypes,
    parkingTypes,
    currencyTypes,
    feeTypes,
    taxTypes,
    discountTypes,
    fetchCountries,
    fetchCities,
    fetchStates,
    fetchNeighbourhoods,
    fetchPropertyTypes,
    fetchPropertyAttributeAvailabilityTypes,
    fetchParkingTypes,
    fetchCurrencyTypes,
    fetchFeeTypes,
    fetchTaxTypes,
    fetchDiscountTypes,
    fetchingCountries,
    fetchingCities,
    fetchingStates,
    fetchingNeighbourhoods,
    fetchingPropertyTypes,
    fetchingPropertyAttributeAvailabilityTypes,
    fetchingParkingTypes,
    fetchingCurrencyTypes,
    fetchingFeeTypes,
    fetchingTaxTypes,
  }
}
