<template>
  <q-step :name="4" prefix="4" title="4. Price" caption="&nbsp;Of Your Property" :done="createListingStep > 4">
    <div class="text-h6">
      Title
    </div>

    <div class="row q-mb-lg">
      <div class="col">
        <q-input ref="titleRef" :rules="titleRules" maxlength="100" counter v-model="createListingForm.title"
          label="Title for your Property" hint="Give your property a title. You can change this later anytime." dense />
      </div>
    </div>

    <div class="text-h6">
      Rental Price
    </div>
    <div class="text-body2">
      Set the initial price per-day for your property.
    </div>

    <div class="text-caption q-mt-sm q-mb-md">
      The price is for <b>one day</b>. Once you've created the listing, you'll be able to
      further refine this with weekly discounts, minimum stay length, etc.
    </div>

    <div class="row">
      <div class="col-7">
        <div class="row q-mb-sm">
          <div class="col q-mr-sm">
            <q-input ref="priceRef" :rules="priceRules" mask="#.##" reverse-fill-mask maxlength="9"
              v-model="createListingForm.price.base" label="Price" placeholder="0.0" prefix="AED"
              hint="Base Price for your Property" dense />
          </div>
          <div class="col">
            <q-input ref="depositRef" :rules="depositRules" mask="#.##" reverse-fill-mask maxlength="9"
              v-model="createListingForm.price.security_deposit" label="Security Deposit"
              :placeholder="createListingForm.price.base" prefix="AED" dense />
          </div>
        </div>

        <div class="row q-mb-sm">
          <div class="col q-mr-sm">
            <q-input ref="feeRef" :rules="feeRules" mask="#.##" reverse-fill-mask maxlength="9"
              v-model="createListingForm.price.fee" label="Fee" :placeholder="createListingForm.price.fee" prefix="AED"
              dense />
          </div>
          <div class="col">
            <q-select ref="feeTypeRef" :rules="feeTypeRules" v-model="createListingForm.price.fee_type"
              :options="feeTypes" label="Fee Type" dense />
          </div>
        </div>

        <div class="row">
          <div class="col-3">
            <q-input ref="taxRef" :rules="taxRules" mask="#.##" reverse-fill-mask maxlength="9"
              v-model="createListingForm.price.taxes" label="Taxes" :placeholder="createListingForm.price.base"
              prefix="AED" dense />
          </div>
        </div>
      </div>

      <q-separator vertical class="q-ml-md" />

      <div class="col">
        <div class="text-h6 text-center">Total Listing Price</div>
        <q-separator inset />
        <div class="row q-mt-sm">
          <div class="col text-right self-center">
            Base Rental Price
          </div>
          <div class="col text-bold text-left q-pl-md">
            {{ createListingForm.price.base || 0 }}
          </div>
        </div>

        <div class="row">
          <div class="col text-right self-center">
            Security Deposit
          </div>
          <div class="col text-bold text-left q-pl-md">
            {{ createListingForm.price.security_deposit }}
          </div>
        </div>

        <div class="row">
          <div class="col text-right self-center">
            Fees
          </div>
          <div class="col text-bold text-left q-pl-md">
            {{ createListingForm.price.fee }}
          </div>
        </div>

        <div class="row">
          <div class="col text-right self-center">
            Taxes
          </div>
          <div class="col text-bold text-left q-pl-md">
            {{ createListingForm.price.taxes }}
          </div>
        </div>

        <div class="row q-mt-md">
          <div class="col text-center">
            <span class="q-pr-sm">Total Listing Price</span>
            <span class="text-h6">{{ totalPrice }}</span><span class="q-ml-sm text-caption">Per Day</span>
          </div>
        </div>
      </div>
    </div>

    <div v-if="Object.keys(createListingFormErrors).length">
      <q-separator class="q-my-md" />
      <div class="q-mb-sm">There were some errors when trying to create your listing:</div>
      <div v-for="(errors, errorType) in createListingFormErrors" :key="errorType">

        <div class="row">
          <q-icon name='warning' color="orange" size="xs" class="q-mr-sm" />
          {{ errorType }}
        </div>
        <div class="row q-ml-md" v-for="error in errors" :key="error">
          <div class="col-auto">
            <q-icon name='error' color="grey" size="xs" class="q-mr-sm" />
          </div>
          <div class="col">
            <div>{{ error }}</div>
          </div>
        </div>

      </div>
    </div>
    <q-stepper-navigation>
      <q-btn :disabled="fetchingListings" :loading="fetchingListings" @click="submitStep4" color="primary"
        label="Finish" />
      <q-btn flat @click="createListingStep = 3" color="secondary" label="Back" class="q-ml-sm" />
      <q-btn flat @click="resetForm" color="red" label="Reset Form" class="q-ml-sm" />
    </q-stepper-navigation>
  </q-step>
</template>

<script setup>
import { computed, onMounted, ref } from 'vue'
import { useRouter } from 'vue-router'

import { useHostListings } from '@/composables/hostListings'
import { useMeta } from '@/composables/meta'

const router = useRouter()

const {
  feeTypes,
  fetchCurrencyTypes,
  fetchFeeTypes,
  fetchTaxTypes,
} = useMeta()
const {
  createListing,
  resetCreateListingForm,
  createListingForm,
  createListingFormErrors,
  createListingStep,
  fetchingListings,
} = useHostListings()

const priceRef = ref(null)
const depositRef = ref(null)
const taxRef = ref(null)
const feeRef = ref(null)
const feeTypeRef = ref(null)
const titleRef = ref(null)

const moneyAmountRegex = /^\d+(?:\.\d{0,2})?$/

const priceRules = [
  val => (moneyAmountRegex.test(val) && parseFloat(val) > 0) || 'Please enter a price'
]
const depositRules = [
  val => (moneyAmountRegex.test(val) && parseFloat(val) >= 0) || 'Please enter a deposit amount'
]
const taxRules = [
  val => (moneyAmountRegex.test(val) && parseFloat(val) >= 0) || 'Please enter a tax amount'
]
const feeRules = [
  val => (moneyAmountRegex.test(val) && parseFloat(val) >= 0) || 'Please enter a fee amount'
]
const feeTypeRules = [
  val => (val && val.length > 0) || 'Please select a Fee Type'
]
const titleRules = [
  val => (val && val.length > 0 && val.length <= 100) || 'Please enter a title for your property.'
]

onMounted(async () => {
  await fetchCurrencyTypes()
  await fetchFeeTypes()
  await fetchTaxTypes()
})

const totalPrice = computed(() => {
  let _totalPrice = 0

  const priceKeys = ['base', 'taxes', 'fee']
  for (const key of priceKeys) {
    let amount = createListingForm.value.price[key]
    if (amount) {
      _totalPrice += parseFloat(amount)
    }
  }

  return _totalPrice.toFixed(2)
})

const resetForm = () => {
  resetCreateListingForm()
}

const submitStep4 = async () => {
  titleRef.value.validate()
  priceRef.value.validate()
  depositRef.value.validate()
  taxRef.value.validate()
  feeRef.value.validate()
  feeTypeRef.value.validate()

  if (
    titleRef.value.hasError ||
    priceRef.value.hasError ||
    depositRef.value.hasError ||
    taxRef.value.hasError ||
    feeRef.value.hasError ||
    feeTypeRef.value.hasError
  ) {
    return
  }

  const response = await createListing()

  if ([200, 201].includes(response.status)) {
    router.push({ name: 'hostListingsDetailsView', params: { uuid: response.data.uuid } })
    // Clear the form
    resetCreateListingForm()
  } else {
    const errors = {}
    for (const key of Object.keys(response.data)) {
      if (key === 'non_field_errors') {
        errors['General'] = response.data.non_field_errors
      } else {
        if (Array.isArray(response.data[key])) {

          errors[key] = response.data[key]
        } else {
          const _errors = []
          for (const innerKey of Object.keys(response.data[key])) {
            _errors.push(
              `${innerKey} -> ${response.data[key][innerKey].join(' ')}`
            )
          }
          errors[key] = _errors
        }
      }
    }

    createListingFormErrors.value = errors
  }
}
</script>
