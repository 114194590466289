<template>
  <q-card bordered class="q-mx-sm q-mb-sm" flat>
    <div class="row">
      <div class="col">
        <div v-if="listing.images.length > 0">
          <q-card flat square>
            <q-carousel arrows swipeable animated v-model="slide" infinite height="15em">
              <q-carousel-slide v-for="(image, index) in listing.images" :key="image.uuid" :name="index"
                :img-src="image.signed_url" />
            </q-carousel>
          </q-card>
        </div>
        <div v-else class="row text-h5 text-grey text-center" style="min-height: 10em; background-color: #efefef;">
          <div class="col self-center">
            No images to show.
          </div>
        </div>
      </div>
    </div>

    <div class="q-mx-sm q-mt-xs q-mb-xs">
      <div class="row">
        <div class="col-10">
          <div v-if="isDeleted" class="q-mx-md">
            <span class="text-grey text-subtitle1 text-weight-bold">
              <s>{{ truncate(listing.title, 40) }}</s>
            </span>
            <div class="text-body1 text-grey-8 q-mt-sm q-mb-xs">
              This listing was deleted!
            </div>
          </div>
          <div v-else>
            <router-link style="text-decoration: none; color: inherit;"
              class="text-primary rb-public-listing-card__title"
              :to="{ name: 'publicListingDetailsMobileView', params: { uuid: listing.uuid, listingSlug: listing.slug } }">
              <span class="">{{ truncate(listing.title, 50) }}</span>
            </router-link>
            <span class="q-ml-sm text-grey-9 rb-public-listing-card__subtitle">
              <template v-if="listing.sub_title.length">
                {{ truncate(listing.sub_title, 55) }}
              </template>
              <template v-else>
                {{ truncate(listing.title, 50) }}
              </template>
            </span>
          </div>
        </div>

        <div class="col">
          <div class="row text-center">
            <div class="col">
              <router-link :to="{ name: 'publicHostDetailsView', params: { uuid: listing.company?.uuid } }">
                <q-img no-spinner :src="listing.company.logo" />
              </router-link>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <PropertyTypeChip :property-type="listing.property_type" />
        <BasicInfoChip :text="listing.bedrooms" icon="bed" />
        <BasicInfoChip :text="listing.bathrooms" icon="bathtub" />
        <BasicInfoChip :text="listing.property_sqft" icon="square_foot" />
      </div>

      <q-separator class="q-my-xs" />

      <div class="text-body2">
        <ListingPriceChip :amount="listing.price.total_price" />
        <ListingRatingChip :rating-overall="listing.rating_overall" unrated-text="-" />
      </div>
    </div>
    <q-separator />
    <div class="flex justify-between q-mx-sm q-my-xs">
      <div class="text-caption text-grey-8 self-center">
        Bookmarked on {{ createdOn }}
      </div>
      <q-btn class="rb-public-profile__delete_bookmark" @click="onDeleteBookmark" size="sm" dense>Delete
        Bookmark</q-btn>
    </div>
  </q-card>
</template>

<script setup>
import { ref } from 'vue'

import { useGuestProfile } from '@/composables/guestProfile'

import PropertyTypeChip from '@/components/chips/PropertyTypeChip.vue'
import ListingRatingChip from '@/components/chips/ListingRatingChip.vue'
import BasicInfoChip from '@/components/chips/BasicInfoChip.vue'
import ListingPriceChip from '@/components/chips/ListingPriceChip.vue'

import { truncate } from '@/utils/string'

const {
  deleteBookmarkListing,
  fetchBookmarksListing,
} = useGuestProfile()

const props = defineProps({
  listing: Object,
  createdOn: String,
  bookmarkUuid: String,
  isDeleted: Boolean,
})

const slide = ref(0)

const onDeleteBookmark = async () => {
  const result = await deleteBookmarkListing(props.bookmarkUuid)
  if (result.status === 204) {
    fetchBookmarksListing()
  }
}
</script>
