import { defineStore } from 'pinia'

import { client } from '@/services/request'

import { refreshPaginationValues } from '@/utils/pagination'
import { REVIEW_SEARCH_ORDER_OPTIONS } from '@/constants/review.js'

export const useStoreReviews = defineStore('storeReviews', {
  state: () => {
    return {
      listingReviews: [],
      reviewsPage: {
        pageCurrent: 1,
        pageLast: 1,
        pageDisplayMax: 1,
        pageTotal: 0,
      },
      // Used to scroll up to the top of the Reviews tab
      // on pagination events.
      reviewsTabRef: null,
      fetchingListingReviews: false,
      // Search
      reviewSearch: '',
      reviewSearchOrder: REVIEW_SEARCH_ORDER_OPTIONS[0],
      // Create review
      creatingReview: false,
      showCreateReviewDialog: false,
      // Update review
      updatingReview: false,
      showUpdateReviewDialog: false,
      selectedReviewForUpdate: {},
      reviewText: '',
      ratingClean: 3,
      ratingLocation: 3,
      ratingHelpful: 3,
      ratingResponsive: 3,
      ratingValue: 3,
      // self/user-specific contextual data related to this review
      reviewSelf: {},
      searchReviewsOrder: '',
      showSearchReviewsOrder: false,
    }
  },

  actions: {
    async fetchListingReviews(uuid, pageSize, pageDisplayMax) {
      const params = new URLSearchParams({
        limit: pageSize,
        offset: (this.reviewsPage.pageCurrent * pageSize) - pageSize,
        order: this.reviewSearchOrder,
        search: this.reviewSearch,
      })

      const url = `reviews/${uuid}/?${params}`
      this.fetchingListingReviews = true
      const response = await client.authOrAnonRequest(url)
      this.fetchingListingReviews = false

      if ([200].includes(response.status)) {
        const data = response.data
        this.listingReviews = data.results
        refreshPaginationValues(this.reviewsPage, data.count, pageSize, pageDisplayMax)
      } else {
        // TODO-FEATURE: Handle unsuccessful responses
      }
    },

    async createListingReview(uuid, form) {
      const url = `reviews/${uuid}/new/`

      this.creatingReview = true
      const result = await client.authRequest(url, { method: 'POST', form })
      this.creatingReview = false

      return result
    },

    async updateListingReview(uuid, form) {
      const url = `reviews/${uuid}/update/`

      this.updatingReview = true
      const result = await client.authRequest(url, { method: 'PATCH', form })
      this.updatingReview = false

      return result
    },

    async getListingReviewSelf(uuid) {
      const url = `reviews/${uuid}/self/`

      const result = await client.authRequest(url, { method: 'GET' })
      if (result.status === 200) {
        this.reviewSelf = result.data
      }
    },
  }
})
